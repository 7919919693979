export const API_URL = process.env.REACT_APP_API_URL;
export const STATIC_URL = process.env.REACT_APP_STATIC_URL;
export const WS_API_URL = process.env.REACT_APP_WS_API_URL;
export const GOOGLE_API_KEY = process.env.REACT_APP_GOOGLE_API_KEY as string;
export const COOKIE_TOKEN_NAME = 'nagalocal_token';
export const COOKIE_REFRESH_TOKEN_NAME = 'nagalocal_refreshtoken';
export const COOKIE_NO_ENTER_PIN_MODAL = 'nagalocal_noenterpin';
export const LOCAL_STORAGE_STORE_NAME = 'nagalocal_store';

export const FIAT_SIGN_AFTER_COMMA = 2;
export const GLOBAL_FEE = 0.005;