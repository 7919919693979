import React from 'react';
import styled from 'styled-components';
import Rating from './Rating';

interface Props {
  className?: string;
  username: string;
  rating: number;
}

const Wrapper = styled.div`
  display: flex;
  align-items: center;
`;

const UsernameWrapper = styled.span`
  display: inline-block;
  margin-right: 8px;
`;

class Username extends React.Component<Props> {
  render() {
    const { username, className, rating } = this.props;
    return (
      <Wrapper className={className}>
        <UsernameWrapper>{username}</UsernameWrapper>
        <Rating rating={rating} />
      </Wrapper>
    );
  }
}

export default Username;