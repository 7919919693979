import { ActionModel } from "../models/ActionModel";
import { USER_SET_PIN_SUCCESS, USER_EMAIL_VERIFY, USER_CURRENT_GET_SUCCESS, USER_GET_SUCCESS, USER_UPDATE_SUCCESS, USER_UPDATE_AVATAR_SUCCESS } from "../actions/user";
import { UserModel } from "../models/UserModel";

export interface UserInitialState {
  currentUser: UserModel | null;
  guestUser: UserModel | null;
  statuses: {
    userSetPinSucceed: boolean;
  }
}

const initialState = {
  currentUser: null,
  guestUser: null,
  statuses: {
    userSetPinSucceed: false,
  }
};

const user = (state: UserInitialState = initialState, action: ActionModel) => {
  switch(action.type) {
    case USER_CURRENT_GET_SUCCESS:
      return {
        ...state,
        currentUser: action.payload.user
      };
    case USER_GET_SUCCESS:
      return {
        ...state,
        guestUser: action.payload.user
      }
    case USER_SET_PIN_SUCCESS:
      return {
        ...state,
        currentUser: {
          ...state.currentUser,
          isPinSet: true
        }
      }
    case USER_EMAIL_VERIFY:
      return {
        ...state,
        currentUser: {
          ...state.currentUser,
          isEmailVerified: true
        }
      };
    case USER_UPDATE_SUCCESS:
      return {
        ...state,
        currentUser: {
          ...state.currentUser,
          ...action.payload
        }
      }
    case USER_UPDATE_AVATAR_SUCCESS:
      return {
        ...state,
        currentUser: {
          ...state.currentUser,
          avatarPath: action.payload.avatarPath
        }
      }
    default: 
      return state;
  }
}

export default user;