import styled from 'styled-components';
import { ADMIN_HEADER_HEIGHT, ADMIN_SIDEBAR_WIDTH } from '../../constants/admin';

const AdminContentWrapper = styled.div`
  display: flex;
  min-height: 100vh;
  padding-top: ${ADMIN_HEADER_HEIGHT};
  padding-left: ${ADMIN_SIDEBAR_WIDTH};
`;

export default AdminContentWrapper;