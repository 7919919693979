import React from 'react';
import styled from 'styled-components';
import { withNamespaces, WithNamespaces } from 'react-i18next';
import safeIcon from '../../images/pages/home/safe_logo.svg';
import simpleIcon from '../../images/pages/home/simple_logo.svg';
import sendingMoneyIcon from '../../images/pages/home/sending_money_logo.svg';
import screens from '../../constants/screens';

interface Props {
  isUserLoggedIn: boolean;
}

const Container = styled.div`
  display: flex;
  justify-content: center;
  padding: ${({isUserLoggedIn}: any) => isUserLoggedIn ? '55px 20px' : '0 20px 55px'};
` as any;

const List = styled.ul`
  max-width: 965px;
  margin: 0 -20px;
  width: 100%;
  ${screens.mobile} {
    margin: 0;
  }
`;

const Item = styled.li`
  display: inline-flex;
  flex-direction: column;
  justify-content: flex-start;
  flex-grow: 1;
  max-width: 33.333%;
  width: 33.333%;
  padding: 0 20px;
  ${screens.mobile} {
    max-width: 100%;
    width: 100%;
    text-align: center;
    padding: 0;
    &:not(:last-child) {
      margin-bottom: 50px;
    }
  }
`;

const IconWrapper = styled.div`
  min-height: 124px;
  margin-bottom: 30px;
  ${screens.mobile} {
    margin-bottom: 20px;
  }
`;

const Icon = styled.img`
  width: 120px;
  height: 120px;
`;

const Title = styled.div`
  font-size: 20px;
  font-weight: 700;
  color: #4d4d4d;
  line-height: 24px;
`;

const Subtitle = styled.div`
  font-size: 17px;
  font-weight: 400;
  color: #4d4d4d;
`;

class Advantages extends React.Component<Props & WithNamespaces> {
  render() {
    const { t, isUserLoggedIn } = this.props;
    return (
      <Container isUserLoggedIn={isUserLoggedIn}>
        <List>
          <Item>
            <IconWrapper>
              <Icon src={safeIcon} />
            </IconWrapper>
            <Title>{t('STARTPAGE_INFO_BLOCK_1_TITLE')}</Title>
            <Subtitle>{t('STARTPAGE_INFO_BLOCK_1_DESCRIPTION')}</Subtitle>
          </Item>
          <Item>
            <IconWrapper>
              <Icon src={simpleIcon} />
            </IconWrapper>
            <Title>{t('STARTPAGE_INFO_BLOCK_2_TITLE')}</Title>
            <Subtitle>{t('STARTPAGE_INFO_BLOCK_2_DESCRIPTION')}</Subtitle>
          </Item>
          <Item>
            <IconWrapper>
              <Icon src={sendingMoneyIcon} />
            </IconWrapper>
            <Title>{t('STARTPAGE_INFO_BLOCK_3_TITLE')}</Title>
            <Subtitle>{t('STARTPAGE_INFO_BLOCK_3_DESCRIPTION')}</Subtitle>
          </Item>
        </List>
      </Container>
    )
  }
}

export default withNamespaces()(Advantages);