import React from 'react';
import styled from 'styled-components';
import AdminSectionHeader from './AdminSectionHeader';
import { SECTION_BORDER_RADIUS } from './config';

const SectionWrapper = styled.div`
  font-size: 19px;
  border-radius: ${SECTION_BORDER_RADIUS};
  overflow: hidden;
  background: #fff;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

interface Props {
  children: React.ReactNode,
  title: string,
  options?: any[]
}

const AdminSection = ({children, title, options}: Props) => (
  <SectionWrapper>
    <AdminSectionHeader title={title} options={options} />
    {children}
  </SectionWrapper>
);

export default AdminSection;