import React from 'react';
import styled from 'styled-components';
import { withNamespaces, WithNamespaces } from 'react-i18next';
import Container from '../../components/Container';
import GreenCheckMarkIcon from '../../components/Icons/GreenCheckMarkIcon';

const GreenCheckMarkIconStyled = styled(GreenCheckMarkIcon)`
  display: block;
  width: 96px;
  height: 96px;
  margin: 0 auto 32px;
`;

const Title = styled.div`
  text-align: center;
  font-size: 1.8rem;
  font-weight: 600;
  margin-bottom: 24px;
`;

export class WithdrawalSuccess extends React.Component<WithNamespaces> {
  render() {
    const { t } = this.props;
    return (
      <Container
        footerConfig={{
          prevButton: {
            link: '/wallet',
            arrowRotate: true,
            title: t('BACK_TO_WALLET_OVERVIEW')
          }
        }}
        title={t('BITCOIN_WITHDRAWAL')}
      >
        <>
          <GreenCheckMarkIconStyled />
          <Title>{t('WITHDRAWAL_SUCCESS')}</Title>
        </>
      </Container>
    )
  }
}

export default withNamespaces()(WithdrawalSuccess);