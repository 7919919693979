import { StoreModel } from "../models/StoreModel";
import { UserModel } from "../models/UserModel";
import { createSelector } from "reselect";

export const isUserLoggedIn = (state: StoreModel): boolean => !!(state.auth.token && state.user.currentUser);
export const isUserEmailVerify = (state: StoreModel): boolean => !!(state.user.currentUser && state.user.currentUser.isEmailVerified);
export const isUserPinExist = (state: StoreModel): boolean | null => state.user.currentUser && state.user.currentUser.isPinSet;
export const isUserSetPinSucceed = (state: StoreModel): boolean => !!(state.user.statuses && state.user.statuses.userSetPinSucceed);
export const getCurrentUser = (state: StoreModel): UserModel | null => state.user.currentUser;
export const getGuestUser = (state: StoreModel): UserModel | null => state.user.guestUser;

export const getCurrentUserLang = createSelector(
  [getCurrentUser],
  (user: UserModel | null) => {
    if (!user) { return 'en'; }

    return user.currentLanguage;
  }
)

export const isOwnPageVisible = (state: StoreModel, openedUserId: string) => {
  if (!state.user.currentUser) { return false; }
  
  return state.user.currentUser.id === parseInt(openedUserId, 10);
}