import React from 'react';
import styled from 'styled-components';
import AdminSection from '../../components/AdminSection/AdminSection';
import AdminColumn from '../../components/AdminSection/AdminColumn';
import AdminDealsOverview from '../AdminDealsOverview';
import AdminNewUsers from '../AdminNewUsers';
import AdminUserRegistration from '../AdminUserRegistration';
import AdminClosedTrades from '../AdminClosedTrades/AdminClosedTrades';

interface ContainerProps {
  columnsGap: number
}

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  margin: ${( { columnsGap }: ContainerProps ) => `-${columnsGap}px ${-columnsGap / 2}px 0`};
`;

const COLUMNS_COUNT = 24;
const COLUMNS_GAP = 40;

const options = [
  {
    label: 'Action 1',
    action: () => { console.log('Action 1 fired') }
  },
  {
    label: 'Action 2',
    action: () => { console.log('Action 2 fired') }
  },
  {
    label: 'Action 3',
    action: () => { console.log('Action 3 fired') }
  },
];

class AdminDashboard extends React.Component {
  render () {
    return (
      <Container columnsGap={COLUMNS_GAP}>
        <AdminColumn columnWidth={15} columnsCount={COLUMNS_COUNT} columnsGap={COLUMNS_GAP}>
          <AdminSection title="User Registration">
            <AdminUserRegistration />
          </AdminSection>
        </AdminColumn>
        <AdminColumn columnWidth={9} columnsCount={COLUMNS_COUNT} columnsGap={COLUMNS_GAP}>
          <AdminSection title="Deals Overview" options={options}>
            <AdminDealsOverview />
          </AdminSection>
        </AdminColumn>
        <AdminColumn columnWidth={9} columnsCount={COLUMNS_COUNT} columnsGap={COLUMNS_GAP}>
          <AdminSection title="Closed Trades" options={options}>
            <AdminClosedTrades />
          </AdminSection>
        </AdminColumn>
        <AdminColumn columnWidth={15} columnsCount={COLUMNS_COUNT} columnsGap={COLUMNS_GAP}>
          <AdminSection title="New Users" options={options}>
            <AdminNewUsers />
          </AdminSection>
        </AdminColumn>
      </Container>
    )
  }
}

export default AdminDashboard;