import React from 'react';

const CheckboxIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25.25 25.25" width="25" height="25">
    <g>
      <g>
        
        <path id="checkbox-rectangle" d="M20.59 7.91A1.1 1.1 0 0 0 19.49 9v14H2.2V5.75h13.73a1.1 1.1 0 0 0 0-2.2H1.1A1.1 1.1 0 0 0 0 4.65v19.5a1.1 1.1 0 0 0 1.1 1.1h19.49a1.1 1.1 0 0 0 1.1-1.1V9a1.1 1.1 0 0 0-1.1-1.09z"/>
        <path id="checkbox-checkmark" d="M24.92.32a1.1 1.1 0 0 0-1.55 0l-15 15-3.51-3.41a1.09 1.09 0 0 0-.78-.32 1.1 1.1 0 0 0-.78 1.87l4.25 4.24a1.1 1.1 0 0 0 1.55 0L24.92 1.87a1.1 1.1 0 0 0 0-1.55z"/>
      </g>
    </g>
  </svg>
);

export default CheckboxIcon;