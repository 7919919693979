import React from 'react';
import styled from 'styled-components';
import { withNamespaces, WithNamespaces } from 'react-i18next';
import { formattedCryptoValue } from '../../utils';

interface Props {
  className?: string;
  balance: number | null;
}

const Container = styled.div`
  display: inline-block;
  font-weight: 300;
  font-size: 1.4rem;
  strong {
    color: #2bbc70;
  }
`;

class Balance extends React.Component<Props & WithNamespaces> {
  render() {
    const { className, balance, t } = this.props;
    return (
      <Container className={className}>
        {t('WALLET_BALANCE', {
          balance: formattedCryptoValue(balance)
        })} BTC
      </Container>
    );
  }
}

export default withNamespaces()(Balance);