import { takeEvery, call, put } from 'redux-saga/effects';
import { AUTH_REFRESH, AUTH_SAVE_TOKENS, AUTH_UPDATE_PASSWORD_WITH_TOKEN_SUCCESS, AUTH_VERIFY_EMAIL_REQUESTED, AUTH_VERIFY_EMAIL_FAILED, AUTH_VERIFY_EMAIL_SUCCESS, AUTH_FORGOT_PASSWORD_REQUESTED, AUTH_FORGOT_PASSWORD_SUCCESS, AUTH_FORGOT_PASSWORD_FAILED, AUTH_UPDATE_PASSWORD_WITH_TOKEN_REQUESTED, AUTH_UPDATE_PASSWORD_WITH_TOKEN_FAILED, AUTH_NAGA_REQUESTED, AUTH_NAGA_FAILED, AUTH_NAGA_SUCCESS } from '../actions/auth';
import { ActionModel } from '../models/ActionModel';
import { API_URL } from '../constants/config';
import { USER_EMAIL_VERIFY, USER_CURRENT_GET_REQUESTED } from '../actions/user';
import { AuthResponse } from '../models/AuthResponse';
import { CLOSE_ALL_MODALS, SET_MODAL } from '../actions/modal';
import modals from '../constants/modals';

function* sendForgotPasswordRequest(body: string) {
  const res = yield fetch(`${API_URL}/user/password-forgot`, {
    method: 'POST',
    headers: {
      "Content-Type": "application/json; charset=utf-8"
    },
    body: JSON.stringify(body)
  });

  const resJson = yield res.json();

  if (!res.ok) { throw resJson.msg; }

  return resJson;
}

function* sendVerifyEmailRequest(token: string) {
  const res = yield fetch(`${API_URL}/user/email-verify?token=${token}`, {
    method: 'GET'
  });
  const resJson = yield res.json();

  if (!res.ok) { throw resJson.message; }

  return resJson[1];
}

function* sendAuthNagaRequest(body: any) {
  const res = yield fetch(`${API_URL}/user/naga-login`, {
    method: 'POST',
    headers: {
      "Content-Type": "application/json; charset=utf-8"
    },
    body: JSON.stringify(body)
  });

  const resJson = yield res.json();

  if (!res.ok) { throw resJson.message }

  return resJson;
}

function* sendRefreshTokenRequest(body: any) {
  const res = yield fetch(`${API_URL}/user/refresh-token`, {
    method: 'POST',
    headers: {
      "Content-Type": "application/json; charset=utf-8"
    },
    body: JSON.stringify(body)
  });
  const resJson = yield res.json();

  if (!res.ok) { throw resJson.message; }

  return resJson;
}

function* sendUpdatePasswordWithTokenRequest(body: any) {
  const res = yield fetch(`${API_URL}/user/password-forgot`, {
    method: 'PUT',
    headers: {
      "Content-Type": "application/json; charset=utf-8"
    },
    body: JSON.stringify(body)
  });
  const resJson = yield res.json();

  if (!res.ok) { throw resJson.message; }

  return resJson;
}

function* updatePasswordWithToken(action: ActionModel) {
  try {
    yield call(sendUpdatePasswordWithTokenRequest, action.payload);
    yield put({type: AUTH_UPDATE_PASSWORD_WITH_TOKEN_SUCCESS});
  } catch(err) {
    yield put({type: AUTH_UPDATE_PASSWORD_WITH_TOKEN_FAILED});
    return err;
  }
}

function* verifyEmail(action: ActionModel) {
  try {
    yield call(sendVerifyEmailRequest, action.payload.token);

    yield put({ type: AUTH_VERIFY_EMAIL_SUCCESS })
    if (action.payload.isUserLoggedIn) {
      yield put({ type: USER_EMAIL_VERIFY });
    }
  } catch(err) {
    yield put({ type: AUTH_VERIFY_EMAIL_FAILED });
    return err;
  }
}

function* forgotPassword(action: ActionModel) {
  try {
    yield call(sendForgotPasswordRequest, action.payload);

    yield put({ type: AUTH_FORGOT_PASSWORD_SUCCESS });
    yield put({ type: CLOSE_ALL_MODALS });
  } catch(err) {
    yield put({ type: AUTH_FORGOT_PASSWORD_FAILED });
    return err;
  }
}

function* authNaga(action: ActionModel) {
  try {
    const payload: AuthResponse = yield call(sendAuthNagaRequest, action.payload.data);
  
    yield put({ type: AUTH_NAGA_SUCCESS });
    yield put({ type: AUTH_SAVE_TOKENS, payload });
    yield put({ type: USER_CURRENT_GET_REQUESTED });

    if (!action.payload.data.isEmailVerified) {
      yield put({ type: SET_MODAL, payload: { nextModal: modals.VERIFY_EMAIL }});
    }

    return payload;
  } catch(err) {
    yield put({ type: AUTH_NAGA_FAILED });
    return err;
  }
}

function* refreshToken(action: ActionModel) {
  try {
    const payload: AuthResponse = yield sendRefreshTokenRequest(action.payload.refreshToken);

    yield put({ type: AUTH_SAVE_TOKENS, payload });

    return payload
  } catch(err) {
    return err;
  }
}

const auth = [
  takeEvery(AUTH_NAGA_REQUESTED, authNaga),
  takeEvery(`${AUTH_REFRESH}_REQUESTED`, refreshToken),
  takeEvery(AUTH_VERIFY_EMAIL_REQUESTED, verifyEmail),
  takeEvery(AUTH_FORGOT_PASSWORD_REQUESTED, forgotPassword),
  takeEvery(AUTH_UPDATE_PASSWORD_WITH_TOKEN_REQUESTED, updatePasswordWithToken)
];

export default auth;