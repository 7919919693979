import { takeEvery, put } from 'redux-saga/effects';
import { WALLET_GET_BALANCE_REQUESTED, WALLET_GET_BALANCE_SUCCESS, WALLET_GET_WALLET_ADDRESS_REQUESTED, WALLET_GET_WALLET_ADDRESS_SUCCESS, WALLET_GET_WALLET_ADDRESS_FAILED, WALLET_GET_BALANCE_FAILED, WALLET_WITHDRAWAL_REQUESTED, WALLET_WITHDRAWAL_FAILED, WALLET_WITHDRAWAL_SUCCESS } from '../actions/wallet';
import { API_URL } from '../constants/config';
import { getAccessTokenHeader } from '../utils';
import { ActionModel } from '../models/ActionModel';

function* getUserBalance(action: ActionModel) {
  try {
    const currency = (action.payload.currency || 'btc').toUpperCase();
    const res = yield fetch(`${API_URL}/wallet/balance?currency=${currency}`, {
      headers: {
        Authorization: getAccessTokenHeader()
      }
    });
    const resJson = yield res.json();

    if (!res.ok) { throw {
      status: res.statusCode,
      message: resJson.message
    }}
    
    yield put({ type: WALLET_GET_BALANCE_SUCCESS, payload: { totalBalance: resJson.balance, availableBalance: resJson.available }})
  } catch(err) {
    yield put({ type: WALLET_GET_BALANCE_FAILED });
    return err;
  }
}

function* getWalletAddress(action: ActionModel) {
  try {
    const currency = (action.payload.currency || 'btc').toUpperCase();
    const res = yield fetch(`${API_URL}/wallet/address?currency=${currency}`, {
      headers: {
        Authorization: getAccessTokenHeader()
      }
    });
    const resJson = yield res.json();

    if (!res.ok) { throw {
      status: res.statusCode,
      message: resJson.message
    }}
    
    yield put({ type: WALLET_GET_WALLET_ADDRESS_SUCCESS, payload: { address: resJson.address }});
  } catch(err) {
    yield put({ type: WALLET_GET_WALLET_ADDRESS_FAILED });
    return err;
  }
}

function* withdrawal(action: ActionModel) {
  try {
    const res = yield fetch(`${API_URL}/wallet/withdraw`, {
      method: 'POST',
      headers: {
        Authorization: getAccessTokenHeader(),
        "Content-Type": "application/json; charset=utf-8"
      },
      body: JSON.stringify(action.payload)
    });
    const resJson = yield res.json();

    if (!res.ok) { throw {
      status: res.statusCode,
      message: resJson.message
    }}
    
    yield put({ type: WALLET_WITHDRAWAL_SUCCESS });
  } catch(err) {
    yield put({ type: WALLET_WITHDRAWAL_FAILED });
    return err;
  }
}

const wallet = [
  takeEvery(WALLET_GET_BALANCE_REQUESTED, getUserBalance),
  takeEvery(WALLET_GET_WALLET_ADDRESS_REQUESTED, getWalletAddress),
  takeEvery(WALLET_WITHDRAWAL_REQUESTED, withdrawal),
];

export default wallet;