import React from 'react';
import AuthModal from './AuthModal';
import LoginForm from '../AuthSections/LoginForm';
import SignupForm from '../AuthSections/SignupForm';
import ForgotPasswordForm from '../AuthSections/ForgotPasswordForm';
import ResetPasswordForm from '../AuthSections/ResetPasswordForm';
import ResetPasswordSuccess from '../AuthSections/ResetPasswordSuccess';

interface Props {
  onClose: () => void;
  scope?: any;
}

export const LoginModal = ({ onClose }: Props) => (
  <AuthModal onClose={onClose}>
    <LoginForm />
  </AuthModal>
);

export const SignupModal = ({ onClose }: Props) => (
  <AuthModal onClose={onClose}>
    <SignupForm />
  </AuthModal>
);

export const ForgotPasswordModal = ({ onClose }: Props) => (
  <AuthModal onClose={onClose}>
    <ForgotPasswordForm />
  </AuthModal>
);

export const ResetPasswordModal = ({ onClose, scope }: Props) => (
  <AuthModal onClose={onClose}>
    <ResetPasswordForm scope={scope}/>
  </AuthModal>
);

export const ResetPasswordSuccessModal = ({ onClose }: Props) => (
  <AuthModal onClose={onClose}>
    <ResetPasswordSuccess />
  </AuthModal>
);

// export const ResetPasswordSuccessModal = ({ onClose }: Props) => (
//   <AuthModal onClose={onClose}>
//     <ResetPasswordSuccess />
//   </AuthModal>
// );

export default AuthModal;