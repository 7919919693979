import React from 'react';
import styled from 'styled-components';
import Navigation from './Navigation';
import screens from '../../constants/screens';
import Dropdown from '../Dropdown';
import { connect } from 'react-redux';
import { StoreModel } from '../../models/StoreModel';
import { isUserLoggedIn, getCurrentUser } from '../../selectors/user';
import { UserModel } from '../../models/UserModel';
import Sidebar from '../Sidebar';
import Logo from '../Logo';
import { GREY_7F } from '../../constants/colors';
import { withNamespaces, WithNamespaces } from 'react-i18next';

interface Props {
  user: UserModel | null;
  isLoggedIn: boolean;
}

const Container = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  padding: 16px 20px;
  max-width: 1032px;
  margin: 0 auto;
  background-color: #fff;
  ${screens.mobile} {
    padding: 8px;
  }
`;

const StyledLogo = styled(Logo)`
  display: block;
  margin-right: auto;
  img {
    width: 190px;
    ${screens.mobile} {
      width: 160px;
    }
  }
`;

const NavigationWrapper = styled.div`
  margin-right: 16px;
  ${screens.mobile} {
    display: none;
  }
`;

const StyledNavigation = styled(Navigation)`
  display: flex;
  align-items: center;
  list-style-type: none;
  li {
    &:not(:first-child) {
      margin-left: 12px;
    }
  }
  a {
    color: ${GREY_7F};
    font-weight: 500;
    font-size: 12px;
    text-decoration: none;
  }
`;

const CtaContainer = styled.div`
  display: ${({isHidden}: any) => isHidden ? 'none' : 'block'};
  button {
    &:not(:first-child) {
      margin-left: 12px;
    }
  }
  ${screens.mobile} {
    display: none;
  }
` as any;

const AuthButton = styled.button`
  height: 23px;
  color: ${GREY_7F};
  border: 1px solid ${GREY_7F};
  padding: 0 8px;
  background: none;
  cursor: pointer;
  &:focus {
    outline: none;
  }
`;

const StyledLoginButton = styled(AuthButton)`
  color: #fff;
  background-color: #000;
  border-color: #000;
`;

class Header extends React.Component<Props & WithNamespaces> {
  onSignupClick = () => {
    if ((window as any).NagaAuth) {
      (window as any).NagaAuth.ShowSignupModal();
    }
  }
  onLoginClick = () => {
    if ((window as any).NagaAuth) {
      (window as any).NagaAuth.ShowLoginModal();
    }
  }
  render() {
    const { isLoggedIn, user, t } = this.props;

    return (
      <Container>
        <StyledLogo />
        <NavigationWrapper>
          <StyledNavigation />
        </NavigationWrapper>
        <CtaContainer isHidden={isLoggedIn}>
          <AuthButton
            onClick={this.onSignupClick}
          >
            {t('HEADER_MENU_SIGNUP_LABEL')}
          </AuthButton>
          <StyledLoginButton
            onClick={this.onLoginClick}
          >
            {t('HEADER_MENU_LOGIN_LABEL')}
          </StyledLoginButton>
        </CtaContainer>
        { isLoggedIn && <Dropdown user={user as UserModel} /> }
        <Sidebar isLoggedIn={isLoggedIn} />
      </Container>
    );
  }
}

const mapStateToProps = (state: StoreModel) => ({
  user: getCurrentUser(state),
  isLoggedIn: isUserLoggedIn(state)
});

export default connect(mapStateToProps)(withNamespaces()(Header));