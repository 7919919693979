import { ActionModel } from "../models/ActionModel";
import { CHAT_CREATE_RESET, CHAT_GET_HISTORY, CHAT_CHANGE_TRADE_STATUS, CHAT_GET_OPEN_CHATS_SUCCESS, CHAT_CREATE_NEW_CHAT_SUCCESS, CHAT_GET_CHAT_SUCCESS, CHAT_GET_CHAT_CLEAR } from "../actions/chat";
import { ChatModel } from "../models/ChatModel";
import { MessageModel } from "../models/MessageModel";

export interface ChatInitialModel {
  chats: ChatModel[] | null;
  chat: ChatModel | null;
  chatHistory: MessageModel[] | null;
  statuses: {
    chatCreationSuccess: boolean
  }
}

const initialState = {
  chats: null,
  chat: null,
  chatHistory: null,
  statuses: {
    chatCreationSuccess: false
  }
};

const chat = (state: ChatInitialModel = initialState, action: ActionModel) => {
  switch(action.type) {
    case CHAT_GET_CHAT_SUCCESS:
      return {
        ...state,
        chat: action.payload.chat
      }
    case CHAT_GET_CHAT_CLEAR:
      return {
        ...state,
        chat: null
      }
    case CHAT_GET_HISTORY:
      return {
        ...state,
        chatHistory: action.payload.chatHistory
      }
    case CHAT_GET_OPEN_CHATS_SUCCESS:
      return {
        ...state,
        chats: action.payload.chats
      }
    case CHAT_CREATE_NEW_CHAT_SUCCESS:
      return {
        ...state,
        chat: action.payload.chat,
        statuses: {
          ...state.statuses,
          chatCreationSuccess: true
        }
      }
    case CHAT_CREATE_RESET:
      return {
        ...state,
        statuses: {
          ...state.statuses,
          chatCreationSuccess: false
        }
      }
    case CHAT_CHANGE_TRADE_STATUS:
      if (!state.chat) { return state; }

      return {
        ...state,
        chat: {
          ...state.chat,
          status: action.payload.status
        }
      }
    default:
      return state;
  }
};

export default chat;