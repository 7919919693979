import React from 'react';
import styled from 'styled-components';
import { withNamespaces, WithNamespaces } from 'react-i18next';
import Button from '../../components/Button';
import { connect } from 'react-redux';
import { StoreModel } from '../../models/StoreModel';
import { createLoadingSelector } from '../../selectors/loading';
import { AD_CREATE_NEW } from '../../actions/ads';

interface Props {
  isPending: boolean;
  isNextButtonDisabled: boolean;
  activeStep: number;
  activeType: string | null;
  stepsAmount: number;
  onBackClick: () => void;
  onNextClick: () => void;
  onFinish: () => void;
}

const Container = styled.div`
  display: flex;
  justify-content: ${({isFirstStep}: any) => isFirstStep ? 'flex-end' : 'space-between'};
  align-items: center;
  margin-top: auto;
  background-color: #f9f9f9;
  height: 80px;
  padding: 0 50px;
` as any;

const NavigationButton = styled(Button)`
  display: flex;
  align-items: center;
  font-size: 1.8rem;
  font-weight: 500;
  padding: 0;
  .loader {
    width: 32px;
    height: 32px;
    left: 4px;
  }
`;

class CreateTradeFooter extends React.Component<Props & WithNamespaces> {
  render() {
    const { activeStep, activeType, stepsAmount, onBackClick, onNextClick, t, onFinish, isPending, isNextButtonDisabled } = this.props;
    return (
      <Container isFirstStep={activeStep === 0}>
        {
          activeStep !== 0 && (
            <NavigationButton
              type="button"
              isArrowExist
              isArrowReverted
              isArrowDark
              onClick={onBackClick}
            >
              {t('TRADE_PAGE_BACK')}
            </NavigationButton>
          )
        }
        {
          (activeStep !== stepsAmount - 1) && (
            <NavigationButton 
              isArrowExist 
              isArrowDark={!activeType || isNextButtonDisabled} 
              type="button" 
              disabled={isNextButtonDisabled}
              onClick={onNextClick}
            >
              {t('TRADE_PAGE_NEXT')}
            </NavigationButton>
          )
        }
        {
          (activeStep === stepsAmount - 1) && (
            <NavigationButton 
              isArrowExist
              type="button" 
              onClick={onFinish}
              isPending={isPending}
            >
              {t('TRADE_PAGE_FINISH')}
            </NavigationButton>
          )  
        }
      </Container>
    )
  }
}

const mapStateToProps = (state: StoreModel) => ({
  isPending: createLoadingSelector([AD_CREATE_NEW])(state)
});

export default connect(mapStateToProps)(withNamespaces()(CreateTradeFooter));