export const RED_E0 = '#e01b2e';
export const RED_CF = '#cf0022';
export const BLUE_17 = '#172b42';

export const GREY_7F = '#7f7f7f';
export const GREY_D7 = '#d7d7d7';
export const GREY_97 = '#979797';
export const GREY_B2 = '#B2B2B2';
export const GREY_F5 = '#F5F5F5';
export const GREY_F9 = '#F9F9F9';
export const GREY_9A = '#9A9A9A';
export const GREY_A6 = '#A6A6A6';
export const GREY_EE = '#eee';

export const YELLOW_FD = '#fdca35';

export const ADMIN_SUMMER_SKY = '#46A9D4';
export const ADMIN_DARK_BLUE = '#505275';
export const ADMIN_BACKGROUND_COLOR = '#DEE9EE';
export const ADMIN_GREEN = '#58C5AD';
export const ADMIN_ORANGE = '#FF8B4C';
export const ADMIN_BLUE = '#009DFF';
export const ADMIN_RED = '#FF0000';