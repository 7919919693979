import React from 'react';
import styled from 'styled-components';
import { withNamespaces, WithNamespaces } from 'react-i18next';
import ActionIcon from '../../components/Icons/ActionIcon';
import { RED_E0, GREY_7F } from '../../constants/colors';

const Container = styled.div`
  display: flex;
  justify-content: center;
`;

const Wrapper = styled.div`
  background-color: #fff;
  border-radius: 6px;
  max-width: 800px;
  width: 100%;
  margin: 0 20px;
  box-shadow: 2px 2px 10px 0 rgba(0,0,0, .2);
  text-align: center;
  padding: 40px 20px 26px;
  transform: translateY(-22%);
`;

const Title = styled.h4`
  font-size: 32px;
  line-height: 34px;
  font-weight: 500;
  margin: 0 0 18px;
`;

const Subtitle = styled.h5`
  font-size: 20px;
  line-height: 22px;
  font-weight: 400;
  color: ${GREY_7F};
  margin: 0 0 36px;
`;

const Button = styled.button`
  font-size: 27px;
  color: ${RED_E0};
  font-weight: 700;
  svg {
    vertical-align: middle;
    margin-left: 10px;
  }
`

class SignUpBanner extends React.Component<WithNamespaces> {
  onSignupClick = () => {
    if ((window as any).NagaAuth) {
      (window as any).NagaAuth.ShowSignupModal();
    }
  }
  render() {
    const { t } = this.props;
    return (
      <Container>
        <Wrapper>
          <Title>{t('STARTPAGE_SIGN_UP_CALL_TO_ACTION_TITLE_1')}</Title>
          <Subtitle>{t('STARTPAGE_SIGN_UP_CALL_TO_ACTION_TITLE_2')}</Subtitle>
          <Button onClick={this.onSignupClick}>
            {t('STARTPAGE_SIGN_UP_CALL_TO_ACTION_LABEL')}
            <ActionIcon fill={RED_E0} />
          </Button>
        </Wrapper>
      </Container>
    );
  }
}

export default withNamespaces()(SignUpBanner);