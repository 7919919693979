import React, { SyntheticEvent } from 'react';
import styled from 'styled-components';
import Select from 'react-select';
import { GREY_D7 } from '../../constants/colors';
import { connect } from 'react-redux';
import { StoreModel } from '../../models/StoreModel';
import { CountryModelForSelect } from '../../models/CountryModel';
import { getPhoneCodes, getDetectedCountryPhoneCodeForSelect } from '../../selectors/country';
import { PhoneCodeModel } from '../../models/PhoneCodeModel';

const Container = styled.div`
  display: flex;
  height: 40px;
  border-radius: 3px;
`;

const Input = styled.input`
  flex-grow: 1;
  padding: 0 10px;
  font-size: 16px;
  border: 1px solid;
  border-left: none;
  max-width: none;
  height: 40px;
  border-radius: 0 3px 3px 0;
  border-color: ${({err}: any) => err ? '#ed2024' : GREY_D7};
  background-color: ${({err}: any) => err ? 'rgba(237,32,36, .3)' : '#fff'}; 
` as any;

interface Props {
  phoneCodes?: PhoneCodeModel[];
  phoneCodeForDetectedCountry?: PhoneCodeModel;
  selectedCountry: CountryModelForSelect | null;
  onChange: (field: string, phoneNumber: string) => void;
  onBlur: (field: string) => void;
  name: string;
}

interface State {
  selectedCode: PhoneCodeModel | null;
  phoneNumber?: string;
}

class PhoneNumber extends React.Component<Props, State> {
  state: State = {
    selectedCode: null,
    phoneNumber: '',
  };

  componentWillReceiveProps(nextProps: Props) {
    if ((!this.props.selectedCountry && nextProps.selectedCountry) || (this.props.selectedCountry && nextProps.selectedCountry && (this.props.selectedCountry.phoneCode !== nextProps.selectedCountry.phoneCode))) {
      this.setState({
        selectedCode: {
          label: nextProps.selectedCountry && nextProps.selectedCountry.phoneCode,
          value: nextProps.selectedCountry && nextProps.selectedCountry.phoneCode,
        }
      }, this.onChange);
    }
  }
  onSelect = (selectedCode: any) => {
    this.setState({ selectedCode }, this.onChange);
  }
  onEnter = (e: SyntheticEvent<HTMLInputElement>) => {
    this.setState({ 
      phoneNumber: (e.target as any).value
    }, this.onChange);
  }
  onChange = () => {
    const code = this.state.selectedCode && this.state.selectedCode.value || '';
    const phoneNumber = this.state.phoneNumber || '';
    const phone = (code + phoneNumber).replace('+', '')
    
    this.props.onChange(this.props.name, phone);
  }
  onBlur = () => {
    this.props.onBlur(this.props.name);
  }
  render() {
    const styles = {
      valueContainer: (base: any) => {
        return {
          ...base,
          padding: 0,
          ':focus': {
            outline: 'none'
          }
        }
      },
      indicatorSeparator: (base: any) => {
        return {
          display: 'none'
        }
      },
      input: (base: any) => {
        return {
          ...base,
          fontSize: 16,
          color: '#fff'
        }
      },
      control: (base: any) => {
        return {
          ...base,
          background: '#828282',
          border: `1px solid ${GREY_D7}`,
          borderRight: 'none',
          borderRadius: '3px 0 0 3px',
          minWidth: '96px',
          padding: '0 10px',
          boxShadow: 'none',
          height: 40,
          '&:hover': {
            border: `1px solid ${GREY_D7}`,
            borderRight: 'none'
          }
        }
      },
      singleValue: (base: any) => {
        return {
          ...base,
          position: 'static',
          transform: 'translateY(-2px)',
          color: '#fff',
          fontWeight: 400,
          fontSize: 16
        }
      },
      option: (base: any) => {
        return {
          ...base,
          background: 'none',
          color: '#000',
          fontSize: '14px',
          '&:hover': {
            backgroundColor: '#f3f7fa'
          }
        }
      },
      dropdownIndicator: (base: any) => {
        return {
          ...base,
          color: 'red',
          padding: 0,
          border: '5px solid transparent',
          borderTop: '5px solid #fff',
          transform: 'translateY(2px)',
          'svg': {
            display: 'none'
          }
        }
      }
    };
    return (
      <Container>
        <Select 
          styles={styles} 
          placeholder="" 
          options={this.props.phoneCodes}
          value={this.state.selectedCode}
          defaultValue={this.props.phoneCodeForDetectedCountry}
          onChange={this.onSelect}
          onBlur={this.onBlur}
        />
        <Input 
          type="text" 
          onChange={this.onEnter} 
          value={this.state.phoneNumber}
          onBlur={this.onBlur} 
        />
      </Container>
    );
  }
}

const mapStateToProps = (state: StoreModel) => ({
  phoneCodes: getPhoneCodes(state),
  phoneCodeForDetectedCountry: getDetectedCountryPhoneCodeForSelect(state)
})

export default connect(mapStateToProps)(PhoneNumber);