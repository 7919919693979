import React from 'react';
import styled from 'styled-components';
import SendMessageIcon from '../Icons/SendMessageIcon';

interface Props {
  onSendNewMessage: (message: string) => void;
  onFocus: () => void;
  onBlur: () => void;
}

interface State {
  message: string;
}

const Form = styled.form`
  position: relative;
  margin-right: 20px;
`;

const Input = styled.input`
  width: 100%;
  height: 42px;
  border: 1px solid #0e0e0e;
  border-radius: 6px;
  font-size: 16px;
  padding: 0 54px 0 12px;

`;

const SendMessageButton = styled.button`
  position: absolute;
  top: 50%;
  right: 12px;
  transform: translateY(-50%);
  width: 30px;
  height: 30px;
`;

class NewMessageForm extends React.Component<Props> {
  state: State = {
    message: ''
  }
  onChange = (e: React.SyntheticEvent<HTMLInputElement>) => {
    this.setState({
      message: (e.target as any).value
    });
  }
  sendMessage = (e: React.SyntheticEvent<HTMLFormElement>) => {
    e.preventDefault();

    this.setState({
      message: ''
    });
    this.props.onSendNewMessage(this.state.message);
  }
  render() {
    return (
      <Form onSubmit={this.sendMessage}>
        <Input 
          type="text"
          onChange={this.onChange}
          value={this.state.message}
          onFocus={this.props.onFocus}
          onBlur={this.props.onBlur}
        />
        <SendMessageButton type="submit">
          <SendMessageIcon />
        </SendMessageButton>
      </Form>
    )
  }
}

export default NewMessageForm;