import React from 'react';
import styled from 'styled-components';
import { GREY_9A, GREY_F5, GREY_F9 } from '../../constants/colors';
import { getItemValue } from './utils';

const List = styled.ul`
`;

interface ListItemProps {
  tall?: boolean,
  index: number
}

const ListItem = styled.li`
  height: ${({tall}: ListItemProps) => tall ? 119 : 98}px;
  padding-top: ${({tall}: ListItemProps) => tall ? 19 : 7}px;
  background: ${({index}: ListItemProps) => index % 2 === 0 ? GREY_F5 : GREY_F9};
`;

const ListItemText = styled.p`
  margin: 0;
  text-align: center;
  font-weight: 500;
  color: ${GREY_9A};
`;

const ListItemValue = styled(ListItemText)`
  font-size: 48px;
  line-height: 57px;
`;

const ListItemLabel = styled(ListItemText)`
  font-size: 19px;
  line-height: 23px;
`;

const AdminDealsOverviewList = ({list}: any) => (
  <List>
    {
      list.map((item: any, index: number) => (
        <ListItem index={index} key={item.label} tall={item.label === 'Total Deposit'}>
          <ListItemValue>{getItemValue(item)}</ListItemValue>
          <ListItemLabel>{item.label}</ListItemLabel>
        </ListItem>
      ))
    }
  </List>
);

export default AdminDealsOverviewList;