import React, { SyntheticEvent } from 'react';
import styled from 'styled-components';

interface Props {
  name: string;
  onChange?: (field: string, value?: string | number) => void;
  onBlur?: (field: string) => void;
  className?: string;
  value?: string | number;
}

const RadioInput = styled.input`
  display: none;
  &:checked {
    & + span {
      &:after {
        display: block;
      }
    }
  }
  & + span {
    display: flex;
    align-items: center;
    &:before {
      display: block;
      content: '';
      display: block;
      width: 16px;
      height: 16px;
      border: 1px solid #000;
      border-radius: 50%;
      margin-right: 10px;
    }
    &:after {
      display: none;
      position: absolute;
      left: 4px;
      content: '';
      width: 8px;
      height: 8px;
      background-color: #000;
      border-radius: 50%;
    }
  }
` as any;

class RadioButton extends React.Component<Props> {
  onChange = (e: SyntheticEvent<HTMLTextAreaElement>) => {
    this.props.onChange && this.props.onChange(this.props.name, this.props.value);
  }
  onBlur = () => {
    this.props.onBlur && this.props.onBlur(this.props.name);
  }
  render() {
    const { className, name } = this.props;
    return (
      <RadioInput 
        type="radio"
        name={name}
        className={className}  
        onChange={this.onChange}
        onBlur={this.onBlur}
      />
    )
  }
}

export default RadioButton;