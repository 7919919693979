import { takeEvery, put, call } from "redux-saga/effects";
import { AD_LIST_GET_SUCCESS, AD_LIST_GET_REQUESTED, AD_GUEST_LIST_GET_REQUESTED, AD_GUEST_LIST_GET_SUCCESS, AD_GET_REQUESTED, AD_GET_SUCCESS, AD_CREATE_NEW_REQUESTED, AD_CREATE_NEW_SUCCESS, AD_LIST_GET_FAILED, AD_GUEST_LIST_GET_FAILED, AD_GET_FAILED, AD_CREATE_NEW_FAILED, AD_DELETE_REQUESTED, AD_DELETE_SUCCESS, AD_DELETE_FAILED, AD_SEARCH_REQUESTED, AD_SEARCH_SUCCESS, AD_SEARCH_FAILED } from "../actions/ads";
import { API_URL } from "../constants/config";
import { ActionModel } from "../models/ActionModel";
import { getAccessTokenHeader } from "../utils";
import * as queryString from 'query-string';

function* getUserAds() {
  try {
    const res = yield fetch(`${API_URL}/trade/self`, {
      headers: {
        Authorization: getAccessTokenHeader(),
        "Content-Type": "application/json; charset=utf-8"
      }
    });
    const resJson = yield res.json();

    if (!res.ok) { 
      throw {
        status: res.status,
        message: resJson.message
      }; 
    }

    yield put({ type: AD_LIST_GET_SUCCESS, payload: { ads: resJson }});
  } catch (err) {
    yield put({ type: AD_LIST_GET_FAILED });
    return err;
  }
}

function* getGuestUserAds(action: ActionModel) {
  try {
    const res = yield fetch(`${API_URL}/trade?id=${action.payload.id}`, {
      headers: {
        Authorization: getAccessTokenHeader(),
        "Content-Type": "application/json; charset=utf-8"
      }
    });
    const resJson = yield res.json();

    if (!res.ok) { throw {
      status: res.status,
      message: resJson.message
    } }

    yield put({ type: AD_GUEST_LIST_GET_SUCCESS, payload: { ads: resJson }});
  } catch (err) {
    yield put({ type: AD_GUEST_LIST_GET_FAILED });
    return err;
  }
}

function* createNewAdRequest(payload: any) {
  const res = yield fetch(`${API_URL}/trade`, {
    method: 'POST',
    headers: {
      Authorization: getAccessTokenHeader(),
      "Content-Type": "application/json; charset=utf-8"
    },
    body: JSON.stringify({
      type: payload.adType,
      ...payload.ad
    })
  });

  const resJson = yield res.json();

  if (!res.ok) { throw resJson.message }

  return resJson;
}

function* searchAdsRequest(body: any) {
  let query = queryString.stringify({
    sort: 1,
  });

  if (body) {
    query = queryString.stringify({
      sort: 1,
      ...body
    });
  }

  const res = yield fetch(`${API_URL}/trade/search?${query}`, {
    method: 'GET',
    headers: {
      "Content-Type": "application/json; charset=utf-8"
    }
  });

  const resJson = yield res.json();

  if (!res.ok) { throw resJson.message }

  return resJson;
}

function* deleteAdRequest(body: any) {
  const res = yield fetch(`${API_URL}/trade`, {
    method: 'DELETE',
    headers: {
      Authorization: getAccessTokenHeader(),
      "Content-Type": "application/json; charset=utf-8"
    },
    body: JSON.stringify(body),
  });

  const resJson = yield res.json();

  if (!res.ok) { throw resJson.message }

  return resJson;
}

function* getAd(action: ActionModel) {
  try {
    const res = yield fetch(`${API_URL}/trade/${action.payload.adId}`, {
      method: 'GET'
    });

    const resJson = yield res.json();

    if (!res.ok) { throw resJson.message }

    yield put({ type: AD_GET_SUCCESS, payload: { openedAd: resJson }})
  } catch(err) {
    yield put({ type: AD_GET_FAILED });
    return err;
  }
}

function* createNewAd(action: ActionModel) {
  try {
    const ad = yield call(createNewAdRequest, action.payload);

    yield put({ type: AD_CREATE_NEW_SUCCESS, payload: { openedAd: ad }});
  } catch(err) {
    yield put({ type: AD_CREATE_NEW_FAILED });
    return err;
  }
}

function* deleteAd(action: ActionModel) {
  try {
    yield call(deleteAdRequest, action.payload);

    yield put({ type: AD_DELETE_SUCCESS, payload: { id: action.payload.id } });
  } catch(err) {
    yield put({ type: AD_DELETE_FAILED });
    return err;
  }
}

function* searchAds(action: ActionModel) {
  try {
    const res = yield call(searchAdsRequest, action.payload.body);

    yield put({ type: AD_SEARCH_SUCCESS, payload: { ads: res }});
  } catch(err) {
    yield put({ type: AD_SEARCH_FAILED });
    
    return err;
  }
}

const ads = [
  takeEvery(AD_LIST_GET_REQUESTED, getUserAds),
  takeEvery(AD_GUEST_LIST_GET_REQUESTED, getGuestUserAds),
  takeEvery(AD_CREATE_NEW_REQUESTED, createNewAd),
  takeEvery(AD_SEARCH_REQUESTED, searchAds),
  takeEvery(AD_GET_REQUESTED, getAd),
  takeEvery(AD_DELETE_REQUESTED, deleteAd),
];

export default ads;