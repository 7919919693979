import { ActionModel } from "../models/ActionModel";
import { COUNTRY_LIST, DETECT_COUNTRY } from "../actions/country";
import { CountryModel } from "../models/CountryModel";

export interface CountryInitialState {
  countryList?: CountryModel[];
  detectedCountry?: CountryModel;
}

const initialState: CountryInitialState = {
  countryList: undefined,
  detectedCountry: undefined
};

const country = (state: CountryInitialState = initialState, action: ActionModel) => {
  switch (action.type) {
    case COUNTRY_LIST:
      return {
        ...state,
        countryList: action.payload.countryList
      }
    case DETECT_COUNTRY:
      return {
        ...state,
        detectedCountry: action.payload.detectedCountry
      }
    default:
      return state;
  }
};

export default country;