import React from 'react';
import styled from 'styled-components';
import { Switch, Route } from 'react-router-dom';
import { RouteComponentProps } from 'react-router-dom';
import AdminDashboard from '../../containers/AdminDashboard';
import AdminHeader from '../AdminHeader';
import AdminContentWrapper from '../AdminContentWrapper';
import AdminSidebar from '../AdminSidebar';
import { ADMIN_BACKGROUND_COLOR } from '../../constants/colors';
import { ADMIN_SIDEBAR_WIDTH } from '../../constants/admin';

const Container = styled.div`
 font-family: "Helvetica Neue", Helvetica, Arial, sans-serif; 
`;

const AdminContent = styled.div`
  flex-grow: 1;
  padding: 40px;
  background: ${ADMIN_BACKGROUND_COLOR};
  max-width: calc(100vw - ${ADMIN_SIDEBAR_WIDTH});
  overflow: hidden;
`;

const Admin = ({ match }: RouteComponentProps<any>) => (
  <Container>
    <AdminHeader />
    <AdminSidebar baseUrl={match.url} />
    <AdminContentWrapper>
      <AdminContent>
        <Switch>
          <Route path={match.url} exact component={AdminDashboard} />
          <Route path={`${match.url}/users`} exact render={() => (<div>users</div>)} />
          <Route path={`${match.url}/trade-ad`} exact render={() => (<div>trade-ad</div>)} />
          <Route path={`${match.url}/transactions`} exact render={() => (<div>transactions</div>)} />
        </Switch>
      </AdminContent>
    </AdminContentWrapper>
  </Container>
);

export default Admin;