import React from 'react';
import styled from 'styled-components';
import Question from './Question';
import screens from '../../constants/screens';

interface Props {
  questions: any[] | undefined;
  topicTitle: string;
}

const Container = styled.div`
  ${screens.mobile} {
    &:not(:last-child) {
      margin-bottom: 32px;
    }
  }
`;

const Title = styled.div`
  display: none;
  font-size: 2.4rem;
  font-weight: 700;
  margin-bottom: 16px;
  ${screens.mobile} {
    display: block;
  }
`;

class Questions extends React.Component<Props> {
  render() {
    const { questions, topicTitle } = this.props;
    return (
      <Container>
        <Title>{topicTitle}</Title>
        {
          questions && questions.map((question: any, index: number) => <Question question={question} key={question.id} />)
        }
      </Container>
    )
  }
}

export default Questions;