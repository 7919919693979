import { put, takeEvery } from 'redux-saga/effects';
import { USER_SET_PIN_SUCCESS, USER_SET_PIN_FAILED, USER_CURRENT_GET_REQUESTED, USER_CURRENT_GET_SUCCESS, USER_GET_REQUESTED, USER_GET_SUCCESS, USER_SET_PIN_REQUESTED, USER_GET_FAILED, USER_CURRENT_GET_FAILED, USER_UPDATE_PIN_REQUESTED, USER_UPDATE_PIN_SUCCESS, USER_UPDATE_PIN_FAILED, USER_REPORT_REQUESTED, USER_REPORT_SUCCESS, USER_REPORT_FAILED, USER_UPDATE_SUCCESS, USER_UPDATE_FAILED, USER_UPDATE_REQUESTED, USER_UPDATE_AVATAR_REQUESTED, USER_UPDATE_AVATAR_FAILED, USER_UPDATE_AVATAR_SUCCESS, USER_UPDATE_PASSWORD_REQUESTED, USER_UPDATE_PASSWORD_SUCCESS, USER_UPDATE_PASSWORD_FAILED } from '../actions/user';
import { API_URL } from '../constants/config';
import { getAccessTokenHeader } from '../utils';
import { ActionModel } from '../models/ActionModel';
import { CLOSE_ALL_MODALS } from '../actions/modal';

function* getCurrentUser() {
  try {
    const res = yield fetch(`${API_URL}/user/self`, {
      headers: {
        Authorization: getAccessTokenHeader()
      }
    });
    const resJson = yield res.json();

    if (!res.ok) { throw resJson.message }

    yield put({ type: USER_CURRENT_GET_SUCCESS, payload: { user: resJson }});
  } catch (err) {
    yield put({ type: USER_CURRENT_GET_FAILED });
    return err;
  }  
}

function* getGuestUser(action: ActionModel) {
  try {
    const res = yield fetch(`${API_URL}/user?id=${action.payload.id}`, {
      headers: {
        Authorization: getAccessTokenHeader()
      }
    });
    const resJson = yield res.json();

    if (!res.ok) { throw resJson.message }

    yield put({ type: USER_GET_SUCCESS, payload: { user: resJson }});
  } catch (err) {
    yield put({ type: USER_GET_FAILED });
    return err;
  }  
}

function* sendReport(action: ActionModel) {
  try {
    yield fetch(`${API_URL}/user/report`, {
      method: 'POST',
      headers: {
        Authorization: getAccessTokenHeader(),
        "Content-Type": "application/json; charset=utf-8"
      },
      body: JSON.stringify(action.payload)
    });
    // const resJson = yield res.json();

    // if (!res.ok) { throw resJson.message }

    yield put({ type: USER_REPORT_SUCCESS });
    yield put({ type: CLOSE_ALL_MODALS });
  } catch (err) {
    yield put({ type: USER_REPORT_FAILED });
    return err;
  }  
}

function* setPin(action: ActionModel) {
  try {
    const res = yield fetch(`${API_URL}/user/pin`, {
      method: 'PUT',
      headers: {
        Authorization: getAccessTokenHeader(),
        "Content-Type": "application/json; charset=utf-8"
      },
      body: JSON.stringify(action.payload)
    });
    const resJson = yield res.json();

    if (!res.ok) { throw {
      status: res.statusCode,
      message: resJson.message
    }}

    yield put({ type: USER_SET_PIN_SUCCESS });
    yield put({ type: CLOSE_ALL_MODALS });
  } catch(err) {
    yield put({ type: USER_SET_PIN_FAILED });
    return err;
  }
}

function* updateUser(action: ActionModel) {
  try {
    const res = yield fetch(`${API_URL}/user`, {
      method: 'PUT',
      headers: {
        Authorization: getAccessTokenHeader(),
        "Content-Type": "application/json; charset=utf-8"
      },
      body: JSON.stringify(action.payload)
    });
    const resJson = yield res.json();

    if (!res.ok) { throw {
      status: res.statusCode,
      message: resJson.message
    }}

    yield put({ type: USER_UPDATE_SUCCESS, payload: action.payload });
  } catch(err) {
    yield put({ type: USER_UPDATE_FAILED });
    return err;
  }
}

function* updatePin(action: ActionModel) {
  try {
    const res = yield fetch(`${API_URL}/user/pin-reset`, {
      method: 'PUT',
      headers: {
        Authorization: getAccessTokenHeader(),
        "Content-Type": "application/json; charset=utf-8"
      },
      body: JSON.stringify(action.payload)
    });
    const resJson = yield res.json();
  
    if (!res.ok) { throw resJson.message }
  
    yield put({ type: USER_UPDATE_PIN_SUCCESS });
  } catch(err) {
    yield put({ type: USER_UPDATE_PIN_FAILED });
    return err;
  }
}

function* updatePassword(action: ActionModel) {
  try {
    const res = yield fetch(`${API_URL}/user/password`, {
      method: 'PUT',
      headers: {
        Authorization: getAccessTokenHeader(),
        "Content-Type": "application/json; charset=utf-8"
      },
      body: JSON.stringify(action.payload)
    });
    const resJson = yield res.json();
  
    if (!res.ok) { throw resJson.message }
  
    yield put({ type: USER_UPDATE_PASSWORD_SUCCESS });
  } catch(err) {
    yield put({ type: USER_UPDATE_PASSWORD_FAILED });
    return err;
  }
}

function* updateAvatar(action: ActionModel) {
  try {
    const res = yield fetch(`${API_URL}/user/avatar`, {
      method: 'PUT',
      headers: {
        Authorization: getAccessTokenHeader()
      },
      body: action.payload.formData
    });
    const resJson = yield res.json();

    if (!res.ok) { throw resJson.message }

    yield put({ type: USER_UPDATE_AVATAR_SUCCESS, payload: { avatarPath: resJson.avatar } });
  } catch(err) {
    yield put({ type: USER_UPDATE_AVATAR_FAILED });
    return err;
  }
}

const user = [
  takeEvery(USER_CURRENT_GET_REQUESTED, getCurrentUser),
  takeEvery(USER_GET_REQUESTED, getGuestUser),
  takeEvery(USER_SET_PIN_REQUESTED, setPin),
  takeEvery(USER_UPDATE_PIN_REQUESTED, updatePin),
  takeEvery(USER_REPORT_REQUESTED, sendReport),
  takeEvery(USER_UPDATE_REQUESTED, updateUser),
  takeEvery(USER_UPDATE_PASSWORD_REQUESTED, updatePassword),
  takeEvery(USER_UPDATE_AVATAR_REQUESTED, updateAvatar)
];

export default user;