import React from 'react';
import styled from 'styled-components';
import { SettingsIcon } from '../Icons/AdminIcons';
import { SECTION_BORDER_RADIUS } from './config';
import { GREY_9A } from '../../constants/colors';

const HeaderOptions = styled.div`
  position: absolute;
  right: 21px;
  top: 0;
  height: 100%;
  display: flex;
  align-items: center;
`;

const HeaderOptionsButton = styled.button`
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: .77;
  transition: opacity .15s ease-in-out;
  
  &:hover, &:focus {
    opacity: 1;
  }
`;

const HeaderOptionsList = styled.div`
  font-size: 19px;
  padding: .75em 1em;
  position: absolute;
  right: 0;
  top: 90%;
  border: 1px solid ${GREY_9A};
  overflow: hidden;
  background: #fff;
  z-index: 10;
  border-radius: ${SECTION_BORDER_RADIUS};
`;

const HeaderOptionsListItem = styled.button`
  display: block;
  text-align: center;
  white-space: nowrap;
  font-size: 19px;
  padding: .25em 0;
  transition: opacity .15s ease-in-out;
  
  &:hover, &:focus {
    opacity: .7;
  }
`;

interface Props {
  options: any[]
}

interface State {
  isOptionsVisible: boolean
}

class AdminSectionOptions extends React.Component<Props, State> {
  state = {
    isOptionsVisible: false
  };

  optionsList = React.createRef<any>();
  optionsButton = React.createRef<any>();

  componentDidMount() {
    document.addEventListener('mouseup', this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('mouseup', this.handleClickOutside);
  }

  handleClickOutside = (e: Event) => {
    const shouldHideOptions = this.state.isOptionsVisible && (
      (this.optionsList.current && !this.optionsList.current.contains(e.target))
    );

    if (shouldHideOptions) {
      this.setState({
        isOptionsVisible: false
      })
    }
  };

  showOptions = () => {
    if (!this.state.isOptionsVisible) {
      this.setState({
        isOptionsVisible: true
      })
    }
  };

  render () {
    const { isOptionsVisible } = this.state;
    const { options } = this.props;

    return (
      <HeaderOptions>
        <HeaderOptionsButton onClick={this.showOptions} ref={this.optionsButton}>
          <SettingsIcon />
        </HeaderOptionsButton>
        {isOptionsVisible &&
          <HeaderOptionsList ref={this.optionsList}>
            {options.map(({action, label}) => (
              <HeaderOptionsListItem onClick={action} key={label}>{label}</HeaderOptionsListItem>
            ))}
          </HeaderOptionsList>
        }
      </HeaderOptions>
    )
  }
}

export default AdminSectionOptions;