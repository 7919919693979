import React from 'react';

interface Props {
  className?: string;
}

const LocationIcon = ({ className }: Props) => (
  <svg className={className} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 30.22" width="20" height="30">
    <g>
      <g>
        <path d="M10 0A10 10 0 0 0 0 10c0 4.77 3.26 11 6 15.29A1.21 1.21 0 0 0 8 24c-4.61-7.38-5.58-11.68-5.58-14a7.58 7.58 0 0 1 15.16 0c0 7-8.45 18.16-8.54 18.27A1.21 1.21 0 1 0 11 29.75c.37-.48 9-11.8 9-19.75A10 10 0 0 0 10 0z"/>
        <path d="M10 7.63a1.21 1.21 0 1 0 0-2.42A4.79 4.79 0 1 0 14.79 10a4.75 4.75 0 0 0-1-2.89 1.21 1.21 0 1 0-1.93 1.46 2.35 2.35 0 0 1 .51 1.43A2.37 2.37 0 1 1 10 7.63z"/>
      </g>
    </g>
  </svg>
);

export default LocationIcon;