import React from 'react';
import styled from 'styled-components';
import { ArrowWithLineIcon } from './ArrowIcons';

interface Props {
  dark?: boolean;
  arrowRotate?: boolean;
}

const Circle = styled.div`
  display: flex;
  margin-right: 14px;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  background-color: ${({dark}: any) => dark ? '#919191' : '#2bbc70'};
  border-radius: 50%;
  transform: rotate(${({arrowRotate}: any) => arrowRotate ? '180deg' : '0'})
` as any;

class CircleWithArrow extends React.Component<Props> {
  render() {
    const { dark, arrowRotate } = this.props;
    return (
      <Circle dark={dark} arrowRotate={arrowRotate}>
        <ArrowWithLineIcon width="34" height="18" fill="#fff" />
      </Circle>
    );
  }
}

export default CircleWithArrow;