import { put, takeLatest, call } from 'redux-saga/effects';
import { COUNTRY_LIST, DETECT_COUNTRY } from '../actions/country';
import { API_URL } from '../constants/config';

const fetchCountryList = () => {
  return fetch(`${API_URL}/country`);
}

const fetchUserCountry = () => {
  return fetch('https://api-v2.swipestox.com/country/detect');
}

function* getCountryList() {
  let countryList;
  let detectedCountry;

  try {
    countryList = yield call(fetchCountryList);
    countryList = yield countryList.json();
    countryList = countryList.data;
  } catch(err) {
    console.log(err);
  }

  try {
    detectedCountry = yield call(fetchUserCountry);
    detectedCountry = yield detectedCountry.json();
    detectedCountry = detectedCountry.data;
  } catch(err) {
    console.log(err);
  }

  yield put({
    type: COUNTRY_LIST,
    payload: {
      countryList
    }
  });
  yield put({
    type: DETECT_COUNTRY,
    payload: {
      detectedCountry
    }
  })
}

const country = [
  takeLatest(`${COUNTRY_LIST}_REQUESTED`, getCountryList)
];

export default country;