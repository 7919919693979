import React from 'react';
import styled from 'styled-components';
import FormElements from '../../shared/FormElements';

interface Props {
  name?: string;
}

const Container = styled.div`
  display: inline-flex;
  align-items: center;
  border: 1px solid #000;
  border-radius: 6px;
  height: 26px;
  font-size: 14px;
`;

const Input = styled(FormElements.Input)`
  padding: 0 8px;
  height: 100%;
  max-width: 120px;
  font-size: 14px;
  border: none;
`;

const Sign = styled.div`
  line-height: 26px;
  padding: 0 8px;
  border-left: 1px solid #000;
`;


class FeeInput extends React.Component<Props> {
  render() {
    const { name } = this.props;
    return (
      <Container>
        <Input
          name={name} 
        />
        <Sign>%</Sign>
      </Container>
    );
  }
}

export default FeeInput;