import { createStore, applyMiddleware } from 'redux';
import rootReducer from './reducers';
import createSagaMiddleware from 'redux-saga';
import rootSaga from './sagas';
import { LOCAL_STORAGE_STORE_NAME } from './constants/config';
import { loadState } from './utils';
import { StoreModel } from './models/StoreModel';
import { authLogout } from './actions/auth';

const sagaMiddleware = createSagaMiddleware();
const initialState = loadState();

const store = createStore(
  rootReducer,
  initialState,
  applyMiddleware(sagaMiddleware)
);

const rootTask = sagaMiddleware.run(rootSaga);

rootTask.done.catch(function (err) {
  if (err.status === 401) {
    store.dispatch(authLogout());
  }
});

store.subscribe(() => {
  const state: StoreModel = JSON.parse(JSON.stringify(store.getState()));

  delete state.modal.currentModal;
  delete state.modal.scope;
  delete state.ads.searchAds;
  delete state.ads.openedAd;
  delete state.chat.chat;
  delete state.chat.chatHistory;
  delete state.loading;
  delete state.errorResponse;
  delete state.successResponse;
  delete state.user.statuses;

  localStorage.setItem(LOCAL_STORAGE_STORE_NAME, JSON.stringify(state));
});

export default store;