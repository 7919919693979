export const CHAT_GET_CHAT = 'CHAT_GET_CHAT';
export const CHAT_GET_CHAT_SUCCESS = 'CHAT_GET_CHAT_SUCCESS';
export const CHAT_GET_CHAT_REQUESTED = 'CHAT_GET_CHAT_REQUESTED';
export const CHAT_GET_CHAT_FAILED = 'CHAT_GET_CHAT_FAILED';
export const CHAT_GET_CHAT_CLEAR = 'CHAT_GET_CHAT_CLEAR';

export const CHAT_GET_HISTORY = 'CHAT_GET_HISTORY';
export const CHAT_GET_HISTORY_SUCCESS = 'CHAT_GET_HISTORY_SUCCESS';
export const CHAT_GET_HISTORY_REQUESTED = 'CHAT_GET_HISTORY_REQUESTED';
export const CHAT_GET_HISTORY_FAILED = 'CHAT_GET_HISTORY_FAILED';

export const CHAT_GET_OPEN_CHATS = 'CHAT_GET_OPEN_CHATS';
export const CHAT_GET_OPEN_CHATS_SUCCESS = 'CHAT_GET_OPEN_CHATS_SUCCESS';
export const CHAT_GET_OPEN_CHATS_REQUESTED = 'CHAT_GET_OPEN_CHATS_REQUESTED';
export const CHAT_GET_OPEN_CHATS_FAILED = 'CHAT_GET_OPEN_CHATS_FAILED';

export const CHAT_CREATE_NEW_CHAT = 'CHAT_CREATE_NEW_CHAT';
export const CHAT_CREATE_NEW_CHAT_SUCCESS = 'CHAT_CREATE_NEW_CHAT_SUCCESS';
export const CHAT_CREATE_NEW_CHAT_REQUESTED = 'CHAT_CREATE_NEW_CHAT_REQUESTED';
export const CHAT_CREATE_NEW_CHAT_FAILED = 'CHAT_CREATE_NEW_CHAT_FAILED';

export const CHAT_CHANGE_TRADE_STATUS = 'CHAT_CHANGE_TRADE_STATUS';

export const CHAT_CREATE_RESET = 'CHAT_CREATE_RESET';