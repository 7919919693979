import React from 'react';
import styled from 'styled-components';
import { withNamespaces, WithNamespaces } from 'react-i18next';
import HomeStyledPlaces from '../Places/HomeStyledPlaces';
import { PlaceModel } from '../../models/PlaceModel';

interface Props {
  onPlaceSelect: (place: PlaceModel) => void;
}

const Title = styled.div`
  font-size: 2.2rem;
  font-weight: 700;
  color: #000;
  line-height: 2.6rem;
  margin-bottom: 1rem;
`;

class CitySearchInput extends React.Component<Props & WithNamespaces> {
  render() {
    const { t, onPlaceSelect } = this.props;
    return (
      <>
        <Title>{t('STARTPAGE_SEARCH_BLOCK_WHERE_LABEL')}</Title>
        <HomeStyledPlaces
          onPlaceSelect={onPlaceSelect}
        />
      </>
    );
  }
}

export default withNamespaces()(CitySearchInput);