import { SignupBodyModel } from "../models/SignupBodyModel";

export const AUTH_SAVE_TOKENS = 'AUTH_SIGNUP';

export const AUTH_NAGA = 'AUTH_NAGA';
export const AUTH_NAGA_REQUESTED = 'AUTH_NAGA_REQUESTED';
export const AUTH_NAGA_SUCCESS = 'AUTH_NAGA_SUCCESS';
export const AUTH_NAGA_FAILED = 'AUTH_NAGA_FAILED';
export const authNaga = (data: SignupBodyModel) => ({
  type: AUTH_NAGA_REQUESTED,
  payload: {
    data
  }
});

export const AUTH_SIGNUP = 'AUTH_SIGNUP';
export const AUTH_SIGNUP_REQUESTED = 'AUTH_SIGNUP_REQUESTED';
export const AUTH_SIGNUP_SUCCESS = 'AUTH_SIGNUP_SUCCESS';
export const AUTH_SIGNUP_FAILED = 'AUTH_SIGNUP_FAILED';
export const authSignup = (data: SignupBodyModel) => ({
  type: AUTH_SIGNUP_REQUESTED,
  payload: {
    data
  }
});

export const AUTH_LOGIN = 'AUTH_LOGIN';
export const AUTH_LOGIN_REQUESTED = 'AUTH_LOGIN_REQUESTED';
export const AUTH_LOGIN_SUCCESS = 'AUTH_LOGIN_SUCCESS';
export const AUTH_LOGIN_FAILED = 'AUTH_LOGIN_FAILED';
export const authLogin = (data: any) => ({
  type: AUTH_LOGIN_REQUESTED,
  payload: {
    data
  }
});

export const AUTH_FORGOT_PASSWORD = 'AUTH_FORGOT_PASSWORD';
export const AUTH_FORGOT_PASSWORD_REQUESTED = 'AUTH_FORGOT_PASSWORD_REQUESTED';
export const AUTH_FORGOT_PASSWORD_SUCCESS = 'AUTH_FORGOT_PASSWORD_SUCCESS';
export const AUTH_FORGOT_PASSWORD_FAILED = 'AUTH_FORGOT_PASSWORD_FAILED';
export const authForgotPassword = (email?: string) => ({
  type: AUTH_FORGOT_PASSWORD_REQUESTED,
  payload: {
    email
  }
});

export const AUTH_REFRESH = 'AUTH_REFRESH';

export const AUTH_LOGOUT = 'AUTH_LOGOUT';
export const authLogout = () => ({
  type: AUTH_LOGOUT
});


export const AUTH_VERIFY_EMAIL = 'AUTH_VERIFY_EMAIL';
export const AUTH_VERIFY_EMAIL_REQUESTED = 'AUTH_VERIFY_EMAIL_REQUESTED';
export const AUTH_VERIFY_EMAIL_SUCCESS = 'AUTH_VERIFY_EMAIL_SUCCESS';
export const AUTH_VERIFY_EMAIL_FAILED = 'AUTH_VERIFY_EMAIL_FAILED';
export const authVerifyEmail = (token: string, isUserLoggedIn: boolean) => ({
  type: AUTH_VERIFY_EMAIL_REQUESTED,
  payload: {
    token,
    isUserLoggedIn
  }
});

export const AUTH_UPDATE_PASSWORD_WITH_TOKEN = 'AUTH_UPDATE_PASSWORD_WITH_TOKEN';
export const AUTH_UPDATE_PASSWORD_WITH_TOKEN_REQUESTED = 'AUTH_UPDATE_PASSWORD_WITH_TOKEN_REQUESTED';
export const AUTH_UPDATE_PASSWORD_WITH_TOKEN_SUCCESS = 'AUTH_UPDATE_PASSWORD_WITH_TOKEN_SUCCESS';
export const AUTH_UPDATE_PASSWORD_WITH_TOKEN_FAILED = 'AUTH_UPDATE_PASSWORD_WITH_TOKEN_FAILED';
export const authUpdatePasswordWithToken = (token: string, newPassword: string, newPasswordConfirmation: string) => ({
  type: AUTH_UPDATE_PASSWORD_WITH_TOKEN,
  payload: {
    token,
    newPassword,
    newPasswordConfirmation
  }
});


export const AUTH_UPDATE_PASSWORD_WITH_TOKEN_RESET = 'AUTH_UPDATE_PASSWORD_WITH_TOKEN_RESET';
export const authUpdatePasswordWithTokenReset = () => ({
  type: AUTH_UPDATE_PASSWORD_WITH_TOKEN_RESET
});