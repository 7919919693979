import React from 'react';
import styled from 'styled-components';
import screens from '../../constants/screens';

interface Props {
  children: React.ReactNode;
}

const Container = styled.div`
  display: none;
  ${screens.mobile} {
    display: block;
  }
`;

class Mobile extends React.Component<Props> {
  render() {
    return (
      <Container>
        {this.props.children}
      </Container>
    )
  }
}

export default Mobile;