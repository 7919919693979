import styled from 'styled-components';
import closeModalButton from '../../hoc/closeModalButton';
import Button from '../Button';
import FormElements from '../../shared/FormElements';
import { RED_CF } from '../../constants/colors';

const Container = styled.div`
  width: 100%;
  max-width: 530px;
  margin: 0 20px;
  background-color: #fff;
  border-radius: 6px;
  box-shadow: 0 0 24px 0 rgba(0,0,0,.1);
  overflow: hidden;
`;

const Header = styled.div`
  font-size: 14px;
  font-weight: 700;
  text-align: center;
  padding: 13px 8px;
  background-color: ${({ isWarning }: any) => isWarning ? RED_CF : '#2bbc70'};
  color: #fff;
` as any;

const Content = styled.div`
  font-size: 14px;
  font-weight: 400;
  text-align: center;
  padding: 38px 16px 16px;
`;

const Title = styled.div`
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 16px;
  line-height: 20px;
`;

const Footer = styled.div`
  background-color: #fafafa;
  font-size: 14px;
  font-weight: 400;
  text-align: center;
  padding: 17px 16px 18px;
`;

const Input = styled(FormElements.Input)`
  max-width: 200px;
  width: 100%;
  height: 32px;
  font-size: 14px;
  border: 1px solid #000;
  border-radius: 6px;
  padding: 0 8px;
  margin: 8px 0 16px;
`;

const RadioItem = styled.label`
  position: relative;
  display: flex;
  max-width: 320px;
  margin: 0 auto;
  cursor: pointer;
  &:not(:last-child) {
    margin-bottom: 13px;
  }
`;

const Radio = FormElements.RadioButton;

const Textarea = styled(FormElements.Textarea)`
  max-width: 320px;
  width: 100%;
  padding: 8px;
  height: 90px;
  font-size: 14px;
  border-radius: 6px;
  border: 1px solid #000;
  margin: 8px 0 24px;
  resize: none;
`;

const ActionButton = styled(Button)`
  display: block;
  background-color: #2bbc70;
  border-radius: 6px;
  margin: 8px auto;
  height: 35px;
  font-size: 14px;
  font-weight: 700;
  color: #fff;
`;

const CancelButton = styled.button`
  display: block;
  margin: 0 auto;
  border-radius: 6px;
  border: 1px solid #000;
  min-width: 85px;
  height: 21px;
  font-size: 10px;
  font-weight: 700;
`;

const RateButton = styled.button`
  display: block;
  margin: 11px auto 16px;
  border-radius: 6px;
  min-width: 104px;
  height: 28px;
  font-size: 14px;
  font-weight: 700;
  background-color: #fdca35;
`;

const PopupsComponents = {
  Container,
  Header,
  Content,
  Title,
  Footer,
  Textarea,
  Input,
  RadioItem,
  Radio,
  ActionButton,
  CancelButton: closeModalButton(CancelButton),
  RateButton
};

export default PopupsComponents;