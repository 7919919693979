import React from 'react';
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from 'react-places-autocomplete';
import styled from 'styled-components';
import { PlaceModel } from '../../models/PlaceModel';
import LocationIcon from '../Icons/LocationIcon';
import { withNamespaces, WithNamespaces } from 'react-i18next';

interface LatLngModel {
  lat: number;
  lng: number;
}

interface Props {
  onPlaceSelect: (place: PlaceModel) => void;
}
interface State {
  address: string
}

const InputContainer = styled.div`
  display: flex;
  align-items: flex-end;
`;

const Container = styled.div`
  position: relative;
`;

const Input = styled.input`
  padding: 0 8px;
  font-size: 17px;
  color: #7f7f7f;
`;

const SuggestionContainer = styled.div`
  position: absolute;
  width: 100%;
  border: 1px solid #7f7f7f;
  background-color: #fff;
  border-radius: 6px;
  padding: 0 8px;
  font-size: 14px;
  &:empty {
    display: none;
  }
`;

const Suggestion = styled.div`
  margin: 4px 0; 
`;

class HomeStyledPlaces extends React.Component<Props & WithNamespaces, State> {
  state: State = {
    address: ''
  }

  onChange = (address: string) => {
    this.setState({
      address
    });
  }

  onSelect = (address: string) => {
    geocodeByAddress(address)
      .then((results: any) => getLatLng(results[0]))
      .then((latLng: LatLngModel) => {
        this.setState({
          address
        });
        this.props.onPlaceSelect({
          location: address,
          longitude: latLng.lng,
          latitude: latLng.lat
        });
      });
  };

  render() {
    const { t } = this.props;
    return (
      <PlacesAutocomplete
        value={this.state.address}
        onChange={this.onChange}
        onSelect={this.onSelect}
      >
        {({ getInputProps, suggestions, getSuggestionItemProps, loading }: any) => (
          <Container>
            <InputContainer>
              <LocationIcon />
              <Input
                {...getInputProps({
                  placeholder: t('STARTPAGE_SEARCH_BLOCK_WHERE_INLINE_LABEL')
                })}
              />
            </InputContainer>
            <SuggestionContainer>
              {loading && <div>{t('LOADING')}</div>}
              {suggestions.map((suggestion: any) => {
                return (
                  <Suggestion
                    key={suggestion.id}
                    {...getSuggestionItemProps(suggestion)}
                  >
                    <span>{suggestion.description}</span>
                  </Suggestion>
                );
              })}
            </SuggestionContainer>
          </Container>
        )}
      </PlacesAutocomplete>
    )
  }
}

export default withNamespaces()(HomeStyledPlaces);