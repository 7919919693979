import React from 'react';
import { 
  Container,
  Head,
  Title,
  Row,
  Label,
  Wrapper
} from './Form';
import { connect } from 'react-redux';
import { withNamespaces, Trans, WithNamespaces } from 'react-i18next';
import { setModal } from '../../actions/modal';
import styled from 'styled-components';
import { RED_E0 } from '../../constants/colors';
import modals from '../../constants/modals';

const StyledLabel = styled(Label)`
  display: block;
  button {
    font-size: inherit;
    text-decoration: underline;
    color: ${RED_E0};
  }
`;

interface Props {
  openModal: (modal: string) => void;
}

class ResetPasswordSuccess extends React.Component<Props & WithNamespaces> {
  openLoginModal = () => {
    this.props.openModal(modals.LOGIN);
  }
  render() {
    const { t } = this.props;
    return (
      <Container>
        <Wrapper>
          <Head>
            <Title>{t('AUTH_MODAL_RESET_PASSWORD')}</Title>    
          </Head>
          <Row>
            <Label>{t('AUTH_MODAL_RESET_PASSWORD_WAS_UPDATED')}</Label>
            <StyledLabel>
              <Trans i18nKey="AUTH_MODAL_RESET_PASSWORD_LOGIN">
                Please click here to <button onClick={this.openLoginModal} type="button">log in</button>
              </Trans>
            </StyledLabel>
          </Row>
        </Wrapper>
      </Container>
    );
  }
}

const mapDispatchToProps = (dispatch: any) => ({
  openModal: (modal: string) => dispatch(setModal(modal))
});

export default connect(null, mapDispatchToProps)(withNamespaces()(ResetPasswordSuccess));