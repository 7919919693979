import React from 'react';
import styled from 'styled-components';

interface Props {
  topic: any;
  onSelect: (topic: any) => void;
}

const TopicButton = styled.button`
  position: relative;
  display: block;
  width: 100%;
  text-align: left;
  padding: 16px 8px 16px 16px;
  font-size: 1.6rem;
  font-weight: ${({ isActive }: any) => isActive ? '700': '500'};
  &:not(:last-child) {
    border-bottom: 1px solid #000;
  }
  &:after {
    display: block;
    content: '';
    position: absolute;
    top: 50%;
    right: 14px;
    width: 10px;
    height: 10px;
    border-top: 1px solid #000;
    border-right: 1px solid #000;
    transform: translateY(-50%) rotate(45deg);
  }
` as any;

class Topic extends React.Component<Props> {
  onClick = () => {
    this.props.onSelect(this.props.topic);
  }
  render() {
    const { topic } = this.props;
    return (
      <TopicButton onClick={this.onClick} isActive={topic.active}>{topic.title}</TopicButton>
    )
  }
}

export default Topic;
