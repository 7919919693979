
export const USER_CURRENT_GET = 'USER_CURRENT_GET';
export const USER_CURRENT_GET_SUCCESS = 'USER_CURRENT_GET_SUCCESS';
export const USER_CURRENT_GET_REQUESTED = 'USER_CURRENT_GET_REQUESTED';
export const USER_CURRENT_GET_FAILED = 'USER_CURRENT_GET_FAILED';

export const USER_GET = 'USER_GET';
export const USER_GET_SUCCESS = 'USER_GET_SUCCESS';
export const USER_GET_REQUESTED = 'USER_GET_REQUESTED';
export const USER_GET_FAILED = 'USER_GET_FAILED';

export const USER_SET_PIN = 'USER_SET_PIN';
export const USER_SET_PIN_SUCCESS = 'USER_SET_PIN_SUCCESS';
export const USER_SET_PIN_REQUESTED = 'USER_SET_PIN_REQUESTED';
export const USER_SET_PIN_FAILED = 'USER_SET_PIN_FAILED';

export const USER_UPDATE_PIN = 'USER_UPDATE_PIN';
export const USER_UPDATE_PIN_SUCCESS = 'USER_UPDATE_PIN_SUCCESS';
export const USER_UPDATE_PIN_REQUESTED = 'USER_UPDATE_PIN_REQUESTED';
export const USER_UPDATE_PIN_FAILED = 'USER_UPDATE_PIN_FAILED';

export const USER_REPORT = 'USER_REPORT';
export const USER_REPORT_SUCCESS = 'USER_REPORT_SUCCESS';
export const USER_REPORT_REQUESTED = 'USER_REPORT_REQUESTED';
export const USER_REPORT_FAILED = 'USER_REPORT_FAILED';

export const USER_UPDATE = 'USER_UPDATE';
export const USER_UPDATE_SUCCESS = 'USER_UPDATE_SUCCESS';
export const USER_UPDATE_REQUESTED = 'USER_UPDATE_REQUESTED';
export const USER_UPDATE_FAILED = 'USER_UPDATE_FAILED';

export const USER_UPDATE_AVATAR = 'USER_UPDATE_AVATAR';
export const USER_UPDATE_AVATAR_SUCCESS = 'USER_UPDATE_AVATAR_SUCCESS';
export const USER_UPDATE_AVATAR_REQUESTED = 'USER_UPDATE_AVATAR_REQUESTED';
export const USER_UPDATE_AVATAR_FAILED = 'USER_UPDATE_AVATAR_FAILED';

export const USER_UPDATE_PASSWORD = 'USER_UPDATE';
export const USER_UPDATE_PASSWORD_SUCCESS = 'USER_UPDATE_PASSWORD_SUCCESS';
export const USER_UPDATE_PASSWORD_REQUESTED = 'USER_UPDATE_PASSWORD_REQUESTED';
export const USER_UPDATE_PASSWORD_FAILED = 'USER_UPDATE_PASSWORD_FAILED';

export const USER_EMAIL_VERIFY = '[User] User email verify';