import styled from 'styled-components'
import { BLUE_17, RED_E0, GREY_D7, GREY_97 } from '../../constants/colors';
import facebookLogo from '../../images/common/facebook_logo.svg';
import googleLogo from '../../images/common/google_logo.svg';
import screens from '../../constants/screens';
import ButtonTest from '../Button';
import UI from '../../shared/UI';
import FormElements from '../../shared/FormElements';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  flex-shrink: 0;
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  ${screens.mobile} {
    width: 100%;
  }
`;

export const Head = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: 52px;
`;

export const Title = styled.div`
  font-size: 32px;
  font-weight: 500;
  color: ${BLUE_17};
  text-transform: capitalize;
`;

export const Link = styled.a`
  font-size: 16px;
  color: #000;
  font-weight: 300;
  cursor: pointer;
`;

export const LinkColored = styled(Link)`
  color: ${RED_E0};
`;

export const Form = styled(UI.Form)`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  ${screens.mobile} {
    width: 100%;
  }
` as any;

export const Info = styled.div`
  color: ${GREY_97};
  font-size: 16px;
  font-weight: 300;
  margin-top: 80px;
  margin-bottom: 66px;
`;

export const Row = styled.div`
  &:not(:last-child) {
    margin-bottom: 40px;
  }
`;

export const LastRow = styled(Row)`
  margin-top: auto;
  margin-bottom: 88px !important;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  ${screens.mobile} {
    flex-wrap: wrap;
    justify-content: center;
  }
`;

export const BottomButtonsContainer = styled(ButtonsContainer)`
  margin-top: auto;
`;

export const Button = styled(ButtonTest)`
  margin-right: auto;
  height: 40px;
  font-size: 19px;
  text-transform: uppercase;
  color: #fff;
  min-width: 200px;
  background-color: ${({ disabled }) => disabled ? '#919191' : '#f00'};
  border-radius: 3px;
  font-weight: 300;
  ${screens.mobile} {
    width: 100%;
    margin-bottom: 20px;
  }
`;

export const SocialButtonsTitle = styled.span`
  font-size: 16px;
  font-weight: 300;
  margin-right: 16px;
`;

export const SocialButton = styled.button`
  width: 64px;
  min-width: 64px;
  height: 40px;
  border: 1px solid ${GREY_D7};
  border-radius: 3px;
  background-repeat: no-repeat;
  background-position: center center;
`;

export const FacebookButton = styled(SocialButton)`
  background-image: url(${facebookLogo});
  background-size: 12px 24px;

`;
export const GoogleButton = styled(SocialButton)`
  margin-left: 16px;
  background-image: url(${googleLogo});
  background-size: 24px 24px;
`;

export const Label = styled.label`
  display: flex;
  justify-content: space-between;
  font-size: 16px;
  line-height: 29px;
  font-weight: 400;
  color: #000;
  &:not(:first-child) {
    margin-top: 14px;
  }
`;

export const Input = styled(FormElements.Input)`
  border-radius: 3px;
  border: 1px solid;
  width: 100%;
  height: 40px;
  padding: 0 10px;
  font-size: 16px;
  appearance: none;
  max-width: none;
  border-color: ${({err}: any) => err ? '#ed2024' : GREY_D7};
  background-color: ${({err}: any) => err ? 'rgba(237,32,36, .3)' : '#fff'}; 
`;