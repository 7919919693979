import React from 'react';
import styled from 'styled-components';
import queryString from 'query-string';
import Map from '../../components/Map';
import SearchTradeForm from './SearchTradeForm';
import SearchTradeList from './SearchTradeList';
import { connect } from 'react-redux';
import { StoreModel } from '../../models/StoreModel';
import { AdModel } from '../../models/AdModel';
import { RouteComponentProps } from 'react-router';
import screens from '../../constants/screens';
import { isUserLoggedIn } from '../../selectors/user';
import { API_URL } from '../../constants/config';

interface Props {
  isUserLoggedIn: boolean;
}

interface State {
  hasMore: boolean;
  ads: AdModel[];
  page: number;
  title: string;
  type?: string | string[] | null;
  latitude: string | string[] | null;
  location: string;
  longitude: string | string[] | null;
}

const Wrapper = styled.div`
  display: flex;
  max-width: 992px;
  overflow: hidden;
  margin: 0 auto;
  padding: 0 20px 30px;
`;

const LeftContainer = styled.div`
  flex-basis: 56%;
  ${screens.mobile} {
    flex-basis: 100%;
  }
`;

const RightContainer = styled.div`
  margin-left: 30px;
  flex-grow: 1;
  height: 760px;
  box-shadow: 0 0 24px 0 rgba(0,0,0,.1);
  ${screens.mobile} {
    display: none;
  }
`;

class SearchTrade extends React.Component<Props & RouteComponentProps<any>, State> {
  adsPerPage: number = 10;
  state: State = {
    hasMore: true,
    ads: [],
    page: 1,
    title: '',
    type: null,
    latitude: null,
    location: '',
    longitude: null,
  }
  componentDidMount() {
    if (this.props.location.search) {
      const body = queryString.parse(this.props.location.search);

      this.setState({
        type: body.type
      });

      this.sendSearchRequest({
        title: body.title,
        type: body.type,
        longitude: body.longitude,
        latitude: body.latitude,
      });
    }
  }
  componentWillReceiveProps(nextProps: Props & RouteComponentProps<any>) {
    if (this.props.location.search !== nextProps.location.search || (this.props.isUserLoggedIn !== nextProps.isUserLoggedIn)) {
      const body = queryString.parse(nextProps.location.search);

      this.setState({
        ads: [],
        page: 1,
        hasMore: true
      }, () => {
        this.sendSearchRequest({
          title: body.title,
          type: body.type,
          longitude: body.longitude,
          latitude: body.latitude
        });
      });
    }
  }
  sendSearchRequest = (body: any, reset?: boolean) => {
    for (let f in body) {
      const field = body[f];

      if (!field || !field.length) {
        delete body[f];
      }
    }

    const query = queryString.stringify({
      page: this.state.page,
      ...body
    });
    
    fetch(`${API_URL}/trade/search?${query}`, {
      method: 'GET',
      headers: {
        "Content-Type": "application/json; charset=utf-8"
      }
    }).then((res) => {
      return res.json();
    }).then((res: AdModel[]) => {
      const ads = Array.isArray(res) ? res : [];

      this.setState({
        ads: reset ? ads : [...this.state.ads, ...ads],
        hasMore: ads.length !== 0
      });
    });
  }
  loadMore = () => {
    this.setState((prevState: State) => {
      return {
        ...prevState,
        page: prevState.page + 1
      }
    }, () => {
      this.sendSearchRequest({
        title: this.state.title.length,
        type: this.state.type,
        longitude: this.state.longitude,
        latitude: this.state.latitude,
      });
    });
  }
  onFormSubmit = (e: React.SyntheticEvent<HTMLFormElement>) => {
    e.preventDefault();

    this.setState({
      page: 1
    }, () => {
      this.sendSearchRequest({
        title: this.state.title,
        type: this.state.type,
        longitude: this.state.longitude,
        latitude: this.state.latitude
      }, true);
    });
  }
  onTitleChange = (title: string) => {
    this.setState({
      title
    });
  }
  onPlaceSelect = (place: any) => {
    this.setState({
      ...place
    });
  }
  onPlaceChange = (address: string) => {
    if (!address.length) {
      this.setState({
        location: '',
        latitude: null,
        longitude: null,
      });
    }
  }
  getMarkersCoordinates = () => {
    if (!this.state.ads) { return []; }

    return this.state.ads.map((ad: AdModel) => ({
      id: ad.id,
      title: ad.title,
      username: ad.user.username,
      rating: ad.user.rating,
      lat: ad.latitude,
      lng: ad.longitude,
      type: ad.type
    }));
  }
  render() {
    const { latitude, longitude } = this.state;
    return (
      <div>
        <Wrapper>
          <LeftContainer>
            <SearchTradeForm
              onTitleChange={this.onTitleChange}
              onFormSubmit={this.onFormSubmit}
              onPlaceSelect={this.onPlaceSelect}
              onPlaceChange={this.onPlaceChange}
            />
            {
              this.state.ads.length !== 0 && (
                <SearchTradeList
                  ads={this.state.ads}
                  loadMore={this.loadMore}
                  hasMore={this.state.hasMore}
                />
              )
            }
          </LeftContainer>
          <RightContainer>
            <Map
              lat={latitude}
              lng={longitude}
              markers={this.getMarkersCoordinates()}
            />
          </RightContainer>
        </Wrapper>
      </div>
    );
  }  
}

const mapStateToProps = (state: StoreModel) => ({
  isUserLoggedIn: isUserLoggedIn(state),
});

export default connect(mapStateToProps)(SearchTrade);