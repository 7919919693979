import React from 'react';
import styled from 'styled-components';
import bannerImg from '../../images/pages/home/banner.png';
import { Trans, WithNamespaces, withNamespaces } from 'react-i18next';
import screens from '../../constants/screens';

interface ContainerProps {
  banner: string;
}

const Container = styled.div`
  display: flex;
  background-image: url(${({ banner }: ContainerProps) => banner});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  min-height: 550px;
` as any;

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 20px;
  ${screens.mobile} {
    text-align: center;
  }
`;

const Title = styled.h1`
  font-weight: 700;
  font-size: 3.8rem;
  line-height: 4.5rem;
  color: #fff;
  margin: 0 0 1.6rem 0;
  max-width: 800px;
  width: 100%;
  text-transform: uppercase;
`

const SubTitle = styled.h3`
  font-weight: 400;
  font-size: 2rem;
  line-height: 2.5rem;
  color: #fff;
  margin: 0;
  max-width: 800px;
  width: 100%;
`

class HomeBanner extends React.Component<WithNamespaces> {
  render() {
    return (
      <Container banner={bannerImg}>
        <Wrapper>
          <Title>
            <Trans i18nKey="STARTPAGE_BANNER_IMAGE_TITLE">
              Buy or sell<br/> cryptocurrencies<br/> with cash
            </Trans>
          </Title>
          <SubTitle>
            <Trans i18nKey="STARTPAGE_BANNER_IMAGE_DESCRIPTION_LINE_1" />
            <br/>
            <Trans i18nKey="STARTPAGE_BANNER_IMAGE_DESCRIPTION_LINE_2" />
          </SubTitle>
        </Wrapper>
      </Container>
    );
  }
}

export default withNamespaces()(HomeBanner);