import React from 'react';
import styled from 'styled-components';
import errorIcon from '../../images/common/error.svg';

const Container = styled.div`
  text-align: center;
`;

const ErrorIcon = styled.img`
  display: inline-block;
  max-width: 240px;
  height: 100%;
  width: 100%;
  margin: 0 auto 24px;
`;

const ErrorTitle = styled.div`
  font-size: 1.8rem;
  margin-bottom: 32px;
`;

const ReloadButton = styled.button`
  color: #fff;
  font-size: 1.8rem;
  height: 40px;
  background-color: #2bbc70;
  padding: 0 32px;
  border-radius: 6px;
  font-weight: 300;
`;

class Error extends React.Component {
  reloadPage = () => {
    location.reload();
  }
  render() {
    return (
      <Container>
        <ErrorIcon src={errorIcon} />
        <ErrorTitle>Something went wrong, please reload the page</ErrorTitle>
        <ReloadButton onClick={this.reloadPage}>Reload the page</ReloadButton>
      </Container>
    );
  }
}

export default Error;