import React from 'react';
import styled from 'styled-components';
import SearchCheckbox from './SearchCheckbox';
import CitySearchInput from './CitySearchInput';
import ActionIcon from '../Icons/ActionIcon';
import { RED_E0 } from '../../constants/colors';
import screens from '../../constants/screens';
import { PlaceModel } from '../../models/PlaceModel';
import { connect } from 'react-redux';
import { StoreModel } from '../../models/StoreModel';
import { getSearchedAds } from '../../selectors/ads';
import { AD_SEARCH } from '../../actions/ads';
import { AdModel } from '../../models/AdModel';
import * as queryString from 'query-string';
import { RouteComponentProps, withRouter } from 'react-router';

interface Props {
  searchAds: (body: any) => void;
  ads: AdModel[] | null;
}
interface State {
  type?: number;
  location?: string;
  latitude?: number;
  longitude?: number;
}

const Container = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 2.8rem;
`;

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  max-width: 800px;
  margin: 0 2rem;
  box-shadow: 2px 2px 10px 0 rgba(0,0,0, .2);
  transform: translateY(-50%);
  border-radius: 6px;
  background-color: #fff;
`;

const SearchButton = styled.button`
  width: 96px;
  background-color: ${RED_E0};
  margin-left: auto;
  height: 100%;
  border-radius: 0 6px 6px 0;
`;

const SearchContainer = styled.div`
  display: flex;
  padding: 13px 34px;
  ${screens.mobile} {
    display: block;
    padding: 13px 20px;
  }
`

const CheckboxContainer = styled.div`
  ${screens.mobile} {
    margin-bottom: 2rem;
  }
`;

const CitySearchContainer = styled.div`
  margin-left: 5.8rem;
  ${screens.mobile} {
    margin-left: 0;
  }
`;

class TraderSearch extends React.Component<Props & RouteComponentProps<any>, State> {
  state: State = {
    type: undefined,
    location: undefined,
    latitude: undefined,
    longitude: undefined
  }
  onTypeSelect = (e: React.SyntheticEvent<HTMLInputElement>) => {
    this.setState({
      type: (e.target as any).value
    });
  }
  onPlaceSelect = (place: PlaceModel) => {
    this.setState({
      ...place
    });
  }
  onClick = () => {
    const body = queryString.stringify({
      type: this.state.type,
      latitude: this.state.latitude,
      longitude: this.state.longitude
    });
    this.props.history.push(`/trade/search?${body}`);
  }
  render() {
    return (
      <Container>
        <Wrapper>
          <SearchContainer>
            <CheckboxContainer>
              <SearchCheckbox
                onTypeSelect={this.onTypeSelect}
                type={this.state.type}
              />
            </CheckboxContainer>
            <CitySearchContainer>
              <CitySearchInput
                onPlaceSelect={this.onPlaceSelect}
              />
            </CitySearchContainer>
          </SearchContainer>
          <SearchButton onClick={this.onClick}>
            <ActionIcon />
          </SearchButton>
        </Wrapper>
      </Container>
    );
  }
}

const mapStateToProps = (state: StoreModel) => ({
  ads: getSearchedAds(state)
});

const mapDispatchToProps = (dispatch: any) => ({
  searchAds: (body: any) => {
    dispatch({
      type: `${AD_SEARCH}_REQUESTED`,
      payload: {
        body
      }
    })
  }
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(TraderSearch));