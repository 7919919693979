import React from 'react';
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from 'react-places-autocomplete';
import styled from 'styled-components';
import { PlaceModel } from '../../models/PlaceModel';
import LocationIcon from '../Icons/LocationIcon';
import { withNamespaces, WithNamespaces } from 'react-i18next';

interface LatLngModel {
  lat: number;
  lng: number;
}

interface Props {
  defaultValue?: string;
  onSelect: (place: PlaceModel) => void;
  onChange: (address: string) => void;
}
interface State {
  address?: string
}

const InputContainer = styled.div`
  position: relative;
  height: 52px;
  border: 1px solid #7f7f7f;
  border-radius: 6px;
`;

const StyledLocationIcon = styled(LocationIcon)`
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  left: calc(54px / 2);
`;

const Container = styled.div`
  position: relative;
`;

const Input = styled.input`
  width: 100%;
  height: 100%;
  padding: 0 8px 0 54px;
  font-size: 17px;
  color: #7f7f7f;
`;

const SuggestionContainer = styled.div`
  position: absolute;
  border: 1px solid #7f7f7f;
  background-color: #fff;
  border-radius: 6px;
  width: 100%;
  padding: 0 8px;
  font-size: 14px;
  z-index: 1;
  &:empty {
    display: none;
  }
`;

const Suggestion = styled.div`
  margin: 4px 0; 
`;

class StyledPlaces extends React.Component<Props & WithNamespaces, State> {
  state: State = {
    address: ''
  }

  componentWillReceiveProps(nextProps: Props & WithNamespaces) {
    if (!this.state.address && nextProps.defaultValue) {
      this.setState({
        address: this.props.defaultValue
      });
    }
  }
  
  onChange = (address: string) => {
    this.props.onChange(address);
    this.setState({
      address
    });
  }

  onSelect = (address: string) => {
    if (address.length) {
      geocodeByAddress(address)
      .then((results: any) => getLatLng(results[0]))
      .then((latLng: LatLngModel) => {
        this.setState({
          address
        });
        this.props.onSelect({
          location: address,
          longitude: latLng.lng,
          latitude: latLng.lat
        });
      });
    }
  };

  render() {
    const { t } = this.props;
    return (
      <PlacesAutocomplete
        value={this.state.address}
        onChange={this.onChange}
        onSelect={this.onSelect}
      >
        {({ getInputProps, suggestions, getSuggestionItemProps, loading }: any) => (
          <Container>
            <InputContainer>
              <StyledLocationIcon />
              <Input
                {...getInputProps({
                  placeholder: t('SEARCH_PLACES')
                })}
              />
            </InputContainer>
            <SuggestionContainer>
              {loading && <div>{t('LOADING')}</div>}
              {suggestions.map((suggestion: any) => {
                return (
                  <Suggestion
                    key={suggestion.id}
                    {...getSuggestionItemProps(suggestion)}
                  >
                    <span>{suggestion.description}</span>
                  </Suggestion>
                );
              })}
            </SuggestionContainer>
          </Container>
        )}
      </PlacesAutocomplete>
    )
  }
}

export default withNamespaces()(StyledPlaces);