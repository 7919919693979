import Input from './Input';
import Textarea from './Textarea';
import Checkbox from './Checkbox';
import RadioButton from './RadioButton';

const FormElements = {
  Input,
  Textarea,
  Checkbox,
  RadioButton,
};

export default FormElements;