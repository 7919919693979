import React from 'react';
import Static from '../../shared/Static';
import { withNamespaces, WithNamespaces } from 'react-i18next';

class Privacy extends React.Component<WithNamespaces> {
  render() {
    const { t } = this.props;
    return (
      <Static.Container>
        <Static.Header>{t('PRIVACY_TITLE')}</Static.Header>
        <Static.UpdatedHeader>{t('PRIVACY_UPDATED')}</Static.UpdatedHeader>
        <Static.Paragraph>{t('PRIVACY_S_0_P_1')}</Static.Paragraph>
        <Static.Paragraph>{t('PRIVACY_S_0_P_2')}</Static.Paragraph>
        <Static.Paragraph>{t('PRIVACY_S_0_P_3')}</Static.Paragraph>

        <Static.SubHeader>{t('PRIVACY_S_1_TITLE')}</Static.SubHeader>
        <Static.Paragraph>{t('PRIVACY_S_1_P_1')}</Static.Paragraph>

        <Static.SubHeader>{t('PRIVACY_S_2_TITLE')}</Static.SubHeader>
        <Static.Paragraph>{t('PRIVACY_S_2_P_1')}</Static.Paragraph>
        <Static.List>
          <Static.ListItem>{t('PRIVACY_S_2_LI_1')}</Static.ListItem>
          <Static.ListItem>{t('PRIVACY_S_2_LI_2')}</Static.ListItem>
          <Static.ListItem>{t('PRIVACY_S_2_LI_3')}</Static.ListItem>
          <Static.ListItem>{t('PRIVACY_S_2_LI_4')}</Static.ListItem>
        </Static.List>

        <Static.SubHeader>{t('PRIVACY_S_3_TITLE')}</Static.SubHeader>
        <Static.Paragraph>{t('PRIVACY_S_3_P_1')}</Static.Paragraph>
        <Static.List>
          <Static.ListItem>{t('PRIVACY_S_3_LI_1')}</Static.ListItem>
          <Static.ListItem>{t('PRIVACY_S_3_LI_2')}</Static.ListItem>
        </Static.List>
        <Static.Paragraph>{t('PRIVACY_S_3_P_2')}</Static.Paragraph>

        <Static.SubHeader>{t('PRIVACY_S_4_TITLE')}</Static.SubHeader>
        <Static.SubHeader>{t('PRIVACY_S_4_SUBTITLE_1')}</Static.SubHeader>
        <Static.Paragraph>{t('PRIVACY_S_4_P_1')}</Static.Paragraph>
        <Static.List>
          <Static.ListItem>{t('PRIVACY_S_4_LI_1')}</Static.ListItem>
          <Static.ListItem>{t('PRIVACY_S_4_LI_2')}</Static.ListItem>
          <Static.ListItem>{t('PRIVACY_S_4_LI_3')}</Static.ListItem>
        </Static.List>
        <Static.Paragraph>{t('PRIVACY_S_4_P_2')}</Static.Paragraph>

        <Static.SubHeader>{t('PRIVACY_S_4_SUBTITLE_2')}</Static.SubHeader>
        <Static.Paragraph>{t('PRIVACY_S_4_P_3')}</Static.Paragraph>
        <Static.Paragraph>{t('PRIVACY_S_4_P_4')}</Static.Paragraph>
        <Static.Paragraph>{t('PRIVACY_S_4_P_5')}</Static.Paragraph>

        <Static.SubHeader>{t('PRIVACY_S_4_SUBTITLE_3')}</Static.SubHeader>
        <Static.Paragraph>{t('PRIVACY_S_4_P_6')}</Static.Paragraph>
        <Static.List>
          <Static.ListItem>{t('PRIVACY_S_4_LI_4')}</Static.ListItem>
          <Static.ListItem>{t('PRIVACY_S_4_LI_5')}</Static.ListItem>
          <Static.ListItem>{t('PRIVACY_S_4_LI_6')}</Static.ListItem>
          <Static.ListItem>{t('PRIVACY_S_4_LI_7')}</Static.ListItem>
        </Static.List>

        <Static.SubHeader>{t('PRIVACY_S_4_SUBTITLE_4')}</Static.SubHeader>
        <Static.Paragraph>{t('PRIVACY_S_4_P_7')}</Static.Paragraph>
        <Static.List>
          <Static.ListItem>{t('PRIVACY_S_4_LI_8')}</Static.ListItem>
          <Static.ListItem>{t('PRIVACY_S_4_LI_9')}</Static.ListItem>
          <Static.ListItem>{t('PRIVACY_S_4_LI_10')}</Static.ListItem>
          <Static.ListItem>{t('PRIVACY_S_4_LI_11')}</Static.ListItem>
          <Static.ListItem>{t('PRIVACY_S_4_LI_12')}</Static.ListItem>
        </Static.List>
        <Static.Paragraph>{t('PRIVACY_S_4_P_8')}</Static.Paragraph>

        <Static.SubHeader>{t('PRIVACY_S_4_SUBTITLE_5')}</Static.SubHeader>
        <Static.Paragraph>{t('PRIVACY_S_4_P_9')}</Static.Paragraph>
        <Static.List>
          <Static.ListItem>{t('PRIVACY_S_4_LI_13')}</Static.ListItem>
          <Static.ListItem>{t('PRIVACY_S_4_LI_14')}</Static.ListItem>
        </Static.List>
        <Static.Paragraph>{t('PRIVACY_S_4_P_10')}</Static.Paragraph>

        <Static.SubHeader>{t('PRIVACY_S_4_SUBTITLE_6')}</Static.SubHeader>
        <Static.Paragraph>{t('PRIVACY_S_4_P_11')}</Static.Paragraph>
        
        <Static.SubHeader>{t('PRIVACY_S_5_TITLE')}</Static.SubHeader>
        <Static.Paragraph>{t('PRIVACY_S_5_P_1')}</Static.Paragraph>
        <Static.List>
          <Static.ListItem>{t('PRIVACY_S_5_LI_1')}</Static.ListItem>
          <Static.ListItem>{t('PRIVACY_S_5_LI_2')}</Static.ListItem>
          <Static.ListItem>{t('PRIVACY_S_5_LI_3')}</Static.ListItem>
          <Static.ListItem>{t('PRIVACY_S_5_LI_4')}</Static.ListItem>
        </Static.List>
        <Static.Paragraph>{t('PRIVACY_S_5_P_2')}</Static.Paragraph>

        <Static.SubHeader>{t('PRIVACY_S_6_TITLE')}</Static.SubHeader>
        <Static.Paragraph>{t('PRIVACY_S_6_P_1')}</Static.Paragraph>
        <Static.List>
          <Static.ListItem>{t('PRIVACY_S_6_LI_1')}</Static.ListItem>
          <Static.ListItem>{t('PRIVACY_S_6_LI_2')}</Static.ListItem>
          <Static.ListItem>{t('PRIVACY_S_6_LI_3')}</Static.ListItem>
          <Static.ListItem>{t('PRIVACY_S_6_LI_4')}</Static.ListItem>
          <Static.ListItem>{t('PRIVACY_S_6_LI_5')}</Static.ListItem>
          <Static.ListItem>{t('PRIVACY_S_6_LI_6')}</Static.ListItem>
          <Static.ListItem>{t('PRIVACY_S_6_LI_7')}</Static.ListItem>
        </Static.List>

        <Static.SubHeader>{t('PRIVACY_S_7_TITLE')}</Static.SubHeader>
        <Static.Paragraph>{t('PRIVACY_S_7_P_1')}</Static.Paragraph>
        <Static.List>
          <Static.ListItem>{t('PRIVACY_S_7_LI_1')}</Static.ListItem>
          <Static.ListItem>{t('PRIVACY_S_7_LI_2')}</Static.ListItem>
          <Static.ListItem>{t('PRIVACY_S_7_LI_3')}</Static.ListItem>
        </Static.List>

        <Static.SubHeader>{t('PRIVACY_S_8_TITLE')}</Static.SubHeader>
        <Static.Paragraph>{t('PRIVACY_S_8_P_1')}</Static.Paragraph>
        <Static.Paragraph>{t('PRIVACY_S_8_P_2')}</Static.Paragraph>
        <Static.Paragraph>{t('PRIVACY_S_8_P_3')}</Static.Paragraph>
        <Static.List>
          <Static.ListItem>{t('PRIVACY_S_8_LI_1')}</Static.ListItem>
          <Static.ListItem>{t('PRIVACY_S_8_LI_2')}</Static.ListItem>
          <Static.ListItem>{t('PRIVACY_S_8_LI_3')}</Static.ListItem>
        </Static.List>

        <Static.SubHeader>{t('PRIVACY_S_9_TITLE')}</Static.SubHeader>
        <Static.Paragraph>{t('PRIVACY_S_9_P_1')}</Static.Paragraph>

        <Static.SubHeader>{t('PRIVACY_S_10_TITLE')}</Static.SubHeader>
        <Static.Paragraph>{t('PRIVACY_S_10_P_1')}</Static.Paragraph>
        <Static.List>
          <Static.ListItem>{t('PRIVACY_S_10_LI_1')}</Static.ListItem>
          <Static.ListItem>{t('PRIVACY_S_10_LI_2')}</Static.ListItem>
          <Static.ListItem>{t('PRIVACY_S_10_LI_3')}</Static.ListItem>
        </Static.List>
        <Static.Paragraph>{t('PRIVACY_S_10_P_2')}</Static.Paragraph>
        <Static.Paragraph>{t('PRIVACY_S_10_P_3')}</Static.Paragraph>

        <Static.SubHeader>{t('PRIVACY_S_11_TITLE')}</Static.SubHeader>
        <Static.Paragraph>{t('PRIVACY_S_11_P_1')}</Static.Paragraph>
        <Static.Paragraph>{t('PRIVACY_S_11_P_2')}</Static.Paragraph>
        <Static.Paragraph>{t('PRIVACY_S_11_P_3')}</Static.Paragraph>
        <Static.Paragraph>{t('PRIVACY_S_11_P_4')}</Static.Paragraph>

        <Static.SubHeader>{t('PRIVACY_S_12_TITLE')}</Static.SubHeader>
        <Static.Paragraph>{t('PRIVACY_S_12_P_1')}</Static.Paragraph>
        <Static.Paragraph>{t('PRIVACY_S_12_P_2')}</Static.Paragraph>
      </Static.Container>
    );
  }
}

export default withNamespaces()(Privacy);