import React from 'react';
import styled from 'styled-components';
import Loader from '../Loader';
import CircleWithArrow from '../Icons/CircleWithArrow';

const ButtonStyles = styled.button`
  position: relative;
  padding: ${({ small }: any) => small ? '0 32px' : '0 40px'};
  .arrow-with-line-icon {
    opacity: ${({ isPending }: any) => isPending ? '0' : '1'};
    transition: .25s opacity ease-in-out;
  }
  .loader {
    width: ${({ small }: any) => small ? '16px' : '24px'};
    height: ${({ small }: any) => small ? '16px' : '24px'};
    right: 8px;
    opacity: ${({ isPending }: any) => isPending ? '1' : '0'};
    transition: .25s opacity ease-in-out;
  }
` as any;

interface Props {
  className?: string;
  type?: string;
  isPending?: boolean;
  isArrowExist?: boolean;
  isArrowReverted?: boolean;
  isArrowDark?: boolean;
  isLoaderRed?: boolean;
  small?: boolean;
  onClick?: () => void;
  disabled?: boolean;
  children?: any;
}

class Button extends React.Component<Props> {
  render() {
    const { className, children, type, isPending, isLoaderRed, isArrowExist, isArrowReverted, isArrowDark, onClick, small, disabled } = this.props;
    return (
      <ButtonStyles type={type} className={className} isPending={isPending} onClick={onClick} small={small} disabled={disabled}>
        {isArrowExist && <CircleWithArrow dark={isArrowDark} arrowRotate={isArrowReverted} />}
        {children}
        <Loader red={isLoaderRed} />
      </ButtonStyles>
    )
  }
}

export default Button;