import React from 'react';
import styled from 'styled-components';
import Avatar from '../Avatar';
import { UserModel } from '../../models/UserModel';
import screens from '../../constants/screens';
import Languages from './Languages';
import Username from './Username';

interface Props {
  user: UserModel;
}

const Container = styled.div`
  display: flex;
  margin-bottom: 50px;
  flex-wrap: wrap;
  ${screens.tablet} {
    display: block;
  }
  ${screens.mobile} {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 10px;
  }
`;

const LeftContainer = styled.div`
  padding-right: 24px;
  ${screens.mobile} {
    padding-right: 0;
    margin-bottom: 16px;
  }
`;

const RightContainer = styled.div`
  padding-top: 16px;
  flex-grow: 1;
  ${screens.tablet} {
    max-width: none;
  }
  ${screens.mobile} {
    padding-top: 0;
    max-width: 320px;
    text-align: center;
    width: 100%;
  }
`;

const StyledUsername = styled(Username)`
  display: block;
  font-size: 24px;
  line-height: 24px;
  font-weight: 500;
  margin-bottom: 16px;
  word-break: break-word;
  span {
    margin-bottom: 8px;
    ${screens.mobile} {
      margin-bottom: 0;
    }
  }
  ${screens.mobile} {
    display: flex;
    justify-content: center;
  }
`;

class Profile extends React.Component<Props> {
  render() {
    const { username, avatarPath, rating, languages, isEmailVerified } = this.props.user as UserModel;
    return (
      <Container>
        <LeftContainer>
          <Avatar avatarPath={avatarPath} verified={isEmailVerified} size={142} mobileSize={135} rounded />
        </LeftContainer>
        <RightContainer>
          <StyledUsername username={username} rating={rating} />
          <Languages languages={languages} />
        </RightContainer>
      </Container>
    );
  }
}

export default Profile;