import React from 'react';
import PopupsComponents from './components';
import GreenCheckMarkIcon from '../Icons/GreenCheckMarkIcon';
import styled from 'styled-components';
import { withNamespaces, WithNamespaces } from 'react-i18next';
import { ChatModel } from '../../models/ChatModel';
import CoinPrice from '../../shared/CoinPrice';
import { setModal } from '../../actions/modal';
import { connect } from 'react-redux';
import modals from '../../constants/modals';
import { StoreModel } from '../../models/StoreModel';
import { getCurrentUser } from '../../selectors/user';
import { UserModel } from '../../models/UserModel';

interface Props {
  user: UserModel | null;
  scope: ChatModel;
  openModal: (modal: string, scope: ChatModel) => void;
}

const GreenCheckMarkIconStyled = styled(GreenCheckMarkIcon)`
  width: 96px;
  height: 96px;
  margin-bottom: 33px;
`;

class TransactionSuccess extends React.Component<Props & WithNamespaces> {
  onRateClick = () => {
    this.props.openModal(modals.RATE, this.props.scope);
  }
  render() {
    const { t, scope, user } = this.props;
    const { trade, amount } = scope;

    const otherTransactionUser = (trade.type === 1 && user!.id === trade.userId) ? scope.recipient : scope.sender;
    return (
      <CoinPrice volume={amount}>
        {(coinPrice: number) => (
          <PopupsComponents.Container>
            <PopupsComponents.Header>{t('TRANSACTION_INFO', {
                btc: amount,
                eur: coinPrice,
                username: otherTransactionUser.username
              })}
            </PopupsComponents.Header>
            <PopupsComponents.Content>
              <GreenCheckMarkIconStyled />
              <PopupsComponents.Title>
                {t('TRANSACTION_CONGRATULATION_AMOUNT', {
                  btc: amount
                })}
                <br />
                {t('TRANSACTION_CONGRATULATION_SENDER', {
                  username: otherTransactionUser.username
                })}
              </PopupsComponents.Title>
            </PopupsComponents.Content>
            <PopupsComponents.Footer>
              <p>{t('TRANSACTION_RATE')}</p>
              <PopupsComponents.RateButton onClick={this.onRateClick}>{t('RATE_NOW')}</PopupsComponents.RateButton>
              <PopupsComponents.CancelButton>{t('CANCEL')}</PopupsComponents.CancelButton>
            </PopupsComponents.Footer>
          </PopupsComponents.Container>
        )}
      </CoinPrice>
    );
  }
}

const mapStateToProps = (state: StoreModel) => ({
  user: getCurrentUser(state)
});

const mapDispatchToProps = (dispatch: any) => ({
  openModal: (modal: string, scope: ChatModel) => dispatch(setModal(modal, scope))
});

export default connect(mapStateToProps, mapDispatchToProps)(withNamespaces()(TransactionSuccess));