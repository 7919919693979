import React from 'react';
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from 'react-places-autocomplete';
import styled from 'styled-components';
import { withNamespaces, WithNamespaces } from 'react-i18next';

interface LatLngModel {
  lat: number;
  lng: number;
}

interface Props {
  defaultValue?: string;
  onChange: (field: string, value: string | number) => void;
  onBlur: (field: string) => void;
}
interface State {
  address?: string
}

const Input = styled.input`
  height: 26px;
  border: 1px solid #000;
  border-radius: 6px;
  max-width: 440px;
  width: 100%;
  padding: 0 8px;
  font-size: 14px;
`;

const SuggestionContainer = styled.div`
  border: 1px solid #000;
  border-radius: 6px;
  max-width: 440px;
  width: 100%;
  padding: 0 8px;
  font-size: 14px;
  &:empty {
    display: none;
  }
`;

const Suggestion = styled.div`
  margin: 4px 0; 
`;

class Places extends React.Component<Props & WithNamespaces, State> {
  state: State = {
    address: ''
  }

  componentWillReceiveProps(nextProps: Props & WithNamespaces) {
    if (!this.state.address && nextProps.defaultValue) {
      this.setState({
        address: this.props.defaultValue
      });
    }
  }

  onChange = (address: string) => {
    this.setState({
      address
    });
    this.props.onChange('location', address);
  }

  onSelect = (address: string) => {
    geocodeByAddress(address)
      .then((results: any) => getLatLng(results[0]))
      .then((latLng: LatLngModel) => {
        this.setState({
          address
        });
        this.props.onChange('location', address);
        this.props.onChange('longitude', latLng.lng);
        this.props.onChange('latitude', latLng.lat);
      });
  };

  onBlur = () => {
    this.props.onBlur('location');
  }

  render() {
    const { t } = this.props;

    return (
      <PlacesAutocomplete
        value={this.state.address}
        onChange={this.onChange}
        onSelect={this.onSelect}
      >
        {({ getInputProps, suggestions, getSuggestionItemProps, loading }: any) => (
          <>
            <Input
              {...getInputProps({
                placeholder: t('SEARCH_PLACES')
              })}
              onBlur={this.onBlur}
            />
            <SuggestionContainer>
              {loading && <div>{t('LOADING')}</div>}
              {suggestions.map((suggestion: any) => {
                return (
                  <Suggestion
                    key={suggestion.id}
                    {...getSuggestionItemProps(suggestion)}
                  >
                    <span>{suggestion.description}</span>
                  </Suggestion>
                );
              })}
            </SuggestionContainer>
          </>
        )}
      </PlacesAutocomplete>
    )
  }
}

export default withNamespaces()(Places);