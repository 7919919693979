import i18n from 'i18next';
import { reactI18nextModule } from 'react-i18next';
import de from './translations/de.json';
import en from './translations/en.json';

const instance = i18n
  .use(reactI18nextModule)
  .init({
    interpolation: { escapeValue: false },  // React already does escaping
    fallbackLng: 'en',
    resources: process.env.NODE_ENV === 'test' ? {
      en: {},
      de: {}
    } : {
      en,
      de
    },
    defaultNS: 'translations',
    ns: ['translations'],
    debug: process.env.NODE_ENV !== 'production' && process.env.NODE_ENV !== 'test',
  });

export default instance;