import React from 'react';

interface Props {
  className?: string;
}

const GreenCheckMarkIcon = ({ className }: Props) => (
  <svg className={className} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
    <g data-name="Layer 2">
      <g data-name="Capa 1">
        <path fill="#2bbc70" d="M424.48 167.31a12.31 12.31 0 0 0-12.31 12.31v307.75H24.63V99.83H326a12.32 12.32 0 0 0 0-24.63H12.32A12.33 12.33 0 0 0 0 87.52v412.16A12.33 12.33 0 0 0 12.32 512h412.16a12.33 12.33 0 0 0 12.32-12.32V179.62a12.32 12.32 0 0 0-12.32-12.31z"/>
        <path fill="#2bbc70" d="M508.39 3.61a12.32 12.32 0 0 0-17.42 0l-325.9 325.9-80.86-80.86a12.31 12.31 0 0 0-17.42 17.41l89.57 89.57a12.31 12.31 0 0 0 17.42 0L508.39 21a12.32 12.32 0 0 0 0-17.39z"/>
      </g>
    </g>
  </svg>
);

export default GreenCheckMarkIcon;