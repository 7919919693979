import React from 'react';
import styled, { keyframes } from 'styled-components'
import { RED_CF } from '../../constants/colors';

interface Props {
  dark?: boolean;
  red?: boolean;
  className?: string;
}

export const internal = keyframes`
  0% {
    stroke-dashoffset: 187;
  }
  25% {
    stroke-dashoffset: 80;
  }
  100% {
    stroke-dashoffset: 187;
    transform: rotate(360deg);
  }
`;

export const external = keyframes`
  0% {
    stroke-dashoffset: 312;
    transform: rotate(70deg);
  }
  60% {
    stroke-dashoffset: -312;
  }
  100% {
    stroke-dashoffset: -312;
    transform: rotate(450deg);
  }
`;

const Svg = styled.svg`
  position: absolute;
  transform: rotate(-90deg);
  stroke-linecap: round;
  stroke-width: 4;
  fill: none;
  top: 0;
  bottom: 0;
  margin: auto 0;
`;

const Circle = styled.circle`
  stroke: ${({ dark, red }: any) => dark ? '#333' : red ? RED_CF : '#fff'};
  stroke-dashoffset: 0;
  transform-origin: center;
` as any;

const InternalCircle = styled(Circle)`
  stroke-dasharray: 187;
  animation: ${internal} 1s ease-in-out infinite;
  opacity: .4;
`;

const ExternalCircle = styled(Circle)`
  stroke-dasharray: 312;
  animation: ${external} 1s linear infinite;
  opacity: .9;
`;

class Loader extends React.Component<Props> {
  render() {
    const { className, dark, red } = this.props;
    return (
      <Svg className={`loader ${className}`} viewBox="0 0 120 120" xmlns="http://www.w3.org/2000/svg">
        <InternalCircle cx="60" cy="60" r="30" dark={dark} red={red} />
        <ExternalCircle cx="60" cy="60" r="50" dark={dark} red={red} />
      </Svg>
    )
  }
}

export default Loader;