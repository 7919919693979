import React from 'react';
import styled from 'styled-components';
import { withNamespaces, WithNamespaces } from 'react-i18next';
import { connect } from 'react-redux';
import { StoreModel } from '../../models/StoreModel';
import { getUserAvailableBalance, getUserTotalBalance } from '../../selectors/wallet';
import { WALLET_GET_BALANCE, WALLET_GET_BALANCE_REQUESTED } from '../../actions/wallet';
import { getFiatByBTC, formattedCryptoValue } from '../../utils';
import { Link } from 'react-router-dom';
import { createLoadingSelector } from '../../selectors/loading';
import Container from '../../components/Container';
import { createErrorSelector } from '../../selectors/errorResponse';
import screens from '../../constants/screens';
import plusIcon from '../../images/common/plus.svg';
import minusIcon from '../../images/common/minus.svg';

interface Props {
  totalBalance: number | null;
  availableBalance: number | null;
  isPending: boolean;
  isError: boolean;
  getUserBalance: () => void;
}

interface State {
  fiatAvailableBalance: string | null;
  fiatTotalBalance: string | null;
}

const Balance = styled.div`
  display: flex;
  justify-content: space-between;
  box-shadow: 0 0 24px 0 rgba(0,0,0,.1);
  padding: 30px 35px;
  font-size: 2rem;
  border-radius: 6px;
  font-weight: 600;
  margin: 0 auto 26px;
  text-align: center;
  ${screens.mobile} {
    display: block;
    padding: 24px 16px;
  }
`;

const BalanceSubtitle = styled.div`
  font-weight: 400;
  ${screens.mobile} {
    margin-bottom: 4px;
  }
`;

const BalanceValue = styled.div`
  font-weight: 700;
`;

const Links = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const WalletActionBtn = styled(Link)`
  display: flex;
  box-shadow: 0 0 24px 0 rgba(0,0,0,.1);
  border-radius: 6px;
  overflow: hidden;
  font-size: 2rem;
  font-weight: 500;
  width: 100%;
  &:not(:last-child) {
    margin-bottom: 22px;
  }
`;

const ActionIconWrapper = styled.div`
  display: flex;
  width: 93px;
  justify-content: center;
  align-items: center;
  font-size: 3.2rem;
  color: #fff;
  background-color: ${({ color }) => color || '#2bbc70'};
  img {
    width: 24px;
    height: 24px;
  }
` as any;

const ActionTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 50px;
  flex-grow: 1;
  padding: 0 16px;
`;


export class Wallet extends React.Component<Props & WithNamespaces, State> {
  state: State = {
    fiatAvailableBalance: null,
    fiatTotalBalance: null,
  }

  static defaultProps = {
    availableBalance: 0,
    totalBalance: 0
  }

  componentDidMount() {
    this.props.getUserBalance();
    this.getFiatBalance(this.props);
  }
  componentWillReceiveProps(nextProps: Props & WithNamespaces) {
    this.getFiatBalance(nextProps);
  }

  getFiatBalance = (props: Props & WithNamespaces) => {
    if (props.availableBalance !== null) {
      getFiatByBTC(props.availableBalance).then((price: string) => {
        this.setState({
          fiatAvailableBalance: price || '0'
        })
      });
    }
    if (props.totalBalance !== null) {
      getFiatByBTC(props.totalBalance).then((price: string) => {
        this.setState({
          fiatTotalBalance: price || '0'
        })
      });
    }
  }
  
  render() {
    const { t, isPending, isError, availableBalance, totalBalance } = this.props;
    const { fiatTotalBalance, fiatAvailableBalance } = this.state;

    return (
      <Container
        isPending={isPending}
        isError={isError}
        title={t('WALLET_OVERVIEW')}
      >
        <Balance>
          <BalanceSubtitle>{t('WALLET_AVAILABLE_BALANCE')}</BalanceSubtitle>
          <BalanceValue>{formattedCryptoValue(availableBalance)} BTC ({fiatAvailableBalance} EUR)</BalanceValue>
        </Balance>
        <Balance>
          <BalanceSubtitle>{t('WALLET_TOTAL_BALANCE')}</BalanceSubtitle>
          <BalanceValue>{formattedCryptoValue(totalBalance)} BTC ({fiatTotalBalance} EUR)</BalanceValue>
        </Balance>
        <Links>
          <WalletActionBtn to="/wallet/deposit">
            <ActionTitle>{t('DEPOSIT_BITCONS')}</ActionTitle>
            <ActionIconWrapper>
              <img src={plusIcon} />
            </ActionIconWrapper>
          </WalletActionBtn>
          <WalletActionBtn to="/wallet/withdrawal">
            <ActionTitle>{t('WITHDRAWAL_BITCOINS')}</ActionTitle>
            <ActionIconWrapper color="#cf0022">
              <img src={minusIcon} />
            </ActionIconWrapper>
          </WalletActionBtn>
        </Links>
      </Container>
    )
  }
}

const mapStateToProps = (state: StoreModel) => ({
  totalBalance: getUserTotalBalance(state),
  availableBalance: getUserAvailableBalance(state),
  isPending: createLoadingSelector([WALLET_GET_BALANCE])(state),
  isError: createErrorSelector([WALLET_GET_BALANCE])(state),
});

const mapDispatchToProps = (dispatch: any) => ({
  getUserBalance: () => dispatch({
    type: WALLET_GET_BALANCE_REQUESTED,
    payload: {}
  })
});

export default connect(mapStateToProps, mapDispatchToProps)(withNamespaces()(Wallet));