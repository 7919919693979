import React from 'react';
import { 
  Container,
  Head,
  Title,
  Link,
  LinkColored,
  Form,
  Row,
  Label,
  Input,
  Button,
  BottomButtonsContainer
} from './Form';
import { setModal } from '../../actions/modal';
import { connect } from 'react-redux';
import { withNamespaces, WithNamespaces } from 'react-i18next';
import { AUTH_LOGIN } from '../../actions/auth';
import { StoreModel } from '../../models/StoreModel';
import { Formik } from 'formik';
import { createLoadingSelector } from '../../selectors/loading';

interface Props {
  isRequestPending: boolean;
  // login: (data: LoginBodyModel) => void;
  openModal: (modal: string) => void;
}

interface FormValues {
  username: string;
  password: string;
}
class LoginForm extends React.Component<Props & WithNamespaces> {
  openModal = (modal: string) => () => this.props.openModal(modal);
  onSubmit = (values: FormValues) => {
    // this.props.login(values);
  }
  render() {
    const { t } = this.props;
    return (
      <Container>
        <Head>
          <Title>{t('AUTH_MODAL_SIGN_IN')}</Title>
          <LinkColored onClick={this.openModal('sign-up')}>{t('HEADER_MENU_SIGNUP_LABEL')}</LinkColored>      
        </Head>
        <Formik 
          initialValues={{
            username: '',
            password: '',
          }}
          onSubmit={this.onSubmit}
          render={() => (
            <Form onSubmit={this.onSubmit}>
              <Row>
                <Label>{t('AUTH_MODAL_USERNAME_OR_EMAIL')}</Label>
                <Input 
                  name="username"
                  type="text" 
                  autoComplete="email" 
                  autoCapitalize="off"
                />
              </Row>
              <Row>
                <Label>
                  <span>{t('AUTH_MODAL_PASSWORD')}</span>
                  <Link onClick={this.openModal('forgot-password')}>{t('AUTH_MODAL_FORGOT_QUESTION')}</Link>
                </Label>
                <Input 
                  name="password" 
                  type="password" 
                />
              </Row>
              <BottomButtonsContainer>
                <Button 
                  type="submit"
                  isPending={this.props.isRequestPending} 
                >
                  {t('AUTH_MODAL_SIGN_IN')}
                </Button>
                {/* <SocialButtonsTitle>{t('AUTH_MODAL_OR_USE')}</SocialButtonsTitle>
                <FacebookButton />
                <GoogleButton /> */}
              </BottomButtonsContainer>
            </Form>
          )}
        />
      </Container>
    );
  }
}

const mapStateToProps = (state: StoreModel) => ({
  isRequestPending: createLoadingSelector([AUTH_LOGIN])(state)
});

const mapDispatchToProps = (dispatch: any) => ({
  // login: (data: LoginBodyModel) => dispatch(authLogin(data)),
  openModal: (modal: string) => dispatch(setModal(modal))
});

export default connect(mapStateToProps, mapDispatchToProps)(withNamespaces()(LoginForm));