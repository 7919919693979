import React from 'react';
import PopupsComponents from './components';
import { CancelScopeModal } from '../../models/CancelScopeModal';
import { withNamespaces, WithNamespaces } from 'react-i18next';

interface Props {
  scope: CancelScopeModal;
}

class CancelModal extends React.Component<Props & WithNamespaces> {
  render() {
    const { scope, t } = this.props;
    const { title, onSubmit } = scope;
    return (
      <PopupsComponents.Container>
        <PopupsComponents.Header isWarning>{t('WARNING')}</PopupsComponents.Header>
        <PopupsComponents.Content>
          <PopupsComponents.Title>{title}</PopupsComponents.Title>
          <PopupsComponents.ActionButton onClick={onSubmit}>{t('YES')}</PopupsComponents.ActionButton>
          <PopupsComponents.CancelButton>{t('NOT')}</PopupsComponents.CancelButton>
        </PopupsComponents.Content>
      </PopupsComponents.Container>
    )
  }
}

export default withNamespaces()(CancelModal);