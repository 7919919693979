import React from 'react';
import styled from 'styled-components';

const UserImgWrapper = styled.div`
  width: 74px;
  height: 74px;
  border-radius: 50%;
  box-shadow: inset rgba(0, 0, 0, .12) 0 1px 4px 0;
  overflow: hidden;
  margin-left: auto;
  margin-right: 39px;
`;

const UserImg = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1;
  position: relative;
`;

class AdminUserPanel extends React.Component {
  render () {
    return (
      <UserImgWrapper>
        <UserImg src="https://images.pexels.com/photos/614810/pexels-photo-614810.jpeg?auto=compress&cs=tinysrgb&h=350" alt="" />
      </UserImgWrapper>
    )
  }
}

export default AdminUserPanel;