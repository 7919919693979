import Balance from './Balance';
import Info from './Info';
import Languages from './Languages';
import Profile from './Profile';
import Rating from './Rating';
import Username from './Username';
import Stats from './Stats';
import Feedbacks from './UserFeedbacks';
import WorkingHours from './WorkingHours';

export default {
  Username,
  Balance,
  Info,
  Languages,
  Profile,
  Rating,
  Stats,
  Feedbacks,
  WorkingHours
};