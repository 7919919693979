import React from 'react';
import styled from 'styled-components';
import { AdModel } from '../../models/AdModel';
import { withNamespaces, WithNamespaces } from 'react-i18next';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { AD_DELETE_REQUESTED } from '../../actions/ads';
import { RED_CF } from '../../constants/colors';
import { getLimit, getCoinPriceWithMargin } from '../../utils';
import screens from '../../constants/screens';

interface Props {
  ad: AdModel;
  type: string;
  coinPrice: number | null;
  isOwnPage: boolean;
  deleteAd: (id: number) => void;
}

const Row = styled.tr``;

const Cell = styled.td`
  padding: 8px;
  &:first-child {
    padding-left: 20px;
  }
  &:last-child {
    padding-right: 0;
    text-align: right;
  }
`;

const CellMobileHidden = styled(Cell)`
  ${screens.mobileSm} {
    display: none;
  }
`;

const Button = styled.button`
  display: flex;
  width: 100%;
  height: 16px;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 12px;
  color: #fff;
  border-radius: 2px;
  background-color: ${({red}: any) => red ? RED_CF : '#2bbc70'};
  min-width: 42px;
  padding: 0 4px;
` as any;

class AdRow extends React.Component<Props & WithNamespaces> {
  removeAd = () => {
    this.props.deleteAd(this.props.ad.id);
  }
  render() {
    const { ad, type, isOwnPage, t, coinPrice } = this.props;
    const { maximum, minimum, currency, fee } = ad;
    return (
      <Row>
        <Cell>{getCoinPriceWithMargin(coinPrice, fee)}</Cell>
        <Cell>{getLimit(minimum, maximum, coinPrice, currency)}</Cell>
        <CellMobileHidden>{ad.location}</CellMobileHidden>
        <Cell>
          <Button as={Link} to={`/trade/${ad.id}`} type={type}>{t('USER_PAGE_VIEW')}</Button>
        </Cell>
        {isOwnPage && (
          <Cell>
            <Button onClick={this.removeAd} red type={type}>{t('USER_PAGE_DELETE')}</Button>
          </Cell>
        )}
      </Row>
    )
  }
}

const mapDispatchToProps = (dispatch: any) => ({
  deleteAd: (id: number) => dispatch({
    type: AD_DELETE_REQUESTED,
    payload: {
      id
    }
  })
});

export default connect(null, mapDispatchToProps)(withNamespaces()(AdRow));