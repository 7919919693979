import React from 'react';
import Static from '../../shared/Static';
import { withNamespaces, WithNamespaces } from 'react-i18next';

class Terms extends React.Component<WithNamespaces> {
  render() {
    const { t } = this.props;
    return (
      <Static.Container>
        <Static.Header>{t('TERMS_TITLE')}</Static.Header>
        <Static.UpdatedHeader>{t('TERMS_UPDATED')}</Static.UpdatedHeader>
        <Static.Paragraph>{t('TERMS_S_0_P_1')}</Static.Paragraph>
        <Static.Paragraph>{t('TERMS_S_0_P_2')}</Static.Paragraph>
        <Static.Paragraph>{t('TERMS_S_0_P_3')}</Static.Paragraph>
        <Static.Paragraph>{t('TERMS_S_0_P_4')}</Static.Paragraph>

        <Static.SubHeader>{t('TERMS_S_1_TITLE')}</Static.SubHeader>
        <Static.Paragraph>{t('TERMS_S_1_P_1')}</Static.Paragraph>
        <Static.Paragraph>{t('TERMS_S_1_P_2')}</Static.Paragraph>
        <Static.Paragraph>{t('TERMS_S_1_P_3')}</Static.Paragraph>

        <Static.SubHeader>{t('TERMS_S_2_TITLE')}</Static.SubHeader>
        <Static.Paragraph>{t('TERMS_S_2_P_1')}</Static.Paragraph>
        <Static.Paragraph>{t('TERMS_S_2_P_2')}</Static.Paragraph>
        <Static.Paragraph>{t('TERMS_S_2_P_3')}</Static.Paragraph>
        <Static.Paragraph>{t('TERMS_S_2_P_4')}</Static.Paragraph>

        <Static.SubHeader>{t('TERMS_S_3_TITLE')}</Static.SubHeader>
        <Static.Paragraph>{t('TERMS_S_3_P_1')}</Static.Paragraph>
        <Static.Paragraph>{t('TERMS_S_3_P_2')}</Static.Paragraph>

        <Static.SubHeader>{t('TERMS_S_4_TITLE')}</Static.SubHeader>
        <Static.Paragraph>{t('TERMS_S_4_P_1')}</Static.Paragraph>
        <Static.Paragraph>{t('TERMS_S_4_P_2')}</Static.Paragraph>
        <Static.Paragraph>{t('TERMS_S_4_P_3')}</Static.Paragraph>
        <Static.Paragraph>{t('TERMS_S_4_P_4')}</Static.Paragraph>
        <Static.Paragraph>{t('TERMS_S_4_P_5')}</Static.Paragraph>
        <Static.Paragraph>{t('TERMS_S_4_P_6')}</Static.Paragraph>

        <Static.SubHeader>{t('TERMS_S_5_TITLE')}</Static.SubHeader>
        <Static.Paragraph>{t('TERMS_S_5_P_1')}</Static.Paragraph>
        <Static.Paragraph>{t('TERMS_S_5_P_2')}</Static.Paragraph>
        <Static.Paragraph>{t('TERMS_S_5_P_3')}</Static.Paragraph>
        <Static.Paragraph>{t('TERMS_S_5_P_4')}</Static.Paragraph>

        <Static.SubHeader>{t('TERMS_S_6_TITLE')}</Static.SubHeader>
        <Static.Paragraph>{t('TERMS_S_6_P_1')}</Static.Paragraph>
        <Static.Paragraph>{t('TERMS_S_6_P_2')}</Static.Paragraph>
        <Static.Paragraph>{t('TERMS_S_6_P_3')}</Static.Paragraph>
        <Static.Paragraph>{t('TERMS_S_6_P_4')}</Static.Paragraph>

        <Static.SubHeader>{t('TERMS_S_6_TITLE')}</Static.SubHeader>
        <Static.Paragraph>{t('TERMS_S_6_P_1')}</Static.Paragraph>
        <Static.Paragraph>{t('TERMS_S_6_P_2')}</Static.Paragraph>
        <Static.Paragraph>{t('TERMS_S_6_P_3')}</Static.Paragraph>
        <Static.Paragraph>{t('TERMS_S_6_P_4')}</Static.Paragraph>

        <Static.SubHeader>{t('TERMS_S_7_TITLE')}</Static.SubHeader>
        <Static.Paragraph>{t('TERMS_S_7_P_1')}</Static.Paragraph>
        <Static.Paragraph>{t('TERMS_S_7_P_2')}</Static.Paragraph>
        <Static.List>
          <Static.ListItem>{t('TERMS_S_7_LI_1')}</Static.ListItem>
          <Static.ListItem>{t('TERMS_S_7_LI_2')}</Static.ListItem>
          <Static.ListItem>{t('TERMS_S_7_LI_3')}</Static.ListItem>
          <Static.ListItem>{t('TERMS_S_7_LI_4')}</Static.ListItem>
          <Static.ListItem>{t('TERMS_S_7_LI_5')}</Static.ListItem>
          <Static.ListItem>{t('TERMS_S_7_LI_6')}</Static.ListItem>
          <Static.ListItem>{t('TERMS_S_7_LI_7')}</Static.ListItem>
        </Static.List>
        <Static.Paragraph>{t('TERMS_S_7_P_3')}</Static.Paragraph>
        <Static.Paragraph>{t('TERMS_S_7_P_4')}</Static.Paragraph>
        <Static.Paragraph>{t('TERMS_S_7_P_5')}</Static.Paragraph>

        <Static.SubHeader>{t('TERMS_S_8_TITLE')}</Static.SubHeader>
        <Static.Paragraph>{t('TERMS_S_8_P_1')}</Static.Paragraph>
        <Static.Paragraph>{t('TERMS_S_8_P_2')}</Static.Paragraph>
        <Static.Paragraph>{t('TERMS_S_8_P_3')}</Static.Paragraph>
        <Static.Paragraph>{t('TERMS_S_8_P_4')}</Static.Paragraph>
        <Static.Paragraph>{t('TERMS_S_8_P_5')}</Static.Paragraph>
        <Static.Paragraph>{t('TERMS_S_8_P_6')}</Static.Paragraph>

        <Static.SubHeader>{t('TERMS_S_10_TITLE')}</Static.SubHeader>
        <Static.Paragraph>{t('TERMS_S_10_P_1')}</Static.Paragraph>
        <Static.Paragraph>{t('TERMS_S_10_P_2')}</Static.Paragraph>

        <Static.SubHeader>{t('TERMS_S_11_TITLE')}</Static.SubHeader>
        <Static.Paragraph>{t('TERMS_S_11_P_1')}</Static.Paragraph>
        <Static.Paragraph>{t('TERMS_S_11_P_2')}</Static.Paragraph>

        <Static.SubHeader>{t('TERMS_S_12_TITLE')}</Static.SubHeader>
        <Static.Paragraph>{t('TERMS_S_12_P_1')}</Static.Paragraph>

        <Static.SubHeader>{t('TERMS_S_13_TITLE')}</Static.SubHeader>
        <Static.Paragraph>{t('TERMS_S_13_P_1')}</Static.Paragraph>
        <Static.Paragraph>{t('TERMS_S_13_P_2')}</Static.Paragraph>
        <Static.Paragraph>{t('TERMS_S_13_P_3')}</Static.Paragraph>
        <Static.Paragraph>{t('TERMS_S_13_P_4')}</Static.Paragraph>
        <Static.Paragraph>{t('TERMS_S_13_P_5')}</Static.Paragraph>
        <Static.Paragraph>{t('TERMS_S_13_P_6')}</Static.Paragraph>
        <Static.Paragraph>{t('TERMS_S_13_P_7')}</Static.Paragraph>

        <Static.SubHeader>{t('TERMS_S_14_TITLE')}</Static.SubHeader>
        <Static.Paragraph>{t('TERMS_S_14_P_1')}</Static.Paragraph>
        <Static.Paragraph>{t('TERMS_S_14_P_2')}</Static.Paragraph>
        <Static.List>
          <Static.ListItem>{t('TERMS_S_14_LI_1')}</Static.ListItem>
          <Static.ListItem>{t('TERMS_S_14_LI_2')}</Static.ListItem>
          <Static.ListItem>{t('TERMS_S_14_LI_3')}</Static.ListItem>
          <Static.List>
            <Static.ListItem>{t('TERMS_S_14_LI_4')}</Static.ListItem>
            <Static.ListItem>{t('TERMS_S_14_LI_5')}</Static.ListItem>
            <Static.ListItem>{t('TERMS_S_14_LI_6')}</Static.ListItem>
            <Static.ListItem>{t('TERMS_S_14_LI_7')}</Static.ListItem>
            <Static.ListItem>{t('TERMS_S_14_LI_8')}</Static.ListItem>
          </Static.List>
        </Static.List>

        <Static.SubHeader>{t('TERMS_S_15_TITLE')}</Static.SubHeader>
        <Static.Paragraph>{t('TERMS_S_15_P_1')}</Static.Paragraph>

        <Static.SubHeader>{t('TERMS_S_16_TITLE')}</Static.SubHeader>
        <Static.Paragraph>{t('TERMS_S_16_P_1')}</Static.Paragraph>

        <Static.SubHeader>{t('TERMS_S_17_TITLE')}</Static.SubHeader>
        <Static.Paragraph>{t('TERMS_S_17_P_1')}</Static.Paragraph>
        <Static.Paragraph>{t('TERMS_S_17_P_2')}</Static.Paragraph>
        <Static.Paragraph>{t('TERMS_S_17_P_3')}</Static.Paragraph>

        <Static.SubHeader>{t('TERMS_S_18_TITLE')}</Static.SubHeader>
        <Static.Paragraph>{t('TERMS_S_18_P_1')}</Static.Paragraph>
        <Static.Paragraph>{t('TERMS_S_18_P_2')}</Static.Paragraph>
      </Static.Container>
    );
  }
}

export default withNamespaces()(Terms);