import React from 'react';
import styled from 'styled-components';
import { withNamespaces, WithNamespaces } from 'react-i18next';
import VerifiedIcon from '../Icons/VerifiedIcon';
import { UserModel } from '../../models/UserModel';
import moment from 'moment';

interface Props {
  user: UserModel
}

const Table = styled.table`
  width: 100%;
  font-size: 14px;
  text-align: right;
  margin-bottom: 54px;
`;

const Body = styled.tbody``;

const Row = styled.tr``;

const Cell = styled.td`
  padding: 4px 0;
  span,
  svg {
    vertical-align: middle;
  }
  svg {
    margin-right: 4px;
  }
`;
const BoldedCell = styled(Cell)`
  font-weight: 500;
  text-align: left
`;

class Info extends React.Component<Props & WithNamespaces> {
  render() {
    const { t, user } = this.props;

    return (
      <Table>
        <Body>
          {
            user.isEmailVerified && user.emailVerifiedDate && (
              <Row>
                <BoldedCell>{t('AUTH_MODAL_EMAIL')}:</BoldedCell>
                <Cell>
                  {user.isEmailVerified && <VerifiedIcon size={11} />}
                  <span>{moment(user.emailVerifiedDate).fromNow()}</span>
                </Cell>
              </Row>
            )
          }
          {/* <Row>
            <BoldedCell>{t('USER_PAGE_PERSONAL_ID')}:</BoldedCell>
            <Cell>
              <VerifiedIcon size={11} />
              <span>8 Month ago</span>
            </Cell>
          </Row> */}
          <Row>
            <BoldedCell>{t('USER_PAGE_ACCOUNT_CREATED')}:</BoldedCell>
            <Cell>
              <VerifiedIcon size={11} />
              <span>{moment(user.createdAt).fromNow()}</span>
            </Cell>
          </Row>
        </Body>
      </Table>
    );
  }
}

export default withNamespaces()(Info);