import React, { SyntheticEvent } from 'react';
import styled from 'styled-components';

interface Props {
  name: string;
  onChange?: (field: string, value: string) => void;
  onBlur?: (field: string) => void;
  className?: string;
  placeholder?: string | null;
}

const TextareaElement = styled.textarea`
  border: 1px solid #000;
  border-radius: 6px;
  max-width: 440px;
  width: 100%;
  resize: none;
  height: 180px;
  padding: 8px 8px;
  font-size: 14px;
` as any;

class Textarea extends React.Component<Props> {
  onChange = (e: SyntheticEvent<HTMLTextAreaElement>) => {
    this.props.onChange && this.props.onChange(this.props.name, (e.target as any).value);
  }
  onBlur = () => {
    this.props.onBlur && this.props.onBlur(this.props.name);
  }
  render() {
    const { className, name, placeholder } = this.props;
    return (
      <TextareaElement 
        name={name}
        className={className}  
        onChange={this.onChange}
        onBlur={this.onBlur}
        defaultValue={placeholder}
      />
    )
  }
}

export default Textarea;