import React from 'react';
import * as yup from 'yup';
import { 
  Container,
  Head,
  Title,
  LinkColored,
  Row,
  Label,
  Input,
  LastRow,
  ButtonsContainer,
  Button,
  // SocialButtonsTitle,
  // FacebookButton,
  // GoogleButton,
  Form
} from './Form';
import CountrySelect from '../CountrySelect';
import PhoneNumber from '../PhoneNumber';
import { withNamespaces, Trans, WithNamespaces } from 'react-i18next';
import { connect } from 'react-redux';
import { Formik, FormikProps } from 'formik';
import { setModal } from '../../actions/modal';
import { SignupBodyModel } from '../../models/SignupBodyModel';
import { AUTH_SIGNUP, authSignup } from '../../actions/auth';
import { CountryModelForSelect } from '../../models/CountryModel';
import { StoreModel } from '../../models/StoreModel';
import { createLoadingSelector } from '../../selectors/loading';
import { showBluredErrors } from '../../utils';
import { Link } from 'react-router-dom';
import FormElements from '../../shared/FormElements';

interface Props {
  isRequestPending: boolean;
  signup: (data: SignupBodyModel) => void
  openModal: (modal: string) => void
}

interface FormValues {
  username: string;
  email: string;
  password: string;
  passwordConfirmation: string;
  isConfirmed: string;
  country: CountryModelForSelect | null;
  phoneNumber: string;
}

class SignupForm extends React.Component<Props & WithNamespaces> {
  signupFormSchema = yup.object().shape({
    username: yup.string()
      .required(this.props.t('USERNAME_NO_EMPTY'))
      .matches(/(?!^[0-9]*$)^[A-Za-z]+((([.]|[-]|[_]|)[a-zA-Z0-9]+)?)$/, this.props.t('USERNAME_ONLY_ENGLISH_LETTERS'))
      .min(6, this.props.t('USERNAME_MIN_LENGTH'))
      .max(20, this.props.t('USERNAME_MAX_LENGTH'))
      .matches(/^(?!^[0-9]*$)^[A-Za-z]+((([.]|[-]|[_]|)[a-zA-Z0-9]+)?).*$/, this.props.t('USERNAME_START_WITH_ENGLISH_LETTER')),
    email: yup.string()
      .required(this.props.t('EMAIL_NO_EMPTY'))
      .matches(/^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,6}$/, this.props.t('EMAIL_NOT_VALID')),
    password: yup.string()
      .required(this.props.t('PASSWORD_NO_EMPTY'))
      .min(6, this.props.t('PASSWORD_MIN_LENGTH'))
      .max(20, this.props.t('PASSWORD_MAX_LENGTH'))
      .matches(/^(?=.*?[A-Za-z])(?=.*?[0-9]).+$/, this.props.t('PASSWORD_NOT_VALID'))
      .oneOf([yup.ref('passwordConfirmation')], this.props.t('PASSWORD_DIFFERENT_PASSWORDS')),
    passwordConfirmation: yup.string()
      .oneOf([yup.ref('password')], this.props.t('PASSWORD_DIFFERENT_PASSWORDS')),
    country: yup.mixed()
      .required(this.props.t('COUNTRY_NO_EMPTY')),
    phoneNumber: yup.string()
      .required(this.props.t('PHONE_NUMBER_NO_EMPTY'))
      .matches(/^\d{4,}$/im, this.props.t('PHONE_NUMBER_ENTER_VALID')),
    isConfirmed: yup.string()
      .required(this.props.t('AUTH_MODAL_CONFIRM_READ')),
  });
  openModal = (modal: string) => {
    return () => {
      this.props.openModal(modal);
    }
  }
  onSubmit = (values: any) => {
    const country = values.country.label;

    this.props.signup({
      ...values,
      country
    });
  }

  render() {
    const { t } = this.props;
    return (
      <Container>
        <Head>
          <Title>{t('HEADER_MENU_SIGNUP_LABEL')}</Title>
          <LinkColored onClick={this.openModal('login')}>{t('AUTH_MODAL_SIGN_IN')}</LinkColored>      
        </Head>
        <Formik 
          initialValues={{
            username: '',
            email: '',
            password: '',
            passwordConfirmation: '',
            isConfirmed: '',
            country: null,
            phoneNumber: '',
          }}
          validationSchema={this.signupFormSchema}
          isInitialValid={false}
          onSubmit={this.onSubmit}
          render={({ errors, touched, values, setFieldValue, setFieldTouched, isValid }: FormikProps<FormValues>) => (
            <Form errors={showBluredErrors(errors, touched)}> 
              <Row>
                <Label>{t('AUTH_MODAL_USERNAME')}</Label>
                <Input type="text" name="username" />
                <Label>{t('AUTH_MODAL_EMAIL')}</Label>
                <Input type="email" name="email" />
              </Row>
              <Row>
                <Label>{t('AUTH_MODAL_PASSWORD')}</Label>
                <Input type="password" name="password" />
                <Label>{t('AUTH_MODAL_CONFIRM_PASSWORD')}</Label>
                <Input type="password" name="passwordConfirmation" />
              </Row>
              <Row>
                <Label>{t('AUTH_MODAL_COUNTRY')}</Label>
                <CountrySelect 
                  onChange={setFieldValue}
                  onBlur={setFieldTouched}
                  name="country"
                  selectedCountry={values.country} 
                />
                <Label>{t('AUTH_MODAL_PHONE_NUMBER')}</Label>
                <PhoneNumber 
                  onChange={setFieldValue}
                  onBlur={setFieldTouched}
                  name="phoneNumber"
                  selectedCountry={values.country} 
                />
              </Row>
              <LastRow>
                <FormElements.Checkbox name="isConfirmed">
                  <Trans i18nKey="AUTH_MODAL_TERMS_AND_POLICY">
                    I agree to the <Link to="/terms" target="_blank">Terms & Conditions</Link> and the <Link to="/privacy" target="_blank">Privacy Policy</Link>
                  </Trans>
                </FormElements.Checkbox>
              </LastRow>
              <ButtonsContainer>
                <Button 
                  type="submit"
                  disabled={!isValid}
                  isPending={this.props.isRequestPending} 
                >
                  {t('HEADER_MENU_SIGNUP_LABEL')}
                </Button>
                {/* <SocialButtonsTitle>{t('AUTH_MODAL_OR_USE')}</SocialButtonsTitle>
                <FacebookButton />
                <GoogleButton /> */}
              </ButtonsContainer>
            </Form>
          )}
        />
      </Container>
    );
  }
}

const mapStateToProps = (state: StoreModel) => ({
  isRequestPending: createLoadingSelector([AUTH_SIGNUP])(state)
});

const mapDispatchToProps = (dispatch: any) => ({
  signup: (data: SignupBodyModel) => dispatch(authSignup(data)),
  openModal: (modal: string) => dispatch(setModal(modal))
});

export default connect(mapStateToProps, mapDispatchToProps)(withNamespaces()(SignupForm));