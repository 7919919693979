import React from 'react';
import styled from 'styled-components';
import { withNamespaces, WithNamespaces } from 'react-i18next';

const List = styled.ul`
  list-style-type: disc;
  font-size: 14px;

`;

const Item = styled.li`
  &:not(:last-child) {
    margin-bottom: 6px;
  }
`;

class SpecialMessage extends React.Component<WithNamespaces> {
  render() {
    const { t } = this.props;
    return (
      <List>
        <Item>{t('TRADE_CHAT_SPECIAL_1')}</Item>
        <Item>{t('TRADE_CHAT_SPECIAL_2')}</Item>
        <Item>{t('TRADE_CHAT_SPECIAL_3')}</Item>
        <Item>{t('TRADE_CHAT_SPECIAL_4')}</Item>
        <Item>{t('TRADE_CHAT_SPECIAL_5')}</Item>
      </List>
    )
  }
}

export default withNamespaces()(SpecialMessage);