import React from 'react';
import styled, { keyframes } from 'styled-components';
import { LoginModal, SignupModal, ForgotPasswordModal, ResetPasswordModal, ResetPasswordSuccessModal } from '../AuthModal';
import { connect } from 'react-redux';
import { StoreModel } from '../../models/StoreModel';
import { SET_MODAL } from '../../actions/modal';
import { isModalOpened } from '../../selectors/modal';
import modals from '../../constants/modals';
import OpenTransactionModal from '../Popups/OpenTransactionModal';
import RateModal from '../Popups/RateModal';
import TransactionSuccess from '../Popups/TransactionSuccess';
import EnterPinModal from '../Popups/EnterPinModal';
import VerifyEmailPopup from '../Popups/VerifyEmailPopup';
import ReportPopup from '../Popups/ReportPopup';
import SetPinModal from '../Popups/SetPinModal';
import CancelModal from '../Popups/CancelModal';


interface Props {
  currentModal?: string;
  scope?: any;
  removeCurrentModal: () => void;
  isVisible: boolean;
}

interface State {
  isVisible: boolean;
}

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const fadeOut = keyframes`
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
`;

const ModalWrapper = styled.div`
  display: flex;
  position: fixed;
  align-items: center;
  justify-content: center;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0,0,0,.6);
  z-index: 2;
  opacity: 0;
  animation: ${({ isVisible }: any) => isVisible ? fadeIn : fadeOut} .25s;
  animation-fill-mode: forwards;
` as any;

const modalWrapper = (modal: any, isVisible: boolean) => (
  <ModalWrapper id="modal-wrapper" isVisible={isVisible}>
    {modal}
  </ModalWrapper>
);

class Modal extends React.Component<Props, State> {
  componentWillReceiveProps(nextProps: Props) {
    document.removeEventListener('click', this.onClickOutsideHandler);

    if (nextProps.currentModal) {
      document.addEventListener('click', this.onClickOutsideHandler);
    }
  }
  onClickOutsideHandler = (e: Event) => {
    const wrapper = document.querySelector('#modal-wrapper > div');
    const target = e.target as any;
    const targetParent = target.parentElement;

    if (wrapper && !wrapper.contains(target) && !target.id.includes('react-select') && !targetParent.id.includes('react-select')) {
      this.onClose();
    }
  }
  onClose = () => {
    this.setState({
      isVisible: false
    }, () => {
      setTimeout(() => {
        this.props.removeCurrentModal();
      }, 250);
    })
  }
  render() {
    const { currentModal, scope, isVisible } = this.props;
    switch (currentModal) {
      case modals.SIGN_UP:
        return modalWrapper(<SignupModal onClose={this.onClose} />, isVisible);
      case modals.LOGIN:
        return modalWrapper(<LoginModal onClose={this.onClose} />, isVisible);
      case modals.FORGOT_PASSWORD:
        return modalWrapper(<ForgotPasswordModal onClose={this.onClose} />, isVisible);
      case modals.RESET_PASSWORD:
        return modalWrapper(<ResetPasswordModal scope={scope} onClose={this.onClose} />, isVisible);
      case modals.RESET_PASSWORD_SUCCESS:
        return modalWrapper(<ResetPasswordSuccessModal onClose={this.onClose} />, isVisible);
      case modals.OPEN_TRANSACTION:
        return modalWrapper(<OpenTransactionModal scope={scope} />, isVisible);
      case modals.TRANSACTION_SUCCESS:
        return modalWrapper(<TransactionSuccess scope={scope} />, isVisible);
      case modals.ENTER_PIN:
        return modalWrapper(<EnterPinModal scope={scope} />, isVisible);
      case modals.SET_PIN:
        return modalWrapper(<SetPinModal />, isVisible);
      case modals.VERIFY_EMAIL:
        return modalWrapper(<VerifyEmailPopup />, isVisible);
      case modals.REPORT:
        return modalWrapper(<ReportPopup />, isVisible);
      case modals.CANCEL:
        return modalWrapper(<CancelModal scope={scope} />, isVisible);
      case modals.RATE:
        return modalWrapper(<RateModal scope={scope} />, isVisible);
      default:
        return null;
    }
  }
}

const mapStateToProps = (state: StoreModel) => ({
  currentModal: state.modal.currentModal,
  scope: state.modal.scope,
  isVisible: isModalOpened(state)
});

const mapDispatchToProps = (dispatch: any) => ({
  removeCurrentModal: () => dispatch({
    type: SET_MODAL,
    payload: {
      currentModal: undefined
    }
  })
})

export default connect(mapStateToProps, mapDispatchToProps)(Modal);