import React from 'react';

export const ArrowIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 6.56 11.27" width="6" height="10">
    <g>
      <path d="M.62 10.05a.42.42 0 0 0 .6.6l4.72-4.71a.42.42 0 0 0 0-.6L1.22.62a.42.42 0 0 0-.6.6L5 5.64zm0 0" stroke="#1e1f36" strokeMiterlimit="10" />
    </g>
  </svg>
);

export const ArrowWithLineIcon = ({width, height, fill}: any) => (
  <svg className="arrow-with-line-icon" xmlns="http://www.w3.org/2000/svg" width={width || 7} height={height || 5} viewBox="0 0 400 400">
    <path d="M0 200c0 9.6 7.8 17.3 17.3 17.3h323.6l-77.2 77.2c-6.8 6.8-6.8 17.7 0 24.5 3.4 3.4 7.8 5.1 12.2 5.1 4.4 0 8.9-1.7 12.2-5.1l106.8-106.8c6.8-6.8 6.8-17.7 0-24.5L288.2 81c-6.8-6.8-17.7-6.8-24.5 0-6.8 6.8-6.8 17.7 0 24.5l77.2 77.2H17.3C7.8 182.7 0 190.4 0 200z" fill={fill || '#9f9f9f'} />
  </svg>
);
