import styled, { keyframes } from 'styled-components';
import screens from '../../constants/screens';
import CircleButton from './CircleButton';
import Error from './Error';
import Form from './Form';

const FadeIn = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

const Container = styled.div`
  position: relative;
  margin: 0 20px 50px;
  .preloader {
    display: block;
    width: 90px;
    height: 90px;
  }
`;

const Wrapper = styled.div`
  display: ${({ splited, isError }: any) => splited ? isError ? 'block' : 'flex' : 'block'};
  max-width: 992px;
  overflow: hidden;
  margin: 0 auto;
  box-shadow: ${({ noShadow }: any) => noShadow ? 'none' : '0 0 24px 0 rgba(0,0,0,.1)'};
  padding: ${({ splited, isPending, isError }: any) => splited ? isPending || isError ? '100px 70px' : '0' : '100px 70px'};
  ${({ noPadding }: any) => noPadding && 'padding: 0'};
  animation-name: ${({ isPending, isError }: any) => !isPending || (!isPending && isError) ? FadeIn : 'none' };
  animation-duration: .25s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  animation-delay: .1s;
  opacity: 0;
  ${screens.mobile} {
    flex-direction: column;
    box-shadow: none;
    padding: 0;
    overflow: visible;
  }
` as any;

const Title = styled.div`
  font-size: 4.4rem;
  font-weight: 500;
  margin-bottom: 62px;
`;

const Subtitle = styled.div`
  font-size: 1.4rem;
  font-weight: 500;
`;

const Footer = styled.div`
  position: relative;
  display: flex;
  max-width: 992px;
  margin: auto auto 0;
  justify-content: space-between;
  align-items: center;
  background-color: #f9f9f9;
  height: 80px;
  padding: 0 50px;
  ${screens.mobile} {
    background: none;
    padding: 0;
  }
`;

const UI = {
  Container,
  Wrapper,
  Title,
  Subtitle,
  Footer,
  CircleButton,
  Error,
  Form,
};

export default UI;