import React from 'react';

export const PositiveFeedbackIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 505 505">
    <g>
      <g>
        <circle cx="252.5" cy="252.5" r="252.5" fill="#2bbc70"/>
        <path d="M410.3 222.5c0-10.4-8.8-18.9-19.8-18.9h-63.1a13.89 13.89 0 0 1-7.5-5.2c-3.6-5.4-2.4-12.6.3-18.5 4-8.9 10.7-16.4 14.9-25.2a49.79 49.79 0 0 0 .9-40.7c-2.7-6.4-7-12.4-13.2-15.9-2.5-1.4-5.4-2.4-8.1-1.7-4.6 1.1-6.7 6.2-7.9 10.6-2.6 9.6-4.3 19.5-9.2 28.2a88.53 88.53 0 0 1-8.9 12.2c-7.4 8.8-15.2 17.5-24.7 24.3-10.9 7.9-21.8 18.5-34.5 23.6-10.4 4.2-23.4 3.9-33.7 8.1v151.2h170.1c10.9 0 19.8-8.5 19.8-18.9s-8.8-18.9-19.8-18.9h13.4c10.9 0 19.8-8.5 19.8-18.9s-8.8-18.9-19.8-18.9h9c10.9 0 19.8-8.5 19.8-18.9s-8.8-18.9-19.8-18.9h2.3c10.9.2 19.7-8.2 19.7-18.7z" fill="#fff"/>
        <path d="M190.7 183.8h-93a3 3 0 0 0-3 3v183.5a3 3 0 0 0 3 3h93c7.4 0 13.4-6.4 13.4-14.4V198.2c.1-7.9-6-14.4-13.4-14.4z" fill="#324a5e"/>
        <circle cx="149.4" cy="341.1" r="16.6" fill="#acb3ba"/>
      </g>
    </g>
  </svg>
);

export const NegativeFeedbackIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 505 505">
    <g>
      <g>
        <circle cx="252.5" cy="252.5" r="252.5" fill="#cf0022"/>
        <path d="M94.7 290.74c0 10.4 8.8 18.9 19.8 18.9h63.1a13.89 13.89 0 0 1 7.5 5.2c3.6 5.4 2.4 12.6-.3 18.5-4 8.9-10.7 16.4-14.9 25.2a49.79 49.79 0 0 0-.9 40.7c2.7 6.4 7 12.4 13.2 15.9 2.5 1.4 5.4 2.4 8.1 1.7 4.6-1.1 6.7-6.2 7.9-10.6 2.6-9.6 4.3-19.5 9.2-28.2a88.53 88.53 0 0 1 8.9-12.2c7.4-8.8 15.2-17.5 24.7-24.3 10.9-7.9 21.8-18.5 34.5-23.6 10.4-4.2 23.4-3.9 33.7-8.1v-151.2H139.1c-10.9 0-19.8 8.5-19.8 18.9s8.8 18.9 19.8 18.9h-13.4c-10.9 0-19.8 8.5-19.8 18.9s8.8 18.9 19.8 18.9h-9c-10.9 0-19.8 8.5-19.8 18.9s8.8 18.9 19.8 18.9h-2.3c-10.9-.2-19.7 8.2-19.7 18.7z" fill="#fff"/>
        <path d="M314.3 329.44h93a3 3 0 0 0 3-3v-183.5a3 3 0 0 0-3-3h-93c-7.4 0-13.4 6.4-13.4 14.4V315c-.1 7.94 6 14.44 13.4 14.44z" fill="#324a5e"/>
        <circle cx="355.6" cy="172.14" r="16.6" fill="#acb3ba"/>
      </g>
    </g>
  </svg>
);
