import React from 'react';
import styled from 'styled-components';
import { ADMIN_SUMMER_SKY } from '../../constants/colors';
import AdminSectionOptions from './AdminSectionOptions';

const Header = styled.div`
  background: ${ADMIN_SUMMER_SKY};
  color: #fff;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 77px;
`;

const HeaderTitle = styled.h3`
  font-size: 25px;
  letter-spacing: 1px;
  font-weight: 400;
`;

interface Props {
  title: string,
  options?: any[]
}

const AdminSectionHeader = ({ title, options}: Props) => (
  <Header>
    <HeaderTitle>
      {title}
    </HeaderTitle>
    {options && <AdminSectionOptions options={options} />}
  </Header>
);

export default AdminSectionHeader;