import React from 'react';
import styled from 'styled-components/macro'
import QRCode from 'qrcode.react';
import { withNamespaces, WithNamespaces } from 'react-i18next';
import Container from '../../components/Container';
import { connect } from 'react-redux';
import { StoreModel } from '../../models/StoreModel';
import { WALLET_GET_WALLET_ADDRESS_REQUESTED, WALLET_GET_WALLET_ADDRESS } from '../../actions/wallet';
import { getWalletAddress } from '../../selectors/wallet';
import { createLoadingSelector } from '../../selectors/loading';
import { createErrorSelector } from '../../selectors/errorResponse';
import screens from '../../constants/screens';

interface Props {
  walletAddress: string | null;
  getWalletAddress: () => void;
  isPending: boolean;
  isError: boolean;
}

const Content = styled.div`
  text-align: center;
`;

const Code = styled.div`
  display: inline-block;
  font-size: 18px;
  font-weight: 600;
  padding: 4px 60px;
  border: 2px solid #000;
  border-radius: 6px;
  margin: 37px auto 32px;
  word-break: break-all;
  ${screens.mobile} {
    padding: 4px 32px;
  }
`;

const Subtitle = styled.div`
  color: #2bbc70;
  font-weight: 500;
  font-size: 1.8rem;
`;

const Note = styled.div`
  font-size: 1.8rem;
  font-weight: 400;
  color: #000;
  max-width: 420px;
  margin: 0 auto 24px;
`;

export class Deposit extends React.Component<Props & WithNamespaces> {
  componentDidMount() {
    this.props.getWalletAddress();
  }
  render() {
    const { t, isPending, walletAddress, isError } = this.props;
    return (
      <Container
        isPending={isPending}
        isError={isError}
        footerConfig={{
          prevButton: {
            link: '/wallet',
            arrowRotate: true,
            title: t('BACK_TO_WALLET_OVERVIEW')
          }
        }}
        title={t('DEPOSIT_BITCOIN')}
      >      
        <Content>
          <div>
            {walletAddress && <QRCode value={walletAddress} size={103} />}
          </div>
          <Code>{walletAddress}</Code>
          <Subtitle>{t('BITCOIN_WALLET_ADDRESS')}</Subtitle>
          <Note>{t('BITCOIN_WALLET_NOTE')}</Note>
        </Content>
      </Container>
    )
  }
}

const mapStateToProps = (state: StoreModel) => ({
  walletAddress: getWalletAddress(state),
  isPending: createLoadingSelector([WALLET_GET_WALLET_ADDRESS])(state),
  isError: createErrorSelector([WALLET_GET_WALLET_ADDRESS])(state),
});

const mapDispatchToProps = (dispatch: any) => ({
  getWalletAddress: () => {
    dispatch({
      type: WALLET_GET_WALLET_ADDRESS_REQUESTED,
      payload: {}
    });
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(withNamespaces()(Deposit));