import React from 'react';
import styled from 'styled-components';
import Topic from './Topic';

interface Props {
  topics: any[] | null;
  onTopicSelect: (topic: any) => void;
}

const Container = styled.div`
  border: 1px solid #000;
  border-radius: 6px;
`;

class Topics extends React.Component<Props> {
  render() {
    const { topics } = this.props;
    return (
      <Container>
        {
          topics && topics.map((topic: any) => (
            <Topic topic={topic} key={topic.id} onSelect={this.props.onTopicSelect} />
          ))
        }
      </Container>
    )
  }
}

export default Topics;