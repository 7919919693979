import React from 'react';
import styled from 'styled-components';
import { withNamespaces, WithNamespaces } from 'react-i18next';
import Trade from './Trade';
import { GREY_7F } from '../../constants/colors';
import { AdModel } from '../../models/AdModel';
import { getFiatByBTC } from '../../utils';

interface Props {
  ads: AdModel[] | null;
}
interface State {
  oneCoinPrice: number | null;
}

const Table = styled.table`
  max-width: 925px;
  margin: 0 auto;
  width: 100%;
  border-collapse: collapse;
  font-size: 1.7rem;
`;

const Head = styled.thead`
  border-bottom: 1px solid ${GREY_7F};
`;

const HeadCell = styled.th`
  text-align: left;
  font-weight: 600;
  font-size: 2.2rem;
  padding: 17px 0;
`;

const Body = styled.tbody``;

const Row = styled.tr``;

class TradesTable extends React.Component<Props & WithNamespaces, State> {
  state: State = {
    oneCoinPrice: null
  }
  componentDidMount() {
    getFiatByBTC(1).then((price: string) => {
      this.setState({
        oneCoinPrice: !!price.length ? parseFloat(price) : null
      });
    });
  }
  render() {
    const { t, ads } = this.props;
    return (
      <Table>
        <Head>
            <Row>
              <HeadCell>{t('STARTPAGE_LAST_LOCALE_ADVERTISMENT_TABLE_USER_TITLE')}</HeadCell>
              <HeadCell>{t('USER_PAGE_PRICE')}</HeadCell>
              <HeadCell>{t('STARTPAGE_LAST_LOCALE_ADVERTISMENT_TABLE_LIMITS_TITLE')}</HeadCell>
              <HeadCell>{t('STARTPAGE_LAST_LOCALE_ADVERTISMENT_TABLE_TRANSACTION_TYPE_TITLE')}</HeadCell>
              <HeadCell>{t('STARTPAGE_LAST_LOCALE_ADVERTISMENT_TABLE_PLACE_TITLE')}</HeadCell>
              <HeadCell />
            </Row>
        </Head>
        <Body>
          {
            ads && ads.slice(0, 5).map((ad: AdModel) => (
              <Trade key={ad.id} ad={ad} coinPrice={this.state.oneCoinPrice} />
            ))
          }
        </Body>
      </Table>
    );
  }
}

export default withNamespaces()(TradesTable);