import React from 'react';
import store from '../store';
import { CLOSE_ALL_MODALS } from '../actions/modal';

interface Props {
  onClick?: () => void;
}

const closeModalButton = (WrapperComponent: any) => {
  return class extends React.Component<Props> {
    onClick = () => {
      this.props.onClick && this.props.onClick();
      store.dispatch({
        type: CLOSE_ALL_MODALS
      });
    }
    render() {
      return (
        <WrapperComponent
          {...this.props}
          onClick={this.onClick}
        />
      )
    }
  }
}

export default closeModalButton;