import React from 'react';
import * as yup from 'yup';
import PopupsComponents from './components';
import { withNamespaces, WithNamespaces } from 'react-i18next';
import { Formik, FormikProps } from 'formik';
import UI from '../../shared/UI';
import { connect } from 'react-redux';
import { StoreModel } from '../../models/StoreModel';
import { getGuestUser } from '../../selectors/user';
import { UserModel } from '../../models/UserModel';
import { USER_REPORT_REQUESTED, USER_REPORT } from '../../actions/user';
import { createLoadingSelector } from '../../selectors/loading';

interface Props {
  isRequestPending: boolean;
  guestUser: UserModel | null;
  sendReport: (userId: number, body: FormValues) => void;
}

interface FormValues {
  type: string;
  message: string;
}

class ReportPopup extends React.Component<Props & WithNamespaces> {
  pinSchema = yup.object().shape({
    type: yup.string()
      .required(this.props.t('REPORT_TYPE_NO_EMPTY'))
  })
  onSubmit = (values: FormValues) => {
    if (this.props.guestUser) {
      this.props.sendReport(this.props.guestUser.id, values);
    }
  }
  render() {
    const { t, isRequestPending } = this.props;
    return (
      <PopupsComponents.Container>
        <PopupsComponents.Header>
          {t('REPORT_HEADER')}
        </PopupsComponents.Header>
        <PopupsComponents.Content>
        <Formik 
          initialValues={{
            type: '',
            message: ''
          }}
          validationSchema={this.pinSchema}
          isInitialValid={false}
          onSubmit={this.onSubmit}
          render={({ errors, values, setFieldValue, setFieldTouched, isValid }: FormikProps<FormValues>) => (
            <UI.Form errors={errors}>
              <PopupsComponents.RadioItem>
                <PopupsComponents.Radio 
                  name="type" 
                  onChange={setFieldValue}
                  value={'0'}
                />
                <span>{t('REPORT_NOT_REAL_PERSON')}</span>
              </PopupsComponents.RadioItem>
              <PopupsComponents.RadioItem>
                <PopupsComponents.Radio 
                  name="type" 
                  onChange={setFieldValue}
                  value={'1'}
                />
                <span>{t('REPORT_LANGUAGE')}</span>
              </PopupsComponents.RadioItem>
              <PopupsComponents.RadioItem>
                <PopupsComponents.Radio 
                  name="type" 
                  onChange={setFieldValue}
                  value={'2'}
                />
                <span>{t('REPORT_TRADE_ISSUE')}</span>
              </PopupsComponents.RadioItem>
              <PopupsComponents.Textarea 
                name="message"
                placeholder={t('REPORT_DESCRIBE')} 
                onChange={setFieldValue}
                onBlur={setFieldTouched}
              />
              <PopupsComponents.ActionButton
                type="submit"
                disabled={!isValid}
                isPending={isRequestPending}
              >
                {t('Send')}
              </PopupsComponents.ActionButton>
            </UI.Form>
          )}
        />
        </PopupsComponents.Content>
      </PopupsComponents.Container>
    )
  }
}

const mapStateToProps = (state: StoreModel) => ({
  isRequestPending: createLoadingSelector([USER_REPORT])(state),
  guestUser: getGuestUser(state)
})

const mapDispatchToProps = (dispatch: any) => ({
  sendReport: (userId: number, body: FormValues) => dispatch({
    type: USER_REPORT_REQUESTED,
    payload: {
      userId,
      ...body
    }
  })
});

export default connect(mapStateToProps, mapDispatchToProps)(withNamespaces()(ReportPopup));