import React from 'react';
import BarChart from '../../components/BarChart';

const data = [
  {key: 'Mon', value: 43},
  {key: 'Tue', value: 31},
  {key: 'Wed', value: 29},
  {key: 'Thu', value: 58},
  {key: 'Fri', value: 78},
  {key: 'Sat', value: 47},
  {key: 'Sun', value: 63},
];

class AdminUserRegistration extends React.Component {
  render () {
    return (
      <BarChart data={data} showArrows />
    )
  }
}

export default AdminUserRegistration;