import React from 'react';
import HomeBanner from './HomeBanner';
import TraderSearch from '../../components/TraderSearch';
import LastTrades from '../../components/LastTrades';
import SignUpBanner from './SignUpBanner';
import Advantages from './Advantages';
import { connect } from 'react-redux';
import { StoreModel } from '../../models/StoreModel';
import { isUserLoggedIn } from '../../selectors/user';
import { RouteComponentProps } from 'react-router';
import modals from '../../constants/modals';
import { setModal } from '../../actions/modal';

interface Props {
  openModal: (modal: string, scope?: any) => void;
  isUserLoggedIn: boolean;
}

class Home extends React.Component<Props & RouteComponentProps<any>> {
  componentDidMount() {
    let token = null;

    const tokenMatch = this.props.location.search.match('[?&]reset-password-token=(.+)$');
    token = tokenMatch && tokenMatch[1];

    if (token) {
      this.props.history.push('/');
      this.props.openModal(modals.RESET_PASSWORD, {
        token
      });
    }
  }
  render() {
    return (
      <>
        <HomeBanner />
        <TraderSearch />
        <LastTrades />
        {!this.props.isUserLoggedIn && <SignUpBanner />}
        <Advantages isUserLoggedIn={this.props.isUserLoggedIn} />
      </>
    )
  }
}

const mapStateToProps = (state: StoreModel) => ({
  isUserLoggedIn: isUserLoggedIn(state)
});

const mapDispatchToProps = (dispatch: any) => ({
  openModal: (modal: string, scope?: any) => {
    dispatch(setModal(modal, scope));
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(Home);