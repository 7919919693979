import React from 'react';
import styled from 'styled-components';
import Avatar from '../../components/Avatar';
import { withNamespaces, WithNamespaces } from 'react-i18next';
import { Link } from 'react-router-dom';
import { ChatModel } from '../../models/ChatModel';
import { connect } from 'react-redux';
import { StoreModel } from '../../models/StoreModel';
import { getCurrentUser } from '../../selectors/user';
import { UserModel } from '../../models/UserModel';
import screens from '../../constants/screens';
import { GREY_A6 } from '../../constants/colors';

interface Props {
  user: UserModel | null;
  chat: ChatModel;
}

const ChatLink = styled.div`
  display: flex;
  align-items: center;
  padding: 16px 40px;
  box-shadow: 0 0 24px 0 rgba(0,0,0,.1);
  border-radius: 6px;
  background-color: #fbfbfb;
  ${screens.mobile} {
    padding: 20px;
  }
  &:not(:last-child) {
    margin-bottom: 30px;
  }
`;

const UserInfoContainer = styled.div`
  padding-left: 18px;
`;

const Username = styled(Link)`
  font-size: 1.4rem;
  font-weight: 600;
`;

const ButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  margin-left: auto;
`;

const StyledLink = styled(Link)`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${GREY_A6};
  box-shadow: 0 0 24px 0 rgba(0,0,0,.1);
  border-radius: 6px;
  height: 32px;
  min-width: 120px;
  padding: 0 8px;
  font-size: 1.4rem;
  color: #fff;
  font-weight: 700;
  margin-left: 8px;
`;

const OpenChatButton = styled(StyledLink)`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #2bbc70;
  box-shadow: 0 0 24px 0 rgba(0,0,0,.1);
  border-radius: 6px;
  height: 32px;
  min-width: 120px;
  padding: 0 8px;
  font-size: 1.4rem;
  color: #fff;
  font-weight: 700;
  margin-left: 8px;
`;

class ChatItem extends React.Component<Props & WithNamespaces> {
  render() {
    const { user, t, chat } = this.props;
    const { recipient } = chat;
    const chatCreator = chat.sender;

    if (!user) { return null; }
    
    const visibleUser = chatCreator.id === user.id ? recipient : chatCreator;
    
    return (
      <ChatLink>
        <Avatar rounded avatarPath={visibleUser.avatarPath} />
        <UserInfoContainer>
          <Username to={`/user/${visibleUser.id}`}>{visibleUser.username}</Username>
        </UserInfoContainer>
        <ButtonsContainer>
          <StyledLink to={`/trade/${chat.tradeId}`}>{t('DETAILS')}</StyledLink>
          <OpenChatButton to={`/chat/${chat.id}`}>{t('CHATS_OPEN_CHAT')}</OpenChatButton>
        </ButtonsContainer>
      </ChatLink>
    )
  }
}

const mapStateToProps = (state: StoreModel) => ({
  user: getCurrentUser(state)
});

export default connect(mapStateToProps)(withNamespaces()(ChatItem));