import React from 'react';

const TraderNumberIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="23" height="23" viewBox="0 0 477.9 477.9">
    <path d="M392.5 0H85.3C38.2.1.1 38.2 0 85.3v307.2c.1 47.1 38.2 85.3 85.3 85.3h307.2c47.1-.1 85.3-38.2 85.3-85.3V85.3c0-47.1-38.2-85.2-85.3-85.3zm30.1 215.9c-.6.7-1.2 1.3-1.9 1.9L301.2 320.2c-7.2 6.1-17.9 5.3-24.1-1.8-2.7-3.1-4.1-7-4.1-11.1V256H170.7c-9.4 0-17.1-7.6-17.1-17.1v-27.1L92.4 273l61.2 61.2v-27.1c0-9.4 7.6-17.1 17.1-17.1h51.2c9.4 0 17.1 7.6 17.1 17.1s-7.6 17.1-17.1 17.1h-34.1v51.2c0 6.9-4.2 13.1-10.5 15.8-2.1.9-4.3 1.3-6.5 1.3-4.5 0-8.9-1.8-12.1-5L56.2 285.2c-6.7-6.7-6.7-17.5 0-24.1l102.4-102.4c6.7-6.7 17.5-6.7 24.1 0 3.2 3.2 5 7.5 5 12.1V222h102.4c9.4 0 17.1 7.6 17.1 17.1v31.2l76.2-65.3-76.2-65.3v31.1c0 9.4-7.6 17.1-17.1 17.1h-51.2c-9.4 0-17.1-7.6-17.1-17.1s7.6-17.1 17.1-17.1H273v-51.2c0-9.4 7.6-17.1 17.1-17.1 4.1 0 8 1.5 11.1 4.1l119.5 102.4c7.2 6.1 8 16.9 1.9 24z"/>
  </svg>
);

export default TraderNumberIcon;

