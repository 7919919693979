import React from 'react';
import * as yup from 'yup';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { StoreModel } from '../../models/StoreModel';
import { isOwnTrade } from '../../selectors/ads';
import { AdModel } from '../../models/AdModel';
import User from '../../components/User';
import { withNamespaces, WithNamespaces } from 'react-i18next';
import PriceInput from '../../components/PriceInput';
import { getFiatByBTC, getCoinPriceWithMargin, getLimit } from '../../utils';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import { CHAT_CREATE_NEW_CHAT, CHAT_CREATE_NEW_CHAT_REQUESTED } from '../../actions/chat';
import { getCurrentUser } from '../../selectors/user';
import { UserModel } from '../../models/UserModel';
import Button from '../../components/Button';
import { createLoadingSelector } from '../../selectors/loading';
import screens from '../../constants/screens';
import { AD_DELETE_REQUESTED, AD_DELETE } from '../../actions/ads';
import { RED_CF } from '../../constants/colors';
import { createSuccessSelector } from '../../selectors/successResponse';
import FiatInput from '../../shared/FormElements/FiatInput';
import { Formik, FormikProps } from 'formik';
import CoinPrice from '../../shared/CoinPrice';
import UI from '../../shared/UI';

interface Props {
  ad: AdModel;
  user: UserModel | null;
  availableBalance: number;
  isOwnTrade: boolean;
  isUserLoggedIn: boolean;
  isCreatingChatPending: boolean;
  isDeletingAdPending: boolean;
  isDeletingAdSuccess: boolean;
  createChat: (tradeId: number, senderId: number, recipientId: number, amount: string) => void;
  deleteAd: (id: number) => void;
}

interface FormValues {
  volume: string;
  volumeFiat: string;
}

const LeftContainer = styled.div`
  flex: 1 0 55%;
  max-width: 55%;
  background-color: #fff;
  padding: 90px 60px 60px;
  ${screens.mobile} {
    flex: 1 0 100%;
    max-width: 100%;
    padding: 0;
    margin-bottom: 20px;
  }
`;

const RightContainer = styled.div`
  flex: 1 0 45%;
  max-width: 45%;
  background-color: #f0f0f0;
  padding: 60px;
  ${screens.mobile} {
    flex: 1 0 100%;
    max-width: 100%;
    padding: 90px 20px 60px;
  }
`;

const Title = styled.div`
  font-size: 44px;
  line-height: 50px;
  margin-bottom: 66px;
`;

const TermsTitle = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 30px;
`;

const TermsContainer = styled.div`
  font-size: 12px;
  font-weight: 300;
  font-style: italic;
  padding: 20px;
  border: 1px solid #000;
  border-radius: 6px;
  margin-bottom: 40px;
  word-break: break-word;
`;

const PriceTitle = styled.div`
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 18px;
`;

const PriceContainer = styled.div`
  margin-bottom: 40px;
`;


const StartChatButton = styled(Button)`
  height: 35px;
  color: #fff;
  background-color: ${({ disabled }: any) => disabled ? '#919191' : '#2bbc70'};
  border-radius: 6px;
  font-size: 14px;
  font-weight: 700;
` as any;

const TableContainer = styled.div`
  padding: 10px 20px;
  box-shadow: 0 0 24px 0 rgba(0,0,0,.1);
  background-color: #fff;
  border-radius: 6px;
  margin-bottom: 40px;
`;

const Table = styled.table`
  width: 100%;
  font-size: 14px;
  border-collapse: separate;
  border-spacing: 0 10px;
`;

const Body = styled.tbody``;

const Row = styled.tr``;

const Cell = styled.td`
  vertical-align: middle;
  width: 50%;
  padding: 5px 5px 15px;
  &:first-child {
    font-weight: 600;
  }
`;

const StyledFiatInput = styled(FiatInput)`
  margin-left: 16px;
` as any;

const Username = styled(Link)`
  display: inline-block;
  font-size: 14px;
  font-weight: 500;
  margin-right: 4px;
  &:hover {
    text-decoration: underline;
  }
`;

const DeleteTradeButton = styled(Button)`
  border-radius: 6px;
  min-height: 35px;
  width: 100%;
  font-weight: 700;
  font-size: 1.4rem;
  line-height: 2rem;
  margin-bottom: 24px;
  color: ${RED_CF};
  border: 2px solid ${RED_CF};
`;

const StyledUserRating = styled(User.Rating)`
  display: inline-flex;
`;

class Trade extends React.Component<Props & WithNamespaces & RouteComponentProps<any>> {
  volumeFormSchema = yup.object().shape({
    volume: yup.number()
      .required(this.props.t('TR_VOLUME_NO_EMPTY'))
      .moreThan(0, this.props.t('TR_VOLUME_MORE_THAN_ZERO'))
      .min(this.props.ad.minimum, this.props.t('TR_VOLUME_LESS_THAN_MIN'))
      .max(this.props.availableBalance, this.props.t('TR_VOLUME_MORE_THAN_BALANCE'))
      .max(this.props.ad.maximum, this.props.t('TR_VOLUME_MORE_THAN_MAX'))
  });

  componentDidUpdate() {
    if (this.props.isDeletingAdSuccess && this.props.user) {
      this.props.history.push(`/user/${this.props.user.id}`)
    }
  }

  componentDidMount() {
    getFiatByBTC(1).then((price: string) => {
      this.setState({
        oneCoinPrice: parseFloat(price)
      });
    })
  }

  onSubmit = (values: FormValues) => {
    const { ad, user } = this.props;

    if (ad && user && values.volume) { 
      if (ad.type === 1) {
        this.props.createChat(ad.id, ad.userId, user.id, values.volume);
      } else {
        this.props.createChat(ad.id, user.id, ad.userId, values.volume);
      }
    }
  }

  deleteAd = () => {
    this.props.deleteAd(this.props.ad.id);
  }
  
  render() {
    const { ad, t, isCreatingChatPending, isDeletingAdPending, isOwnTrade, isUserLoggedIn } = this.props;
    const { user, fee, minimum, maximum, currency } = ad;

    return (
      <CoinPrice>
        {(coinPrice: number) => (
          <>
            <LeftContainer>
              <Title>{ad.title}</Title>
              {
                user.tradeCondition && (
                  <>
                    <TermsTitle>
                      {
                        t('TRADE_PAGE_USER_TERMS_OF_TRADE', {
                          username: user.username
                        })
                      }
                    </TermsTitle>
                    <TermsContainer>{user.tradeCondition}</TermsContainer>
                  </>
                )
              }
              {
                ad.description && (
                  <>
                    <TermsTitle>
                      {
                        t('TRADE_PAGE_AD_DESCRIPTION', {
                          username: user.username
                        })
                      }
                    </TermsTitle>
                    <TermsContainer>{ad.description}</TermsContainer>
                  </>
                )
              }
              {
                !isOwnTrade && isUserLoggedIn && (
                  <Formik 
                    initialValues={{
                      volume: '',
                      volumeFiat: ''
                    }}
                    validationSchema={this.volumeFormSchema}
                    isInitialValid={false}
                    onSubmit={this.onSubmit}
                    render={({ errors, setFieldValue, isValid }: FormikProps<FormValues>) => (
                      <UI.Form errors={errors}>
                        <PriceTitle>
                          {ad.type === 1 ? t('TRADE_PAGE_HOW_MUCH_DO_YOU_WANT_TO_BUY') : t('TRADE_PAGE_HOW_MUCH_DO_YOU_WANT_TO_SELL')}
                        </PriceTitle>
                        <PriceContainer>
                          <PriceInput
                            name="volume"
                            connectedName="volumeFiat"
                            onChange={setFieldValue}
                            coinPrice={coinPrice}
                          />
                          <StyledFiatInput
                            name="volumeFiat"
                            connectedName="volume"
                            onChange={setFieldValue}
                            coinPrice={coinPrice}
                          />
                        </PriceContainer>
                        <StartChatButton 
                          type="submit"
                          disabled={!isValid}
                          isPending={isCreatingChatPending}
                        >
                          {t('TRADE_PAGE_START_TRADE_CHAT')}
                        </StartChatButton> 
                      </UI.Form>
                    )}
                  />
                )
              }
            </LeftContainer>
            <RightContainer>
              {isOwnTrade && (
                <DeleteTradeButton 
                  onClick={this.deleteAd}
                  isPending={isDeletingAdPending}
                  isLoaderRed
                >
                  {t('TRADE_DELETE_AD')}
                </DeleteTradeButton>
              )}
              <TableContainer>
                <Table>
                  <Body>
                    <Row>
                      <Cell>{t('USER_PAGE_PRICE')}</Cell>
                      <Cell>{getCoinPriceWithMargin(coinPrice, fee)}</Cell>
                    </Row>
                    { user && (
                      <Row>
                        <Cell>{t('TRADE_PAGE_USER')}</Cell>
                        <Cell>
                          <Username to={`/user/${user.id}`}>{user.username}</Username>
                          <StyledUserRating rating={user.rating} />
                        </Cell>
                      </Row>
                    )}
                    <Row>
                      <Cell>{t('STARTPAGE_LAST_LOCALE_ADVERTISMENT_TABLE_LIMITS_TITLE')}</Cell>
                      <Cell>{getLimit(minimum, maximum, coinPrice, currency)}</Cell>
                    </Row>
                    <Row>
                      <Cell>{t('TRADE_PAGE_LOCATED')}</Cell>
                      <Cell>{ad.location}</Cell>
                    </Row>
                  </Body>
                </Table>
              </TableContainer>
              {false && <User.WorkingHours hours={user.workingHours} />}
            </RightContainer>
          </>
        )}
      </CoinPrice>
      
    );
  }
}

const mapStateToProps = (state: StoreModel) => ({
  isOwnTrade: isOwnTrade(state),
  user: getCurrentUser(state),
  isCreatingChatPending: createLoadingSelector([CHAT_CREATE_NEW_CHAT])(state),
  isDeletingAdPending: createLoadingSelector([AD_DELETE])(state),
  isDeletingAdSuccess: createSuccessSelector(AD_DELETE)(state),
});

const mapDispatchToProps = (dispatch: any) => ({
  createChat: (tradeId: number, senderId: number, recipientId: number, amount: number | string) => {
    dispatch({
      type: CHAT_CREATE_NEW_CHAT_REQUESTED,
      payload: {
        tradeId,
        senderId,
        recipientId,
        amount
      }
    })
  },
  deleteAd: (id: number) => dispatch({
    type: AD_DELETE_REQUESTED,
    payload: {
      id
    }
  })
});

export default connect(mapStateToProps, mapDispatchToProps)(withNamespaces()(withRouter(Trade)));