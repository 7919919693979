import React from 'react';
import styled from 'styled-components';
import { withNamespaces, WithNamespaces } from 'react-i18next';
import { WorkingHourModel } from '../../models/WorkingHourModel';
import TimePicker from 'react-time-picker'
import screens from '../../constants/screens';

interface Props {
  editable?: boolean;
  className?: string;
  hours: WorkingHourModel[];
}

const Container = styled.div``;

const Title = styled.div`
  font-size: 1.4rem;
  font-weight: 600;
  margin-bottom: 12px;
`;

const Table = styled.table`
  width: 100%;
  font-size: 1.4rem;
  font-weight: 600;
  border-collapse:separate;
  border-spacing: 0 10px;
`;

const Body = styled.tbody``;

const Row = styled.tr`
  font-size: 1.4rem;
  margin-bottom: 10px;
`;

const Day = styled.td`
  padding: 10px 15px;
  border-radius: 6px 0 0 6px;
  background-color: #df0022;
  color: #fff;
`;

const Time = styled.td`
  width: 100%;
  padding: 10px 15px;
  border-radius: 0 6px 6px 0;
  background-color: #fff;
  text-align: right;
`;

const StyledTimePicker = styled(TimePicker)`
  .react-time-picker__wrapper {
    border: none;
  }
`;

const Separator = styled.div`
  display: inline-block;
  margin: 0 8px;
  ${screens.mobile} {
    margin: 0;
  }
`;

const DisableCell = styled.td`
  padding: 0;
  padding-left: 8px;
`;

const DisableButton = styled.button`
  position: relative;
  width: 32px;
  height: 32px;
  vertical-align: middle;
  &:after,
  &:before {
    position: absolute;
    display: block;
    content: '';
    width: 20px;
    height: 1px;
    background-color: #a0a0a0;
    top: 50%;
    left: 50%;
  }
  &:after {
    transform: translate(-50%, -50%) rotate(45deg);
  }
  &:before {
    transform: translate(-50%, -50%) rotate(-45deg);
  }
`;

class WorkingHours extends React.Component<Props & WithNamespaces> {
  render() {
    const startTimeDefault = "09:00";
    const endTimeDefault = "17:00";
    const { t, hours, className, editable } = this.props;
    const days = [
      this.props.t('PROFILE_PAGE_MONDAY'),
      this.props.t('PROFILE_PAGE_TUESDAY'),
      this.props.t('PROFILE_PAGE_WEDNESDAY'),
      this.props.t('PROFILE_PAGE_THURSDAY'),
      this.props.t('PROFILE_PAGE_FRIDAY'),
      this.props.t('PROFILE_PAGE_SATURDAY'),
      this.props.t('PROFILE_PAGE_SUNDAY'),
    ];

    if (!hours.length) { return null; }

    return (
      <Container className={className}>
        <Title>{t('TRADE_PAGE_OPENING_HOURS')}</Title>
        <Table>
          <Body>
            {
              hours.map((hour: WorkingHourModel) => (
                <Row key={hour.day}>
                  <Day>{days[hour.day]}</Day>
                  <Time>
                    {
                      editable ? (
                        <>
                          <StyledTimePicker
                            clearIcon={null}
                            clockIcon={null}
                            maxDetail="minute"
                            disableClock
                            value={startTimeDefault}
                          />
                          <Separator>-</Separator>
                          <StyledTimePicker
                            clearIcon={null}
                            clockIcon={null}
                            maxDetail="minute"
                            disableClock
                            value={endTimeDefault}
                          />
                        </>
                      ) : (
                        <>{hour.timeStart || startTimeDefault} - {hour.timeEnd || endTimeDefault}</>
                      )
                    }
                  </Time>
                  {
                    editable && 
                    <DisableCell>
                      <DisableButton />
                    </DisableCell>
                  }
                </Row>
              ))
            }
          </Body>
        </Table>
      </Container>
    )
  }
}

export default withNamespaces()(WorkingHours);
