import React from 'react';
import * as ReactDOM from 'react-dom';
import App from './App';
import './styles/vendors/swiper.min.css';
import './styles/base.css';
import './styles/fonts.css';

ReactDOM.render(
  <App />,
  document.getElementById('root') as HTMLElement
);
