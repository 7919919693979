import React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { USER_UPDATE_AVATAR } from '../../actions/user';
import { withNamespaces, WithNamespaces } from 'react-i18next';
import { UserModel } from '../../models/UserModel';
import Avatar from '../Avatar/Avatar';
import Plus from '../Icons/Plus';

interface Props {
  user: UserModel | null;
  className?: string;
  updateAvatar: (formData: FormData) => void;
}

const Form = styled.form`
  display: flex;
  align-items: center;
` as any;

const Input = styled.input`
  position: absolute;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 0;
` as any;

const AvatarPlaceholderContainer = styled.div`
  position: relative;
  flex-shrink: 0;
`;

const AvatarPlusSign = styled(Plus)`
  position: absolute;
  background-color: #71c386;
  padding: 8px;
  border-radius: 50%;
  fill: #fff;
  width: 32px;
  height: 32px;
  right: -2px;
  bottom: -2px;
`;

const TextContainer = styled.div`
  flex-grow: 1;
  padding-left: 36px;
  font-size: 12px;
  div {
    opacity: .5;
    &:first-child {
      font-size: 16px;
      line-height: 20px;
      font-weight: 500;
      opacity: 1;
    }
  }
`;

const HiddenButton = styled.button`
  display: none;
` as any;

class AvatarInput extends React.Component<Props & WithNamespaces> {
  formEl: HTMLFormElement | null = null;
  buttonEl: HTMLButtonElement | null = null;
  inputEl: HTMLInputElement | null = null;
  onChange = (e: React.SyntheticEvent<HTMLInputElement>) => {
    if (this.buttonEl) {
      this.buttonEl.click();
    }
  }
  onFormElSave = (el: HTMLFormElement) => {
    this.formEl = el;
  }
  onButtonElSave = (el: HTMLButtonElement) => {
    this.buttonEl = el;
  }
  onInputElSave = (el: HTMLInputElement) => {
    this.inputEl = el;
  }
  onSubmit = (e: React.SyntheticEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (this.formEl && this.inputEl && this.inputEl.files) {
      const formData = new FormData();

      formData.append('avatar', this.inputEl.files[0])
      this.props.updateAvatar(formData);
    }
  }
  render() {
    const { className, t, user } = this.props;
    return (
      <Form className={className} ref={this.onFormElSave} onSubmit={this.onSubmit}>
        <AvatarPlaceholderContainer>
          <Avatar avatarPath={user!.avatarPath} rounded size={100} mobileSize={100} />
          <AvatarPlusSign />
          <Input
            name="avatar"
            type="file"
            accept="image/png,image/jpg,image/jpeg"
            onChange={this.onChange}
            ref={this.onInputElSave}
          />
          <HiddenButton ref={this.onButtonElSave} type="submit" />
        </AvatarPlaceholderContainer>
        <TextContainer>
          <div>{t('CHANGE_AVATAR')}</div>
          <div>{t('UPLOAD_YOUR_PHOTO')}</div>
        </TextContainer>
      </Form>
    )
  }
}

const mapStateToProps = () => ({
  
});

const mapDispatchToProps = (dispatch: any) => ({
  updateAvatar: (formData: FormData) => dispatch({
    type: `${USER_UPDATE_AVATAR}_REQUESTED`,
    payload: {
      formData
    }
  })
});

export default withNamespaces()(connect(mapStateToProps, mapDispatchToProps)(AvatarInput));