import React from 'react';
import styled from 'styled-components';
import { NewAdModel } from '../../models/NewAdModel';
import { withNamespaces, WithNamespaces } from 'react-i18next';
import screens from '../../constants/screens';
import { getCoinPriceWithMargin, getLimit } from '../../utils';
import CoinPrice from '../../shared/CoinPrice';

interface Props {
  ad: NewAdModel;
  type: string;
}

const Container = styled.div``;


const Table = styled.div`
  display: table;
  border-collapse: collapse;
  font-size: 1.4rem;
  ${screens.mobile} {
    display: block;
    width: 100%;
  }
`;

const Row = styled.div`
  display: table-row;
  ${screens.mobile} {
    display: block; 
  }
`;

const Cell = styled.div`
  display: table-cell;
  padding: 20px 25px;
  ${screens.mobile} {
    display: block; 
  }
  &:first-letter {
    text-transform: uppercase;
  }
  &:first-child {
    background-color: #f0f0f0;
    white-space: nowrap;
    padding-right: 45px;
    ${screens.mobile} {
      padding: 10px 25px;
    }
  }
`;

class CreateTradeOverview extends React.Component<Props & WithNamespaces> {
  render() {
    const { ad, type, t } = this.props;

    if (!ad) { return; }

    return (
      <CoinPrice>
        {(coinPrice: number) => (
          <Container>
            <Table>
              <Row>
                <Cell>{t('TRADE_PAGE_AD_TYPE')}</Cell>
                <Cell>{type}</Cell>
              </Row>
              <Row>
                <Cell>{t('TRADE_PAGE_AD_YOUR_CONTENT')}</Cell>
                <Cell>{ad.description}</Cell>
              </Row>
              <Row>
                <Cell>{t('USER_PAGE_PRICE')}</Cell>
                <Cell>{getCoinPriceWithMargin(coinPrice, ad.fee)}</Cell>
              </Row>
              <Row>
                <Cell>{t('STARTPAGE_LAST_LOCALE_ADVERTISMENT_TABLE_LIMITS_TITLE')}:</Cell>
                <Cell>{getLimit(ad.minimum, ad.maximum, coinPrice, ad.currency)}</Cell>
              </Row>
              <Row>
                <Cell>{t('TRADE_PAGE_FEE')}</Cell>
                <Cell>{ad.fee}%</Cell>
              </Row>
              <Row>
                <Cell>{t('TRADE_PAGE_TRADING_LOCATION')}</Cell>
                <Cell>{ad.location}</Cell>
              </Row>
            </Table>
          </Container>
        )}
      </CoinPrice>
    );
  }
}

export default withNamespaces()(CreateTradeOverview);