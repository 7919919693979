import { createSelector } from 'reselect';
import { StoreModel } from '../models/StoreModel';
import { CountryModel, CountryModelForSelect } from '../models/CountryModel';

export const getCountries = (state: StoreModel) => state.country.countryList;
export const getDetectedCountry = (state: StoreModel) => state.country.detectedCountry;

export const getCountriesForSelect = createSelector(
  [getCountries],
  (countries?: CountryModel[]): CountryModelForSelect[] | undefined => {
    if (!countries) { return countries }

    return countries.map((c: CountryModel) => ({
      label: c.name,
      value: c.code,
      phoneCode: c.phone_code
    }));
  }
);

export const getDetectedCountryForSelect = createSelector(
  [getCountries, getDetectedCountry],
  (countries?: CountryModel[], detectedCountry?: CountryModel) => {
    let detectedCountryForSelect;

    if (!countries) { return detectedCountryForSelect; }
    countries.forEach((c: CountryModel) => {
      if (detectedCountry && (c.country_id === detectedCountry.country_id.toString())) { 
        detectedCountryForSelect = {
          label: c.name,
          value: c.code,
          phoneCode: c.phone_code
        };
      }
    });

    return detectedCountryForSelect;
  }
)

export const getPhoneCodes = createSelector(
  [getCountries],
  (countries?: CountryModel[]) => {
    if (!countries) { return countries }

    return countries.map((c: CountryModel) => ({
      label: c.phone_code,
      value: c.phone_code
    }));
  }
)

export const getDetectedCountryPhoneCodeForSelect = createSelector(
  [getDetectedCountry],
  (detectedCountry?: CountryModel) => {
    if (!detectedCountry) { return detectedCountry }

    return {
      label: detectedCountry.phone_code,
      value: detectedCountry.phone_code
    }
  }
)