import { combineReducers } from 'redux';
import modal from './modal';
import ads from './ads';
import country from './country';
import auth from './auth';
import user from './user';
import wallet from './wallet';
import chat from './chat';
import loading from './loading';
import successResponse from './successResponse';
import errorResponse from './errorResponse';
import { StoreModel } from '../models/StoreModel';
import { ActionModel } from '../models/ActionModel';
import { AUTH_LOGOUT } from '../actions/auth';
import Cookies from 'js-cookie';
import { COOKIE_TOKEN_NAME, COOKIE_REFRESH_TOKEN_NAME, LOCAL_STORAGE_STORE_NAME } from '../constants/config';

const reducers = combineReducers({
  modal,
  country,
  auth,
  user,
  ads,
  wallet,
  chat,
  loading,
  successResponse,
  errorResponse,
});

const rootReducer = (state: StoreModel | undefined, action: ActionModel) => {
  if (action.type === AUTH_LOGOUT) {
    Cookies.remove(COOKIE_TOKEN_NAME);
    Cookies.remove(COOKIE_REFRESH_TOKEN_NAME);
    localStorage.removeItem(LOCAL_STORAGE_STORE_NAME);
    return reducers(undefined, { type: undefined });
  }
  return reducers(state, action);
}

export default rootReducer;