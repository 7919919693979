import React from 'react';
import styled from 'styled-components';
import icon404 from '../../images/pages/404/404.svg';
import screens from '../../constants/screens';
import { withNamespaces, WithNamespaces } from 'react-i18next';

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  flex-grow: 1;
  padding: 0 20px;
`;

const ImgNotFound = styled.img`
  max-width: 460px;
`;

const Title = styled.div`
  font-size: 66px;
  font-weight: 600;
  text-transform: uppercase;
  text-align: center;
  ${screens.mobile} {
    font-size: 36px;
  }
`;

const Subtitle = styled.div`
  font-size: 36px;
  font-weight: 300;
  text-align: center;
  ${screens.mobile} {
    font-size: 24px;
  }
`;

class PageNotFound extends React.Component<WithNamespaces> {
  render() {
    const { t } = this.props;
    return (
      <Container>
        <ImgNotFound src={icon404} alt="404" />
        <Title>{t('OOPS')}</Title>
        <Subtitle>{t('PAGE_NOT_FOUND')}</Subtitle>
      </Container>
    )
  }
}

export default withNamespaces()(PageNotFound);