import React from 'react';
import styled from 'styled-components';
import moment from 'moment';
import { PositiveFeedbackIcon, NegativeFeedbackIcon } from '../Icons/FeedbackIcons';
import { FeedbackModel } from '../../models/FeedbackModel';

interface Props {
  feedback: FeedbackModel;
}

const FeedbackItemContainer = styled.div`
  max-width: 100%;
  flex-grow: 1;
  padding: 40px 30px 40px 35px;
  border-radius: 15px;
  border: 1px solid #f0f0f0;
`;

const FeedbackTime = styled.div`
  display: flex;
  align-items: center;
  font-weight: 300;
  font-size: 10px;
  margin-bottom: 10px;
  svg {
    margin-right: 6px;
  }
`;

const FeedbackBody = styled.div`
  font-size: 12px;
  font-weight: 400;
`;

class UserFeedback extends React.Component<Props> {
  getFeedbackIcon = (rating: number) => {
    if (rating >= 0 && rating <= 2) {
      return <NegativeFeedbackIcon />;
    }

    return <PositiveFeedbackIcon />
  }
  render() {
    const { createdAt, message, rating } = this.props.feedback;
    return (
      <FeedbackItemContainer>
        <FeedbackTime>
          {this.getFeedbackIcon(rating)}
          {moment(createdAt).format('LLL')}
        </FeedbackTime>
        <FeedbackBody>{message}</FeedbackBody>
      </FeedbackItemContainer>
    )
  }
}

export default UserFeedback;