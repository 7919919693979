import React from 'react';
import styled from 'styled-components';
import Container from '../../components/Container';
import { withNamespaces, WithNamespaces } from 'react-i18next';
import Topics from './Topics';
import Questions from './Questions';
import uuidv1 from 'uuid/v1';
import screens from '../../constants/screens';
import Media from '../../shared/Media';
import Button from '../../components/Button';
import { RED_E0 } from '../../constants/colors';

interface State {
  topics: any[] | null;
}

const Wrapper = styled.div`
  display: flex;
`;

const TopicsContainer = styled.div`
  flex-basis: 36%;
  max-width: 36%;
  padding-right: 40px;
  flex-shrink: 0;
  ${screens.mobile} {
    display: none;
  }
`;

const AnswersContainer = styled.div`
  flex-grow: 1;
`;

const NoAnswerContainer = styled.div`
  margin-top: 62px;
  text-align: center;
  font-size: 1.6rem;
  font-weight: 700;
`;

const ContactButton = styled(Button)`
  background-color: ${RED_E0};
  height: 32px;
  margin-top: 8px;
  color: #fff;
  border-radius: 3px;
  font-size: 1.4rem;
  font-weight: 700;
`;

class FAQ extends React.Component<WithNamespaces, State> {
  state: State = {
    topics: null
  }

  componentDidMount() {
    const topics: any[] = [];
    const { t } = this.props;

    const categories = t('FAQ_CATEGORIES').split(',');

    categories.forEach((catId: string, index: number) => {
      const categoryId = catId.trim();
      const questions = t(`FAQ_CATEGORY_${categoryId}_QUESTIONS`).split(',');
      let topic = {
        id: uuidv1(),
        active: index === 0,
        title: t(`FAQ_CATEGORY_${categoryId}`),
        questions: questions.map((qId: string) => {
          const questionId = qId.trim();

          return {
            id: uuidv1(),
            title: t(`FAQ_CATEGORY_${categoryId}_QUESTION_${questionId}`),
            answer: t(`FAQ_CATEGORY_${categoryId}_QUESTION_${questionId}_ANSWER`),
          }
        })
      };

      topics.push(topic);
    });

    this.setState({
      topics
    });
  }

  getActiveTopic = () => {
    if (!this.state.topics) {
      return {};
    }

    const activeTopic = this.state.topics.find((topic) => topic.active);

    return activeTopic;
  }

  getActiveTopicTitle = () => this.getActiveTopic().title;
  getActiveAnswers = () => this.getActiveTopic().questions;

  onTopicSelect = (topic: any) => {
    this.setState({
      topics: this.state.topics && this.state.topics.map((t: any) => {
        return {
          ...t,
          active: t === topic
        }
      })
    });
  }

  render() {
    const { t } = this.props;
    return (
      <Container
        noShadow
        noPadding
        title={t('FAQ')}
      >
        <Wrapper>
          <TopicsContainer>
            <Topics topics={this.state.topics} onTopicSelect={this.onTopicSelect}/> 
          </TopicsContainer>
          <AnswersContainer>
            <Media.Mobile>
              {
                this.state.topics && this.state.topics.map((topic: any) => <Questions key={topic.id} topicTitle={topic.title} questions={topic.questions} />)
              }
            </Media.Mobile>
            <Media.Desktop>
              <Questions topicTitle={this.getActiveTopicTitle()} questions={this.getActiveAnswers()}/>
            </Media.Desktop>
          </AnswersContainer>
        </Wrapper>
        <NoAnswerContainer>
          <div>{t('FAQ_NO_ANSWER')}</div>
          <ContactButton>{t('CONTACT_US')}</ContactButton>
        </NoAnswerContainer>
      </Container>
    )
  }
}

export default withNamespaces()(FAQ);