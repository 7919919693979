import React from 'react';

interface Props {
  className?: string;
  fill?: string;
}

const Plus = ({ className, fill }: Props) => (
  <svg 
    xmlns="http://www.w3.org/2000/svg" 
    viewBox="0 0 31.444 31.444"
    className={className}
  >
    <path d="M1.119 16.841a1.118 1.118 0 0 1-1.111-1.127c0-.619.492-1.111 1.111-1.111h13.475V1.127A1.133 1.133 0 0 1 15.722 0c.619 0 1.111.508 1.111 1.127v13.476h13.475c.619 0 1.127.492 1.127 1.111s-.508 1.127-1.127 1.127H16.833v13.476c0 .619-.492 1.127-1.111 1.127a1.131 1.131 0 0 1-1.127-1.127V16.841H1.119z" fill={fill}/>
  </svg>
);

export default Plus;