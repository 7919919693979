import React from 'react';
import styled from 'styled-components';

const ErrorContainer = styled.ul`
  background-color: rgba(255, 0, 0, .1);
  width: 100%;
  padding: 5px 10px 5px 30px;
  font-size: 1.4rem;
  border-radius: 3px;
  border: 1px solid #f00;
  margin-top: 4px;
  color: #f00;
  list-style-type: disc;
  margin-bottom: 20px;
`;

interface Props {
  children: React.ReactNode;
}

class Error extends React.Component<Props> {
  render() {
    return (
      <ErrorContainer>
        {
          this.props.children
        }
      </ErrorContainer> 
    )
  }
}

export default Error;