import React from 'react';
import styled from 'styled-components';
import { withNamespaces, WithNamespaces } from 'react-i18next';

interface Props {
  languages: string[];
}

const Container = styled.div`
  font-size: 10px;
`;

const Title = styled.span`
  font-weight: 500;
`;

const Language = styled.span`
  font-weight: 400;
`;

class Languages extends React.Component<Props & WithNamespaces> {
  render() {
    const { t, languages } = this.props;
    return (
      <Container>
        <Title>{t('PROFILE_PAGE_LANGUAGE')}: </Title>
        {
          languages && languages.map((language: string, index: number) => (
            <Language key={index}>{language}{(index !== languages.length - 1) && ','} </Language>
          ))
        }
      </Container>
    );
  }
}

export default withNamespaces()(Languages);