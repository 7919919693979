import React from 'react';
import styled from 'styled-components';
import { withNamespaces, WithNamespaces } from 'react-i18next';
import { ArrowIcon } from '../Icons/ArrowIcons';
import { GREY_7F } from '../../constants/colors';
import screens from '../../constants/screens';
import { AdModel } from '../../models/AdModel';
import { getAdTypeByType, getLimit, getCoinPriceWithMargin } from '../../utils';
import { Link } from 'react-router-dom';

interface Props {
  ad: AdModel;
  coinPrice: number | null;
}

interface State {
  minimumFiat: string;
  maximumFiat: string;
}

const Row = styled.tr`
  border-bottom: 1px solid ${GREY_7F};
  height: 55px;
`;

const Cell = styled.td`
  padding: 17px 0;
  color: ${GREY_7F};
`;

const CellZeroPadding = styled(Cell)`
  padding: 0;
  ${screens.mobile} {
    display: none
  }
`;

const StyledLink = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  font-size: 1.7rem;
  font-weight: 500;
  background-color: #fff;
  border-left: 4px solid #000;
  color: #000;
  height: 55px;
  svg {
    margin-left: 1.7rem;
  }
`;

const Username = styled(Link)`
  &:hover {
    text-decoration: underline;
  }
`;


class Trade extends React.Component<Props & WithNamespaces, State> {
  getAdLink = (id: number) => `/trade/${id}`;
  getUserLink = (id: number) => `/user/${id}`;
  render() {
    const { t, ad, coinPrice } = this.props;
    const { id, type, location, user, minimum, maximum, currency, fee } = ad;

    return (
      <Row>
        <Cell><Username to={this.getUserLink(user.id)}>{user.username}</Username></Cell>
        <Cell>{getCoinPriceWithMargin(coinPrice, fee)}</Cell>
        <Cell>{getLimit(minimum, maximum, coinPrice, currency)}</Cell>
        <Cell>{getAdTypeByType(t)(type)}</Cell>
        <Cell>{location}</Cell>
        <CellZeroPadding>
          <StyledLink to={this.getAdLink(id)}>
            {t('STARTPAGE_LAST_LOCALE_ADVERTISMENT_ACTION_TITLE')}
            <ArrowIcon />
          </StyledLink>
        </CellZeroPadding>
      </Row>
    )
  }
}

export default withNamespaces()(Trade);