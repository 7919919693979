import React from 'react';

interface Props {
  fill?: string;
}

const ActionIcon = ({ fill }: Props) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 46.86 46.85" width="46" height="46">
    <g>
      <g>
        <path fill={fill || '#fff'} d="M45.48 15.57a23.42 23.42 0 0 0-43.21-2.18 23.26 23.26 0 0 0-.9 17.9A1.91 1.91 0 0 0 5 30 19.59 19.59 0 0 1 31.82 5.73a19.59 19.59 0 1 1-18.44 34.51 1.91 1.91 0 0 0-2 3.29 23.42 23.42 0 0 0 34.07-28z"/>
        <path fill={fill || '#fff'} d="M31.32 23.43a1.92 1.92 0 0 0-1.91-1.91H9.83a1.91 1.91 0 0 0 0 3.83h19.58a1.92 1.92 0 0 0 1.91-1.92z"/>
        <path fill={fill || '#fff'} d="M25.49 32.26a1.91 1.91 0 1 0 2.7 2.74l10.19-10.22a1.92 1.92 0 0 0 0-2.71L28.19 11.89a1.91 1.91 0 1 0-2.71 2.71l8.83 8.83z"/>
      </g>
    </g>
  </svg>
);

export default ActionIcon;