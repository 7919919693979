import React from 'react';
import { connect } from 'react-redux';
import { StoreModel } from '../../models/StoreModel';
import { getOpenedAd } from '../../selectors/ads';
import { AD_GET_REQUESTED, AD_GET, AD_CLEAR_OPENED_AD } from '../../actions/ads';
import { AdModel } from '../../models/AdModel';
import { RouteComponentProps } from 'react-router';
import { withNamespaces, WithNamespaces } from 'react-i18next';
import { getCurrentUser, isUserLoggedIn } from '../../selectors/user';
import { UserModel } from '../../models/UserModel';
import { getChat } from '../../selectors/chat';
import { ChatModel } from '../../models/ChatModel';
import Container from '../../components/Container';
import TradeContainer from './TradeContainer';
import { createLoadingSelector } from '../../selectors/loading';
import { CHAT_GET_CHAT_REQUESTED, CHAT_CREATE_NEW_CHAT } from '../../actions/chat';
import { createErrorSelector } from '../../selectors/errorResponse';
import { getUserAvailableBalance } from '../../selectors/wallet';
import { WALLET_GET_BALANCE_REQUESTED, WALLET_GET_BALANCE } from '../../actions/wallet';
import { createSuccessSelector } from '../../selectors/successResponse';

interface Props {
  isUserLoggedIn: boolean;
  ad: AdModel | null;
  user: UserModel | null;
  chat: ChatModel | null;
  availableBalance: number | null;
  getAd: (adId: string) => void;
  getChat: (id: number) => void;
  getUserBalance: () => void;
  clearOpenedAd: () => void;
  chatCreationSuccess: boolean;
  isPending: boolean;
  isError: boolean;
}

class Trade extends React.Component<Props & WithNamespaces & RouteComponentProps<any>> {
  componentDidMount() {
    const id = this.props.match.params.id;

    id && this.props.getAd(id);
    this.props.getUserBalance();
  }
  componentWillUnmount() {
    this.props.clearOpenedAd();
  }
  componentWillReceiveProps(nextProps: Props & WithNamespaces & RouteComponentProps<any>) {
    if (this.props.isUserLoggedIn && !nextProps.isUserLoggedIn) {
      const id = this.props.match.params.id;

      id && this.props.getAd(id);
    }
  }
  componentDidUpdate() {
    if (this.props.chat && this.props.chatCreationSuccess) {
      this.props.getChat(this.props.chat.id);
    }
    if (this.props.chatCreationSuccess && this.props.chat && this.props.chat.trade) {
      this.props.history.push(`/chat/${this.props.chat.id}`);
    }
  }
  render() {
    const { ad, isPending, isError, availableBalance, isUserLoggedIn } = this.props;

    return (
      <Container
        isPending={isPending}
        isError={isError}
        splited
      >
        {ad && <TradeContainer ad={ad} isUserLoggedIn={isUserLoggedIn} availableBalance={availableBalance as number} />}
      </Container>
    );
  }
}

const mapStateToProps = (state: StoreModel) => ({
  isUserLoggedIn: isUserLoggedIn(state),
  ad: getOpenedAd(state),
  user: getCurrentUser(state),
  chatCreationSuccess: createSuccessSelector(CHAT_CREATE_NEW_CHAT)(state),
  chat: getChat(state),
  availableBalance: getUserAvailableBalance(state),
  isPending: createLoadingSelector([AD_GET, WALLET_GET_BALANCE])(state),
  isError: createErrorSelector([AD_GET])(state),
});

const mapDispatchToProps = (dispatch: any) => ({
  getAd: (adId: string) => {
    dispatch({
      type: AD_GET_REQUESTED,
      payload: {
        adId
      }
    })
  },
  getUserBalance: () => dispatch({
    type: WALLET_GET_BALANCE_REQUESTED,
    payload: {}
  }),
  getChat: (id: number) => dispatch({
    type: CHAT_GET_CHAT_REQUESTED,
    payload: {
      id
    }
  }),
  clearOpenedAd: () => dispatch({
    type: AD_CLEAR_OPENED_AD
  }),
});

export default connect(mapStateToProps, mapDispatchToProps)(withNamespaces()(Trade));