import React from 'react';
import * as yup from 'yup';
import styled from 'styled-components';
import screens from '../../constants/screens';
import AvatarInput from '../../components/AvatarInput';
import User from '../../components/User';
import EditGlobalLanguage from './EditGlobalLanguage';
import { connect } from 'react-redux';
import { StoreModel } from '../../models/StoreModel';
import { USER_UPDATE_PIN_REQUESTED, USER_UPDATE_PIN, USER_UPDATE_REQUESTED, USER_UPDATE, USER_SET_PIN_REQUESTED } from '../../actions/user';
import { getCurrentUser } from '../../selectors/user';
import { withNamespaces, WithNamespaces } from 'react-i18next';
import { UserModel } from '../../models/UserModel';
import { createLoadingSelector } from '../../selectors/loading';
import FormElements from '../../shared/FormElements';
import Button from '../../components/Button';
import { FormikProps, Formik } from 'formik';
import UI from '../../shared/UI';

interface Props {
  user: UserModel | null;
  isUpdatePinPending: boolean;
  isUpdateUserPending: boolean;
  updatePin: (oldPin: string, newPin: string) => void;
  setPin: (pin: string) => void;
  updateUser: (body: any) => void;
}

interface PinFormValues {
  oldPin: string;
  newPin: string;
}

interface TradeConditionFormValues {
  tradeCondition: string;
}

const Container = styled.div`
  margin: 0 20px 50px;
`;

const Wrapper = styled.div`
  display: flex;
  max-width: 992px;
  margin: 0 auto;
  box-shadow: 0 0 24px 0 rgba(0,0,0,.1);
  ${screens.mobile} {
    display: block;
    box-shadow: none;
  }
`;

const Title = styled.div`
  font-size: 44px;
  font-weight: 500;
  margin-bottom: 78px;
`;

const LeftContainer = styled.div`
  flex: 1 0 45%;
  background-color: #f0f0f0;
  padding: 48px 32px 48px 32px;
  ${screens.mobile} {
    background-color: #fff;
    padding: 20px 0;
  }
`;

const RightContainer = styled.div`
  flex: 1 0 55%;
  padding: 179px 56px 48px;
  ${screens.mobile} {
    padding: 20px 0;
  }
`;

const Row = styled.div`
  &:not(:last-child) {
    margin-bottom: 40px;
  }
`;

const ActionTitle = styled.div`
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 24px;
`;

const ActionRow = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  margin-bottom: 8px;
`;

const ActionColumn = styled.div`
  width: calc(50% - 8px);
  max-width: calc(50% - 8px);
`;

const InputContainerTitle = styled.div`
  font-size: 12px;
  font-weight: 500;
  margin-bottom: 10px;
  opacity: .5;
`;

const Input = styled(FormElements.Input)`
  width: 100%;
  height: 26px;
  line-height: 26px;
  border: 1px solid #000;
  border-radius: 6px;
  padding: 0 8px;
` as any;

const Textarea = styled(FormElements.Textarea)`
  height: 120px;
  ${screens.tablet} {
    max-width: none;
    height: 160px;
  }
`;

const StyledAvatarInput = styled(AvatarInput)`
  margin-bottom: 40px;
` as any;

const StyledWorkingHours = styled(User.WorkingHours)`
  margin-bottom: 40px;
` as any;

const SaveButton = styled(Button)`
  height: 26px;
  border-radius: 6px;
  background-color: #444d6e;
  font-size: 12px;
  font-weight: 700;
  color: #fff;
  min-width: 132px;
`;

class AccountSettings extends React.Component<Props & WithNamespaces> {
  updatePinSchema = yup.object().shape({
    oldPin: yup.string()
      .required(this.props.t('TR_PIN_NO_EMPTY')),
    newPin: yup.string()
      .required(this.props.t('TR_PIN_NO_EMPTY'))
  })
  setPinSchema = yup.object().shape({
    newPin: yup.string()
      .required(this.props.t('TR_PIN_NO_EMPTY'))
  })
  onPinUpdate = (values: PinFormValues, bag: any) => {
    this.props.updatePin(values.oldPin, values.newPin);
    bag.resetForm();
  }
  onPinSet = (values: PinFormValues, bag: any) => {
    this.props.setPin(values.newPin);
    bag.resetForm();
  }
  onTradeConditionUpdate = (values: TradeConditionFormValues, bag: any) => {
    this.props.updateUser(values);
    bag.resetForm();
  }
  render() {
    const { t, user } = this.props;

    if (!user) { return; }

    const { workingHours } = user;
    return (
      <Container>
        <Wrapper>
          <LeftContainer>
            <Title>{t('ACCOUNT_SETTINGS')}</Title>
            <StyledAvatarInput user={user} />
            {false && <StyledWorkingHours hours={workingHours} editable />}
            <EditGlobalLanguage />
          </LeftContainer>
          <RightContainer>
            {
              <Row>
                <Formik 
                  initialValues={{
                    oldPin: '',
                    newPin: '',
                  }}
                  validationSchema={user.isPinSet ? this.updatePinSchema : this.setPinSchema}
                  isInitialValid={false}
                  onSubmit={user.isPinSet ? this.onPinUpdate : this.onPinSet}
                  render={({ errors, isValid }: FormikProps<PinFormValues>) => (
                    <>
                      <ActionTitle>
                        {user.isPinSet ? t('UPDATE_YOUR_PIN') : t('SET_PIN')}
                      </ActionTitle>
                      <UI.Form errors={errors}>
                        <ActionRow>
                          {
                            user.isPinSet && (
                              <ActionColumn>
                                <InputContainerTitle>{t('OLD_PIN')}</InputContainerTitle>
                                <Input 
                                  type="password"
                                  name="oldPin"
                                  />
                              </ActionColumn>
                            )
                          }
                          {
                            user.isPinSet ? (
                              <ActionColumn>
                                <InputContainerTitle>{t('NEW_PIN')}</InputContainerTitle>
                                <Input
                                  type="password"
                                  name="newPin"
                                />
                              </ActionColumn>
                            ) : (
                              <Input
                                type="password"
                                name="newPin"
                              />
                            )
                          }
                        </ActionRow>
                      <SaveButton
                        type="submit" 
                        disabled={!isValid} 
                        isPending={this.props.isUpdatePinPending} 
                        small
                      >
                        {t('UPDATE_PIN')}
                      </SaveButton>
                    </UI.Form>
                  </>
                )}
                />
              </Row>
            }
            <Row>
              <Formik
                initialValues={{
                  tradeCondition: '',
                }}
                onSubmit={this.onTradeConditionUpdate}
                render={({ errors, setFieldValue, setFieldTouched }: FormikProps<TradeConditionFormValues>) => (
                  <>
                    <ActionTitle>{t('PROFILE_PAGE_TRADE_CONDITION')}</ActionTitle>
                    <UI.Form errors={errors}>
                      <ActionRow>
                        <Textarea
                          name="tradeCondition"
                          onChange={setFieldValue}
                          onBlur={setFieldTouched}
                          placeholder={user.tradeCondition}
                        />
                      </ActionRow>
                      <SaveButton
                        type="submit" 
                        isPending={this.props.isUpdateUserPending} 
                        small
                      >
                        {t('UPDATE_TRADE_CONDITION')}
                      </SaveButton>
                    </UI.Form>
                  </>
                )}
              />
            </Row>
          </RightContainer>
        </Wrapper>
      </Container>
    );
  }
}

const mapStateToProps = (state: StoreModel) => ({
  user: getCurrentUser(state),
  isUpdatePinPending: createLoadingSelector([USER_UPDATE_PIN])(state),
  isUpdateUserPending: createLoadingSelector([USER_UPDATE])(state),
});

const mapDispatchToProps = (dispatch: any) => ({
  updateUser: (body: any) => dispatch({
    type: USER_UPDATE_REQUESTED,
    payload: body
  }),
  updatePin: (oldPin: string, newPin: string) => dispatch({
    type: USER_UPDATE_PIN_REQUESTED,
    payload: {
      old: oldPin,
      new: newPin
    }
  }),
  setPin: (pin: string) => {
    dispatch({
      type: USER_SET_PIN_REQUESTED,
      payload: {
        pin
      }
    })
  },
});

export default withNamespaces()(connect(mapStateToProps, mapDispatchToProps)(AccountSettings));