export const TR_OPEN_TRANSACTION = 'TR_OPEN_TRANSACTION';
export const TR_OPEN_TRANSACTION_SUCCESS = 'TR_OPEN_TRANSACTION_SUCCESS';
export const TR_OPEN_TRANSACTION_REQUESTED = 'TR_OPEN_TRANSACTION_REQUESTED';
export const TR_OPEN_TRANSACTION_FAILED = 'TR_OPEN_TRANSACTION_FAILED';

export const TR_CANCEL_TRANSACTION = 'TR_CANCEL_TRANSACTION';
export const TR_CANCEL_TRANSACTION_SUCCESS = 'TR_CANCEL_TRANSACTION_SUCCESS';
export const TR_CANCEL_TRANSACTION_REQUESTED = 'TR_CANCEL_TRANSACTION_REQUESTED';
export const TR_CANCEL_TRANSACTION_FAILED = 'TR_CANCEL_TRANSACTION_FAILED';

export const TR_CLOSE_TRANSACTION = 'TR_CLOSE_TRANSACTION';
export const TR_CLOSE_TRANSACTION_SUCCESS = 'TR_CLOSE_TRANSACTION_SUCCESS';
export const TR_CLOSE_TRANSACTION_REQUESTED = 'TR_CLOSE_TRANSACTION_REQUESTED';
export const TR_CLOSE_TRANSACTION_FAILED = 'TR_CLOSE_TRANSACTION_FAILED';

export const TR_SEND_FEEDBACK = 'TR_SEND_FEEDBACK';
export const TR_SEND_FEEDBACK_REQUESTED = 'TR_SEND_FEEDBACK_REQUESTED';
export const TR_SEND_FEEDBACK_SUCCESS = 'TR_SEND_FEEDBACK_SUCCESS';
export const TR_SEND_FEEDBACK_FAILED = 'TR_SEND_FEEDBACK_FAILED';