import React from 'react';
import styled, { keyframes } from 'styled-components';
import UI from '../../shared/UI';
import Loader from '../Loader';
import Error from '../Error';
import { connect } from 'react-redux';
import { ERROR_RESPONSE_RESET_ERRORS } from '../../actions/errorResponse';
import { SUCCESS_RESPONSE_RESET } from '../../actions/successResponse';

const LoaderFadeOut = keyframes`
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
`;

const StyledLoader = styled(Loader)`
  position: absolute;
  top: 50%;
  left: 50%;
  bottom: auto;
  transform: translate(-50%, -50%);
  animation-name: ${({ isPending }: any) => isPending ? 'none' : LoaderFadeOut};
  animation-duration: .25s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
` as any;

interface Props {
  title?: string;
  subtitle?: string;
  isPending?: boolean;
  isError?: boolean;
  splited?: boolean;
  noShadow?: boolean;
  noPadding?: boolean;
  children: React.ReactNode;
  footerConfig?: any;

  clearErrors: () => void;
  clearSuccess: () => void;
}

class Container extends React.Component<Props> {
  componentWillUnmount() {
    this.props.clearErrors();
    this.props.clearSuccess();
  }
  render() {
    const { title, subtitle, isPending, isError, noShadow, noPadding, children, splited, footerConfig } = this.props;
    return (
      <UI.Container>
        <StyledLoader dark isPending={isPending} className="preloader" />
        <UI.Wrapper splited={splited} isError={isError} isPending={isPending} noPadding={noPadding
        } noShadow={noShadow}>
          {isError && <Error />}
          {
            !isPending && !isError && (
              <>
                {title && <UI.Title>{title}</UI.Title>}
                {subtitle && <UI.Subtitle>{subtitle}</UI.Subtitle>}
                {children}
              </>
            )
          }
        </UI.Wrapper>
        {
          footerConfig && (
            <UI.Footer>
              {footerConfig.prevButton && (
                <UI.CircleButton
                  title={footerConfig.prevButton.title}
                  link={footerConfig.prevButton.link}              
                  arrowRotate={footerConfig.prevButton.arrowRotate}              
                />
              )}
            </UI.Footer>
          )
        }
      </UI.Container>
    )
  }
}

const mapDispatchToProps = (dispatch: any) => ({
  clearErrors: () => dispatch({
    type: ERROR_RESPONSE_RESET_ERRORS
  }),
  clearSuccess: () => dispatch({
    type: SUCCESS_RESPONSE_RESET
  })
});

export default connect(null, mapDispatchToProps)(Container);