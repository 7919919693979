import { ActionModel } from "../models/ActionModel";
import { SET_MODAL, CLOSE_ALL_MODALS } from "../actions/modal";

const initialState = {
  currentModal: undefined,
  scope: undefined
}

export interface ModalInitialState {
  currentModal?: string;
  scope: any;
}

const modal = (state: ModalInitialState = initialState, action: ActionModel) => {
  switch (action.type) {
    case SET_MODAL:
      return {
        ...state,
        currentModal: action.payload.nextModal,
        scope: action.payload.scope
      }
    case CLOSE_ALL_MODALS:
      return {
        ...state,
        currentModal: undefined,
        scope: undefined
      }
    default:
      return state;
  }
}

export default modal;