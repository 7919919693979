import React from 'react';
import styled from 'styled-components';
import VerifiedIcon from '../Icons/VerifiedIcon';
import screens from '../../constants/screens';
import avatarDefaultIcon from '../../images/common/avatar_placeholder.svg';
import { STATIC_URL } from '../../constants/config';

const AvatarIcon = styled.div`
  position: relative;
  display: block;
  width: ${({size}: any) => `${size || 50}px`};
  height: ${({size}: any) => `${size || 50}px`};
  border-radius: ${({rounded}: any) => rounded ? '50%' : '0'};
  background-image: url(${({img}: any) => img});
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  flex-shrink: 0;
  ${screens.mobile} {
    width: ${({mobileSize}: any) => `${mobileSize || 50}px`};
    height: ${({mobileSize}: any) => `${mobileSize || 50}px`};
  }
` as any;

const StyledVerifiedIcon = styled(VerifiedIcon)`
  position: absolute;
  top: 8px;
  left: 8px;
`;

interface Props {
  size?: number;
  avatarPath?: string | null;
  mobileSize?: number;
  rounded?: boolean;
  verified?: boolean;
}

class Avatar extends React.Component<Props> {
  getAvatarPath = (url: string) => `${STATIC_URL}${url}`
  render() {
    const { size, avatarPath, rounded, verified, mobileSize } = this.props;
    return (
      <AvatarIcon
        size={size}
        mobileSize={mobileSize}
        rounded={rounded}
        img={avatarPath ? this.getAvatarPath(avatarPath) : avatarDefaultIcon} 
      >
        {verified && <StyledVerifiedIcon />}
      </AvatarIcon>
    )
  }
}

export default Avatar;