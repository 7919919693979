import React from 'react';
import Home from './containers/Home';
import Modal from './components/Modal';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import Profile from './containers/Profile';
import Footer from './components/Footer';
import Header from './components/Header';
import CreateTrade from './containers/CreateTrade';
import PrivateRoute from './components/PrivateRouter';
import EmailVerify from './containers/EmailVerify';
import SearchTrade from './containers/SearchTrade';
import Trade from './containers/Trade';
import TradeChat from './containers/TradeChat';
import Chats from './containers/Chats';
import { connect } from 'react-redux';
import { StoreModel } from './models/StoreModel';
import { isUserLoggedIn, isUserEmailVerify, isUserPinExist } from './selectors/user';
import { setModal } from './actions/modal';
import modals from './constants/modals';
import * as Cookies from 'js-cookie';
import { COOKIE_NO_ENTER_PIN_MODAL } from './constants/config';
import AccountSettings from './containers/AccountSettings';
import PageNotFound from './containers/PageNotFound/PageNotFound';
import Wallet from './containers/Wallet';
import Deposit from './containers/Deposit';
import WithdrawalSuccess from './containers/WithdrawalSuccess';
import WithdrawalForm from './containers/WithdrawalForm';
import Admin from './components/Admin';
import FAQ from './containers/FAQ';
import Terms from './containers/Terms';
import Privacy from './containers/Privacy';
import AuthListener from './components/AuthListener';

interface Props {
  isUserPinExist: boolean | null;
  openModal: (modal: string) => void;
  isUserLoggedIn: boolean;
  isUserEmailVerified: boolean;
}

const Public = () => (
  <>
    <Header />
    <AuthListener />
    <Switch>
      <Route path="/" exact={true} component={Home} />
      <Route path="/email-verify" exact={true} component={EmailVerify} />
      <PrivateRoute path="/user/settings" exact={true} component={AccountSettings} />
      <Route path="/user/:id" exact={true} component={Profile} />
      <PrivateRoute path="/trade/create/:type?" exact={true} component={CreateTrade} />
      <Route path="/trade/search" exact={true} component={SearchTrade} />
      <Route path="/trade/:id" exact={true} component={Trade} />
      <PrivateRoute path="/chat/:id" exact={true} component={TradeChat} />
      <PrivateRoute path="/chats" exact={true} component={Chats} />
      <PrivateRoute path="/wallet" exact={true} component={Wallet} />
      <PrivateRoute path="/wallet/deposit" exact={true} component={Deposit} />
      <PrivateRoute path="/wallet/withdrawal" exact={true} component={WithdrawalForm} />
      <PrivateRoute path="/wallet/withdrawal/success" exact={true} component={WithdrawalSuccess} />
      <Route path="/faq" exact={true} component={FAQ} />
      <Route path="/terms" exact={true} component={Terms} />
      <Route path="/privacy" exact={true} component={Privacy} />
      <Route component={PageNotFound} />
    </Switch>
    <Modal />
    <Footer />
  </>
);

class Router extends React.Component<Props> {
  componentDidMount() {
    const isEnterPinDisable = Cookies.get(COOKIE_NO_ENTER_PIN_MODAL);
    const isEmailVerifyPage = location.pathname.includes('email-verify');
    
    if (!isEmailVerifyPage && !this.props.isUserPinExist && !isEnterPinDisable && this.props.isUserLoggedIn && this.props.isUserEmailVerified) {
      this.props.openModal(modals.SET_PIN);
    }
  }
  
  render() {
    return (
        <BrowserRouter>
          <Switch>
            <Route path="/admin" component={Admin} />
            <Route path="/" component={Public} />
          </Switch>
        </BrowserRouter>
    )
  }
}

const mapStateToProps = (state: StoreModel) => ({
  isUserLoggedIn: isUserLoggedIn(state),
  isUserEmailVerified: isUserEmailVerify(state),
  isUserPinExist: isUserPinExist(state)
});

const mapDispatchToProps = (dispatch: any) => ({
  openModal: (modal: string) => dispatch(setModal(modal))
});

export default connect(mapStateToProps, mapDispatchToProps)(Router);