import React from 'react';

interface Props {
  size?: number;
  className?: string;
}

const VerifiedIcon = ({ size, className }: Props) => (
  <svg className={className} xmlns="http://www.w3.org/2000/svg" width={size || 15} height={size || 15} viewBox="0 0 505 505">
    <g>
      <g>
        <circle cx="252.5" cy="252.5" r="252.5" fill="#2bbc70"/>
        <path d="M61 267.58a14.57 14.57 0 0 1 0-21.11l21.11-21.11a14.57 14.57 0 0 1 21.11 0l1.51 1.51 82.91 88.94a7.29 7.29 0 0 0 10.55 0l202-209.55h1.51a14.57 14.57 0 0 1 21.11 0L444 127.37a14.57 14.57 0 0 1 0 21.11L202.75 398.73a14.57 14.57 0 0 1-21.11 0L64.06 272.1l-3-4.52z" fill="#fff"/>
      </g>
    </g>
  </svg>
);

export default VerifiedIcon;
