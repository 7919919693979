import React from 'react';
import Swiper from 'react-id-swiper';
import styled from 'styled-components';
import { FeedbackModel } from '../../models/FeedbackModel';
import UserFeedback from './UserFeedback';

interface Props {
  feedbacks: FeedbackModel[];
}

const Container = styled.div`
  position: relative;
`;

class UserFeedbacks extends React.Component<Props> {
  render() {
    const { feedbacks } = this.props;
    
    const params = {
      slidesPerView: 1,
      spaceBetween: 20,
      pagination: {
        el: '.swiper-pagination',
        type: 'bullets',
        clickable: true
      },
    }
    return (
      <Container>
        <Swiper {...params}>
          {
            feedbacks.map((feedback) => (
              <div>
                <UserFeedback key={feedback.id} feedback={feedback} />
              </div>
            ))
          }
        </Swiper>
      </Container>
    );
  }
}

export default UserFeedbacks;