import React from 'react';
import styled from 'styled-components';
import { withNamespaces, WithNamespaces } from 'react-i18next';
import screens from '../../constants/screens';

interface Props {
  desktopHidden?: boolean;
  mobileHidden?: boolean;
  onClick: () => void;
}

const Button = styled.button`
  display: ${({desktopHidden}: any) => desktopHidden ? 'none' : 'block'};
  font-weight: 400;
  font-size: 12px;
  text-decoration: underline;
  ${screens.mobile} {
    display: ${({mobileHidden}: any) => mobileHidden ? 'none' : 'block'};
    margin-bottom: 20px;
  }
` as any;

class ReportButton extends React.Component<Props & WithNamespaces> {
  render() {
    const { t, desktopHidden, mobileHidden, onClick } = this.props;
    return (
      <Button 
        desktopHidden={desktopHidden} 
        mobileHidden={mobileHidden}
        onClick={onClick}
      >
        {t('USER_PAGE_REPORT_USER')}
      </Button>
    );
  }
}

export default withNamespaces()(ReportButton);