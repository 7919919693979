import React from 'react';
import PopupsComponents from './components';
import styled from 'styled-components';
import { withNamespaces, WithNamespaces } from 'react-i18next';
import { connect } from 'react-redux';
import { TR_SEND_FEEDBACK_REQUESTED, TR_SEND_FEEDBACK } from '../../actions/transaction';
import { StoreModel } from '../../models/StoreModel';
import { ChatModel } from '../../models/ChatModel';
import { createLoadingSelector } from '../../selectors/loading';
import { getCurrentUser } from '../../selectors/user';
import StarIcon from '../Icons/StarIcon';
import { GREY_9A, YELLOW_FD } from '../../constants/colors';
import UI from '../../shared/UI';
import { FormikProps, Formik } from 'formik';
import { UserModel } from '../../models/UserModel';

interface Props {
  user: UserModel | null;
  scope: ChatModel;
  isPending: boolean;
  sendFeedback: (userId: number, rating: number, message: string) => void;
}

interface FormValues {
  rating: number;
  message: string;
}

const StarsWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 8px;
`;

const StyledStarIcon = styled(StarIcon)`
  width: 24px;
  height: 24px;
  margin: 0 4px;
  cursor: pointer;
`; 

class RateModal extends React.Component<Props & WithNamespaces> {
  onSubmit = (values: FormValues) => {
    const { scope, user } = this.props;
    const { trade } = scope;

    const otherTransactionUser = (trade.type === 1 && user!.id === trade.userId) ? scope.recipient : scope.sender;

    this.props.sendFeedback(otherTransactionUser.id, values.rating, values.message);
  }
  getStarFill = (currentValue: number, value: number) => {
    return currentValue >= value ? YELLOW_FD : GREY_9A;
  }
  render() {
    const { t, scope, isPending, user } = this.props;
    const { trade } = scope;

    const otherTransactionUser = (trade.type === 1 && user!.id === trade.userId) ? scope.recipient : scope.sender;

    return (
      <PopupsComponents.Container>
        <PopupsComponents.Header>
          {t('RATE_MODAL_TITLE', {
            username: otherTransactionUser.username
          })}
        </PopupsComponents.Header>
        <PopupsComponents.Content>
        <Formik 
            initialValues={{
              rating: 1,
              message: ''
            }}
            isInitialValid={false}
            onSubmit={this.onSubmit}
            render={({ values, errors, isValid, setFieldValue, setFieldTouched }: FormikProps<FormValues>) => (
              <UI.Form errors={errors}>
                <StarsWrapper>
                  <StyledStarIcon name="rating" onMouseEnter={setFieldValue} value={1} fill={this.getStarFill(values.rating, 1)} />
                  <StyledStarIcon name="rating" onMouseEnter={setFieldValue} value={2} fill={this.getStarFill(values.rating, 2)} />
                  <StyledStarIcon name="rating" onMouseEnter={setFieldValue} value={3} fill={this.getStarFill(values.rating, 3)} />
                  <StyledStarIcon name="rating" onMouseEnter={setFieldValue} value={4} fill={this.getStarFill(values.rating, 4)} />
                  <StyledStarIcon name="rating" onMouseEnter={setFieldValue} value={5} fill={this.getStarFill(values.rating, 5)} />
                </StarsWrapper>
                <PopupsComponents.Textarea
                  name="message"
                  onChange={setFieldValue}
                  onBlur={setFieldTouched}
                />
                <PopupsComponents.ActionButton
                  isPending={isPending}
                  disabled={!isValid}
                >
                  {t('RATE_MODAL_SEND_FEEDBACK')}
                </PopupsComponents.ActionButton>
              </UI.Form>
          )}
        />
          <PopupsComponents.CancelButton>{t('CANCEL')}</PopupsComponents.CancelButton>
        </PopupsComponents.Content>
      </PopupsComponents.Container>
    );
  }
}

const mapStateToProps = (state: StoreModel) => ({
  user: getCurrentUser(state),
  isPending: createLoadingSelector([TR_SEND_FEEDBACK])(state),
});

const mapDispatchToProps = (dispatch: any) => ({
  sendFeedback: (userId: number, rating: number, message: string) => {
    dispatch({
      type: TR_SEND_FEEDBACK_REQUESTED,
      payload: {
        userId,
        rating,
        message
      }
    })
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(withNamespaces()(RateModal));