import React from 'react';
import GoogleMapReact from 'google-map-react';
import uuidv1 from 'uuid/v1';
import { MarkerModel } from '../../models/MarkerModel';
import Marker from './Marker';
import { GOOGLE_API_KEY } from '../../constants/config';

interface Props {
  lat: string | string[] | null;
  lng: string | string[] | null;
  markers: MarkerModel[];
}

const defaultCenter = {
  lat: 53.5510846,
  lng: 9.993681899999956
}

class Map extends React.Component<Props> {
  render() {
    const { lat, lng, markers } = this.props;
    const center = {
      lat: lat ? parseFloat(lat as string) : defaultCenter.lat,
      lng: lng ? parseFloat(lng as string) : defaultCenter.lng
    };
    return (
      <GoogleMapReact
        bootstrapURLKeys={{ key: GOOGLE_API_KEY }}
        defaultCenter={defaultCenter}
        center={center}
        defaultZoom={11}
      >
        {
          markers.map((marker: MarkerModel) => (
          <Marker
            key={uuidv1()} 
            marker={marker} 
            lat={marker.lat} 
            lng={marker.lng}
          />
          ))
        }
      </GoogleMapReact>
    );
  }
}

export default Map;