import React from 'react';

interface Props {
  className?: string;
  value?: number;
  name?: string;
  active?: boolean;
  fill?: string;
  onMouseEnter?: (field: string, value: number) => void;
}

class StarIcon extends React.Component<Props> {
  static defaultProps = {
    fill: '#000'
  }
  onMouseEnter = () => {
    if (this.props.name && this.props.value) {
      this.props.onMouseEnter && this.props.onMouseEnter(this.props.name, this.props.value);
    }
  }
  render() {
    const { className, fill } = this.props;
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg" 
        onMouseEnter={this.onMouseEnter} 
        className={className} 
        fill={fill} 
        width="8" 
        height="8" 
        viewBox="0 0 25.99 24.71"
      >
        <g data-name="Layer 2">
          <path d="M12.99 0l2 12.36-2 8.76-8.03 3.59.93-8.75L0 9.44l8.6-1.82L12.99 0z" />
          <path d="M20.09 15.96l.93 8.75-8.03-3.59V0l4.39 7.62 8.61 1.82-5.9 6.52z" />
        </g>
      </svg>
    )
  }
}

export default StarIcon;
