import styled from 'styled-components';

const Container = styled.div`
  width: 100%;
  max-width: 965px;
  margin: 0 auto 36px;
  padding: 0 20px;
`;

const Header = styled.h1`
  font-size: 2.4rem;
  text-align: center;
  margin: 12px 0;
`;

const SubHeader = styled.h2`
  font-size: 1.6rem;
  margin: 36px 0 12px;
`;

const UpdatedHeader = styled.h6`
  font-size: 1.2rem;
  text-align: center;
  margin: 12px 0;
`;

const List = styled.ul`
  font-size: 1.4rem;
  text-align: justify;
  list-style-type: disc;
  padding-left: 18px;
  ul {
    list-style-type: circle;
  }
`;

const ListItem = styled.li`

`;

const Paragraph = styled.p`
  font-size: 1.4rem;
  text-align: justify;
`;

const Static = {
  Container,
  Header,
  SubHeader,
  UpdatedHeader,
  List,
  ListItem,
  Paragraph,
};

export default Static;