import React from 'react';

const ShieldIcon = ({ className }: any) => (
  <svg className={className} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 15.37 18.41" width="15" height="18">
    <g>
      <g>
        <path d="M14.53 4.66a.84.84 0 0 0 .84-.84V1.6a1.6 1.6 0 0 0-1.6-1.6H1.6A1.6 1.6 0 0 0 0 1.6v9.13c0 1.94 1.25 3.82 3.7 5.6A20.72 20.72 0 0 0 7 18.27a1.6 1.6 0 0 0 1.3 0 20.48 20.48 0 0 0 3.33-1.95c2.46-1.78 3.7-3.66 3.7-5.6V6.49a.84.84 0 0 0-1.67 0v4.24c0 1.37-1 2.8-3 4.25a19.18 19.18 0 0 1-3 1.76 19.37 19.37 0 0 1-3-1.76c-2-1.45-3-2.88-3-4.24V1.67h12v2.15a.84.84 0 0 0 .87.84z"/>
        <path d="M10.55 5.32l-4 4.23-1.73-1.78A.88.88 0 1 0 3.54 9l2.33 2.45a.88.88 0 0 0 1.28 0l4.67-4.9a.88.88 0 0 0-1.28-1.22z"/>
      </g>
    </g>
  </svg>
);

export default ShieldIcon;