import React from 'react';
import uuidv1 from 'uuid/v1';
import InfiniteScroll from 'react-infinite-scroll-component';
import { AdModel } from '../../models/AdModel';
import SearchTradeItem from './SearchTradeItem';
import CoinPrice from '../../shared/CoinPrice';

interface Props {
  ads: AdModel[] | null;
  loadMore: () => void;
  hasMore: boolean;
}

class SearchTradeList extends React.Component<Props> {
  render() {
    const { ads, loadMore, hasMore } = this.props;

    if (!ads) { return null;}
    
    return (
      <CoinPrice>
        {(coinPrice: number) => (
          <InfiniteScroll
            dataLength={100000000}
            next={loadMore}
            hasMore={hasMore}
            loader={<></>}
            style={{
              overflow: 'visible'
            }}
          >
            {
              ads && ads.map((ad: AdModel) => (
                <SearchTradeItem
                  key={uuidv1()}
                  ad={ad}
                  coinPrice={coinPrice}
                />
              ))
            }
          </InfiniteScroll>
        )}
      </CoinPrice>
    )
  }
}

export default SearchTradeList;