import React from 'react';
import { getFiatByBTC } from '../../utils';

interface Props {
  volume: number;
  children: (coinPrice: number, isPending: boolean) => any;
}

interface State {
  coinPrice: number;
  isPending: boolean;
}

class CoinPrice extends React.Component<Props, State> {
  static defaultProps = {
    volume: 1
  }
  state: State = {
    coinPrice: 0,
    isPending: false
  }
  componentDidMount() {
    this.setState({
      isPending: true
    });
    getFiatByBTC(this.props.volume).then((price: string) => {
      this.setState({
        coinPrice: price ? parseFloat(price) : this.state.coinPrice,
        isPending: false
      });
    });
  }
  render() {
    return this.props.children(this.state.coinPrice, this.state.isPending);
  }
}

export default CoinPrice;