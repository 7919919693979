export const AD_LIST_GET = 'AD_LIST_GET';
export const AD_LIST_GET_SUCCESS = 'AD_LIST_GET_SUCCESS';
export const AD_LIST_GET_REQUESTED = 'AD_LIST_GET_REQUESTED';
export const AD_LIST_GET_FAILED = 'AD_LIST_GET_FAILED';

export const AD_GUEST_LIST_GET = 'AD_GUEST_LIST_GET';
export const AD_GUEST_LIST_GET_SUCCESS = 'AD_GUEST_LIST_GET_SUCCESS';
export const AD_GUEST_LIST_GET_REQUESTED = 'AD_GUEST_LIST_GET_REQUESTED';
export const AD_GUEST_LIST_GET_FAILED = 'AD_GUEST_LIST_GET_FAILED';

export const AD_GET = 'AD_GET';
export const AD_GET_SUCCESS = 'AD_GET_SUCCESS';
export const AD_GET_REQUESTED = 'AD_GET_REQUESTED';
export const AD_GET_FAILED = 'AD_GET_FAILED';

export const AD_DELETE = 'AD_DELETE';
export const AD_DELETE_SUCCESS = 'AD_DELETE_SUCCESS';
export const AD_DELETE_REQUESTED = 'AD_DELETE_REQUESTED';
export const AD_DELETE_FAILED = 'AD_DELETE_FAILED';

export const AD_CREATE_NEW = 'AD_CREATE_NEW';
export const AD_CREATE_NEW_SUCCESS = 'AD_CREATE_NEW_SUCCESS';
export const AD_CREATE_NEW_REQUESTED = 'AD_CREATE_NEW_REQUESTED';
export const AD_CREATE_NEW_FAILED = 'AD_CREATE_NEW_FAILED';

export const AD_CLEAR_OPENED_AD = 'AD_CLEAR_OPENED_AD';

export const AD_SEARCH = 'AD_SEARCH';
export const AD_SEARCH_SUCCESS = 'AD_SEARCH_SUCCESS';
export const AD_SEARCH_REQUESTED = 'AD_SEARCH_REQUESTED';
export const AD_SEARCH_FAILED = 'AD_SEARCH_FAILED';


export const AD_CLEAR_SEARCH = '[Advirtisement] Clear search ad';