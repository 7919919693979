import React from 'react';
import styled from 'styled-components';
import PopupsComponents from './components';
import { withNamespaces, WithNamespaces } from 'react-i18next';
import messageIcon from '../../images/common/message_icon.svg';

const MessageIcon = styled.img`
  display: inline-block;
  max-width: 64px;
  margin-bottom: 20px;
`;

const StyledTitle = styled(PopupsComponents.Title)`
  font-size: 16px;
`;

const Subtitle = styled.div`
  font-size: 14px;
  margin-bottom: 24px;
`;

class VerifyEmailPopup extends React.Component<WithNamespaces> {
  render() {
    const { t } = this.props;
    return (
      <PopupsComponents.Container>
        <PopupsComponents.Content>
          <MessageIcon src={messageIcon} />
          <StyledTitle>{t('VERIFY_POPUP_VERIFY_EMAIL')}</StyledTitle>
          <Subtitle>{t('VERIFY_POPUP_VERIFY_EMAIL_DESCRIPTION')}</Subtitle>
          <PopupsComponents.CancelButton>{t('CLOSE')}</PopupsComponents.CancelButton>
        </PopupsComponents.Content>
      </PopupsComponents.Container>
    )
  }
}

export default withNamespaces()(VerifyEmailPopup);