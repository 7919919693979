import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import logoNaga from '../../images/common/logo_naga.svg';
import screens from '../../constants/screens';
import instagramIcon from '../../images/common/socials/instagram.svg';
import facebookIcon from '../../images/common/socials/facebook.svg';
import twitterIcon from '../../images/common/socials/twitter.svg';
import mediumIcon from '../../images/common/socials/medium.svg';
import redditIcon from '../../images/common/socials/reddit.svg';
import nagaIcon from '../../images/common/naga.svg';
import shieldIcon from '../../images/common/shield.svg';
import messageIcon from '../../images/common/message_red_icon.svg';
import { withNamespaces, WithNamespaces } from 'react-i18next';

const Container = styled.div`
  margin-top: auto;
  justify-content: center;
  background-color: #fff;
`;

const Wrapper = styled.div`
  display: flex;
  max-width: 925px;
  width: calc(100% - 40px);
  margin: 0 20px;
  ${screens.mobile} {
    display: block;
    text-align: center;
  }
`;

const Logo = styled(Link)`
  display: block;
  img {
    width: 95px;
  }
`;

const Top = styled.div`
  display: flex;
  justify-content: center;
  padding: 0 0 56px;
  background-color: #f2f2f2;
  ${screens.mobile} {
    padding: 34px 0 30px;
  }
`;

const Bottom = styled.div`
  display: flex;
  justify-content: center;
  padding: 33px 0 38px;
  ${screens.mobile} {
    padding: 20px 0;
  }
`;

const FooterMenu = styled.div`
  display: flex;
  flex-grow: 1;
  ${screens.mobile} {
    display: block;
    margin-bottom: 40px;
  }
`;

const MenuItem = styled.div`
  display: flex;
  ${screens.mobile} {
    display: block;
  }
  &:not(:first-child) {
    margin-left: 40px;
    ${screens.mobile} {
      margin-left: 0;
      margin-top: 40px;
    }
  }
`;

const MenuIcon = styled.img`
  display: block;
  width: 35px;
  max-height: 40px;
  align-self: center;
  ${screens.mobile} {
    margin: 0 auto 10px;
  }
`;

const MenuTitle = styled.div`
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 10px;
`;

const MenuLinks = styled.div`
  margin-left: 16px;
  padding-top: 56px;
  ${screens.mobile} {
    margin-left: 0;
    padding-top: 0;
  }
`;

const MenuLink = styled(Link)`
  display: block;
  font-size: 12px;
  font-weight: 300;
  line-height: 16px;
`;

const FollowContainer = styled.div`
  margin-left: auto;
  padding-top: 56px
  ${screens.mobile} {
    padding-top: 0;
  }
`;

const FollowTitle = styled.div`
  color: #000;
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 15px;
`;

const SocialList = styled.div`
  display: flex;
  ${screens.mobile} {
    justify-content: center;
  }
`;

const SocialLink = styled(Link)`
  display: block;
  &:not(:first-child) {
    margin-left: 13px;
  }
`;

const SocialImg = styled.img`
  width: 20px;
  height: 20px;
  border-radius: 50%;
`;

class Footer extends React.Component<WithNamespaces> {
  render() {
    const { t } = this.props;
    return (
      <Container>
        <Top>
          <Wrapper>
            <FooterMenu>
              <MenuItem>
                <MenuIcon src={nagaIcon} />
                <MenuLinks>
                  <MenuTitle>{t('FOOTER_ABOUT_US')}</MenuTitle>
                    <MenuLink to="#">{t('FOOTER_OUT_STORY')}</MenuLink>
                    <MenuLink to="#">{t('FOOTER_BENEFITS')}</MenuLink>
                    <MenuLink to="/faq">{t('FOOTER_FAQ')}</MenuLink>
                </MenuLinks>
              </MenuItem>
              <MenuItem>
                <MenuIcon src={shieldIcon} />
                <MenuLinks>
                  <MenuTitle>{t('FOOTER_LEGAL')}</MenuTitle>
                    <MenuLink to="/terms">{t('FOOTER_TAC')}</MenuLink>
                    <MenuLink to="/privacy">{t('FOOTER_PRIVACY_POLICY')}</MenuLink>
                    {/* <MenuLink to="#">{t('FOOTER_KYC')}</MenuLink> */}
                </MenuLinks>
              </MenuItem>
              <MenuItem>
                <MenuIcon src={messageIcon} />
                <MenuLinks>
                  <MenuTitle>{t('FOOTER_KEEP_IN_TOUCH')}</MenuTitle>
                    <MenuLink to="#">{t('FOOTER_CONTACT')}</MenuLink>
                </MenuLinks>
              </MenuItem>
            </FooterMenu>
            <FollowContainer>
              <FollowTitle>{t('FOOTER_FOLLOW_US')}</FollowTitle>
              <SocialList>
                <SocialLink to="#" target="_blank">
                  <SocialImg src={instagramIcon} />
                </SocialLink>
                <SocialLink to="#" target="_blank">
                  <SocialImg src={facebookIcon} />
                </SocialLink>
                <SocialLink to="#" target="_blank">
                  <SocialImg src={twitterIcon} />
                </SocialLink>
                <SocialLink to="#" target="_blank">
                  <SocialImg src={mediumIcon} />
                </SocialLink>
                <SocialLink to="#" target="_blank">
                  <SocialImg src={redditIcon} />
                </SocialLink>
              </SocialList>
            </FollowContainer>
          </Wrapper>
        </Top>
        <Bottom>
          <Wrapper>
            <Logo to="/"><img src={logoNaga} alt="logo" /></Logo>
          </Wrapper>
        </Bottom>
      </Container>
    )
  }
}

export default withNamespaces()(Footer);