import React from 'react';
import styled from 'styled-components';
import { RED_E0 } from '../../constants/colors';

interface Props {
  question: any;
}
interface State {
  isOpen: boolean;
}

const Container = styled.div`

`;

const Title = styled.button`
  display: flex;
  align-items: center;
  width: 100%;
  text-align: left;
  font-size: 1.6rem;
  padding: 16px 0;
  border-bottom: 1px solid #000;
  color: ${({ isOpen }: any) => isOpen ? RED_E0 : '#000'};
  &:before {
    display: inline-block;
    content: '';
    width: 10px;
    height: 10px;
    margin: 0 16px 0 8px;
    border-top: 1px solid;
    border-right: 1px solid;
    border-color: ${({ isOpen }: any) => isOpen ? RED_E0 : '#000'};
    transform: rotate(${({ isOpen }: any) => isOpen ? '135deg' : '45deg'});
  }
` as any;

const Answer = styled.div`
  display: ${({ isOpen }: any) => isOpen ? 'block' : 'none'};
  font-size: 1.4rem;
  padding: 16px 0;
  border-bottom: 1px solid #000;
` as any;

class Question extends React.Component<Props, State> {
  state: State = {
    isOpen: false
  }
  toggleAnswer = () => {
    this.setState({
      isOpen: !this.state.isOpen
    });
  }
  render() {
    const { question } = this.props;
    return (
      <Container>
        <Title onClick={this.toggleAnswer} isOpen={this.state.isOpen}>
          <div>{question.title}</div>
        </Title>
        <Answer isOpen={this.state.isOpen}>{question.answer}</Answer>
      </Container>
    );
  }
}

export default Question;