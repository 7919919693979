import React from 'react';
import * as yup from 'yup';
import { connect } from 'react-redux';
import Cookies from 'js-cookie';
import { StoreModel } from '../../models/StoreModel';
import { getCurrentUser } from '../../selectors/user';
import { UserModel } from '../../models/UserModel';
import { closeAllModals } from '../../actions/modal';
import { COOKIE_NO_ENTER_PIN_MODAL } from '../../constants/config';
import { USER_SET_PIN_REQUESTED, USER_SET_PIN } from '../../actions/user';
import { withNamespaces, WithNamespaces } from 'react-i18next';
import PopupsComponents from './components';
import { createLoadingSelector } from '../../selectors/loading';
import UI from '../../shared/UI';
import { FormikProps, Formik } from 'formik';

interface Props {
  currentUser: UserModel | null;
  closeModals: () => void;
  setPin: (pin: string) => void;
  isPending: boolean;
}

interface FormValues {
  pin: string;
}

class SetPinModal extends React.Component<Props & WithNamespaces> {
  pinSchema = yup.object().shape({
    pin: yup.string()
      .required(this.props.t('TR_PIN_NO_EMPTY'))
  })
  onSubmit = (values: FormValues) => {
    this.props.setPin(values.pin);
  }
  closeModal = () => {
    Cookies.set(COOKIE_NO_ENTER_PIN_MODAL, 'true', { expires: 1 }); // 1 - one day

    this.props.closeModals();
  }

  render() {
    const { t, isPending } = this.props;
    return (
      <PopupsComponents.Container>
        <PopupsComponents.Header>{t('SET_PIN')}</PopupsComponents.Header>
        <PopupsComponents.Content>
          <PopupsComponents.Title>{t('SET_PIN_TITLE')}</PopupsComponents.Title>
          <Formik 
            initialValues={{
              pin: ''
            }}
            validationSchema={this.pinSchema}
            isInitialValid={false}
            onSubmit={this.onSubmit}
            render={({ errors, isValid }: FormikProps<FormValues>) => (
              <UI.Form errors={errors}>
                <PopupsComponents.Input
                  type="password"
                  name="pin"
                  autoComplete="off"
                  autoCapitalize="off"
                  placeholder={t('SET_PIN')}
                />
                <PopupsComponents.ActionButton 
                  type="submit" 
                  isPending={isPending}
                  disabled={!isValid}
                >
                  {t('SAVE')}
                </PopupsComponents.ActionButton>
              </UI.Form>
            )}
          />
          <PopupsComponents.CancelButton 
            onClick={this.closeModal}
          >
            {t('NOT_NOW')}
          </PopupsComponents.CancelButton>
        </PopupsComponents.Content>
      </PopupsComponents.Container>
    )
  }
}

const mapStateToProps = (state: StoreModel) => ({
  currentUser: getCurrentUser(state),
  isPending: createLoadingSelector([USER_SET_PIN])(state)
});

const mapDispatchToProps = (dispatch: any) => ({
  setPin: (pin: string) => {
    dispatch({
      type: USER_SET_PIN_REQUESTED,
      payload: {
        pin
      }
    })
  },
  closeModals: () => dispatch(closeAllModals())
});

export default withNamespaces()(connect(mapStateToProps, mapDispatchToProps)(SetPinModal));