import React from 'react';
import styled from 'styled-components';

interface Props {
  fill?: string;
}

const IconWrapper = styled.div`
  width: 34px;
  margin-right: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const OverviewIcon = ({ fill }: Props) => (
  <IconWrapper>
    <svg width="32" height="26" xmlns="http://www.w3.org/2000/svg">
      <g fill={fill || '#fff'} fillRule="evenodd">
        <path d="M10 24H8V14H4v10H2V12h8v12M20 24h-2V8h-4v16h-2V6h8v18M30 24h-2V2h-4v22h-2V0h8v24M0 24h32v2H0z"/>
      </g>
    </svg>
  </IconWrapper>
);

export const UsersIcon = ({ fill }: Props) => (
  <IconWrapper>
    <svg width="34" height="22" xmlns="http://www.w3.org/2000/svg">
      <g fill={fill || '#fff'} fillRule="evenodd">
        <path d="M17 21.93C5.557 21.93.383 11.845.168 11.416a.93.93 0 0 1 0-.832C.383 10.155 5.557.07 17 .07c11.443 0 16.617 10.085 16.832 10.514a.93.93 0 0 1 0 .832C33.617 11.845 28.443 21.93 17 21.93zM2.065 11c1.08 1.833 5.916 9.07 14.935 9.07S30.855 12.833 31.935 11C30.855 9.167 26.019 1.93 17 1.93S3.145 9.167 2.065 11z"/>
        <path d="M17 19.93c-4.924 0-8.93-4.006-8.93-8.93 0-4.924 4.006-8.93 8.93-8.93 4.924 0 8.93 4.006 8.93 8.93 0 4.924-4.006 8.93-8.93 8.93zm0-16c-3.898 0-7.07 3.172-7.07 7.07s3.172 7.07 7.07 7.07 7.07-3.172 7.07-7.07S20.898 3.93 17 3.93z"/>
        <path d="M17 15.93A4.935 4.935 0 0 1 12.07 11a.93.93 0 1 1 1.86 0A3.074 3.074 0 0 0 17 14.07 3.074 3.074 0 0 0 20.07 11 3.074 3.074 0 0 0 17 7.93a.93.93 0 1 1 0-1.86A4.935 4.935 0 0 1 21.93 11 4.935 4.935 0 0 1 17 15.93"/>
      </g>
    </svg>
  </IconWrapper>
);

export const TradeAdIcon = ({ fill }: Props) => (
  <IconWrapper>
    <svg width="34" height="34" xmlns="http://www.w3.org/2000/svg">
      <g fill={fill || '#fff'} fillRule="evenodd">
        <path d="M17 33.93C7.665 33.93.07 26.335.07 17S7.665.07 17 .07 33.93 7.665 33.93 17 26.335 33.93 17 33.93zm0-32C8.69 1.93 1.93 8.69 1.93 17S8.69 32.07 17 32.07 32.07 25.31 32.07 17 25.31 1.93 17 1.93z"/>
        <path d="M22.293 25.707L16 19.414V8h2v10.586l5.707 5.707-1.414 1.414"/>
      </g>
    </svg>
  </IconWrapper>
);

export const TransactionsIcon = ({ fill }: Props) => (
  <IconWrapper>
    <svg width="29" height="29" xmlns="http://www.w3.org/2000/svg">
      <g fill={fill || '#fff'} fillRule="evenodd">
        <path d="M13 28.086c-.856 0-1.66-.332-2.263-.935l-8.888-8.888a3.204 3.204 0 0 1 0-4.526L13.737 1.849C14.774.812 16.734 0 18.2 0h7.6C27.564 0 29 1.435 29 3.2v7.6c0 1.466-.812 3.426-1.849 4.463L15.263 27.151a3.179 3.179 0 0 1-2.263.935zM18.2 2c-.951 0-2.376.59-3.049 1.263L3.263 15.151a1.202 1.202 0 0 0 0 1.698l8.888 8.888c.451.449 1.247.449 1.698 0l11.888-11.888C26.41 13.176 27 11.751 27 10.8V3.2c0-.661-.539-1.2-1.2-1.2h-7.6z"/>
        <path d="M21 11.93A3.934 3.934 0 0 1 17.07 8 3.934 3.934 0 0 1 21 4.07 3.934 3.934 0 0 1 24.93 8 3.934 3.934 0 0 1 21 11.93zm0-6c-1.142 0-2.07.928-2.07 2.07s.928 2.07 2.07 2.07 2.07-.928 2.07-2.07-.928-2.07-2.07-2.07z"/>
      </g>
    </svg>
  </IconWrapper>
);

export const SettingsIcon = ({ fill }: Props) => (
  <svg width="31" height="31" xmlns="http://www.w3.org/2000/svg">
    <path d="M17.678 30.943h-4.356l-1.257-3.77a12.11 12.11 0 0 1-2.392-.99L6.12 27.96 3.04 24.88l1.776-3.554a12.079 12.079 0 0 1-.99-2.392L.057 17.678v-4.356l3.77-1.256c.244-.833.575-1.635.99-2.392L3.04 6.12 6.12 3.04l3.553 1.776a12.11 12.11 0 0 1 2.392-.99L13.322.057h4.356l1.257 3.77c.83.243 1.633.575 2.392.99L24.88 3.04l3.081 3.08-1.776 3.554c.414.758.745 1.559.99 2.392l3.769 1.256v4.356l-3.77 1.256a12.079 12.079 0 0 1-.99 2.392l1.777 3.553-3.08 3.08-3.553-1.775c-.76.414-1.561.746-2.392.99l-1.257 3.769zM15.5 22.785c4.017 0 7.285-3.268 7.285-7.285S19.517 8.215 15.5 8.215 8.215 11.483 8.215 15.5s3.268 7.285 7.285 7.285z" fill="#FFF" fillRule="evenodd"/>
  </svg>
);