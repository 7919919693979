import React from 'react';
import styled, { keyframes } from 'styled-components';
import Message from './Message';
import NewMessageForm from './NewMessageForm';
import { withNamespaces, WithNamespaces } from 'react-i18next';
import io from 'socket.io-client';
import { WS_API_URL } from '../../constants/config';
import { connect } from 'react-redux';
import { ChatModel } from '../../models/ChatModel';
import { UserModel } from '../../models/UserModel';
import { MessageModel } from '../../models/MessageModel';
import { CHAT_GET_HISTORY } from '../../actions/chat';
import { StoreModel } from '../../models/StoreModel';
import { getChatHistory } from '../../selectors/chat';
import { RouteComponentProps, withRouter } from 'react-router';
import screens from '../../constants/screens';

interface Props {
  chat: ChatModel;
  user: UserModel;
  getChatHistory: (id: number) => void;
  messageHistory: MessageModel[] | null;
}

interface State {
  messages: MessageModel[] | null;
  isRecipientTyping: boolean;
}

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: rgba(240, 240, 240, .3);
  border-radius: 6px;
  padding: 20px 0 10px 20px;
  height: 720px;
`;

const MessagesContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  flex-grow: 1;
  margin-bottom: 50px;
  ${screens.mobile} {
    margin-bottom: 16px;
  }
`;

const Scroll = styled.div`
  height: 100%;
  overflow-y: auto;
  padding-right: 15px;
  margin-right: 5px;
` as any;

const MessageTypingContainer = styled.div`
  position: absolute;
  bottom: 62px;
  font-weight: 300;
  font-size: 8px;
  opacity: ${({ isVisible }: any) => isVisible ? '1' : '0'};
  transition: opacity .15s;
` as any;

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
`;

const AnimatedPoints = styled.div`
  &:after,
  &:before {
    content: '';
    display: inline-block;
    width: 6px;
    height: 6px;
    background-color: #c8c8c8;
    border-radius: 50%;
    animation-name: ${fadeIn};
    animation-duration: 1s;
    animation-iteration-count: infinite;
    opacity: 0;
  }
  &:before {
    animation-delay: 0;
  }
  &:after {
    animation-delay: .5s;
  }
`;

const Point = styled.div`
  display: inline-block;
  width: 6px;
  height: 6px;
  background-color: #c8c8c8;
  margin: 0 3px;
  border-radius: 50%;
  animation-name: ${fadeIn};
  animation-duration: 1s;
  animation-delay: .25s;
  animation-iteration-count: infinite;
  opacity: 0;
`;

class Chat extends React.Component<Props & WithNamespaces & RouteComponentProps<any>, State> {
  socket: any = null;
  scrollEl: HTMLDivElement | null = null;
  state: State = {
    messages: null,
    isRecipientTyping: false
  }
  componentDidMount() {
    const chatId = this.props.match.params.id;
    const { user } = this.props;

    this.scrollToBottom();

    if (chatId) {
      this.props.getChatHistory(chatId);
    }

    if (WS_API_URL) {
      this.socket = io(WS_API_URL);
      const userId = user.id;

      this.socket.on(userId, (msg: MessageModel) => {
        if (msg.type === 'message') {
          this.showMessage(msg);
          return;
        }

        this.showTyping();
      });
    }
  }
  componentDidUpdate() {
    this.scrollToBottom();
  }
  componentWillUnmount() {
    this.socket.disconnect();
  }
  scrollToBottom = () => {
    if (this.scrollEl) {
      this.scrollEl.scrollTop = this.scrollEl.scrollHeight;
    }
  }
  saveScrollEl = (el: HTMLDivElement) => {
    this.scrollEl = el;
  }
  showMessage = (msg: any) => {
    this.setState({
      messages: [
        ...this.state.messages || [],
        msg
      ]
    }, this.scrollToBottom);
  }
  showTyping = () => {
    this.setState({
      isRecipientTyping: !this.state.isRecipientTyping
    });
  }
  onSendNewMessage = (message: string) => {
    const { chat, user } = this.props;
    const { senderId, recipientId } = chat;
    const msg = {
      chatId: chat.id,
      message,
      senderId: user.id,
      recipientId: user.id === recipientId ? senderId : recipientId
    };

    this.showMessage(msg);
    this.socket.emit('chat', msg);
  }
  onFormStatusChange = () => {
    const { chat, user } = this.props;
    const { senderId, recipientId } = chat;

    this.socket.emit('typing', {
      chatId: chat.id,
      recipientId: user.id === recipientId ? senderId : recipientId
    });
  }
  getHistoryMessages = () => this.props.messageHistory || [];
  getStateMessages = () => this.state.messages || [];
  getMessageAvatar = (senderId: number) => {
    const { chat }  = this.props;
    const chatCreator = chat.sender;

    return senderId === chatCreator.id ? chat.sender.avatarPath : chat.recipient.avatarPath;
  }
  render() {
    const { t, chat, user } = this.props;
    const chatCreator = chat.sender;
    const recipientUsername = chatCreator.id === user.id ? chat.recipient.username : chat.sender.username;
    const messages = [
      ...this.getHistoryMessages(),
      ...this.getStateMessages()
    ];

    return (
      <Container>
        <MessagesContainer>
          <Scroll ref={this.saveScrollEl}>
            <Message special revert />
            {
              messages.map((msg: MessageModel, index: number) => (
                <Message key={index} userId={user.id} avatarPath={this.getMessageAvatar(msg.senderId)} msg={msg} />
              ))
            }
          </Scroll>
        </MessagesContainer>
        <MessageTypingContainer isVisible={this.state.isRecipientTyping}>
          <AnimatedPoints>
            <Point />
          </AnimatedPoints>
          {t('TRADE_CHAT_TYPING', {
            username: recipientUsername
          })}
        </MessageTypingContainer>
        <NewMessageForm
          onSendNewMessage={this.onSendNewMessage}
          onFocus={this.onFormStatusChange}
          onBlur={this.onFormStatusChange}
        />
      </Container>
    )
  }
}

const mapStateToProps = (state: StoreModel) => ({
  messageHistory: getChatHistory(state)
});

const mapDispatchToProps = (dispatch: any) => ({
  getChatHistory: (id: number) => dispatch({
    type: `${CHAT_GET_HISTORY}_REQUESTED`,
    payload: {
      id
    }
  })
});


export default connect(mapStateToProps, mapDispatchToProps)(withNamespaces()(withRouter(Chat)));