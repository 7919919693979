import React from 'react';
import AdminDealsOverviewList from './AdminDealsOverviewList';
import AdminDealsOverviewSummary from './AdminDealsOverviewSummary';

const data = {
  list: [
    {
      label: 'Total Trade Advertisment',
      value: 5480452
    },
    {
      label: 'Closed Trades',
      value: 50452
    },
    {
      label: 'Highest Margin',
      value: 3.5,
      unit: '%'
    },
    {
      label: 'Lowest Margin',
      value: -1.5,
      unit: '%'
    },
    {
      label: 'Total Deposit',
      value: 5000100,
      unit: '€',
      shouldAddSpaceBeforeUnit: true
    }
  ],
  summary: [
    {
      label: 'Sell Advertisment',
      value: 51.3,
      unit: '%'
    },
    {
      label: 'Trade Advertisment',
      value: 62.9,
      unit: '%'
    },
  ]
};

class AdminDealsOverview extends React.Component {
  render () {
    return (
      <>
        <AdminDealsOverviewList list={data.list} />
        <AdminDealsOverviewSummary summary={data.summary} />
      </>
    )
  }
}

export default AdminDealsOverview;