import React from 'react';
import { withNamespaces, WithNamespaces } from 'react-i18next';
import { StoreModel } from '../../models/StoreModel';
import { getOpenedChats } from '../../selectors/chat';
import { CHAT_GET_OPEN_CHATS_REQUESTED, CHAT_GET_OPEN_CHATS } from '../../actions/chat';
import { connect } from 'react-redux';
import ChatsContainer from './ChatsContainer';
import { createLoadingSelector } from '../../selectors/loading';
import Container from '../../components/Container';
import { ChatModel } from '../../models/ChatModel';
import { createErrorSelector } from '../../selectors/errorResponse';

interface Props {
  chats: ChatModel[] | null;
  getOpenedChats: () => void;
  isPending: boolean;
  isError: boolean;
}

class Chats extends React.Component<Props & WithNamespaces> {
  componentDidMount() {
    this.props.getOpenedChats();
  }
  render() {
    const { t, chats, isPending, isError } = this.props;

    return (
      <Container
        title={t('CHATS_OVERVIEW')}
        isPending={isPending}
        isError={isError}
      >
        {chats && <ChatsContainer chats={chats} />}
      </Container>
    );
  }
}

const mapStateToProps = (state: StoreModel) => ({
  chats: getOpenedChats(state),
  isPending: createLoadingSelector([CHAT_GET_OPEN_CHATS])(state),
  isError: createErrorSelector([CHAT_GET_OPEN_CHATS])(state),
});

const mapDispatchToProps = (dispatch: any) => ({
  getOpenedChats: () => {
    dispatch({
      type: CHAT_GET_OPEN_CHATS_REQUESTED
    })
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(withNamespaces()(Chats));