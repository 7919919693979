import React from 'react';
// import logo from '../../images/common/logo.svg';
import logoBeta from '../../images/common/logo_beta.svg';
import { Link } from 'react-router-dom';

interface Props {
  className?: string;
}

class Logo extends React.Component<Props> {
  render() {
    return (
      <Link className={this.props.className} to="/">
        <img src={logoBeta} alt="logo" />
      </Link>
    )
  }
}

export default Logo;