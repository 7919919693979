import { ActionModel } from '../models/ActionModel';
import { WALLET_GET_BALANCE_SUCCESS, WALLET_GET_WALLET_ADDRESS_SUCCESS } from '../actions/wallet';

export interface WalletInitialModel {
  totalBalance: number | null;
  availableBalance: number | null;
  address: string | null;
}

const initialState = {
  totalBalance: null,
  availableBalance: null,
  address: null,
};

const wallet = (state: WalletInitialModel = initialState, action: ActionModel) => {
  switch(action.type) {
    case WALLET_GET_BALANCE_SUCCESS:
      return {
        ...state,
        totalBalance: action.payload.totalBalance,
        availableBalance: action.payload.availableBalance
      }
    case WALLET_GET_WALLET_ADDRESS_SUCCESS:
      return {
        ...state,
        address: action.payload.address
      }
    default:
      return state;
  }
};

export default wallet;