import React, { SyntheticEvent } from 'react';
import styled from 'styled-components';
import { Field } from 'formik';
import { FIAT_SIGN_AFTER_COMMA } from '../../constants/config';

interface Props {
  name: string;
  connectedName?: string;
  coinPrice: number;
  onChange: (field: string, value: string) => void;
}

const Container = styled.div`
  display: inline-flex;
  align-items: center;
  border: 1px solid #000;
  border-radius: 6px;
  height: 26px;
  font-size: 14px;
`;

const Input = styled(Field)`
  padding: 0 8px;
  height: 100%;
  max-width: 120px;
  font-size: 14px;
  border-radius: 6px 0 0 6px;
  border: none;
  &:disabled {
    background-color: #eee;
  }
`;

const Currency = styled.div`
  line-height: 26px;
  padding: 0 8px;
  border-left: 1px solid #000;
`;


class PriceInput extends React.Component<Props> {
  static defaultProps = {
    coinPrice: 0
  }
  onChange = (e: SyntheticEvent<HTMLInputElement>) => {
    let priceFiat: string | number = '';
    const price = (e.target as HTMLInputElement).value;

    if (price.length > 0) {
      priceFiat = (parseFloat(price) * this.props.coinPrice).toFixed(FIAT_SIGN_AFTER_COMMA);
    }

    this.props.onChange(this.props.name, price);
    this.props.connectedName && this.props.onChange(this.props.connectedName, priceFiat);
  }
  render() {
    const { name } = this.props;

    return (
      <Container>
        <Input 
          name={name}
          type="number"
          onChange={this.onChange}
        />
        <Currency>BTC</Currency>
      </Container>
    );
  }
}

export default PriceInput;