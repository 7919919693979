import React from 'react';
import styled from 'styled-components';
import screens from '../../constants/screens';
import SidebarMenu from './SidebarMenu';

interface Props {
  isLoggedIn: boolean;
}

interface State {
  isSidebarOpen: boolean;
}

const HamburgerMenu = styled.button`
  position: relative;
  display: none;
  width: 40px;
  height: 40px;
  margin-left: 20px;
  span {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 30px;
    height: 3px;
    background-color: #000;
    border-radius: 3px;
  }
  &:after,
  &:before {
    display: block;
    content: '';
    position: absolute;
    width: 30px;
    height: 3px;
    background-color: #000;
    border-radius: 3px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  &:before {
    top: calc(50% - 10px);
  }
  &:after {
    top: calc(50% + 10px);
  }
  ${screens.mobile} {
    display: block;
  }
`;

const Overlay = styled.div`
  position: fixed;
  pointer-events: ${({ isVisible }: any) => isVisible ? 'auto' : 'none'};
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0,0,0, .4);
  z-index: 3;
  opacity: ${({ isVisible }: any) => isVisible ? '1' : '0'};
  transition: opacity .25s ease-in-out;
` as any;


class Sidebar extends React.Component<Props, State> {
  state: State = {
    isSidebarOpen: false
  }

  openSidebar = () => {
    this.setState({
      isSidebarOpen: true
    });
  }
  
  closeSidebar = () => {
    this.setState({
      isSidebarOpen: false
    });
  }

  render() {
    const { isLoggedIn } = this.props;
    const { isSidebarOpen } = this.state;
    return (
      <>
        <HamburgerMenu onClick={this.openSidebar}>
          <span />
        </HamburgerMenu>
        <SidebarMenu isOpen={isSidebarOpen} isLoggedIn={isLoggedIn} onClose={this.closeSidebar} />
        <Overlay isVisible={isSidebarOpen} onClick={this.closeSidebar} />
      </>
    )
  }
}

export default Sidebar;