import React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { CHAT_GET_CHAT_REQUESTED, CHAT_GET_CHAT, CHAT_GET_CHAT_CLEAR } from '../../actions/chat';
import { StoreModel } from '../../models/StoreModel';
import { getChat } from '../../selectors/chat';
import { ChatModel } from '../../models/ChatModel';
import Container from '../../components/Container';
import TradeChatContainer from './TradeChatContainer';
import { createLoadingSelector } from '../../selectors/loading';
import { createErrorSelector } from '../../selectors/errorResponse';

interface Props {
  chat: ChatModel | null;
  isPending: boolean;
  isError: boolean;
  getChat: (id: number) => void;
  clearChat: () => void;
}

class TradeChat extends React.Component<Props & RouteComponentProps<any>> {
  componentDidMount() {
    const chatId = this.props.match.params.id;

    this.props.clearChat();

    if (chatId) {
      this.props.getChat(chatId);
    }
  }
  render() {
    const { chat, isPending, isError } = this.props;

    return (
      <Container
        isPending={isPending}
        isError={isError}
        splited
      >
        {chat && <TradeChatContainer chat={chat} />}
      </Container>
    )
  }
}

const mapStateToProps = (state: StoreModel) => ({
  chat: getChat(state),
  isPending: createLoadingSelector([CHAT_GET_CHAT])(state),
  isError: createErrorSelector([CHAT_GET_CHAT])(state),
});

const mapDispatchToProps = (dispatch: any) => ({
  clearChat: () => dispatch({
    type: CHAT_GET_CHAT_CLEAR
  }),
  getChat: (id: number) => dispatch({
    type: CHAT_GET_CHAT_REQUESTED,
    payload: {
      id
    }
  }),
});

export default connect(mapStateToProps, mapDispatchToProps)(TradeChat);