import React from 'react';
import styled from 'styled-components';
import { ADMIN_GREEN, GREY_9A, GREY_F5, GREY_F9 } from '../../constants/colors';
import { getItemValue } from './utils';

const List = styled.ul`
  display: flex;
  align-items: center;
  justify-content: center;
`;

interface ListItemProps {
  index: number
}

const ListItem = styled.li`
  flex: 0 0 50%;
  height: 119px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: ${({index}: ListItemProps) => index % 2 === 0 ? GREY_F5 : GREY_F9};
`;

const ListItemValue = styled.p`
  margin: 0;
  color: ${ADMIN_GREEN};
  font-size: 35px;
  line-height: 41px;
`;

const ListItemLabel = styled.p`
  margin: 0;
  color: ${GREY_9A};
  font-weight: 500;
  font-size: 19px;
  line-height: 24px;
`;

const AdminDealsOverviewSummary = ({summary}: any) => (
  <List>
    {summary.map((item: any, index: number) => (
      <ListItem index={index} key={item.label}>
        <ListItemValue>{getItemValue(item)}</ListItemValue>
        <ListItemLabel>{item.label}</ListItemLabel>
      </ListItem>
    ))}
  </List>
);

export default AdminDealsOverviewSummary;