import { StoreModel } from "../models/StoreModel";

export const createErrorSelector = (actions: string[]) => (state: StoreModel) => {
  let isError = false;

  actions.forEach((action: string) => {
    isError = isError || (typeof(state.errorResponse[action]) === 'undefined' ? false : state.errorResponse[action]);
  });

  return isError;
};