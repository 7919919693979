import React from 'react';
import styled from 'styled-components';
import Chat from '../../components/Chat';
import { withNamespaces, WithNamespaces } from 'react-i18next';
import modals from '../../constants/modals';
import { connect } from 'react-redux';
import { setModal } from '../../actions/modal';
import { RouteComponentProps, withRouter } from 'react-router';
import { CHAT_GET_CHAT_REQUESTED } from '../../actions/chat';
import { StoreModel } from '../../models/StoreModel';
import { getCurrentUser } from '../../selectors/user';
import { UserModel } from '../../models/UserModel';
import { getAdTypeByType, getFiatByBTC } from '../../utils';
import { TR_CANCEL_TRANSACTION } from '../../actions/transaction';
import { ChatModel } from '../../models/ChatModel';
import screens from '../../constants/screens';

interface Props {
  user: UserModel | null;
  chat: ChatModel;
  openModal: (modalName: string, scope?: any) => void;
  getChat: (id: number) => void;
  cancelTransaction: (chatId: number, tradeId: number) => void;
}

interface State {
  amountFiat: number | null;
}

const Column = styled.div`
  flex-grow: 1;
  flex-shrink: 0;
`;

const ChatColumn = styled(Column)`
  flex-basis: 56%;
  max-width: 56%;
  padding: 56px 0 56px 40px;
  ${screens.mobile} {
    flex-basis: 100%;
    max-width: 100%;
    padding: 32px 0 0;
    order: 1;
  }
`;

const InfoColumn = styled(Column)`
  padding-left: 40px;
  flex-basis: 44%;
  max-width: 44%;
  padding: 56px 40px 56px 40px;
  ${screens.mobile} {
    flex-basis: 100%;
    max-width: 100%;
    padding: 32px 0 0;
    order: 0;
  }
`;

const TransactionAction = styled.button`
  border-radius: 6px;
  height: 35px;
  width: 100%;
  font-weight: 700;
  font-size: 14px;
  margin-bottom: 24px;
`;

const SetPinButton = styled(TransactionAction)`
  color: #2bbc70;
  border: 2px solid #2bbc70;
`;

const OpenTransactionButton = styled(TransactionAction)`
  color: #2bbc70;
  border: 2px solid #2bbc70;
`;

const CloseTransactionButton = styled(TransactionAction)`
  color: #2bbc70;
  border: 2px solid #2bbc70;
  margin-bottom: 8px;
`;

const CancelTransactionButton = styled(TransactionAction)`
  color: #cf0022;
  border: 2px solid #cf0022;
`;

const InfoContainer = styled.div`
  box-shadow: 0 0 24px 0 rgba(0,0,0,.1);
  border-radius: 6px;
  &:not(:first-of-type) {
    margin-top: 30px;
  }
`;

const InfoHeader = styled.div`
  font-weight: 700;
  color: #fff;
  padding: 10px 20px;
  font-size: 14px;
  background-color: ${({ color }: any) => color ? color: '#2bbc70'};
  border-radius: 6px 6px 0 0;
` as any;

const TransactionDetails = styled.div`
  padding: 20px;
`;

const TransactionInfoTable = styled.table`
  width: 100%;
  font-size: 14px;
  table-layout: fixed;
`;

const TransactionInfoBody = styled.tbody``;

const TransactionInfoRow = styled.tr``;

const TransactionInfoCell = styled.td`
  width: 50%;
  &:first-child {
    font-weight: 600;
  }
`;

const TipsList = styled.ul`
  padding: 20px;
`;

const TipItem = styled.li`
  font-size: 14px;
  font-weight: 600;
  &:not(:last-child) {
    margin-bottom: 6px;
  }
  &:before {
    display: inline-block;
    content: '';
    width: 4px;
    height: 4px;
    background-color: #cf0022;
    border-radius: 50%;
    vertical-align: middle;
    margin-right: 4px;
  }
`;
class TradeChat extends React.Component<Props & WithNamespaces & RouteComponentProps<any>> {
  state: State = {
    amountFiat: null
  }
  constructor(props: Props & WithNamespaces & RouteComponentProps<any>) {
    super(props);

    this.updateFiatVolume = this.updateFiatVolume.bind(this);
  }
  componentDidMount() {
    if (!this.state.amountFiat) {
      getFiatByBTC(this.props.chat.amount).then(this.updateFiatVolume);
    }
  }
  updateFiatVolume = (price: string) => {
    this.setState({
      amountFiat: price
    });
  }
  openModal = (modalName: string, scope?: any) => {
    return () => {
      this.props.openModal(modalName, scope)
    }
  }
  cancelTransaction = () => {
    this.props.cancelTransaction(this.props.chat.id, this.props.chat.trade.id);
  }
  render() {
    const { t, chat, user } = this.props;

    if (!user) { return null; }

    const { trade } = chat;
    const isAdCreator = user.id === trade.userId;

    const isSeller = (isAdCreator && trade.type === 1) || (!isAdCreator && trade.type === 2);

    return (
      <>
        <ChatColumn>
          <Chat user={user} chat={chat} />
        </ChatColumn>
        <InfoColumn>
          {
            !user.isPinSet && isSeller && chat.status === null && (
              <SetPinButton 
                onClick={this.openModal(modals.SET_PIN)}
              >
                {t('SET_PIN')}
              </SetPinButton>
            )
          }
          {
            user.isPinSet && isSeller && chat.status === null && (
              <OpenTransactionButton 
                onClick={this.openModal(modals.OPEN_TRANSACTION, chat)}
              >
                {t('TRANSACTION_OPEN')}
              </OpenTransactionButton>
            )
          }
          {
            isSeller && chat.status === 0 && (
              <CloseTransactionButton 
                onClick={this.openModal(modals.ENTER_PIN, chat)}
              >
                {t('TRANSACTION_CLOSE')}
              </CloseTransactionButton>
            )
          }
          {
            chat.status === 0 && (
              <CancelTransactionButton 
                onClick={this.openModal(modals.CANCEL, {
                  title: t('CANCEL_TRANSACTION_QUESTION'),
                  onSubmit: this.cancelTransaction
                })}
              >
                {t('TRANSACTION_CANCEL')}
              </CancelTransactionButton>
            )
          }
          <InfoContainer>
            <InfoHeader>{t('TRADE_CHAT_OPEN_TRANSACTIONS_DETAILS')}</InfoHeader>
            <TransactionDetails>
              <TransactionInfoTable>
                <TransactionInfoBody>
                  <TransactionInfoRow>
                    <TransactionInfoCell>{t('TRADE_CHAT_AMOUNT')}</TransactionInfoCell>
                    <TransactionInfoCell>{chat.amount} BTC</TransactionInfoCell>
                  </TransactionInfoRow>
                  <TransactionInfoRow>
                    <TransactionInfoCell>{t('TRADE_CHAT_WORTH')}</TransactionInfoCell>
                    <TransactionInfoCell>{this.state.amountFiat} €</TransactionInfoCell>
                  </TransactionInfoRow>
                  <TransactionInfoRow>
                    <TransactionInfoCell>{t('TRADE_CHAT_TYPE')}</TransactionInfoCell>
                    <TransactionInfoCell>{getAdTypeByType(t)(trade.type)}</TransactionInfoCell>
                  </TransactionInfoRow>
                </TransactionInfoBody>
              </TransactionInfoTable>
            </TransactionDetails>
          </InfoContainer>
          <InfoContainer>
            <InfoHeader color="#cf0022">{t('TRADE_CHAT_TIPS')}</InfoHeader>
            <TipsList>
              <TipItem>{t('TRADE_CHAT_MEET')}</TipItem>
              <TipItem>{t('TRADE_CHAT_MONEY')}</TipItem>
              <TipItem>{t('TRADE_CHAT_CHECK_MONEY')}</TipItem>
            </TipsList>
          </InfoContainer>
        </InfoColumn>
      </>
    )
  }
}

const mapStateToProps = (state: StoreModel) => ({
  user: getCurrentUser(state)
});

const mapDispatchToProps = (dispatch: any) => ({
  getChat: (id: number) => dispatch({
    type: CHAT_GET_CHAT_REQUESTED,
    payload: {
      id
    }
  }),
  cancelTransaction: (chatId: number, tradeId: number) => dispatch({
    type: `${TR_CANCEL_TRANSACTION}_REQUESTED`,
    payload: {
      chatId,
      tradeId
    }
  }),
  openModal: (modalName: string, scope?: any) => dispatch(setModal(modalName, scope))
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withNamespaces()(TradeChat)));