import React from 'react';
import styled from 'styled-components';
import StarIcon from '../Icons/StarIcon';

interface Props {
  className?: string;
  rating?: number;
}

const Container = styled.div`
  display: flex;
  align-items: center;
`;

const RatingBox = styled.div`
  font-size: 12px;
  font-weight: 500;
  background-color: ${({ isRatingExist }: any) => isRatingExist ? '#fdca35': '#ccc'};
  line-height: 18px;
  height: 18px;
  padding: 0 9px;
  border-radius: 6px;
  margin-right: 4px;
` as any;

class Rating extends React.Component<Props> {
  render() {
    const { className, rating } = this.props;
    return (
      <Container className={className}>
        <RatingBox isRatingExist={!!rating}>{rating ? rating : '-'}</RatingBox>
        <StarIcon />
      </Container>
    );
  }
}

export default Rating;