import React from 'react';
import * as yup from 'yup';
import { 
  Container,
  Head,
  Title,
  Form,
  Row,
  Label,
  Input,
  BottomButtonsContainer,
  Button
} from './Form';
import { connect } from 'react-redux';
import { withNamespaces, WithNamespaces } from 'react-i18next';
import { setModal } from '../../actions/modal';
import { authUpdatePasswordWithToken, authUpdatePasswordWithTokenReset, AUTH_UPDATE_PASSWORD_WITH_TOKEN } from '../../actions/auth';
import { StoreModel } from '../../models/StoreModel';
import modals from '../../constants/modals';
import { RouteComponentProps, withRouter } from 'react-router';
import { Formik, FormikProps } from 'formik';
import { createLoadingSelector } from '../../selectors/loading';
import { createSuccessSelector } from '../../selectors/successResponse';
import { showBluredErrors } from '../../utils';

interface Props {
  isRequestPending: boolean;
  scope: any;
  isUpdateRequestSucceed: boolean;
  resetUpdatePasswordWithToken: () => void;
  openModal: (modal: string) => void;
  updatePasswordWithToken: (token: string, newPassword: string, newPasswordConfirmation: string) => void;
}
interface FormValues {
  password: string;
  passwordConfirmation: string;
}

class ResetPasswordForm extends React.Component<Props & WithNamespaces & RouteComponentProps<any>> {
  token: string | null = null;
  resetFormSchema = yup.object().shape({
    password: yup.string()
      .required(this.props.t('PASSWORD_NO_EMPTY'))
      .min(6, this.props.t('PASSWORD_MIN_LENGTH'))
      .max(20, this.props.t('PASSWORD_MAX_LENGTH'))
      .matches(/^(?=.*?[A-Za-z])(?=.*?[0-9]).+$/, this.props.t('PASSWORD_NOT_VALID'))
      .oneOf([yup.ref('passwordConfirmation')], this.props.t('PASSWORD_DIFFERENT_PASSWORDS')),
    passwordConfirmation: yup.string()
      .oneOf([yup.ref('password')], this.props.t('PASSWORD_DIFFERENT_PASSWORDS'))
  });
  componentDidMount() {
    if (this.props.scope.token) {
      this.token = this.props.scope.token;
    }
  }
  componentDidUpdate() {
    if (this.props.isUpdateRequestSucceed) {
      this.props.resetUpdatePasswordWithToken();
      this.props.openModal(modals.RESET_PASSWORD_SUCCESS);
    }
  }
  openModal = (modal: string) => {
    this.props.openModal(modal);
  }

  onSubmit = (values: FormValues) => {
    this.token && this.props.updatePasswordWithToken(this.token, values.password, values.passwordConfirmation);
  }

  render() {
    const { t } = this.props;
    return (
      <Container>
        <Head>
          <Title>{t('AUTH_MODAL_RESET_PASSWORD')}</Title>    
        </Head>
        <Formik 
          initialValues={{
            password: '',
            passwordConfirmation: '',
          }}
          validationSchema={this.resetFormSchema}
          isInitialValid={false}
          onSubmit={this.onSubmit}
          render={({ errors, touched, isValid }: FormikProps<FormValues>) => (
            <Form errors={showBluredErrors(errors, touched)}>
              <Row>
                <Label>{t('AUTH_MODAL_PASSWORD')}</Label>
                <Input 
                  name="password"
                  type="password" 
                />
              </Row>
              <Row>
                <Label>{t('AUTH_MODAL_CONFIRM_PASSWORD')}</Label>
                <Input 
                  name="passwordConfirmation"
                  type="password" 
                />
              </Row>
              <BottomButtonsContainer>
                <Button 
                  isPending={this.props.isRequestPending} 
                  type="submit"
                  disabled={!isValid}
                >
                  {t('AUTH_MODAL_CHANGE_PASSWORD')}
                </Button>
              </BottomButtonsContainer>
            </Form>
          )}
        />
      </Container>
    );
  }
}

const mapStateToProps = (state: StoreModel) => ({
  isRequestPending: createLoadingSelector([AUTH_UPDATE_PASSWORD_WITH_TOKEN])(state),
  isUpdateRequestSucceed: createSuccessSelector(AUTH_UPDATE_PASSWORD_WITH_TOKEN)(state)
});

const mapDispatchToProps = (dispatch: any) => ({
  resetUpdatePasswordWithToken: () => dispatch(authUpdatePasswordWithTokenReset()),
  openModal: (modal: string) => dispatch(setModal(modal)),
  updatePasswordWithToken: (token: string, newPassword: string, newPasswordConfirmation: string) => dispatch(authUpdatePasswordWithToken(token, newPassword, newPasswordConfirmation))
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withNamespaces()(ResetPasswordForm)));