export const SET_MODAL = '[Modal] Set modal';
export const setModal = (modal: string, scope?: any) => ({
  type: SET_MODAL,
  payload: {
    nextModal: modal,
    scope
  }
});

export const CLOSE_ALL_MODALS = '[Modal] Close all modals';
export const closeAllModals = () => ({
  type: CLOSE_ALL_MODALS
});