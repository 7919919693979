import * as Cookies from 'js-cookie';
import * as queryString from 'query-string';
import { AuthResponse } from './models/AuthResponse';
import { COOKIE_TOKEN_NAME, COOKIE_REFRESH_TOKEN_NAME, LOCAL_STORAGE_STORE_NAME, API_URL, GLOBAL_FEE, FIAT_SIGN_AFTER_COMMA } from './constants/config';
import { FiatByBTCModel } from './models/FiatByBTCModel';

export const saveTokens = (data: AuthResponse) => {
  const tokenExpiredData = new Date(data.accessTokenExpiredAt * 1000);
  Cookies.set(COOKIE_TOKEN_NAME, data.token, { expires: tokenExpiredData });
  Cookies.set(COOKIE_REFRESH_TOKEN_NAME, data.refreshToken)
}

export const getAccessToken = () => Cookies.get('nagalocal_token');
export const getAccessTokenHeader = () => `${getAccessToken()}`;

export const loadState = () => {
  try {
    const state = localStorage.getItem(LOCAL_STORAGE_STORE_NAME);
    if (!state) {
      return undefined;
    }
    return JSON.parse(state)
  } catch(err) {
    return undefined;
  }
}

export const getAdTypeByType = (t: Function) => (type: number) => {
  return type === 1 ? t('USER_PAGE_SELL') : t('USER_PAGE_BUY');
}

export const getAdCreatorTypeByType = (type: number) => {
  return type === 1 ? 'S' : 'B';
}

export const getPercentValue = (value: number | null) => value ? `${value * 100}%`: '0%';

export const getFiatByBTC = (value: number): Promise<string> => {
  const query = queryString.stringify({
    amount: value, 
    currency: 'EUR'
  });

  return fetch(`${API_URL}/trade/fiat-price?${query}`, {
    method: 'GET'
  }).then((res) => {
    return res.json();
  }).then((res: FiatByBTCModel) => {
    return res.price ? res.price.toFixed(2) : '';
  });
}

export const getMaxSellableBalance = (balance: number) => balance - (balance * GLOBAL_FEE);

export const getFiatPrice = (price: number, coinPrice: number | null) => coinPrice ? roundFiatValue(price * coinPrice) : '';
export const getLimit = (minimum: string | number | null, maximum: string | number | null, coinPrice: number | null, currency: string) => {
  if (minimum === null || maximum === null) {
    return '';
  }

  if (!coinPrice) {
    return `${minimum} - ${maximum} ${currency}`;
  }

  return `${getFiatPrice(+minimum, coinPrice)} - ${getFiatPrice(+maximum, coinPrice)} EUR`;
}

export const getCoinPriceWithMargin = (price: number | null, margin: string | number) => price ? `${roundFiatValue(price * (1 + +margin / 100))} EUR` : '';

export const roundFiatValue = (fiatValue: number) => parseFloat(fiatValue.toFixed(FIAT_SIGN_AFTER_COMMA));

export const showBluredErrors = (errors: {[field: string]: string | undefined}, touched: {[field: string]: boolean | undefined}) => {
  const copyErrors = {...errors};

  for (let field in copyErrors) {
    
    if (!touched[field]) {
      delete copyErrors[field];
    }
  }

  return copyErrors;
}

export const formattedCryptoValue = (value: number | null) => {
  if (value === null) { return 0; }

  return value.toLocaleString('en-EN', {
    minimumFractionDigits: 0,
    maximumFractionDigits: 8,
  });
}