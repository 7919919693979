import { StoreModel } from "../models/StoreModel";

export const createLoadingSelector = (actions: string[]) => (state: StoreModel) => {
  let isPending = false;

  actions.forEach((action: string) => {
    isPending = isPending || state.loading[action];
  });

  return isPending;
};