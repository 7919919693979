import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import logoFullWhite from '../../images/common/logo_full-white.svg';
import { ADMIN_SUMMER_SKY } from "../../constants/colors";
import AdminUserPanel from './AdminUserPanel';
import { ADMIN_HEADER_HEIGHT, ADMIN_SIDEBAR_WIDTH } from '../../constants/admin';

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  height: ${ADMIN_HEADER_HEIGHT};
  box-shadow: rgba(0, 0, 0, .13) 0 1px 4px 0;
  position: fixed;
  top: 0;
  left: 0;
  background: #fff;
  z-index: 50;
`;

const LogoWrapper = styled(Link)`
  height: 100%;
  flex: 0 0 ${ADMIN_SIDEBAR_WIDTH};
  max-width: ${ADMIN_SIDEBAR_WIDTH};
  background: ${ADMIN_SUMMER_SKY};
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Logo = styled.img`
  width: 259px;
  height: 64px;
`;

class AdminHeader extends React.Component {
  render () {
    return (
      <Wrapper>
        <LogoWrapper to="/admin">
          <Logo src={logoFullWhite} alt="logo"/>
        </LogoWrapper>
        <AdminUserPanel />
      </Wrapper>
    )
  }
}

export default AdminHeader;