import React from 'react';
import Select from 'react-select';
import { connect } from 'react-redux';
import { StoreModel } from '../../models/StoreModel';
import styled from 'styled-components';
import { BLUE_17, GREY_D7 } from '../../constants/colors';
import {  getCountriesForSelect, getDetectedCountryForSelect } from '../../selectors/country';
import { CountryModelForSelect } from '../../models/CountryModel';
import { COUNTRY_LIST } from '../../actions/country';
import { ValueType } from 'react-select/lib/types';

interface Props {
  onChange: (field: string, country?: ValueType<CountryModelForSelect>) => void;
  onBlur: (field: string) => void;
  countryList?: CountryModelForSelect[];
  selectedCountry: CountryModelForSelect | null;
  detectedCountry?: CountryModelForSelect;
  getCountries: () => void;
  name: string;
}

const CountryOption = styled.div`
  display: flex;
  align-items: center;
  padding: 10px 16px;
  cursor: pointer;
  &:hover {
    background-color: #f3f7fa;   
  }
`;

const CountryOptionNoPaddings = styled(CountryOption)`
  padding: 0 8px;
`;

const CountryImg = styled.img`
  width: 21px;
  height: 15px;
`;

const CountryTitle = styled.span`
  display: inline-block;
  font-size: 16px;
  color: ${BLUE_17};
  margin-left: 16px;
  font-weight: 400;
`;

const SingleValue = ({data}: any) => {
  const { value, label } = data;
  return (
    <CountryOptionNoPaddings>
      <CountryImg src={`${process.env.PUBLIC_URL}/images/common/flags/${value.toUpperCase()}.svg`} />
      <CountryTitle>{label}</CountryTitle>
    </CountryOptionNoPaddings>
  );
}
const Option = ({data, innerProps}: any) => {
  const { label, value } = data;
  return (
    <CountryOption {...innerProps}>
      <CountryImg src={`${process.env.PUBLIC_URL}/images/common/flags/${value.toUpperCase()}.svg`} />
      <CountryTitle>{label}</CountryTitle>
    </CountryOption>
  );
}

class CountrySelect extends React.Component<Props> {
  componentDidMount() {
    if (!this.props.countryList) {
      this.props.getCountries();
    }
    this.onChange(this.props.detectedCountry);
  }
  componentDidUpdate() {
    if (this.props.detectedCountry && !this.props.selectedCountry) {
      this.onChange(this.props.detectedCountry);
    }
  }
  onChange = (country: ValueType<CountryModelForSelect>) => {
    setTimeout(() => {
      this.props.onChange(this.props.name, country);
    })
  }
  onBlur = () => {
    this.props.onBlur(this.props.name);
  }
  render() {
    const styles = {
      container: (base: any) => {
        return {
          ...base,
          borderRadius: '3px',
          border: `1px solid ${GREY_D7}`
        }
      },
      placeholder: (base: any) => {
        return {
          ...base,
          fontSize: 16
        }
      },
      input: (base: any) => {
        return {
          ...base,
          fontSize: 16
        }
      },
      noOptionsMessage: (base: any) => {
        return {
          ...base,
          fontSize: 14
        }
      },
      control: (base: any) => {
        return {
          ...base,
          minHeight: '40px',
          background: '#fff',
          border: 'none',
          boxShadow: 'none',
          '&:hover': {
            border: 'none'
          }
        }
      }
    };
    return (
      <Select 
        name={this.props.name}
        placeholder="Select country" 
        styles={styles} 
        options={this.props.countryList} 
        value={this.props.selectedCountry}
        defaultValue={this.props.detectedCountry} 
        components={{ Option, SingleValue }}
        onChange={this.onChange}
        onBlur={this.onBlur}
      />
    )
  }
}

const mapStateToProps = (state: StoreModel) => ({
  countryList: getCountriesForSelect(state),
  detectedCountry: getDetectedCountryForSelect(state)
});

const mapDispatchToProps = (dispatch: any) => ({
  getCountries: () => dispatch({
    type: `${COUNTRY_LIST}_REQUESTED`
  })
});

export default connect(mapStateToProps, mapDispatchToProps)(CountrySelect);

