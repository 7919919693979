import React from 'react';
import * as yup from 'yup';
import { connect } from 'react-redux';
import { StoreModel } from '../../models/StoreModel';
import { withNamespaces, WithNamespaces } from 'react-i18next';
import PopupsComponents from './components';
import { TR_CLOSE_TRANSACTION } from '../../actions/transaction';
import { ChatModel } from '../../models/ChatModel';
import { getFiatByBTC } from '../../utils';
import { FormikProps, Formik } from 'formik';
import CoinPrice from '../../shared/CoinPrice';
import { createLoadingSelector } from '../../selectors/loading';
import UI from '../../shared/UI';
import { getCurrentUser } from '../../selectors/user';
import { UserModel } from '../../models/UserModel';

interface Props {
  user: UserModel | null;
  isRequestPending: boolean;
  scope: ChatModel;
  closeTransaction: (transacton: any, scope: ChatModel) => void;
}

interface FormValues {
  pin: string;
}

class EnterPinModal extends React.Component<Props & WithNamespaces> {
  pinSchema = yup.object().shape({
    pin: yup.string()
      .required(this.props.t('TR_PIN_NO_EMPTY'))
  })

  componentDidMount() {
    getFiatByBTC(this.props.scope.trade.volume).then((price: string) => {
      this.setState({
        volumeFiat: price
      });
    });
  }

  onSubmit = (values: FormValues) => {
    const { scope } = this.props;

    this.props.closeTransaction({
      chatId: scope.id,
      tradeId: scope.tradeId,
      pin: values.pin,
    }, scope);
  }
  onChange = (value: string) => {
    this.setState({
      pin: value
    });
  }

  render() {
    const { t, scope, user } = this.props;
    const { trade, amount } = scope;

    const otherTransactionUser = (trade.type === 1 && user!.id === trade.userId) ? scope.recipient : scope.sender;

    return (
      <CoinPrice volume={amount}>
        {
          (coinPrice: number) => (
            <PopupsComponents.Container>
              <PopupsComponents.Header>
                {t('TRANSACTION_INFO', {
                  btc: amount,
                  eur: coinPrice,
                  username: otherTransactionUser.username
                })}
              </PopupsComponents.Header>
              <PopupsComponents.Content>
                <PopupsComponents.Title>{t('TRANSACTION_TO_COMPLETE')}</PopupsComponents.Title>
                <Formik 
                  initialValues={{
                    pin: ''
                  }}
                  validationSchema={this.pinSchema}
                  isInitialValid={false}
                  onSubmit={this.onSubmit}
                  render={({ errors, isValid }: FormikProps<FormValues>) => (
                    <UI.Form errors={errors}>
                      <PopupsComponents.Input
                        type="password"
                        name="pin"
                        autoComplete="off"
                        autoCapitalize="off"
                        placeholder="Enter your pin"
                      />
                      <PopupsComponents.ActionButton 
                        type="submit" 
                        isPending={this.props.isRequestPending}
                        disabled={!isValid}
                      >
                        {t('TRANSACTION_CONFIRM')}
                      </PopupsComponents.ActionButton>
                    </UI.Form>
                  )}
                />
                <PopupsComponents.CancelButton>{t('CANCEL')}</PopupsComponents.CancelButton>
              </PopupsComponents.Content>
            </PopupsComponents.Container>
          )
        }
      </CoinPrice>
      
    )
  }
}

const mapStateToProps = (state: StoreModel) => ({
  user: getCurrentUser(state),
  isRequestPending: createLoadingSelector([TR_CLOSE_TRANSACTION])(state)
});

const mapDispatchToProps = (dispatch: any) => ({
  closeTransaction: (transaction: any, scope: ChatModel) => {
    dispatch({
      type: `${TR_CLOSE_TRANSACTION}_REQUESTED`,
      payload: {
        transaction,
        scope
      }
    })
  }
});

export default withNamespaces()(connect(mapStateToProps, mapDispatchToProps)(EnterPinModal));