import React from 'react';

interface Props {
  className?: string;
}

const BitcoinIcon = ({ className }: Props) => (
  <svg className={className} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 343.29 475.08">
    <g data-name="Layer 2">
      <path d="M329.76 249.24q-16.54-21.42-50-29.41 42.54-21.69 37.4-73.66-1.71-18.85-10.42-32.68a65.41 65.41 0 0 0-23.56-22.27A129.82 129.82 0 0 0 251 78.37 254.54 254.54 0 0 0 211 72V0h-44v70q-11.42 0-34.83.57V0h-44v72q-9.42.28-27.69.29L0 72v46.82h31.69q21.69 0 25.41 19.42v81.94a29 29 0 0 1 4.57.29H57.1v114.71q-2.28 14.56-16.56 14.57H8.85L0 402h57.1q5.43 0 15.71.13t15.41.14v72.81h44v-71.95q12 .3 34.83.29v71.66h44v-72.81a342.06 342.06 0 0 0 41.48-4.27 169.36 169.36 0 0 0 34.69-9.85q16.56-6.7 27.69-16.71A78.66 78.66 0 0 0 333.33 346q7.28-15.42 9.27-35.69 3.73-39.66-12.84-61.07zM133.05 121.9c1.33 0 5.09 0 11.27-.14s11.33-.19 15.42-.29 9.61.15 16.56.72a157.49 157.49 0 0 1 17.56 2.28 97.45 97.45 0 0 1 15.7 4.71 42.52 42.52 0 0 1 13.7 8 39.69 39.69 0 0 1 8.71 12 37.45 37.45 0 0 1 3.57 16.7 39.51 39.51 0 0 1-2.54 14.55 27.34 27.34 0 0 1-7.7 11 76.39 76.39 0 0 1-10.57 7.71 48.18 48.18 0 0 1-13.85 5.28q-8.41 2-14.69 3.14a118.33 118.33 0 0 1-15.71 1.57q-9.42.44-14.27.58t-13.28 0q-8.43-.15-9.85-.15V121.9zm120.06 194.43a40.88 40.88 0 0 1-7 11.43 39.68 39.68 0 0 1-11.28 8.56 97 97 0 0 1-13.56 6 108.18 108.18 0 0 1-15.84 4q-9.3 1.72-16.28 2.43c-4.67.48-10.23.85-16.7 1.14s-11.52.43-15.14.43-8.18-.05-13.7-.15-9-.13-10.57-.13v-96.52q2.28 0 13.57-.29T165 253q7.14 0 19.71.86a169.06 169.06 0 0 1 21.12 2.56 139.88 139.88 0 0 1 18.7 5.28 54.14 54.14 0 0 1 16.47 8.66 42.73 42.73 0 0 1 10.56 13.13 40.82 40.82 0 0 1 1.58 32.84z" data-name="Capa 1"/
    ></g>
  </svg>
);

export default BitcoinIcon;