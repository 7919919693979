import { ActionModel } from "../models/ActionModel";
import { SUCCESS_RESPONSE_RESET } from "../actions/successResponse";

export interface SuccessResponseReducerInitialState {
  [name: string]: boolean;
}

const initialState = {};

const successResponse = (state: SuccessResponseReducerInitialState = initialState, action: ActionModel) => {
  const type = action.type;

  if (type === SUCCESS_RESPONSE_RESET) {
    return {};
  }

  const matches = /(.*)_(SUCCESS|REQUESTED|FAILED)/.exec(type);

  if (!matches) {
    return state;
  }

  const [, requestName, requestState] = matches;

  return {
    ...state,
    [requestName]: requestState === 'SUCCESS'
  }
}

export default successResponse;