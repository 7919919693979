import React from 'react';
import styled from 'styled-components';
import { Field } from 'formik';

interface Props {
  autoComplete?: string;
  autoCapitalize?: string;
  type?: string;
  name?: string;
  placeholder?: string;
  className?: string;
  defaultValue?: string[] | string;
  disabled?: boolean;
}

const InputElement = styled(Field)`
  display: inline-block;
  vertical-align: middle;
  height: 26px;
  border: 1px solid #000;
  border-radius: 6px;
  max-width: 440px;
  width: 100%;
  padding: 0 8px;
  font-size: 14px;
  appearance: none;
`;

class Input extends React.Component<Props> {
  render() {
    const { type, autoCapitalize, autoComplete, name, disabled, defaultValue, placeholder, className } = this.props;
    return (
      <InputElement
        className={className}
        type={type} 
        name={name}
        placeholder={placeholder}
        defaultValue={defaultValue} 
        autoCapitalize={autoCapitalize}
        autoComplete={autoComplete}
        disabled={disabled}
      />
    )
  }
}

export default Input;