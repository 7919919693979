import React from 'react';
import styled from 'styled-components';
import { withNamespaces, WithNamespaces } from 'react-i18next';
import emptyIcon from '../../images/common/empty.svg';
import { ChatModel } from '../../models/ChatModel';
import { Link } from 'react-router-dom';
import ChatItem from './ChatItem';

interface Props {
  chats: ChatModel[];
}

const ChatList = styled.div``;

const EmptyContainer = styled.div`
  text-align: center;
`;

const EmptyIcon = styled.img`
  display: inline-block;
  max-width: 240px;
  margin: 0 auto 24px;
`;

const EmptyTitle = styled.div`
  font-size: 1.8rem;
  margin-bottom: 32px;
`;

const StartTradeLink = styled(Link)`
  display: inline-flex;
  align-items: center;
  color: #fff;
  font-size: 1.8rem;
  height: 40px;
  background-color: #2bbc70;
  padding: 0 32px;
  border-radius: 6px;
  font-weight: 300;
`;

class ChatsContainer extends React.Component<Props & WithNamespaces> {
  render() {
    const { t, chats } = this.props;

    return (
      <>
        {
          chats.length === 0 ? (
            <EmptyContainer>
              <EmptyIcon src={emptyIcon} />
              <EmptyTitle>{t('CHATS_EMPTY')}</EmptyTitle>
              <StartTradeLink to="/trade/create">{t('CHATS_START')}</StartTradeLink>
            </EmptyContainer>
          ) : (
            <ChatList>
              {
                chats.map((chat: any, index: number) => <ChatItem key={index} chat={chat} />)
              }
            </ChatList>
          )
        }
      </>
    );
  }
}

export default withNamespaces()(ChatsContainer);