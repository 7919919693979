import React from 'react';
import styled from 'styled-components';
import { AdModel } from '../../models/AdModel';
import LocationIcon from '../../components/Icons/LocationIcon';
import User from '../../components/User';
import ShieldIcon from '../../components/Icons/ShieldIcon';
import { withNamespaces, Trans, WithNamespaces } from 'react-i18next';
import { Link } from 'react-router-dom';
import { getLimit, getCoinPriceWithMargin } from '../../utils';

interface Props {
  ad: AdModel;
  coinPrice: number;
}

const Container = styled.div`
  border-radius: 6px;
  box-shadow: 0 0 24px 0 rgba(0,0,0,.1);
  overflow: hidden;
  padding: 28px 28px 11px 23px;
  &:not(:first-child) {
    margin-top: 16px;
  }
`;

const Title = styled(Link)`
  display: block;
  font-size: 18px;
  font-weight: 500;
  line-height: 24px;
  margin-bottom: 8px;  
  &:hover {
    text-decoration: underline;
  }
`;

const Description = styled.div`
  color: #7f7f7f;
  font-size: 13px;
  line-height: 1;
  margin-bottom: 24px;
  word-break: break-word;
`;

const Price = styled.div`
  font-size: 14px;
  margin-bottom: 8px;
  strong {
    font-weight: 500;
  }
`;

const Limits = styled.div`
  font-size: 14px;
  margin-bottom: 32px;
  strong {
    font-weight: 500;
  }
`;

const UserInfoContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const StyledShieldIcon = styled(ShieldIcon)`
  fill: #7f7f7f;
`;

const StyledLocationIcon = styled(LocationIcon)`
  fill: #e01b2e;
`;

const Address = styled.div`
  display: flex;
  align-items: center;
  color: #7f7f7f;
  font-size: 13px;
  line-height: 15px;
  padding-top: 16px;
  margin-top: 10px;
  border-top: 1px solid #7f7f7f;
  svg {
    margin-right: 12px;
  }
`;

const Username = styled(Link)`
  font-size: 16px;
  line-height: 20px;
  &:hover {
    text-decoration: underline;
  }
`;

const UserWrapper = styled.div`
  display: flex;
  align-items: center;
  ${Username} {
    margin-right: 8px;
  }
`;

const UserVerification = styled.div`
  display: flex;
  align-items: center;
  ${StyledShieldIcon} {
    margin-right: 10px;
  }
`;

const VerificationTitle = styled.div`
  font-size: 10px;
  color: #7f7f7f;
`;

class SearchTradeItem extends React.Component<Props & WithNamespaces> {
  render() {
    const { ad, coinPrice, t } = this.props;
    const { title, description, location, user, minimum, maximum, fee } = ad;

    return (
      <Container >
        <Title to={`/trade/${ad.id}`}>{title}</Title>
        <Description>{description}</Description>
        <Price>
          <strong>{t('TRADE_PAGE_PRICE')} </strong>{getCoinPriceWithMargin(coinPrice, fee)}
        </Price>
        <Limits>
          <strong>{t('USER_PAGE_LIMITS')}: </strong>{getLimit(minimum, maximum, coinPrice, 'EUR')}
        </Limits>
        <UserInfoContainer>
          <UserWrapper>
            <Username to={`/user/${user.id}`}>{user.username}</Username>
            <User.Rating rating={user.rating} />
          </UserWrapper>
          {
            user.isEmailVerified && (
              <UserVerification>
                <StyledShieldIcon />
                <VerificationTitle>
                  <Trans i18nKey="SEARCH_PAGE_VERIFIED">
                    Verified <br /> trading partner
                  </Trans>
                </VerificationTitle>
              </UserVerification>
            )
          }
        </UserInfoContainer>
        <Address>
          <StyledLocationIcon />
          {location}
        </Address>
      </Container>     
    );
  }
}

export default withNamespaces()(SearchTradeItem);