import React from 'react';
import styled from 'styled-components';
import { withNamespaces, WithNamespaces, Trans } from 'react-i18next';
import { UserModel } from '../../models/UserModel';
import { AdModel } from '../../models/AdModel';
import AdRow from './AdRow';
import { GREY_EE, RED_E0 } from '../../constants/colors';
import { Link } from 'react-router-dom';
import screens from '../../constants/screens';

interface Props {
  user: UserModel;
  ads: AdModel[] | null;
  type: string;
  isOwnPage: boolean;
  isLoggedIn: boolean;
  coinPrice: number;
}

const Container = styled.div`
  &:not(:last-child) {
    margin-bottom: 45px;
  }
`;

const Title = styled.div`
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 18px;
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
`;

const Head = styled.thead`
  font-size: 14px;
  color: #fff;
  td {
    background-color: ${({type}: any) => type === 'buy' ? '#cf0022' : '#2bbc70'};
    &:first-child {
      border-radius: 3px 0 0 0px;
    }
    &:last-child {
      border-radius: 0 3px 0 0;
    }
  }
` as any;

const Body = styled.tbody`
  font-size: 12px;
  color: #000;
`;

const Row = styled.tr``;

const Cell = styled.td`
  padding: 8px;
  &:first-child {
    padding-left: 20px;
  }
  &:last-child {
    padding-right: 0;
    text-align: right;
  }
`;

const CellMobileHidden = styled(Cell)`
  ${screens.mobileSm} {
    display: none;
  }
`;

const EmptyCell = styled.td`
  text-align: center;
  padding: 8px 0;
  background-color: ${GREY_EE};
  a {
    color: ${RED_E0};
  }
`;

interface Props {
  buy?: boolean;
  sell?: boolean;
}

class UserAds extends React.Component<Props & WithNamespaces> {
  render() {
    const { type, t, user, ads, isOwnPage, isLoggedIn, coinPrice } = this.props;
    const { username } = user;
    const title = type === 'buy' ? t('USER_PAGE_BUY_BITCOIN_FROM', { username }) : t('USER_PAGE_SELL_BITCOIN_TO', { username });
    const linkToOpenAd = type === 'buy' ? <Link to="/trade/create/buy">{t('HERE')}</Link> : <Link to="/trade/create/sell">{t('HERE')}</Link>;
    const thead = (
      <Head type={type}>
        <Row>
          <Cell>{t('USER_PAGE_PRICE')}</Cell>
          <Cell>{t('STARTPAGE_LAST_LOCALE_ADVERTISMENT_TABLE_LIMITS_TITLE')}</Cell>
          <CellMobileHidden>{t('USER_PAGE_LOCATION')}</CellMobileHidden>
          <Cell />
          {isOwnPage && <Cell />}
        </Row>
      </Head>
    );
    const emptyBodyState = (
      <tr>
        <EmptyCell colSpan={6}>
          {isLoggedIn && isOwnPage ? (
            <Trans i18nKey="PROFILE_PAGE_NO_ADS_CURRENT">
              You don't have open advertisment yet. Click {linkToOpenAd} to open.
            </Trans>
          ) : (
            t('PROFILE_PAGE_NO_ADS', {
              username
            })
          )}
        </EmptyCell>
      </tr>
    );
    return (
      <Container>
        <Title>{title}</Title>
        <Table>
          {thead}
          <Body>
          {
            ads && ads.length > 0 ? (
              ads.map((ad: AdModel) => <AdRow key={ad.id} ad={ad} type={type} isOwnPage={isOwnPage} coinPrice={coinPrice} />)
            ) : emptyBodyState
          }
          </Body>
        </Table>
      </Container>
      
    );
  }
}

export default withNamespaces()(UserAds);