import React from 'react';
import styled from 'styled-components';
import CircleWithArrow from '../../components/Icons/CircleWithArrow';
import { RouteComponentProps } from 'react-router-dom';
import { connect } from 'react-redux';
import { AUTH_VERIFY_EMAIL, authVerifyEmail } from '../../actions/auth';
import { StoreModel } from '../../models/StoreModel';
import { getCurrentUser } from '../../selectors/user';
import { withNamespaces, WithNamespaces } from 'react-i18next';
import { setModal } from '../../actions/modal';
import modals from '../../constants/modals';
import { UserModel } from '../../models/UserModel';
import { isUserLoggedIn } from '../../selectors/user';
import { createSuccessSelector } from '../../selectors/successResponse';
import { createErrorSelector } from '../../selectors/errorResponse';

interface Props {
  user: UserModel | null;
  isEmailVerifiedSuccess: boolean;
  isEmailVerifiedFailed: boolean;
  isUserLoggedIn: boolean;
  verifyEmail: (token: string, isUserLoggedIn: boolean) => void;
  openModal: (modal: string) => void;
}

const Container = styled.div`
  margin: 0 20px 50px;
`;

const Wrapper = styled.div`
  max-width: 992px;
  overflow: hidden;
  margin: 0 auto;
  box-shadow: 0 0 24px 0 rgba(0,0,0,.1);
  text-align: center;
`;

const Title = styled.div`
  font-size: 4.4rem;
  padding: 80px 20px 0;
  margin-bottom: 30px;
`;

const Subtitle = styled.div`
  font-size: 1.8rem;
`;

const BottomContainer = styled.div`
  display: flex;
  justify-content: center;
  background-color: #f9f9f9;
  padding: 26px 20px;
  font-size: 1.8rem;
  margin-top: 80px;
`;

const Button = styled.button`
  display: flex;
  align-items: center;
  font-size: 1.8rem;
`;

const ButtonTitle = styled.span`
  display: inline-block;
  margin-right: 12px;
`;

class EmailVerify extends React.Component<Props & WithNamespaces & RouteComponentProps<any>> {
  componentDidMount() {
    let token = null;

    const tokenMatch = this.props.location.search.match('[?&]token=(.+)$');
    token = tokenMatch && tokenMatch[1];

    if (token) {
      this.props.verifyEmail(token,this.props.isUserLoggedIn);
    }
  }
  goToHomePage = () => {
    this.props.history.push('/');

    if (this.props.user && !this.props.user.isPinSet) {
      this.props.openModal(modals.SET_PIN);
    }
  }
  render() {
    const { isEmailVerifiedSuccess, isEmailVerifiedFailed, t } = this.props;
    return (
      <Container>
        <Wrapper>
          <Title>
            {isEmailVerifiedSuccess && 'Congratulation!' }
            {isEmailVerifiedFailed && 'Oops, something went wrong' }
          </Title>
          {isEmailVerifiedSuccess && <Subtitle>{t('EMAIL_VERIFY_PAGE_SUCCESS')}</Subtitle>}
          <BottomContainer>
            <Button onClick={this.goToHomePage}>
              <ButtonTitle>{t('EMAIL_VERIFY_PAGE_GO_TO_HOME')}</ButtonTitle>
              <CircleWithArrow />
            </Button>
          </BottomContainer>
        </Wrapper>
      </Container>
    );
  }
}

const mapStateToProps = (state: StoreModel) => ({
  user: getCurrentUser(state),
  isUserLoggedIn: isUserLoggedIn(state),
  isEmailVerifiedSuccess: createSuccessSelector(AUTH_VERIFY_EMAIL)(state),
  isEmailVerifiedFailed: createErrorSelector([AUTH_VERIFY_EMAIL])(state),
});

const mapDispatchToProps = (dispatch: any) => ({
  verifyEmail: (token: string, isUserLoggedIn: boolean) => dispatch(authVerifyEmail(token, isUserLoggedIn)),
  openModal: (modal: string) => dispatch(setModal(modal))
})

export default connect(mapStateToProps, mapDispatchToProps)(withNamespaces()(EmailVerify));