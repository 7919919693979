import React from 'react';
import styled from 'styled-components';
import { ADMIN_DARK_BLUE, GREY_B2 } from '../../constants/colors';
import { NavLink } from 'react-router-dom';
import { OverviewIcon, UsersIcon, TradeAdIcon, TransactionsIcon } from '../Icons/AdminIcons';
import { ADMIN_HEADER_HEIGHT, ADMIN_SIDEBAR_WIDTH } from '../../constants/admin';

const SidebarWrapper = styled.div`
  flex: 0 0 ${ADMIN_SIDEBAR_WIDTH};
  width: ${ADMIN_SIDEBAR_WIDTH};
  background: ${ADMIN_DARK_BLUE};
  position: fixed;
  top: ${ADMIN_HEADER_HEIGHT};
  left: 0;
  height: calc(100vh - ${ADMIN_HEADER_HEIGHT});
`;

const SidebarSection = styled.div`
  padding-top: 46px;
`;

const SidebarSubheader = styled.h3`
  color: ${GREY_B2};
  text-transform: uppercase;
  font-size: 19px;
  font-weight: 300;
  padding-left: 49px;
  padding-right: 25px;
`;

const SidebarLink = styled(NavLink)`
  font-size: 25px;
  font-weight: 500;
  display: flex;
  align-items: center;
  padding-left: 43px;
  padding-right: 24px;
  background: rgba(0, 0, 0, 0);
  height: 65px;
  color: #fff;
  opacity: .46;
  transition: opacity .15s ease-in-out, background .15s ease-in-out, box-shadow .15s ease-in-out;
  letter-spacing: 1px;
  
  &.active {
    background: rgba(0, 0, 0, .13);
    opacity: 1;
    box-shadow: inset rgba(0, 0, 0, .5) 0 1px 4px 0;
  }
  
  &:hover, &:focus {
    opacity: 1;
  }
`;

interface AdminSidebarProps {
  baseUrl: string
}

class AdminSidebar extends React.Component<AdminSidebarProps> {
  render () {
    const { baseUrl } = this.props;

    return (
      <SidebarWrapper>
        <SidebarSection>
          <SidebarSubheader>Data & analytics</SidebarSubheader>
          <SidebarLink to={baseUrl} exact><OverviewIcon />Overview</SidebarLink>
          <SidebarLink to={`${baseUrl}/users`}><UsersIcon />Users</SidebarLink>
          <SidebarLink to={`${baseUrl}/trade-ad`}><TradeAdIcon />Trade Advertisment</SidebarLink>
          <SidebarLink to={`${baseUrl}/transactions`}><TransactionsIcon />Transactions</SidebarLink>
        </SidebarSection>
      </SidebarWrapper>
    )
  }
}

export default AdminSidebar;