import React from 'react';
import styled from 'styled-components';
import screens from '../../constants/screens';

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 40px;
  ${screens.mobile} {
    justify-content: center;
    text-align: center;
  }
`;

const Title = styled.div`
  width: 100%;
  font-size: 1.4rem;
  font-weight: 500;
  margin-bottom: 16px;
  ${screens.mobile} {
    font-size: 1.6rem;
  }
`;

const Value = styled.div`
  display: flex;
  align-items: center;
  font-size: 2.4rem;
  font-weight: 700;
  svg {
    margin-right: 16px;
  }
  ${screens.mobile} {
    justify-content: center;
  }
`;

interface Props {
  icon: React.ReactNode;
  title: string;
  value: number | string;
}

class Stats extends React.Component<Props> {
  render() {
    const { icon, title, value } = this.props;
    return (
      <Container>
        <Title>{title}:</Title>
        <Value>
          {icon}
          {value}
        </Value>
      </Container>
    );
  }
}

export default Stats;