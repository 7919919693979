import React from 'react';
import { StoreModel } from '../../models/StoreModel';
import { connect } from 'react-redux';
import { SignupBodyModel } from '../../models/SignupBodyModel';
import { authNaga } from '../../actions/auth';

interface Props {
  authNaga: (data: SignupBodyModel) => void;
}

class AuthListener extends React.Component<Props> {
  auth = (options: any) => {
    const isLogin = options.request.get_user_info;
    const body = {
      username: isLogin ? options.response.data.user_name : options.request.p_user_name,
      email: isLogin ? options.response.data.email : options.request.p_email,
      country: isLogin ? options.response.data.country : options.request.p_country,
      phoneNumber: isLogin ? options.response.data.phone_number : options.request.p_tel,
      isEmailVerified: isLogin ? options.response.data.email_confirmed : false,
      currentLanguage: isLogin ? options.response.data.language : options.request.p_app_language,
      nagaId: options.response.data.user_id
    }
    !isLogin && window.open(options.response.data.url, '_blank');
    this.props.authNaga(body);
  }
  componentDidMount() {
    (window as any).NagaAuth.RegistrationCallback = (options: any) => {
      if (options.success) {
        (window as any).NagaAuth.CloseModal();
        this.auth(options);
      }
    }
    (window as any).NagaAuth.LoginCallback = (options: any) => {
      if (options.success) {
        (window as any).NagaAuth.CloseModal();
        this.auth(options);
      }
    };
  };

  render() {
    return null;
  }
}

const mapStateToProps = (state: StoreModel) => ({

});

const mapDispatchToProps = (dispatch: any) => ({
  authNaga: (data: SignupBodyModel) => dispatch(authNaga(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AuthListener);