import React from 'react';

const TradeVolumeIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="23" height="23" viewBox="0 0 512 512">
    <path d="M407 512H105C47.1 512 0 464.9 0 407V105C0 47.1 47.1 0 105 0h302c57.9 0 105 47.1 105 105v302c0 57.9-47.1 105-105 105z" fill="#fff"/>
    <path d="M407 0H256v512h151c57.9 0 105-47.1 105-105V105C512 47.1 464.9 0 407 0z" fill="#f2f2f2"/>
    <path d="M408.7 233.1l-33.2 30.1-29.7-69-89.3 174.6-52.2-126.6-37.6 70.8-36.7-36.8-25.2 35.8H0v95c0 57.9 47.1 105 105 105h302c57.9 0 105-47.1 105-105V300h-68.1l-35.2-66.9z"/>
    <path d="M407 0H105C47.1 0 0 47.1 0 105v177h89.2l36.8-52.2 33.3 33.3 48.4-91.2 51.8 125.4 88.7-173.4 38.3 89 30.8-27.9 44.8 85.1H512V105C512 47.1 464.9 0 407 0z"/>
    <path d="M408.7 233.1l-33.2 30.1-29.7-69-89.3 174.6-.5-1.3V512h151c57.9 0 105-47.1 105-105V300h-68.1l-35.2-66.9z"/>
    <path d="M407 0H256v288.8l3.5 8.4 88.7-173.4 38.3 89 30.8-27.9 44.8 85.1H512V105C512 47.1 464.9 0 407 0z"/>
    <path fill="#00aaf0" d="M332 0h30v512h-30z"/>
    <circle cx="347" cy="144" r="30" fill="#00c3ff"/>
  </svg>
);

export default TradeVolumeIcon;
