import React from 'react';
import PopupsComponents from './components';
import BitcoinIcon from '../Icons/BitcoinIcon';
import styled from 'styled-components';
import { withNamespaces, WithNamespaces } from 'react-i18next';
import { connect } from 'react-redux';
import { TR_OPEN_TRANSACTION_REQUESTED, TR_OPEN_TRANSACTION } from '../../actions/transaction';
import { StoreModel } from '../../models/StoreModel';
import { getCurrentUser } from '../../selectors/user';
import { UserModel } from '../../models/UserModel';
import { ChatModel } from '../../models/ChatModel';
import { createLoadingSelector } from '../../selectors/loading';
import CoinPrice from '../../shared/CoinPrice';

interface Props {
  scope: ChatModel;
  isPending: boolean;
  user: UserModel | null;
  openTransaction: (chatId: number, tradeId: number, recipientId: number) => void;
}

const BitcoinIconStyled = styled(BitcoinIcon)`
  width: 52px;
  height: 70px;
  margin-bottom: 19px;
`;

class OpenTransactionModal extends React.Component<Props & WithNamespaces> {
  openTransaction = () => {
    const { scope, user } = this.props;

    if (!user) { return; }
    
    this.props.openTransaction(scope.id, scope.trade.id, user.id);
  }
  render() {
    const { t, scope, user, isPending } = this.props;
    const { trade, amount } = scope;
    const type = trade.type;
    const isAdCreator = user!.id === trade.userId;

    return (
      <CoinPrice volume={amount}>
        {(coinPrice: number) => (
          <PopupsComponents.Container>
            <PopupsComponents.Header>{t('TRANSACTION_OPEN_STATUS')}</PopupsComponents.Header>
            <PopupsComponents.Content>
              <BitcoinIconStyled />
              {
                ((isAdCreator && type === 1) || (!isAdCreator && type === 2)) && (
                  <p>{t('TRANSACTION_SELLING', {
                    btc: amount,
                    eur: coinPrice
                  })}</p>
                )
              }
              {
                ((isAdCreator && type === 2) || (!isAdCreator && type === 1)) && (
                  <p>{t('TRANSACTION_BUYING', {
                    btc: amount,
                    eur: coinPrice
                  })}</p>
                )
              }
              <p>{t('TRANSACTION_ENTER_PIN')}</p>
              <PopupsComponents.ActionButton
                onClick={this.openTransaction}
                isPending={isPending}
              >
                {t('TRANSACTION_OPEN')}
              </PopupsComponents.ActionButton>
              <PopupsComponents.CancelButton>{t('CANCEL')}</PopupsComponents.CancelButton>
            </PopupsComponents.Content>
          </PopupsComponents.Container>
        )}
      </CoinPrice>
      
    );
  }
}

const mapStateToProps = (state: StoreModel) => ({
  user: getCurrentUser(state),
  isPending: createLoadingSelector([TR_OPEN_TRANSACTION])(state),
});

const mapDispatchToProps = (dispatch: any) => ({
  openTransaction: (chatId: number, tradeId: number, recipientId: number) => {
    dispatch({
      type: TR_OPEN_TRANSACTION_REQUESTED,
      payload: {
        chatId,
        tradeId,
        recipientId
      }
    });
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(withNamespaces()(OpenTransactionModal));