import React from 'react';
import * as yup from 'yup';
import { 
  Container,
  Head,
  Title,
  LinkColored,
  Form,
  Row,
  Label,
  Info,
  Input,
  BottomButtonsContainer,
  Button
} from './Form';
import { connect } from 'react-redux';
import { withNamespaces, Trans, WithNamespaces } from 'react-i18next';
import { setModal } from '../../actions/modal';
import { authForgotPassword, AUTH_FORGOT_PASSWORD } from '../../actions/auth';
import { StoreModel } from '../../models/StoreModel';
import { Formik, FormikProps } from 'formik';
import { showBluredErrors } from '../../utils';
import { createLoadingSelector } from '../../selectors/loading';

interface Props {
  isRequestPending: boolean;
  openModal: (modal: string) => void;
  forgotPassword: (email?: string) => void;
}

interface FormValues {
  email: string;
}

class ForgotPasswordForm extends React.Component<Props & WithNamespaces> {
  forgotPasswordFormSchema = yup.object().shape({
    email: yup.string()
      .required(this.props.t('EMAIL_NO_EMPTY'))
      .matches(/^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,6}$/, this.props.t('EMAIL_NOT_VALID'))
  }); 
  openModal = (modal: string) => {
    return () => {
      this.props.openModal(modal);
    }
  }

  onSubmit = (values: FormValues) => {    
    this.props.forgotPassword(values.email);
  }

  render() {
    const { t } = this.props;
    return (
      <Container>
        <Head>
          <Title>{t('AUTH_MODAL_FORGOT_PASSWORD')}</Title>
          <LinkColored onClick={this.openModal('sign-up')}>{t('HEADER_MENU_SIGNUP_LABEL')}</LinkColored>      
        </Head>
        <Info>
          <Trans i18nKey="AUTH_MODAL_FORGET_PASSWORD_INFO">
            Enter the email address associated with your account,<br/> and we will email you a link to reset your password
          </Trans>
        </Info>
        <Formik 
          initialValues={{
            email: '',
          }}
          validationSchema={this.forgotPasswordFormSchema}
          isInitialValid={false}
          onSubmit={this.onSubmit}
          render={({ errors, touched, isValid }: FormikProps<FormValues>) => (
            <Form errors={showBluredErrors(errors, touched)}>
              <Row>
                <Label>{t('AUTH_MODAL_EMAIL')}</Label>
                <Input 
                  name="email"
                  type="text" 
                  autoComplete="email" 
                  autoCapitalize="off"
                />
              </Row>
              <BottomButtonsContainer>
                <Button 
                  isPending={this.props.isRequestPending} 
                  type="submit"
                  disabled={!isValid}
                >
                  {t('AUTH_MODAL_SEND_RESET_LINK')}
                </Button>
              </BottomButtonsContainer>
            </Form>
          )}
        />
      </Container>
    );
  }
}

const mapStateToProps = (state: StoreModel) => ({
  isRequestPending: createLoadingSelector([AUTH_FORGOT_PASSWORD])(state)
});

const mapDispatchToProps = (dispatch: any) => ({
  openModal: (modal: string) => dispatch(setModal(modal)),
  forgotPassword: (email?: string) => dispatch(authForgotPassword(email))
});

export default connect(mapStateToProps, mapDispatchToProps)(withNamespaces()(ForgotPasswordForm));