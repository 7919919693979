import React from 'react';
import styled from 'styled-components';
import { BarChart, XAxis, YAxis, Bar, CartesianGrid } from 'recharts';
import { ADMIN_SUMMER_SKY, ADMIN_ORANGE, GREY_9A } from '../../constants/colors';

const ChartWrapper = styled.div`
  flex-grow: 1;
  
  // bars background
  .recharts-bar-background-rectangle {
    &:nth-child(odd) {
      fill: #C4C4C4;
      opacity: .18;
    }
    
    &:nth-child(even) {
      fill: #C3C3C3;
      opacity: .11;
    }
  }
`;

const AXIS_TICK_FONT_SIZE = 23;
const X_AXIS_TICK_PADDING_TOP = 30;
const X_AXIS_ARROW_PADDING_TOP = 30;

const ARROW_WIDTH = 20;
const ARROW_HEIGHT = 18;
const ArrowIcon = (props: any) => (
  <g fill={props.fill || '#fff'} {...props} fillRule="evenodd">
    <path d="M18.5 7.027l-8.342 8.343-8.342-8.343L.5 8.343 10.158 18l9.658-9.657L18.5 7.027"/>
    <path d="M9.5 17h2V0h-2z"/>
  </g>
);

const MINUS_WIDTH = 17;
const MinusIcon = () => (
  <path
    transform={`
      translate(-${MINUS_WIDTH / 2},${X_AXIS_TICK_PADDING_TOP + X_AXIS_ARROW_PADDING_TOP + (ARROW_HEIGHT / 2)})
    `}
    d="M.071 2V0h17v2z"
    fill="#9F9F9F"
    fillRule="evenodd"
  />
);

const getStyledArrowIcon = (value: number, prevValue: number) => {
  const isRising = value - prevValue > 0;

  return (
    <ArrowIcon
      transform={`
        translate(-${ARROW_WIDTH / 2},${X_AXIS_TICK_PADDING_TOP + X_AXIS_ARROW_PADDING_TOP}),
        rotate(${isRising ? `180,${ARROW_WIDTH / 2},${ARROW_HEIGHT / 2}` : '0'})
      `}
      fill={isRising ? ADMIN_SUMMER_SKY : ADMIN_ORANGE}
    />
  )
};

const XAxisTick = ({graphData, x, y, index, payload, showArrows}: any) => {
  const icon = index === 0 ?
    <MinusIcon /> :
    getStyledArrowIcon(graphData[index].value, graphData[index - 1].value);

  return (
    <g transform={`translate(${x},${y})`}>
      <text x={0}
            y={0}
            dy={X_AXIS_TICK_PADDING_TOP}
            textAnchor="middle"
            fontSize={AXIS_TICK_FONT_SIZE}
            fill={GREY_9A}
      >
        {payload.value}
      </text>
      {showArrows && icon}
    </g>
  )
};

const Y_AXIS_TICK_PADDING_RIGHT = 25;
const Y_AXIS_TICK_PADDING_TOP = 9;
const YAxisTick = ({x, y, payload}: any) => (
  <g transform={`translate(${x},${y})`}>
    <text x={0}
          y={0}
          dx={-Y_AXIS_TICK_PADDING_RIGHT}
          dy={Y_AXIS_TICK_PADDING_TOP}
          textAnchor="middle"
          fontSize={AXIS_TICK_FONT_SIZE}
          fill={GREY_9A}
    >
      {payload.value}
    </text>
  </g>
);

const LABEL_PADDING_BOTTOM = 21;
const Label = ({ x, y, width, height, value }: any) => (
  <g transform={`translate(${x + width / 2},${y + height - LABEL_PADDING_BOTTOM})`}>
    <text x={0}
          y={0}
          textAnchor="middle"
          fontSize={65}
          fontWeight="500"
          fill="#fff"
          opacity={.6}
    >
      {value}
    </text>
  </g>
);

const MARGIN_BOTTOM_WITH_ARROWS = 107;
const MARGIN_BOTTOM_WITHOUT_ARROWS = 50;

interface Props {
  data: any[],
  showArrows?: boolean
}

interface State {
  chartWidth: number,
  chartHeight: number,
  marginBottom: number
}

class CustomBarChart extends React.Component<Props, State> {
  state = {
    chartWidth: 0,
    chartHeight: 0,
    marginBottom: 0
  };

  chartContainer = React.createRef<any>();

  componentDidMount () {
    this.setState({
      marginBottom: this.props.showArrows ? MARGIN_BOTTOM_WITH_ARROWS : MARGIN_BOTTOM_WITHOUT_ARROWS
    });

    this.updateChartSize();

    window.addEventListener('resize', this.updateChartSize);
  };

  updateChartSize = () => {
    this.setState({
      chartWidth: this.chartContainer.current.clientWidth,
      chartHeight: this.chartContainer.current.clientHeight
    })
  };

  componentWillUnmount () {
    window.removeEventListener('resize', this.updateChartSize);
  }

  render () {
    const { chartWidth, chartHeight, marginBottom } = this.state;
    const { showArrows, data } = this.props;

    return (
      <ChartWrapper ref={this.chartContainer}>
        <BarChart width={chartWidth}
                  height={chartHeight}
                  data={data}
                  margin={{
                    top: 60,
                    right: 58,
                    bottom: marginBottom,
                    left: 42
                  }}
                  barGap={0}
                  barCategoryGap={0}
        >
          <defs>
            <linearGradient id="cell-gradient">
              <stop offset="0%" stopColor="#7FE6D1" />
              <stop offset="100%" stopColor="#74DCCA" />
            </linearGradient>
          </defs>
          <XAxis dataKey="key"
                 axisLine={false}
                 tick={<XAxisTick graphData={data} showArrows={showArrows} />}
                 tickLine={false}
          />
          <YAxis tick={<YAxisTick />}
                 tickLine={false}
                 axisLine={false}
          />
          <Bar dataKey="value" fill="url(#cell-gradient)" background label={<Label />} opacity={.67} />
          <CartesianGrid vertical={false} stroke="#5AA5E0" strokeWidth="2px" opacity={.16} />
        </BarChart>
      </ChartWrapper>
    )
  }
}

export default CustomBarChart;