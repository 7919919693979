const modals = {
  SIGN_UP: 'sign-up',
  LOGIN: 'login',
  FORGOT_PASSWORD: 'forgot-password',
  RESET_PASSWORD: 'reset-password',
  RESET_PASSWORD_SUCCESS: 'reset-password-success',
  OPEN_TRANSACTION: 'open-transaction',
  TRANSACTION_SUCCESS: 'transaction-success',
  ENTER_PIN: 'enter-pin',
  SET_PIN: 'set-pin',
  VERIFY_EMAIL: 'verify-email',
  REPORT: 'report',
  CANCEL: 'cancel',
  RATE: 'rate',
}

export default modals;