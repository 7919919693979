import React from 'react';
import styled from 'styled-components';
import Avatar from '../Avatar';
import SpecialMessage from './SpecialMessage';
import InfoIcon from '../Icons/InfoIcon';
import { MessageModel } from '../../models/MessageModel';

interface Props {
  avatarPath?: string | null;
  userId?: number;
  revert?: boolean;
  special?: boolean;
  msg?: MessageModel;
}

const Container = styled.div`
  display: flex;
  align-items: center;
  flex-direction: ${({ revert }: any) => revert ? 'row' : 'row-reverse'};
  flex-shrink: 0;
  svg {
    flex-shrink: 0;
  }
  &:not(:last-child) {
    margin-bottom: 30px;
  }
` as any;

const MessageContainer = styled.div`
  position: relative;
  background-color: ${({ special, isOwnMessage }: any) => special ? '#2bbc70' : isOwnMessage ? '#f0f0f0' : '#ddd'};
  padding: 20px 30px;
  font-size: 14px;
  font-weight: 400;
  margin: ${({ revert }: any) => revert ? '0 0 0 20px' : '0 20px 0 0'};
  border-radius: 6px;
  flex-grow: 1;
  color: ${({ special }: any) => special ? '#fff' : '#000'};
  &:before {
    position: absolute;
    display: block;
    content: '';
    border: 10px solid transparent;
    top: 50%;
    transform: translateY(-50%);
    ${({ revert }: any) => revert ? 'border-right: 10px solid #f0f0f0;' : 'border-left: 10px solid #ddd;'};
    ${({ revert }: any) => revert ? 'left: -20px' : 'right: -20px'};
    ${({ special }: any) => special && 'border-right: 10px solid #2bbc70;'}
  }
` as any;

class Message extends React.Component<Props> {
  isOwnMessage(msg?: MessageModel) {
    const { userId } = this.props;

    if (!msg) { return false; }

    return userId === msg.senderId;
  }
  isMessageReverted = () => {
    const { userId, msg } = this.props;

    if (!msg) { return false; }

    return userId === msg.senderId;
  }
  render() {
    const { revert, special, msg, avatarPath } = this.props;
    const isMessageReverted = revert || this.isMessageReverted();
    return (
      <Container revert={isMessageReverted} special={special}>
        {
          special ? <InfoIcon /> : <Avatar avatarPath={avatarPath} size={55} rounded />
        }
        <MessageContainer isOwnMessage={this.isOwnMessage(msg)} revert={isMessageReverted} special={special}>
          {special ? <SpecialMessage /> : msg && msg.message}
        </MessageContainer>
      </Container>
    );
  }
}

export default Message;