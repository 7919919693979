import React from 'react';
import styled from 'styled-components';
import moment from 'moment';
import { ADMIN_GREEN, GREY_9A, GREY_A6, GREY_F5, GREY_F9 } from '../../constants/colors';

const users = [
  {
    companyName: 'Drop Technologies Inc.',
    employees: '100 - 200',
    dateAcquired: new Date()
  },
  {
    companyName: 'Volta',
    employees: '200 - 500',
    dateAcquired: new Date()
  },
  {
    companyName: 'XGF Army',
    employees: '10 - 20',
    dateAcquired: new Date()
  },
  {
    companyName: 'Luxury Housing Estate',
    employees: '100 - 200',
    dateAcquired: new Date()
  },
  {
    companyName: 'Torch Inc.',
    employees: '100 - 200',
    dateAcquired: new Date()
  },
];

const Table = styled.div`
  width: 100%;
`;

const TableRow = styled.div`
  height: 86px;
  display: flex;
  padding: 0 60px;
`;

const TableHeader = styled(TableRow)`
  background: #fff;
`;

interface TableBodyRowProps {
  index: number
}

const TableBodyRow = styled(TableRow)`
  background: ${({ index }: TableBodyRowProps) => index % 2 ? GREY_F9 : GREY_F5};
`;

const TableCell = styled.div`
  font-size: 23px;
  flex: 0 0 23%;
  display: flex;
  align-items: center;
  
  &:first-child {
    flex: 0 0 44%;
  }
`;

const TableHeaderCell = styled(TableCell)`
  color: ${GREY_9A};
  font-weight: 400;
`;

const TableBodyCell = styled(TableCell)`
  color: ${(props: any) => props.color || GREY_A6};
  font-weight: 300;
`;

class AdminNewUsers extends React.Component {
  render () {
    return (
      <Table>
        <TableHeader>
          <TableHeaderCell>Company Name</TableHeaderCell>
          <TableHeaderCell>Employees</TableHeaderCell>
          <TableHeaderCell>Date Acquired</TableHeaderCell>
        </TableHeader>
        {users.map((user: any, index: number) => (
          <TableBodyRow key={user.companyName} index={index}>
            <TableBodyCell color={ADMIN_GREEN}>{user.companyName}</TableBodyCell>
            <TableBodyCell>{user.employees}</TableBodyCell>
            <TableBodyCell>{moment(user.dateAcquired).format('MMMM DD, YYYY')}</TableBodyCell>
          </TableBodyRow>
        ))}
      </Table>
    )
  }
}

export default AdminNewUsers;