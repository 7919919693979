import { ActionModel } from "../models/ActionModel";
import { AD_CLEAR_OPENED_AD, AD_CLEAR_SEARCH, AD_LIST_GET_SUCCESS, AD_GUEST_LIST_GET_SUCCESS, AD_GET_SUCCESS, AD_CREATE_NEW_SUCCESS, AD_DELETE_SUCCESS, AD_SEARCH_SUCCESS } from "../actions/ads";
import { AdModel } from "../models/AdModel";

export interface AdvertisementInitialState {
  currentUserAds: {
    sell: AdModel[] | null;
    buy: AdModel[] | null;
  };
  guestUserAds: {
    sell: AdModel[] | null;
    buy: AdModel[] | null;
  };
  openedAd: AdModel | null;
  searchAds: AdModel[] | null;
  page: number;
}

const initialState = {
  currentUserAds: {
    sell: null,
    buy: null
  },
  guestUserAds: {
    sell: null,
    buy: null
  },
  openedAd: null,
  searchAds: null,
  page: 1,
};

const ads = (state: AdvertisementInitialState = initialState, action: ActionModel) => {
  switch (action.type) {
    case AD_LIST_GET_SUCCESS:
      return {
        ...state,
        currentUserAds: {
          ...action.payload.ads
        }
      }
    case AD_GUEST_LIST_GET_SUCCESS:
      return {
        ...state,
        guestUserAds: {
          ...action.payload.ads
        }
      }
    case AD_DELETE_SUCCESS:
      return {
        ...state,
        currentUserAds: {
          buy: state.currentUserAds.buy && state.currentUserAds.buy.filter((ad: AdModel) => ad.id !== action.payload.id),
          sell: state.currentUserAds.sell && state.currentUserAds.sell.filter((ad: AdModel) => ad.id !== action.payload.id),
        }
      }
    case AD_CREATE_NEW_SUCCESS:
    case AD_GET_SUCCESS:
      return {
        ...state,
        openedAd: action.payload.openedAd
      }
    case AD_CLEAR_OPENED_AD:
      return {
        ...state,
        openedAd: null
      }
    case AD_SEARCH_SUCCESS:
      return {
        ...state,
        searchAds: action.payload.ads,
      }
    case AD_CLEAR_SEARCH:
      return {
        ...state,
        searchAds: null,
      }
    default:
      return state;
  }
}

export default ads;