import { ActionModel } from "../models/ActionModel";
import { AUTH_SAVE_TOKENS } from "../actions/auth";
import { saveTokens } from "../utils";
import * as Cookies from 'js-cookie';
import { COOKIE_TOKEN_NAME, COOKIE_REFRESH_TOKEN_NAME } from "../constants/config";

export interface AuthInitialState {
  token?: string;
  refreshToken?: string;
  accessTokenExpiredAt?: number;
}

const initialState = {
  token: Cookies.get(COOKIE_TOKEN_NAME),
  refreshToken: Cookies.get(COOKIE_REFRESH_TOKEN_NAME),
  accessTokenExpiredAt: undefined,
};

const auth = (state: AuthInitialState = initialState, action: ActionModel) => {
  switch (action.type) {
    case AUTH_SAVE_TOKENS:
      saveTokens(action.payload);
      return {
        ...state,
        ...action.payload
      }
    default:
      // Logout
      if (typeof(action.type) === 'undefined') {
        return {
          ...state,
          token: undefined,
          refreshToken: undefined,
          accessTokenExpiredAt: undefined
        }
      }
      return state;
  }
}

export default auth;