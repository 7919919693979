import { StoreModel } from "../models/StoreModel";
import { createSelector } from "reselect";
import { AdModel } from "../models/AdModel";
import { getCurrentUser } from "./user";
import { UserModel } from "../models/UserModel";

export const getCurrentUserAds = (state: StoreModel) => state.ads.currentUserAds;
export const getGuestUserAds = (state: StoreModel) => state.ads.guestUserAds;
export const getOpenedAd = (state: StoreModel) => state.ads.openedAd;
export const getSearchedAds = (state: StoreModel) => state.ads.searchAds;

export const isOwnTrade = createSelector(
  [getOpenedAd, getCurrentUser],
  (openedAd: AdModel | null, currentUser: UserModel | null) => {
    if (!openedAd || !currentUser) {
      return false;
    }
    
    return openedAd.user.id === currentUser.id;
  }
)