import React from 'react';
import styled from 'styled-components';
import Logo from '../Logo';
import Navigation from '../Header/Navigation';
import { GREY_7F } from '../../constants/colors';
import { withNamespaces, WithNamespaces } from 'react-i18next';

interface Props {
  isOpen: boolean;
  isLoggedIn: boolean;
  onClose: () => void;
}

const Container = styled.div`
  position: fixed;
  right: 0;
  top: 0;
  min-width: 280px;
  overflow-y: auto;
  height: 100%;
  z-index: 4;
  background-color: #fff;
  box-shadow: ${({ isOpen }: any) => isOpen ? '-5px 0px 30px 0 rgba(0,0,0, .4);' : 'none'};
  transform: translateX(${({ isOpen }: any) => isOpen ? '0' : '100%'});
  transition: transform .25s ease-in-out, box-shadow .25s;
` as any;

const StyledLogo = styled(Logo)`
  display: block;
  img {
    width: 160px;
  }
`;

const Top = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 30px 24px;
`;

const CloseButton = styled.button`
  position: relative;
  width: 40px;
  height: 40px;
  &:after,
  &:before {
    position: absolute;
    display: block;
    content: '';
    width: 20px;
    height: 1px;
    background-color: #000;
    top: 50%;
    right: 0;
  }
  &:after {
    transform: translateY(-50%) rotate(45deg);
  }
  &:before {
    transform: translateY(-50%) rotate(-45deg);
  }
`;

const StyledNavigation = styled(Navigation)`
  list-style-type: none;
  li {
    padding: 0 24px;
  }
  a {
    display: block;
    color: ${GREY_7F};
    font-weight: 500;
    font-size: 1.8rem;
    text-decoration: none;
    padding: 16px 0;
    border-bottom: 1px solid ${GREY_7F};
  }
`;

const AuthButton = styled.button`
  display: block;
  width: calc(100% - 48px);
  height: 40px;
  text-transform: uppercase;
  margin: 0 24px 8px;
  color: ${GREY_7F};
  border: 1px solid ${GREY_7F};
  padding: 0 8px;
  background: none;
  cursor: pointer;
  &:focus {
    outline: none;
  }
`;

const StyledLoginButton = styled(AuthButton)`
  color: #fff;
  background-color: #000;
  border-color: #000;
`;

class SidebarMenu extends React.Component<Props & WithNamespaces> {
  onSignupClick = () => {
    if ((window as any).NagaAuth) {
      (window as any).NagaAuth.ShowSignupModal();
    }
  }
  onLoginClick = () => {
    if ((window as any).NagaAuth) {
      (window as any).NagaAuth.ShowLoginModal();
    }
  }
  render() {
    const { isOpen, isLoggedIn, onClose, t } = this.props;

    return (
      <Container isOpen={isOpen} onClick={onClose}>
        <Top>
          <StyledLogo />
          <CloseButton onClick={onClose} />
        </Top>
        {!isLoggedIn && <AuthButton onClick={this.onSignupClick}>{t('HEADER_MENU_SIGNUP_LABEL')}</AuthButton>}
        {!isLoggedIn && <StyledLoginButton onClick={this.onLoginClick}>{t('HEADER_MENU_LOGIN_LABEL')}</StyledLoginButton>}
        <StyledNavigation />
      </Container>
    )
  }
}

export default withNamespaces()(SidebarMenu);