import React from 'react';
import styled from 'styled-components';
import { Trans, WithNamespaces, withNamespaces } from 'react-i18next';
import TradesTable from './TradesTable';
import { RED_E0 } from '../../constants/colors';
import { AdModel } from '../../models/AdModel';
import { connect } from 'react-redux';
import { StoreModel } from '../../models/StoreModel';
import { getSearchedAds } from '../../selectors/ads';
import { AD_SEARCH } from '../../actions/ads';
interface Props {
  searchAds: () => void;
  ads: AdModel[] | null;
}

const Container = styled.div`
`

const TitleContainer = styled.div`
  max-width: 965px;
  margin: 0 auto;
  padding: 0 20px;
`;

const Title = styled.span`
  display: inline-block;
  border-bottom: 8px solid ${RED_E0};
  padding-bottom: 1.2rem;
  font-size: 2.7rem;
  line-height: 2.7rem;
  font-weight: 700;
  text-transform: uppercase;
`;

const TableContainer = styled.div`
  background-color: #f2f2f2;
  padding: 35px 20px 120px;
`

class LastTrades extends React.Component<Props & WithNamespaces> {
  componentDidMount() {
    this.props.searchAds();
  }
  render() {
    const { ads } = this.props;
    return (
      <Container>
        <TitleContainer>
          <Title>
            <Trans i18nKey="STARTPAGE_LAST_LOCALE_ADVERTISMENT">
              The newest<br/> local advertisment
            </Trans>
          </Title>
        </TitleContainer>
        <TableContainer>
          <TradesTable
            ads={ads}
          />
        </TableContainer>
      </Container>
    )
  }
}

const mapStateToProps = (state: StoreModel) => ({
  ads: getSearchedAds(state),
});

const mapDispatchToProps = (dispatch: any) => ({
  searchAds: () => {
    dispatch({
      type: `${AD_SEARCH}_REQUESTED`,
      payload: {}
    })
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(withNamespaces()(LastTrades));