import { takeEvery, put, call } from "redux-saga/effects";
import { API_URL } from "../constants/config";
import { TR_OPEN_TRANSACTION_REQUESTED, TR_OPEN_TRANSACTION_FAILED, TR_OPEN_TRANSACTION_SUCCESS, TR_CLOSE_TRANSACTION_FAILED, TR_CLOSE_TRANSACTION_SUCCESS, TR_CANCEL_TRANSACTION_FAILED, TR_CANCEL_TRANSACTION_SUCCESS, TR_CLOSE_TRANSACTION_REQUESTED, TR_CANCEL_TRANSACTION_REQUESTED, TR_SEND_FEEDBACK_REQUESTED, TR_SEND_FEEDBACK_SUCCESS, TR_SEND_FEEDBACK_FAILED } from "../actions/transaction";
import { getAccessTokenHeader } from "../utils";
import { ActionModel } from "../models/ActionModel";
import { CLOSE_ALL_MODALS, SET_MODAL } from "../actions/modal";
import { CHAT_CHANGE_TRADE_STATUS } from "../actions/chat";
import modals from "../constants/modals";

function* openTransactionRequest(body: any) {
  const res = yield fetch(`${API_URL}/transaction/open`, {
    method: 'POST',
    headers: {
      Authorization: getAccessTokenHeader(),
      "Content-Type": "application/json; charset=utf-8"
    },
    body: JSON.stringify(body)
  });

  const resJson = yield res.json();

  if (!res.ok) { throw resJson.message }

  return res;
}

function* cancelTransactionRequest(body: any) {
  const res = yield fetch(`${API_URL}/transaction/cancel`, {
    method: 'POST',
    headers: {
      Authorization: getAccessTokenHeader(),
      "Content-Type": "application/json; charset=utf-8"
    },
    body: JSON.stringify(body)
  });

  const resJson = yield res.json();

  if (!res.ok) { throw resJson.message }

  return resJson;
}

function* sendFeedbackRequest(body: any) {
  const res = yield fetch(`${API_URL}/user/feedback`, {
    method: 'POST',
    headers: {
      Authorization: getAccessTokenHeader(),
      "Content-Type": "application/json; charset=utf-8"
    },
    body: JSON.stringify(body)
  });

  const resJson = yield res.json();

  if (!res.ok) { throw resJson.message }

  return resJson;
}

function* closeTransactionRequest(body: any) {
  const res = yield fetch(`${API_URL}/transaction/close`, {
    method: 'POST',
    headers: {
      Authorization: getAccessTokenHeader(),
      "Content-Type": "application/json; charset=utf-8"
    },
    body: JSON.stringify(body)
  });

  const resJson = yield res.json();

  if (!res.ok) { throw resJson.message }

  return res;
}

function* openTransaction(action: ActionModel) {
  try {
    const res = yield call(openTransactionRequest, action.payload);

    yield put({ type: TR_OPEN_TRANSACTION_SUCCESS });
    yield put({ type: CHAT_CHANGE_TRADE_STATUS, payload: { status: 0 } });
    yield put({ type: CLOSE_ALL_MODALS });
    return res;
  } catch(err) {
    yield put({ type: TR_OPEN_TRANSACTION_FAILED });
    return err;
  }
}

function* closeTransaction(action: ActionModel) {
  try {
    const res = yield call(closeTransactionRequest, action.payload.transaction);

    yield put({ type: TR_CLOSE_TRANSACTION_SUCCESS });
    yield put({ type: CHAT_CHANGE_TRADE_STATUS, payload: { status: 1 } });
    yield put({ type: SET_MODAL, payload: { nextModal: modals.TRANSACTION_SUCCESS, scope: action.payload.scope }});
    return res;
  } catch(err) {
    yield put({ type: TR_CLOSE_TRANSACTION_FAILED });
    return err;
  }
}

function* cancelTransaction(action: ActionModel) {
  try {
    const res = yield call(cancelTransactionRequest, action.payload);

    yield put({ type: TR_CANCEL_TRANSACTION_SUCCESS });
    yield put({ type: CHAT_CHANGE_TRADE_STATUS, payload: { status: 1 } });
    yield put({ type: CLOSE_ALL_MODALS });
    return res;
  } catch(err) {
    yield put({ type: TR_CANCEL_TRANSACTION_FAILED });
    return err;
  }
}

function* sendFeedback(action: ActionModel) {
  try {
    const res = yield call(sendFeedbackRequest, action.payload);

    yield put({ type: TR_SEND_FEEDBACK_SUCCESS });
    yield put({ type: CLOSE_ALL_MODALS });
    return res;
  } catch(err) {
    yield put({ type: TR_SEND_FEEDBACK_FAILED });
    return err;
  }
}

const transaction = [
  takeEvery(TR_OPEN_TRANSACTION_REQUESTED, openTransaction),
  takeEvery(TR_CLOSE_TRANSACTION_REQUESTED, closeTransaction),
  takeEvery(TR_CANCEL_TRANSACTION_REQUESTED, cancelTransaction),
  takeEvery(TR_SEND_FEEDBACK_REQUESTED, sendFeedback),
];

export default transaction;