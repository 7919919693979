export const mobileSm = '564px';
export const mobile = '768px';
export const tablet = '1024px';
export const lg = '1200px';

const screens = {
  mobileSm: `@media only screen and (max-width: ${mobileSm})`,
  mobile: `@media only screen and (max-width: ${mobile})`,
  tablet: `@media only screen and (max-width: ${tablet})`,
  lg: `@media only screen and (max-width: ${lg})`,

  laptopHeight: '@media only screen and (max-height: 1000px)'
};

export default screens;