import React from 'react';
import styled from 'styled-components';
import { getAdCreatorTypeByType } from '../../utils';
import { MarkerModel } from '../../models/MarkerModel';

interface Props {
  marker: MarkerModel;
  lat: number;
  lng: number;
}

interface State {
  isPopupOpen: boolean;
}

const Container = styled.button`
  position: relative;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: #fff;
  border: 2px solid #e01b2e;
  cursor: pointer;
  text-align: center;
  span {
    font-size: 16px;
    font-weight: 700;
  }
` as any;

const Popup = styled.div`
  position: absolute;
  top: -10px;
  width: 120px;
  font-size: 12px;
  padding: 4px;
  transform: translate(-50%, -100%);
  left: 50%;
  background: #fff;
  border-radius: 3px;
  opacity: ${({ isOpen }: any) => isOpen ? '1' : '0'};
  pointer-events: ${({ isOpen }: any) => isOpen ? 'auto' : 'none'};
  &:after {
    position: absolute;
    content: '';
    border: 5px solid transparent;
    border-top: 5px solid #fff;
    bottom: -10px;
    left: 50%;
    transform: translateX(-50%);
  }
` as any;

class Marker extends React.Component<Props, State> {
  state: State = {
    isPopupOpen: false
  }

  togglePopup = () => {
    this.setState({
      isPopupOpen: !this.state.isPopupOpen
    });
  }
  
  render() {
    const { title, type } = this.props.marker;
    return (
      <Container onClick={this.togglePopup}>
        <Popup isOpen={this.state.isPopupOpen}>
          { title }
        </Popup>
        <span>{getAdCreatorTypeByType(type)}</span>
      </Container>
    )
  }
}

export default Marker;