export const WALLET_GET_BALANCE = 'WALLET_GET_BALANCE';
export const WALLET_GET_BALANCE_SUCCESS = 'WALLET_GET_BALANCE_SUCCESS';
export const WALLET_GET_BALANCE_REQUESTED = 'WALLET_GET_BALANCE_REQUESTED';
export const WALLET_GET_BALANCE_FAILED = 'WALLET_GET_BALANCE_FAILED';

export const WALLET_GET_WALLET_ADDRESS = 'WALLET_GET_WALLET_ADDRESS';
export const WALLET_GET_WALLET_ADDRESS_SUCCESS = 'WALLET_GET_WALLET_ADDRESS_SUCCESS';
export const WALLET_GET_WALLET_ADDRESS_REQUESTED = 'WALLET_GET_WALLET_ADDRESS_REQUESTED';
export const WALLET_GET_WALLET_ADDRESS_FAILED = 'WALLET_GET_WALLET_ADDRESS_FAILED';

export const WALLET_WITHDRAWAL = 'WALLET_WITHDRAWAL';
export const WALLET_WITHDRAWAL_SUCCESS = 'WALLET_WITHDRAWAL_SUCCESS';
export const WALLET_WITHDRAWAL_REQUESTED = 'WALLET_WITHDRAWAL_REQUESTED';
export const WALLET_WITHDRAWAL_FAILED = 'WALLET_WITHDRAWAL_FAILED';