import React from 'react';
import BarChart from '../../components/BarChart';

const data = [
  {
    key: 'March',
    value: 91
  },
  {
    key: 'April',
    value: 138
  },
  {
    key: 'June',
    value: 50
  },
];

class AdminClosedTrades extends React.Component {
  render () {
    return (
      <BarChart data={data} />
    )
  }
}

export default AdminClosedTrades;