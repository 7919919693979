import React from 'react';
import styled from 'styled-components';
import Avatar from '../Avatar';
import { ArrowIcon } from '../Icons/ArrowIcons';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { authLogout } from '../../actions/auth';
import { UserModel } from '../../models/UserModel';
import { withNamespaces, WithNamespaces } from 'react-i18next';

interface Props {
  user: UserModel;
  logout: () => void;
}
interface State {
  isOpen: boolean
}

const Container = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  svg {
    transform: rotate(90deg);
    margin-left: 1.4rem;
  }
` as any;

const DropdownWrapper = styled.div`
  display: ${({isOpen}: any) => isOpen ? 'block' : 'none'};
  position: absolute;
  min-width: 100%;
  bottom: -12px;
  right: 0;
  background-color: #f2f2f2;
  z-index: 1;
  transform: translateY(100%);
  border-radius: 3px;
  overflow: hidden;
  box-shadow: 2px 2px 4px 0 rgba(0,0,0, .2);
` as any;

const DropdownItem = styled.div`
  text-align: center;
  a,
  button {
    display: block;
    width: 100%;
    font-size: 1.4rem;
    display: block;
    padding: 16px 20px;
    white-space: nowrap;
  }
  &:hover {
    background-color: #e0e0e0;
  }
`;

const Button = styled.button``;

class Dropdown extends React.Component<Props & WithNamespaces & RouteComponentProps<any>, State> {
  container: HTMLButtonElement | null = null;
  state: State = {
    isOpen: false
  }
  constructor(props: Props & WithNamespaces & RouteComponentProps<any>) {
    super(props);

    this.saveContainerRef = this.saveContainerRef.bind(this);
    this.onClickOutsideHandler = this.onClickOutsideHandler.bind(this);
  }
  componentDidMount() {
    document.addEventListener('click', this.onClickOutsideHandler);
  }
  componentWillUnmount() {
    document.removeEventListener('click', this.onClickOutsideHandler);
  }
  onClickOutsideHandler = (e: Event) => {
    if (this.container && !this.container.contains(e.target as any) && (e.target as any).id.indexOf('react-select') < 0) {
      this.toggleDropdown(undefined, false);
    }
  }
  toggleDropdown = (_: any, forceValue?: boolean) => {
    this.setState(((prevState: State) => ({
      isOpen: typeof(forceValue) !== 'undefined' ? forceValue : !prevState.isOpen
    })));
  }
  saveContainerRef = (ref: any) => {
    this.container = ref;
  }

  openProfile = () => {
    if (this.props.user) {
      this.props.history.push(`/user/${this.props.user.id}`);
      setTimeout(() => this.toggleDropdown(undefined, false));
    }
  }

  logout = () => {
    this.props.logout();
  }

  render() {
    const { t, user } = this.props;
    return (
      <Container 
        onClick={this.toggleDropdown} 
        ref={this.saveContainerRef}
      >
        <Avatar avatarPath={user!.avatarPath} rounded size={50} />
        <ArrowIcon />
        <DropdownWrapper isOpen={this.state.isOpen}>
          <DropdownItem><Button onClick={this.openProfile}>{t('MENU_DROPDOWN_PROFILE')}</Button></DropdownItem>
          <DropdownItem><Link to="/chats">{t('MENU_DROPDOWN_MESSAGES')}</Link></DropdownItem>
          <DropdownItem><Link to="/wallet">{t('MENU_DROPDOWN_WALLET')}</Link></DropdownItem>
          <DropdownItem><Link to="/user/settings">{t('MENU_DROPDOWN_SETTINGS')}</Link></DropdownItem>
          <DropdownItem><Button onClick={this.logout}>{t('MENU_DROPDOWN_LOGOUT')}</Button></DropdownItem>
        </DropdownWrapper>
      </Container>
    );
  }
}

const mapDispatchToProps = (dispatch: any) => ({
  logout: () => dispatch(authLogout())
});

export default withRouter(connect(null, mapDispatchToProps)(withNamespaces()(Dropdown)));