import React from 'react';
import styled from 'styled-components';
import { withNamespaces, WithNamespaces, Trans } from 'react-i18next';
import User from '../../components/User';
import ReportButton from '../../components/ReportButton';
import UserAds from './UserAds';
import TradeVolumeIcon from '../../components/Icons/TradeVolumeIcon';
import TraderNumberIcon from '../../components/Icons/TraderNumberIcon';
import { connect } from 'react-redux';
import { StoreModel } from '../../models/StoreModel';
import { getCurrentUser, getGuestUser, isOwnPageVisible, isUserLoggedIn } from '../../selectors/user';
import { UserModel } from '../../models/UserModel';
import screens from '../../constants/screens';
import { AD_LIST_GET_REQUESTED, AD_GUEST_LIST_GET_REQUESTED, AD_LIST_GET, AD_GUEST_LIST_GET } from '../../actions/ads';
import { getCurrentUserAds, getGuestUserAds } from '../../selectors/ads';
import { AdModel } from '../../models/AdModel';
import { RouteComponentProps } from 'react-router';
import { USER_GET_REQUESTED, USER_GET } from '../../actions/user';
import { SET_MODAL } from '../../actions/modal';
import modals from '../../constants/modals';
import Container from '../../components/Container';
import { createLoadingSelector } from '../../selectors/loading';
import { createErrorSelector } from '../../selectors/errorResponse';
import { Link } from 'react-router-dom';
import { RED_E0 } from '../../constants/colors';
import CoinPrice from '../../shared/CoinPrice';
import Media from '../../shared/Media';

interface Props {
  user: UserModel | null;
  guestUser: UserModel | null;
  ads: {
    sell: AdModel[] | null;
    buy: AdModel[] | null
  },
  guestAds: {
    sell: AdModel[] | null;
    buy: AdModel[] | null
  },
  getUserAds: () => void;
  openModal: (modal: string) => void;
  getGuestUserAds: (id: number) => void;
  getGuestUser: (id: number) => void;
  isOwnPage: boolean;
  isLoggedIn: boolean;
  isPending: boolean;
  isError: boolean;
}

const LeftContainer = styled.div`
  padding: 40px 30px 20px 30px;
  flex-basis: 40%;
  max-width: 40%;
  background-color: #f0f0f0;
  ${screens.tablet} {
    padding: 30px;
  }
  ${screens.mobile} {
    flex-basis: 100%;
    max-width: 100%;
    background: none;
    padding: 0;
    margin-top: 24px;
  }
`;

const RightContainer = styled.div`
  padding: 27px 66px 71px 44px;
  flex-basis: 60%;
  max-width: 60%;
  ${screens.tablet} {
    padding: 30px;
  }
  ${screens.mobile} {
    flex-basis: 100%;
    max-width: 100%;
    padding: 0;
  }
`;

const StatsWrapper = styled.div`
  ${screens.mobile} {
    display: flex;
    justify-content: center;
    margin-top: 32px;
  }
`;

const ConditionTitle = styled.div`
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 24px;
`;

const ConditionDescription = styled.div`
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 32px;
`;

const EmptyTradeCondition = styled.div`
  background-color: #eee;
  padding: 16px;
  font-size: 1.4rem;
  border-radius: 3px;
  margin-bottom: 32px;
  a {
    color: ${RED_E0};
  }
`;

export class Profile extends React.Component<Props & WithNamespaces & RouteComponentProps<any>> {
  componentDidMount() {
    const openedUserId = parseInt(this.props.match.params.id, 10);
    const { user } = this.props;

    if (!user || (openedUserId !== user.id)) {
      this.props.getGuestUser(openedUserId);
      this.props.getGuestUserAds(openedUserId);
      return;
    }
    
    this.props.getUserAds();
  }
  componentWillReceiveProps(nextProps: Props & WithNamespaces & RouteComponentProps<any>) {
    const openedUserId = parseInt(this.props.match.params.id, 10);

    if (this.props.isLoggedIn && !nextProps.isLoggedIn) {
      this.props.getGuestUser(openedUserId);
      this.props.getGuestUserAds(openedUserId);
    }
  }
  openReportPopup = () => {
    this.props.openModal(modals.REPORT);
  }
  render() {
    const { t, user, guestUser, ads, guestAds, isOwnPage, isLoggedIn } = this.props;
    const visibleUser = isOwnPage ? user : guestUser;
    const visibleAds = isOwnPage ? ads : guestAds;

    if (!visibleUser) { return null; }

    const { tradeVolume, confirmedTrades, feedbacks, /* workingHours, */ tradeCondition } = visibleUser;

    const tradeConditionBlock = tradeCondition ? (
      <div>
        <ConditionTitle>{t('PROFILE_PAGE_TRADE_CONDITION')}</ConditionTitle>
        <ConditionDescription>{tradeCondition}</ConditionDescription>
      </div>
    ) : (
      <EmptyTradeCondition>
        <Trans i18nKey="USER_PAGE_EMPTY_TRADE_CONDITION">
          You don't have trade condition, add it in <Link to="/user/settings">account settings</Link>.
        </Trans>
      </EmptyTradeCondition>
    ); 

    return (
      <CoinPrice>
        {(coinPrice: number) => (
          <Container
            isPending={this.props.isPending}
            isError={this.props.isError}
            splited
          >
            <LeftContainer>
              <User.Profile user={visibleUser} />
              {!isOwnPage && this.props.isLoggedIn && <ReportButton onClick={this.openReportPopup} desktopHidden />}
              <StatsWrapper>
                <User.Stats 
                  title={t('USER_PAGE_TRADE_VOLUME')} 
                  value={`${tradeVolume} BTC`}
                  icon={<TradeVolumeIcon />}
                />
                <User.Stats 
                  title={t('USER_PAGE_NUMBER_OF_CONFIRMED_TRADES')} 
                  value={confirmedTrades}
                  icon={<TraderNumberIcon />}
                />
              </StatsWrapper> 
              <Media.Mobile>
              </Media.Mobile>
              <Media.Desktop>
                <User.Info user={visibleUser} />
                {!isOwnPage && this.props.isLoggedIn && <ReportButton onClick={this.openReportPopup} />}
              </Media.Desktop>
            </LeftContainer>
            <RightContainer>
              {isOwnPage && tradeConditionBlock}
              <UserAds 
                type='buy' 
                user={visibleUser} 
                ads={visibleAds.buy} 
                isOwnPage={isOwnPage} 
                isLoggedIn={isLoggedIn}
                coinPrice={coinPrice} 
              />
              <UserAds
                type='sell'
                user={visibleUser} 
                ads={visibleAds.sell} 
                isOwnPage={isOwnPage} 
                isLoggedIn={isLoggedIn} 
                coinPrice={coinPrice}
              />
              { feedbacks && feedbacks.length !== 0 && <User.Feedbacks feedbacks={feedbacks} /> }
            </RightContainer>
          </Container>
        )}
      </CoinPrice>
    );
  }
}

const mapStateToProps = (state: StoreModel, props: Props & WithNamespaces & RouteComponentProps<any>) => ({
  isOwnPage: isOwnPageVisible(state, props.match.params.id),
  isLoggedIn: isUserLoggedIn(state),
  user: getCurrentUser(state),
  guestUser: getGuestUser(state),
  ads: getCurrentUserAds(state),
  guestAds: getGuestUserAds(state),
  isPending: createLoadingSelector([USER_GET, AD_LIST_GET, AD_GUEST_LIST_GET])(state),
  isError: createErrorSelector([USER_GET, AD_LIST_GET, AD_GUEST_LIST_GET])(state),
});

const mapDispatchToProps = (dispatch: any) => ({
  openModal: (modal: string) => dispatch({
    type: SET_MODAL,
    payload: {
      nextModal: modal
    }
  }),
  getGuestUser: (id: number) => dispatch({
    type: USER_GET_REQUESTED,
    payload: {
      id
    }
  }),
  getUserAds: () => dispatch({
    type: AD_LIST_GET_REQUESTED
  }),
  getGuestUserAds: (id: number) => dispatch({
    type: AD_GUEST_LIST_GET_REQUESTED,
    payload: {
      id
    }
  })
});

export default withNamespaces()(connect(mapStateToProps, mapDispatchToProps)(Profile));