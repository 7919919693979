import { all } from 'redux-saga/effects';
import country from './country';
import auth from './auth';
import user from './user';
import ads from './ads';
import wallet from './wallet';
import chat from './chat';
import transaction from './transaction';

export default function* rootSaga() {
  yield all([
    ...country,
    ...auth,
    ...user,
    ...ads,
    ...wallet,
    ...transaction,
    ...chat
  ]);
}