import React from 'react';
import * as yup from 'yup';
import styled from 'styled-components';
import { withFormik, FormikBag, FormikProps } from 'formik';
import PriceInput from '../../components/PriceInput';
import User from '../../components/User';
import FeeInput from '../../components/FeeInput/FeeInput';
import { Places } from '../../components/Places';
import { showBluredErrors, getMaxSellableBalance } from '../../utils';
import FormElements from '../../shared/FormElements';
import screens from '../../constants/screens';
import UI from '../../shared/UI';
import { NewAdModel } from '../../models/NewAdModel';

interface Props {
  initiatValues: NewAdModel;
  type: string;
  availableBalance: number;
  onSubmit: (values: FormValues) => void;
  onChangeIsValid: (isValid: boolean) => void;
  onChangeValues: (values: FormValues) => void;
  t: any;
}

interface FormValues {
  title: string;
  description: string;
  minimum: string;
  maximum: string;
  fee: string;
  location: string;
  currency: string;
  longitude: number;
  latitude: number;
}

const InputWrapper = styled.div`
  &:not(:last-child) {
    margin-bottom: 36px;
  }
`;

const InputTitle = styled.div`
  font-size: 1.6rem;
  font-weight: 500;
  margin-bottom: 16px;
`;

const Note = styled.div`
  display: inline-block;
  vertical-align: middle;
  width: calc(100% - 440px);
  padding-left: 28px;
  font-size: 1.2rem;
  font-style: italic;
  font-weight: 300;
  margin-top: -2px;
  ${screens.mobile} {
    display: block;
    width: 100%;
    margin-top: 8px;
    padding-left: 0;
  }
`;

const StyledUserBalance = styled(User.Balance)`
  margin-left: 40px;
  ${screens.mobile} {
    display: block;
    margin-left: 0;
    margin-top: 8px;
  }
` as any;

class CreateTradeForm extends React.Component<Props & FormikProps<FormValues>> {
  componentWillReceiveProps(nextProps: Props & FormikProps<FormValues>) {
    this.props.onChangeValues(nextProps.values);
    if (this.props.isValid !== nextProps.isValid) {
      this.props.onChangeIsValid(nextProps.isValid);
    }
  }
  render() {
    const { availableBalance, t, errors, touched, setFieldValue, setFieldTouched } = this.props;
    return (
      <UI.Form errors={showBluredErrors(errors, touched)}>
        <InputWrapper>
          <InputTitle>{t('TITLE')}</InputTitle>
          <FormElements.Input
            name="title"
          />
          <Note>{t('TRADE_PAGE_CREATE_NOTE')}</Note>
        </InputWrapper>
        <InputWrapper>
          <InputTitle>{t('TRADE_PAGE_AD_CONTENT')}</InputTitle>
          <FormElements.Textarea 
            name="description"
            onChange={setFieldValue}
            onBlur={setFieldTouched}
          />
        </InputWrapper>
        <InputWrapper>
          <InputTitle>{t('TRADE_PAGE_SET_YOUR_MIN_LIMIT')}</InputTitle>
          <PriceInput 
            name="minimum"
            onChange={setFieldValue}
          />
        </InputWrapper>
        <InputWrapper>
          <InputTitle>{t('TRADE_PAGE_SET_YOUR_MAX_LIMIT')}</InputTitle>
          <PriceInput 
            name="maximum"
            onChange={setFieldValue}
          />
          <StyledUserBalance balance={availableBalance} />
        </InputWrapper>
        <InputWrapper>
          <InputTitle>{t('TRADE_PAGE_SET_YOUR_MARGIN')}</InputTitle>
          <FeeInput 
            name="fee"
          />
        </InputWrapper>
        <InputWrapper>
          <InputTitle>{t('TRADE_PAGE_SELECT_YOUR_LOCATION')}</InputTitle>
          <Places 
            onChange={setFieldValue}
            onBlur={setFieldTouched}
          />
        </InputWrapper>
      </UI.Form>
    );
  }
}

export default withFormik<any, FormValues>({
  mapPropsToValues: (props: Props & FormikProps<FormValues>) => props.initialValues,
  handleSubmit: (values: FormValues, bag: FormikBag<any, any>) => {
    bag.props.onSubmit(values);
  },
  isInitialValid: false,
  validationSchema: (props: Props & FormikProps<FormValues>) => {
    if (props.type === 'sell') {
      return yup.object().shape({
        title: yup.string()
          .required(props.t('AD_TITLE_NO_EMPTY')),
        description: yup.string()
          .max(1000, props.t('AD_DESCRIPTION_MAX_LENGTH')),
        minimum: yup.number()
          .moreThan(0, props.t('AD_MINIMUM_POSITIVE'))
          .max(getMaxSellableBalance(props.availableBalance), props.t('AD_MINIMUM_LESS_THAN_BALANCE_WITH_FEE')),
        maximum: yup.number()
          .moreThan(0, props.t('AD_MAXIMUM_POSITIVE'))
          .max(getMaxSellableBalance(props.availableBalance), props.t('AD_MAXIMUM_LESS_THAN_BALANCE_FEE')),
        fee: yup.number()
          .required(props.t('AD_MARGIN_NO_EMPTY'))
          .min(-100, props.t('AD_MARGIN_SHOULD_BE_MORE_THAN_100')),
        location: yup.string()
          .required(props.t('AD_COUNTRY_NO_EMPTY')),
      }); 
    }

    return yup.object().shape({
      title: yup.string()
        .required(props.t('AD_TITLE_NO_EMPTY')),
      description: yup.string()
        .max(150, props.t('AD_DESCRIPTION_MAX_LENGTH')),
      minimum: yup.number()
        .moreThan(0, props.t('AD_MINIMUM_POSITIVE')),
      maximum: yup.number()
        .moreThan(0, props.t('AD_MAXIMUM_POSITIVE')),
      fee: yup.number()
        .required(props.t('AD_MARGIN_NO_EMPTY'))
        .min(-100, props.t('AD_MARGIN_SHOULD_BE_MORE_THAN_100')),
      location: yup.string()
        .required(props.t('AD_COUNTRY_NO_EMPTY')),
    });
  }
})(CreateTradeForm);