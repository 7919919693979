import styled from 'styled-components';

interface Props {
  columnWidth: number,
  columnsCount: number,
  columnsGap: number
}

const Column = styled.div`
  height: auto;
  padding: ${({ columnsGap }: Props) => `${columnsGap}px ${columnsGap / 2}px 0`};
  flex-basis: ${({columnWidth, columnsCount}: Props) => (columnWidth / columnsCount) * 100}%;
  max-width: 100%;
  flex-grow: 1;
`;

export default Column;