import React from 'react';
import uuidv1 from 'uuid/v1';
import { Form } from 'formik';
import UI from '.';

interface Props {
  className?: string;
  children: React.ReactNode;
  onSubmit?: (e: React.SyntheticEvent<HTMLFormElement>) => void;
  errors: {
    [field: string]: string | undefined;
  }
}
class FormElement extends React.Component<Props> {

  isErrorsExist = (errors: {[field: string]: string | undefined}) => !!errors && !!Object.keys(errors).length;

  flatErrors = (errors: {[field: string]: string | undefined}) => {
    let errorsArr: string[] = [];

    for (let f in errors) {
      const field = errors[f];
      
      if (!field) { continue; }

      errorsArr.push(field);
    }

    return errorsArr;
  }

  render() {
    const { children, errors, className } = this.props;
    return (
      <>
        {
          this.isErrorsExist(errors) && (
            <UI.Error>
              {
                this.flatErrors(errors).map((err: string) => (
                  <li key={uuidv1()}>{err}</li>
                ))
              }
            </UI.Error>
          )
        }
        <Form className={className} noValidate>
          {children}
        </Form>
      </>
    )
  }
}

export default FormElement;