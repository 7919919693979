import { put, takeEvery } from "redux-saga/effects";
import { CHAT_GET_HISTORY, CHAT_GET_OPEN_CHATS_SUCCESS, CHAT_GET_OPEN_CHATS_REQUESTED, CHAT_CREATE_NEW_CHAT_SUCCESS, CHAT_CREATE_RESET, CHAT_CREATE_NEW_CHAT_REQUESTED, CHAT_GET_CHAT_REQUESTED, CHAT_GET_CHAT_SUCCESS, CHAT_CREATE_NEW_CHAT_FAILED, CHAT_GET_CHAT_FAILED, CHAT_GET_OPEN_CHATS_FAILED } from "../actions/chat";
import { API_URL } from "../constants/config";
import { getAccessTokenHeader } from "../utils";
import { ActionModel } from "../models/ActionModel";

function* getOpenChats() {
  try {
    const res = yield fetch(`${API_URL}/chat?isOpen=true`, {
      headers: {
        Authorization: getAccessTokenHeader(),
        "Content-Type": "application/json; charset=utf-8"
      },
    });

    const resJson = yield res.json();

    if (!res.ok) { throw resJson.message; }

    yield put({ type: CHAT_GET_OPEN_CHATS_SUCCESS, payload: { chats: resJson }});
  } catch(err) {
    yield put({ type: CHAT_GET_OPEN_CHATS_FAILED });
    return err;
  }
}

function* getChatHistory(action: ActionModel) {
  try {
    const res = yield fetch(`${API_URL}/chat/messages?id=${action.payload.id}`, {
      headers: {
        Authorization: getAccessTokenHeader(),
        "Content-Type": "application/json; charset=utf-8"
      }
    });

    const resJson = yield res.json();

    if (!res.ok) { throw resJson.message; }

    yield put({ type: CHAT_GET_HISTORY, payload: { chatHistory: resJson} } );
  } catch(err) {
    return err;
  }
}

function* getChat(action: ActionModel) {
  try {
    const res = yield fetch(`${API_URL}/chat/${action.payload.id}`, {
      headers: {
        Authorization: getAccessTokenHeader(),
        "Content-Type": "application/json; charset=utf-8"
      }
    });

    const resJson = yield res.json();

    if (!res.ok) { throw resJson.message; }

    yield put({ type: CHAT_GET_CHAT_SUCCESS, payload: { chat: resJson} } );
  } catch(err) {
    yield put({ type: CHAT_GET_CHAT_FAILED });
    return err;
  }
}

function* createNewChat(action: ActionModel) {
  try {
    const res = yield fetch(`${API_URL}/chat`, {
      method: 'POST',
      headers: {
        Authorization: getAccessTokenHeader(),
        "Content-Type": "application/json; charset=utf-8"
      },
      body: JSON.stringify(action.payload)
    });

    const resJson = yield res.json();

    if (!res.ok) { throw {
      status: resJson.message,
      message: resJson.message
    }}

    yield put({ type: CHAT_CREATE_NEW_CHAT_SUCCESS, payload: { chat: resJson } });
    yield put({ type: CHAT_CREATE_RESET });
  } catch(err) {
    yield put({ type: CHAT_CREATE_NEW_CHAT_FAILED });
    return err;
  }
}

const chat = [
  takeEvery(CHAT_GET_CHAT_REQUESTED, getChat),
  takeEvery(`${CHAT_GET_HISTORY}_REQUESTED`, getChatHistory),
  takeEvery(CHAT_GET_OPEN_CHATS_REQUESTED, getOpenChats),
  takeEvery(CHAT_CREATE_NEW_CHAT_REQUESTED, createNewChat)
];

export default chat;