import React from 'react';
import styled from 'styled-components';
import { withNamespaces, WithNamespaces } from 'react-i18next';
import CheckboxIcon from '../Icons/CheckboxIcon';
import { RED_E0, GREY_7F } from '../../constants/colors';
import screens from '../../constants/screens';

interface Props {
  type?: number;
  onTypeSelect: (e: React.SyntheticEvent<HTMLInputElement>) => void;
}

const Title = styled.div`
  font-size: 2.2rem;
  font-weight: 700;
  color: #000;
  line-height: 2.6rem;
  margin-bottom: 1rem;
  ${screens.mobile} {
    margin-bottom: .6rem;
  }
`;

const CheckboxItem = styled.label`
  display: inline-block;
  cursor: pointer;
  #checkbox-checkmark {
    display: none;
  }
  &:not(:last-child) {
    margin-right: 2.4rem;
  }
`;

const CheckboxTitle = styled.span`
  font-size: 1.7rem;
  font-weight: 400;
  margin-right: 1rem;
`;

const Checkbox = styled.input`
  display: none;
  & + svg {
    fill: ${GREY_7F};
  }
  &:checked {
    & + svg {
      fill: ${RED_E0};
      #checkbox-checkmark {
        display: block;
      }
    }
    
  }
`;

class SearchCheckbox extends React.Component<Props & WithNamespaces> {
  render() {
    const { t, onTypeSelect } = this.props;
    return (
      <>
        <Title>{t('STARTPAGE_SEARCH_BLOCK_I_WANT_LABEL')}</Title>
        <CheckboxItem>
          <CheckboxTitle>{t('STARTPAGE_SEARCH_BLOCK_I_WANT_TO_SELL_LABEL')}</CheckboxTitle>
          <Checkbox onChange={onTypeSelect} type="radio" name="searchType" value="2" />
            <CheckboxIcon />
        </CheckboxItem>
        <CheckboxItem>
          <CheckboxTitle>{t('STARTPAGE_SEARCH_BLOCK_I_WANT_TO_BUY_LABEL')}</CheckboxTitle>
          <Checkbox onChange={onTypeSelect} type="radio" name="searchType" value="1" />
          <CheckboxIcon />
        </CheckboxItem>
      </>
    )
  }
}

export default withNamespaces()(SearchCheckbox);