import React from 'react';
import styled from 'styled-components'
import { BLUE_17, RED_E0 } from '../../constants/colors';
import { Field } from 'formik';

interface Props {
  name: string;
  children: React.ReactNode;
}

export const Label = styled.label`
  display: inline-block;
  font-size: 16px;
  line-height: 29px;
  font-weight: 400;
  color: #000;
  &:not(:first-child) {
    margin-top: 14px;
  }
  cursor: pointer;
`;


export const Input = styled.input`
  display: none;
  &:checked + span {
    &:after {
      display: block;
    }
  }
`;

export const Title = styled.span`
  position: relative;
  display: inline-block;
  font-size: 16px;
  font-weight: 300;
  span {
    display: inline;
    vertical-align: middle;
  }
  a {
    color: ${RED_E0}
  }
  &:before {
    display: inline-block;
    vertical-align: middle;
    content: '';
    width: 16px;
    height: 16px;
    border-radius: 2px;
    border: 1px solid ${BLUE_17};
    margin-right: 16px;
  }
  &:after {
    position: absolute;
    display: none;
    content: '';
    width: 5px;
    height: 9px;
    left: 6px;
    top: 11px;
    border-bottom: 1px solid #172b42;
    border-right: 1px solid #172b42;
    transform: rotate(45deg);
  }
`;

class Checkbox extends React.Component<Props> {
  render() {
    const { name, children } = this.props;
    return (
      <Field name={name} >
        {({ field, form }: any) => (
          <Label>
            <Input 
              type="checkbox" 
              checked={field.value === 'checked'}
              onChange={() => {
                form.setFieldValue(name, form.values[name].length ? '' : 'checked');
              }} 
            />
            <Title>
              <span>{children}</span>
            </Title>
          </Label>
        )}       
      </Field>
    )
  }
}

export default Checkbox;