import React from 'react';
import styled from 'styled-components';
import screens from '../../constants/screens';

interface Props {
  activeStep: number;
  steps: string[];
}

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 60px;
  ${screens.mobile} {
    display: none;
  }
`;

const Steps = styled.ul`
  display: flex;
  align-items: center;
  counter-reset: steps;
`;

const Step = styled.li`
  position: relative;
  display: flex;
  align-items: center;
  counter-increment: steps;
  div {
    border-color: ${({active, done}: any) => done ? '#2bbc70' : active ? '#2bbc70' : '#000'};
    background-color: ${({done}: any) => done ? '#2bbc70' : '#fff'};
    &:after {
      color: ${({active, done}: any) => done ? '#fff' : active ? '#2bbc70' : '#000'};
    }
  }
  &:not(:last-child) {
    &:after {
      display: block;
      content: '';
      width: 35px;
      height: 1px;
      background-color: #000;
      margin: 0 18px;
    }
  }
` as any;

const StepCircle = styled.div`
  position: relative;
  width: 35px;
  height: 35px;
  border-radius: 50%;
  border: 1px solid;
  font-size: 14px;
  &:after {
    display: block;
    position: absolute;
    content: counter(steps);
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
`;

const StepTitle = styled.span`
  display: inline-block;
  margin-left: 15px;
  font-size: 14px;
`;
class CreatingTradeSteps extends React.Component<Props> {
  render() {
    const { activeStep, steps } = this.props;
    return (
      <Container>
        <Steps>
          {
            steps.map((step: string, index: number) => (
              <Step key={index} active={index === activeStep} done={index < activeStep}>
                <StepCircle />
                <StepTitle>{step}</StepTitle>
              </Step>
            ))
          }
        </Steps>
      </Container>
    );
  }
}

export default CreatingTradeSteps;