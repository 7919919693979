import React from 'react';
import styled from 'styled-components';
import { StyledPlaces } from '../../components/Places';
import { withNamespaces, WithNamespaces } from 'react-i18next';
import Button from '../../components/Button';
import { connect } from 'react-redux';
import { StoreModel } from '../../models/StoreModel';
import { AD_SEARCH } from '../../actions/ads';
import { createLoadingSelector } from '../../selectors/loading';

interface Props {
  isRequestPending: boolean;
  onTitleChange: (title: string) => void;
  onPlaceSelect: (place: any) => void;
  onPlaceChange: (address: string) => void;
  onFormSubmit: (e: React.SyntheticEvent<HTMLFormElement>) => void;
}

const Form = styled.form`
  margin-bottom: 42px;
`;

const Input = styled.input`
  display: block;
  width: 100%;
  padding: 13px 0;
  color: #7f7f7f;
  font-size: 17px;
  line-height: 20px;
  border-bottom: 1px solid #bbbcc3;
  margin-bottom: 26px;
`;

const ActionButtonsContainer = styled.div`
  margin-top: 12px;
`;

const SearchButton = styled(Button)`
  font-size: 19px;
  background-color: #f00;
  border-radius: 6px;
  height: 40px;
  color: #fff;
  font-weight: 300;
  text-transform: uppercase;
  box-shadow: 0 0 24px 0 rgba(0,0,0,.1);
`;

class SearchTradeForm extends React.Component<Props & WithNamespaces> {
  onTitleChange = (e: React.SyntheticEvent<HTMLInputElement>) => {
    this.props.onTitleChange((e.target as any).value);
  }
  render() {
    const { onPlaceSelect, onPlaceChange, onFormSubmit, t } = this.props;
    return (
      <Form onSubmit={onFormSubmit}>
        <Input 
          onChange={this.onTitleChange}
          placeholder={t('SEARCH_PAGE_SEARCH_PLACEHOLDER')}
        />
        <StyledPlaces onSelect={onPlaceSelect} onChange={onPlaceChange} />
        <ActionButtonsContainer>
          <SearchButton isPending={this.props.isRequestPending} type="submit">{t('SEARCH_PAGE_UPDATE')}</SearchButton>
        </ActionButtonsContainer>
      </Form>
    );
  }
}

const mapStateToProps = (state: StoreModel) => ({
  isRequestPending: createLoadingSelector([AD_SEARCH])(state)
});

export default connect(mapStateToProps)(withNamespaces()(SearchTradeForm));