import React from 'react';
import { withNamespaces, WithNamespaces } from 'react-i18next';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { StoreModel } from '../../models/StoreModel';
import { isUserLoggedIn } from '../../selectors/user';

interface Props {
  isUserLoggedIn: boolean;
  className?: string;
}

class Navigation extends React.Component<Props & WithNamespaces> {
  render() {
    const { t, className } = this.props;
    return (
      <ul className={className}>
        { this.props.isUserLoggedIn && (
          <li>
            <Link to="/trade/create">
              {t('HEADER_MENU_POST_OFFER')}
            </Link>
          </li>
        )}
        <li>
          <Link to="/trade/search?type=2">
            {t('HEADER_MENU_SELL_CRYPTO_LABEL')}
          </Link>
        </li>
        <li>
          <Link to="/trade/search?type=1">
            {t('HEADER_MENU_BUY_CRYPTO_LABEL')}
          </Link>
        </li>
        {/* <li>
          <Link to="#">
            {t('HEADER_MENU_COMMUNIY_LABEL')}
          </Link>
        </li> */}
      </ul>
    )

  }
}

const mapStateToProps = (state: StoreModel) => ({
  isUserLoggedIn: isUserLoggedIn(state)
});

export default withNamespaces()(connect(mapStateToProps)(Navigation));