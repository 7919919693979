import React from 'react';
import styled from 'styled-components';
import CreatingTradeSteps from './CreatingTradeSteps';
import CreateTradeFooter from './CreateTradeFooter';
import CreateTradeType from './CreateTradeType';
import { RouteComponentProps } from 'react-router-dom';
import CreateTradeForm from './CreateTradeForm';
import CreateTradeOverview from './CreateTradeOverview';
import { StoreModel } from '../../models/StoreModel';
import { connect } from 'react-redux';
import { getUserAvailableBalance } from '../../selectors/wallet';
import { WALLET_GET_BALANCE } from '../../actions/wallet';
import { NewAdModel } from '../../models/NewAdModel';
import { AD_CREATE_NEW_REQUESTED } from '../../actions/ads';
import { getOpenedAd } from '../../selectors/ads';
import { AdModel } from '../../models/AdModel';
import { withNamespaces, WithNamespaces } from 'react-i18next';
import screens from '../../constants/screens';

const ad: NewAdModel = {
  title: '',
  description: '',
  minimum: '',
  maximum: '',
  fee: '',
  location: '',
  currency: 'BTC',
  longitude: 0,
  latitude: 0,
};


interface Props {
  availableBalance: number | null;
  getUserBalance: () => void;
  createNewAd: (adType: number, newAd: NewAdModel) => void;
  openedAd: AdModel | null;
  isNewTradeValid: boolean;
}
interface State {
  activeStep: number;
  isFormValid: boolean;
  tradeType: string | null;
}

const Container = styled.div`
  margin: 0 20px 50px;
  background-color: #fff;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 992px;
  overflow: hidden;
  margin: 0 auto;
  box-shadow: 0 0 24px 0 rgba(0,0,0,.1);
`;

const Content = styled.div`
  padding: 95px 50px 55px;
  ${screens.mobile} {
    padding: 35px 25px;
  }
`;

class CreateTrade extends React.Component<Props & WithNamespaces & RouteComponentProps<any>, State> {
  ad: NewAdModel = {...ad};

  initialState: State = {
    activeStep: 0,
    tradeType: null,
    isFormValid: false,
  };

  state: State = {
    ...this.initialState
  }

  componentDidUpdate() {
    if (this.state.activeStep === 0) {
      this.ad = {...ad};
    }

    if (this.props.openedAd) {
      this.props.history.push(`/trade/${this.props.openedAd.id}`);
    }
  }

  componentDidMount() {
    const type = this.props.match.params.type;

    if (!!type) {
      this.setState({
        activeStep: 1,
        tradeType: type
      });
    }

    this.props.getUserBalance();
  }

  resetState = () => {
    this.setState({
      ...this.initialState
    });
  }

  decrementStep = () => {
    this.setState((prevState: State) => {
      const nextStep = prevState.activeStep - 1;
      let tradeType = prevState.tradeType;
      let isFormValid = prevState.isFormValid;

      if (nextStep === 0) {
        tradeType = null;
        isFormValid = false;
        this.props.history.push(`/trade/create`);
      }

      return {
        tradeType,
        isFormValid: isFormValid,
        activeStep: nextStep
      }
    });
  }
  incrementStep = () => {
    if (!this.state.tradeType) { return; }
    this.setState((prevState: State) => {
      const { activeStep, tradeType } = this.state;
      const nextStep = prevState.activeStep + 1;

      if (!!tradeType) {
        if (activeStep === 0) {
          this.props.history.push(`/trade/create/${tradeType}`);
        }

        return {
          activeStep: nextStep
        }
      } else {
        return null;
      }
    });
  }
  onTypeSelect = (type: string) => {
    this.setState({
      tradeType: type
    });
  }
  getTradeTypeId = (tradeType: string | null) => {
    return tradeType === 'sell' ? 1 : 2;
  }
  onChangeValues = (values: NewAdModel) => {
    this.ad = values;
  }
  onChangeIsValid = (isValid: boolean) => {
    this.setState({
      isFormValid: isValid
    });
  }
  onFinish = () => {
    this.props.createNewAd(this.getTradeTypeId(this.state.tradeType), this.ad);
  }
  isLastStep = (activeStep: number, stepsAmount: number) => {
    return activeStep === stepsAmount - 1;
  }
  render() {
    const { availableBalance, t } = this.props;
    const steps = [
      t('TRADE_PAGE_AD_TYPE_SHORT'),
      t('TRADE_PAGE_YOUR_OFFER'),
      t('TRADE_PAGE_FINISH')
    ];
    const stepsAmount = steps.length;
    const type = this.props.match.params.type;
    const { activeStep, tradeType } = this.state;

    return (
      <Container>
        <Wrapper>
          <Content>
            <CreatingTradeSteps
              activeStep={activeStep}
              steps={steps}
            />
            {!type && <CreateTradeType onChange={this.onTypeSelect} />}
            {type === 'buy' && this.props.availableBalance !== null && !this.isLastStep(activeStep, stepsAmount) && 
              <CreateTradeForm
                initialValues={this.ad}
                type={type} 
                availableBalance={availableBalance as number}
                onSubmit={this.onFinish}
                onChangeIsValid={this.onChangeIsValid}
                onChangeValues={this.onChangeValues}
                t={t}
              />
            }
            {type === 'sell' && this.props.availableBalance !== null && !this.isLastStep(activeStep, stepsAmount) && 
              <CreateTradeForm
                initialValues={this.ad}
                type={type} 
                availableBalance={availableBalance as number} 
                onSubmit={this.onFinish}
                onChangeIsValid={this.onChangeIsValid}
                onChangeValues={this.onChangeValues}
                t={t}
              />
            }
            {!!type && this.isLastStep(activeStep, stepsAmount) && 
              <CreateTradeOverview 
                ad={this.ad}
                type={type}
              />
            }
          </Content>
          <CreateTradeFooter
            activeStep={activeStep}
            activeType={tradeType}
            stepsAmount={stepsAmount}
            onBackClick={this.decrementStep}
            onNextClick={this.incrementStep}
            onFinish={this.onFinish}
            isNextButtonDisabled={!this.state.isFormValid && activeStep === 1}
          />
        </Wrapper>
      </Container>
    );
  }
}

const mapStateToProps = (state: StoreModel) => ({
  availableBalance: getUserAvailableBalance(state),
  openedAd: getOpenedAd(state)
});

const mapDispatchToProps = (dispatch: any) => ({
  getUserBalance: (currency?: string) => {
    dispatch({
      type: `${WALLET_GET_BALANCE}_REQUESTED`,
      payload: {
        currency
      }
    });
  },
  createNewAd: (adType: number, newAd: NewAdModel) => {
    dispatch({
      type: AD_CREATE_NEW_REQUESTED,
      payload: {
        adType,
        ad: {
          ...newAd
        }
      }
    })
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(withNamespaces()(CreateTrade));