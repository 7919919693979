import React from 'react';
import * as yup from 'yup';
import styled from 'styled-components/macro';
import { withNamespaces, WithNamespaces } from 'react-i18next';
import Container from '../../components/Container';
import screens from '../../constants/screens';
import Button from '../../components/Button';
import { connect } from 'react-redux';
import { StoreModel } from '../../models/StoreModel';
import { WALLET_WITHDRAWAL, WALLET_WITHDRAWAL_REQUESTED, WALLET_GET_BALANCE_REQUESTED, WALLET_GET_BALANCE } from '../../actions/wallet';
import { createLoadingSelector } from '../../selectors/loading';
import { createSuccessSelector } from '../../selectors/successResponse';
import { withRouter, RouteComponentProps } from 'react-router';
import UI from '../../shared/UI';
import { getUserTotalBalance, getUserAvailableBalance } from '../../selectors/wallet';
import { showBluredErrors } from '../../utils';
import { FormikProps, Formik } from 'formik';
import FormElements from '../../shared/FormElements';
import User from '../../components/User';

interface Props {
  totalBalance: number | null;
  availableBalance: number | null;
  isWithdrawalSuccess: boolean;
  isWithdrawalPending: boolean;
  isBalanceRequestPending: boolean;
  withdrawal: (address: string, amount: string) => void;
  getUserBalance: () => void;
}

interface FormValues {
  address: string;
  amount: string;
}

const Code = styled(FormElements.Input)`
  font-size: 1.8rem;
  font-weight: 600;
  height: 35px;
  padding: 0 8px;
  border: 2px solid #000;
  border-radius: 6px;
  margin-left: 20px;
  width: 400px;
  appearance: none;
  ${screens.mobile} {
    margin-left: 0;
    margin-top: 16px;
    width: 100%;
  }
`;

const Table = styled.div`
  display: table;
  text-align: left;
  table-layout: separate;
  border-spacing: 0 20px;
  ${screens.mobile} {
    display: block;
  }
`;

const Row = styled.div`
  display: table-row;
  &:not(:last-child) {
    margin-bottom: 25px;
  }
  ${screens.mobile} {
    display: block;
  }
`;

const Cell = styled.div`
  display: table-cell;
  ${screens.mobile} {
    display: block;
  }
`;

const RowTitle = styled(Cell)`
  font-size: 1.8rem;
  font-weight: 600;
  color: #cf0022;
`;

const WithdrawalButton = styled(Button)`
  height: 35px;
  font-size: 1.4rem;
  font-weight: 700;
  color: #fff;
  background-color: #cf0022;
  border-radius: 6px;
  margin-left: auto;
  margin-top: 60px;
  margin-bottom: 24px;
  &[disabled] {
    background-color: #919191;
  }
`;

const StyledUserBalance = styled(User.Balance)`
  margin-bottom: 16px;
`;

export class WithdrawalForm extends React.Component<Props & WithNamespaces & RouteComponentProps<any>> {
  withdrawalFormSchema: any = null;
  componentDidMount() {
    this.props.getUserBalance();
    
    this.props.availableBalance !== null && this.initValidation();
  }
  componentDidUpdate() {
    if (this.props.isWithdrawalSuccess) {
      this.props.history.push('/wallet/withdrawal/success');
    }
    this.props.availableBalance !== null && this.initValidation();
  }
  initValidation = () => {
    this.withdrawalFormSchema = yup.object().shape({
      address: yup.string()
        .required(this.props.t('WD_EMPTY_ADDRESS')),
      amount: yup.number()
        .required(this.props.t('WD_AMOUNT_NO_EMPTY'))
        .lessThan(this.props.availableBalance as number, this.props.t('WD_AMOUNT_MORE_THAN_BALANCE'))
        .moreThan(0, this.props.t('WD_AMOUNT_MORE_THAN_ZERO'))
    });
  }

  onSubmit = (values: FormValues) => {
    this.props.withdrawal(values.address, values.amount);
  }

  render() {
    const { t, isWithdrawalPending, availableBalance, isBalanceRequestPending } = this.props;

    return (
      <Container
        isPending={isBalanceRequestPending}
        footerConfig={{
          prevButton: {
            link: '/wallet',
            arrowRotate: true,
            title: t('BACK_TO_WALLET_OVERVIEW')
          }
        }}
        title={t('BITCOIN_WITHDRAWAL')}
      >
      <StyledUserBalance balance={availableBalance} />
      <Formik 
        initialValues={{
          address: '',
          amount: ''
        }}
        validationSchema={this.withdrawalFormSchema}
        isInitialValid={false}
        onSubmit={this.onSubmit}
        render={({ errors, touched, isValid }: FormikProps<FormValues>) => (
          <UI.Form errors={showBluredErrors(errors, touched)}>
            <Table>
              <Row>
                <RowTitle>{t('WITHDRAWAL_ADDRESS')}</RowTitle>
                <Cell>
                  <Code 
                    name="address"
                    type="text"
                    autoCapitalize="off"
                    autoComplete="off"
                  />
                </Cell>
              </Row>
              <Row>
                <RowTitle>{t('WITHDRAWAL_BITCOIN_AMOUNT')}</RowTitle>
                <Cell>
                  <Code name="amount" type="number" />
                </Cell>
              </Row>
            </Table>
            <WithdrawalButton 
              type="submit"
              isPending={isWithdrawalPending}
              disabled={!isValid}
            >
              {t('WITHDRAWAL_BITCOIN')}
            </WithdrawalButton>
          </UI.Form>
          )}
        />
      </Container>
    )
  }
}

const mapStateToProps = (state: StoreModel) => ({
  totalBalance: getUserTotalBalance(state),
  availableBalance: getUserAvailableBalance(state),
  isBalanceRequestPending: createLoadingSelector([WALLET_GET_BALANCE])(state),
  isWithdrawalSuccess: createSuccessSelector(WALLET_WITHDRAWAL)(state),
  isWithdrawalPending: createLoadingSelector([WALLET_WITHDRAWAL])(state)
});

const mapDispatchToProps = (dispatch: any) => ({
  getUserBalance: () => dispatch({
    type: WALLET_GET_BALANCE_REQUESTED,
    payload: {}
  }),
  withdrawal: (address: string, amount: string) => dispatch({
    type: WALLET_WITHDRAWAL_REQUESTED,
    payload: {
      currency: 'BTC',
      address,
      amount: parseFloat(amount)
    }
  })
});

export default connect(mapStateToProps, mapDispatchToProps)(withNamespaces()(withRouter(WithdrawalForm)));