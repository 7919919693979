import React from 'react';
import { Route, Redirect, RouteProps } from 'react-router-dom';
import { StoreModel } from '../../models/StoreModel';
import { isUserLoggedIn } from '../../selectors/user';
import { connect } from 'react-redux';

interface Props {
  isLoggedIn: boolean;
}

class PrivateRoute extends React.Component<Props & RouteProps> {
  render() {
    const { isLoggedIn, component, ...rest } = this.props;

    if (isLoggedIn) {
      return (
        <Route {...rest} component={component} />
      )
    }

    return (<Redirect to="/" />);
  }
}

const mapStateToProps = (state: StoreModel) => ({
  isLoggedIn: isUserLoggedIn(state)
});

export default connect(mapStateToProps)(PrivateRoute);