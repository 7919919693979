import React from 'react';
import styled from 'styled-components';
import Select from 'react-select';
import { withNamespaces, WithNamespaces, withI18n } from 'react-i18next';
import { connect } from 'react-redux';
import { StoreModel } from '../../models/StoreModel';
import { getCurrentUserLang } from '../../selectors/user';
import { USER_UPDATE_REQUESTED } from '../../actions/user';

interface Language {
  label: string;
  value: string;
}

interface Props {
  userLang: string;
  updateUserLang: (lang: string) => void;
}

const Title = styled.div`
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 8px;
`;

class EditGlobalLanguage extends React.Component<Props & WithNamespaces> {
  languages: Language[] = [
    { value: 'en', label: 'English' },
    { value: 'de', label: 'German' }
  ];
  onLanguageChange = ({ value }: any) => {
    this.props.updateUserLang(value);
    this.props.i18n.changeLanguage(value);
  }
  render() {
    const { t } = this.props;
    const styles = {
      valueContainer: (base: any) => {
        return {
          ...base,
          padding: 0,
          ':focus': {
            outline: 'none'
          }
        }
      },
      indicatorSeparator: (base: any) => {
        return {
          display: 'none'
        }
      },
      input: (base: any) => {
        return {
          ...base,
          fontSize: 16,
          color: '#fff'
        }
      },
      control: (base: any) => {
        return {
          ...base,
          background: '#fff',
          border: '1px solid #000',
          borderRadius: '6px',
          // minWidth: '72px',
          padding: '0 10px',
          boxShadow: 'none',
          height: 24,
          minHeight: 24,
          '&:hover': {
            border: '1px solid #000'
          }
        }
      },
      singleValue: (base: any) => {
        return {
          ...base,
          position: 'static',
          transform: 'translateY(-1px)',
          color: '#000',
          fontWeight: 500,
          fontSize: 14
        }
      },
      option: (base: any) => {
        return {
          ...base,
          background: 'none',
          fontSize: 14,
          color: '#000',
          '&:hover': {
            backgroundColor: '#f3f7fa'
          }
        }
      },
      dropdownIndicator: (base: any) => {
        return {
          ...base,
          color: 'red',
          padding: 0,
          border: '5px solid transparent',
          borderTop: '5px solid #000',
          transform: 'translateY(2px)',
          'svg': {
            display: 'none'
          }
        }
      }
    };
    const options = this.languages;
    const selectedOption = options.find((lang: Language) => lang.value === this.props.userLang);
    return (
      <div>
        <Title>{t('WEBSITE_LANGUAGE')}</Title>
        <div>
          <Select
            options={options}
            value={selectedOption}
            styles={styles}
            isSearchable={false}
            onChange={this.onLanguageChange}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: StoreModel) => ({
  userLang: getCurrentUserLang(state),
});

const mapDispatchToProps = (dispatch: any) => ({
  updateUserLang: (lang: string) => dispatch({
    type: USER_UPDATE_REQUESTED,
    payload: {
      currentLanguage: lang
    }
  })
});

export default connect(mapStateToProps, mapDispatchToProps)(withI18n()(withNamespaces()(EditGlobalLanguage)));