import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import CircleWithArrow from '../../components/Icons/CircleWithArrow';

interface Props {
  title: string;
  link: string;
  arrowRotate?: boolean;
}

const Button = styled(Link)`
  display: flex;
  align-items: center;
  font-size: 1.8rem;
  font-weight: 500;
`;

class CircleButton extends React.Component<Props> {
  render() {
    const { title, link, arrowRotate } = this.props;
    return (
      <Button to={link}>
        <CircleWithArrow arrowRotate={arrowRotate} />
        <span>{title}</span>
      </Button>
    )
  }
}

export default CircleButton;