import React from 'react';
import styled from 'styled-components';
import { withNamespaces, WithNamespaces } from 'react-i18next';
import screens from '../../constants/screens';

interface Props {
  onChange: (type: string) => void;
}

const Container = styled.div`
`;

const Title = styled.div`
  font-size: 2.2rem;
  font-weight: 700;
  margin-bottom: 30px;
`;

const RadioWrapper = styled.div`
`;

const RadioItem = styled.label`
  display: inline-block;
  &:not(:first-child) {
    margin-left: 90px;
  }
  ${screens.mobile} {
    display: block;
    &:not(:first-child) {
      margin-left: 0;
      margin-top: 20px;
    }
  }
`;

const RadioInput = styled.input`
  display: none;
  & ~ span {
    position: relative;
    display: flex;
    align-items: center;
    font-size: 2.2rem;
    text-transform: uppercase;
    &:before {
      display: block;
      content: '';
      width: 40px;
      height: 40px;
      border: 1px solid #000;
      border-radius: 50%;
      margin-right: 14px;
    }
    &:after {
      position: absolute;
      display: none;
      content: '';
      width: 24px;
      height: 24px;
      top: 50%;
      left: 8px;
      transform: translateY(-50%);
      background-color: #000;
      border-radius: 50%;
    }
  }
  &:checked ~ span {
    &:after {
      display: block;
    }
  }
`;

class CreateTradeType extends React.Component<Props & WithNamespaces> {
  onChange = (e: React.SyntheticEvent<HTMLInputElement>) => {
    this.props.onChange((e.target as any).value);
  }
  render() {
    const { t } = this.props;
    return (
      <Container>
        <Title>{t('TRADE_PAGE_I_WANT_TO')}</Title>
        <RadioWrapper>
          <RadioItem>
            <RadioInput type="radio" name="type" value="sell" onChange={this.onChange} />
            <span>{t('HEADER_MENU_SELL_CRYPTO_LABEL')}</span>
          </RadioItem>
          <RadioItem>
            <RadioInput type="radio" name="type" value="buy" onChange={this.onChange} />
            <span>{t('HEADER_MENU_BUY_CRYPTO_LABEL')}</span>
          </RadioItem>
        </RadioWrapper>
      </Container>
    );
  }
}

export default withNamespaces()(CreateTradeType);