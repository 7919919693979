import React from 'react';
import styled from 'styled-components';
import bannerImg from '../../images/common/auth_modal_banner.jpg';
import logoImg from '../../images/common/logo_white.svg';
import screens from '../../constants/screens';

const Container = styled.div`
  position: relative;
  display: flex;
  max-width: 1400px;
  width: 100%;
  margin: 0 20px;
  background-color: #fff;
  border-radius: 3px;
  box-shadow: 2px 2px 10px 0 rgba(0,0,0, .2);
  overflow: hidden;
  min-height: 1000px;
  ${screens.laptopHeight} {
    min-height: 0;
    height: 100%;
    max-height: calc(100vh - 48px);
  }
`;

const ButtonClose = styled.button`
  position: absolute;
  width: 40px;
  height: 40px;
  top: 5px;
  right: 5px;
  &:after,
  &:before {
    position: absolute;
    display: block;
    content: '';
    width: 20px;
    height: 1px;
    background-color: #a0a0a0;
    top: 50%;
    left: 50%;
  }
  &:after {
    transform: translate(-50%, -50%) rotate(45deg);
  }
  &:before {
    transform: translate(-50%, -50%) rotate(-45deg);
  }
`;

const Banner = styled.div`
  position: relative;
  flex-basis: 48%;
  width: 48%;
  background-image: url('${bannerImg}');
  background-size: cover;
  background-position: left center;
  background-repeat: no-repeat;
  ${screens.lg} {
    display: none;
  }
`;

const Logo = styled.img`
  position: absolute;
  top: 40px;
  left: 40px;
  max-width: 220px;
  ${screens.tablet} {
    max-width: 190px;
  }
`

const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-basis: 52%;
  width: 52%;
  padding: 96px 115px 128px 80px;
  overflow-y: auto;
  ${screens.laptopHeight} {
    padding-top: 40px;
    padding-bottom: 64px;
  }
  ${screens.lg} {
    flex-basis: 100%;
    width: 100%;
  }
  ${screens.mobile} {
    padding: 50px 24px;
  }
`;

interface Props {
  children: React.ReactNode;
  onClose: () => void
}

class AuthModal extends React.Component<Props> {
  onClose = () => {
    this.props.onClose();
  }
  render() {
    const { children } = this.props;
    return (
      <Container>
        <ButtonClose onClick={this.onClose} />
        <Banner>
          <Logo src={logoImg} />
        </Banner>
        <FormWrapper>
          {children}
        </FormWrapper>
      </Container>
    )
  }
}

export default AuthModal;